import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "videojs-youtube";

const VideoPlayer = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video");
      videoElement.setAttribute("class", "video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady?.(player);
      }));
    } else {
      const player = playerRef.current;

      player.src(options.sources);
    }
  }, [options]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player={true} ref={videoRef} style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          backgroundImage: "rgb(0,0,0,0)",
          top: "0px",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      ></div>
    </div>
  );
};

export default VideoPlayer;
