import { injectable } from "tsyringe";
import { FaqRepository } from "../../app/repository/api/FaqRespository";
import { dataFaq } from "../../entities/Faq";

@injectable()
export class FaqPresenter {
  private repository: FaqRepository;
  constructor(repository: FaqRepository) {
    this.repository = repository;
  }

  public getAllFaqPublic(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<dataFaq[]> {
    return this.repository.getAllFaqPublic(page, rows, keyword);
  }

  public getAllFaq(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<dataFaq[]> {
    return this.repository.getAllFaq(page, rows, keyword);
  }

  public storeFaq(
    pertanyaan: string,
    jawaban: string,
    status_publish: boolean,
  ): Promise<any> {
    return this.repository.storeFaq(pertanyaan, jawaban, status_publish);
  }

  public updateFaq(
    id: number,
    pertanyaan: string,
    jawaban: string,
    status_publish: boolean,
  ): Promise<any> {
    return this.repository.updateFaq(id, pertanyaan, jawaban, status_publish);
  }

  public deleteFaq(id: number): Promise<any> {
    return this.repository.deleteFaq(id);
  }
}
