import {
  Box,
  Container,
  Divider,
  Flex,
  Image,
  Img,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import PintarIcon1 from "../../assets/svg/pintar_1.svg";
import PintarIcon2 from "../../assets/svg/pintar_2.svg";
import AboutImage from "../../assets/webp/about-image.webp";

const AboutComponent: React.FC = () => {
  return (
    <Box bg="white" bgGradient="linear(white 0%,  blue.50 90%)" pb="10">
      <Container maxW="8xl">
        <Flex
          my={{ base: 8, md: 20 }}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          gap={"10"}
        >
          <Box
            w={{ base: "100%", md: "49%" }}
            display="flex"
            justifyContent="center"
          >
            <Image
              w={{ base: "90%", md: "auto", xl: "80%" }}
              fallback={<Skeleton h="full" w="80%" />}
              h="auto"
              maxH="500px"
              src={AboutImage}
              alt="Gambar Tentang Pintar"
              objectFit="cover"
            />
          </Box>
          <Box w={{ base: "100%", md: "49%" }}>
            <Box mb={4}>
              <Text
                fontSize={{ base: "2xl", md: "5xl" }}
                fontWeight="bold"
                mt={{ base: "1rem", md: "0rem" }}
              >
                Tentang <span style={{ color: "#1E40AF" }}>I-Course</span>
              </Text>
              <Text fontSize="md" mt={2} lineHeight="base" fontWeight="medium">
                Lorem ipsum dolor sit amet consectetur. Nisi turpis vitae nullam
                scelerisque. Nulla curabitur orci integer pharetra massa id
                vulputate in. Odio condimentum montes maecenas rutrum placerat.
                At mi sed iaculis sit lacus. Vel fusce tortor dictumst sed.
                Consectetur tincidunt viverra pharetra ullamcorper pharetra
                ornare montes proin. Odio sed morbi vel sodales nisl a proin
                ultrices massa. .
              </Text>
            </Box>
            <Divider my={{ base: 4, md: 6 }} />
            <Flex flexDirection={["row"]} justifyContent="space-between">
              <Box w={{ base: "48%", md: "50%" }} mb={[4, 0]} mr={"10"}>
                <Img src={PintarIcon1} w={12} h={12} />
                <Text
                  mt={2}
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="semibold"
                >
                  Lorem ipsum dolor sit amet consectetur.
                </Text>
              </Box>
              <Box w={{ base: "48%", md: "50%" }}>
                <Img src={PintarIcon2} w={12} h={12} />
                <Text
                  mt={2}
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="semibold"
                >
                  Lorem ipsum dolor sit amet consectetur.
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default AboutComponent;
