import { Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import history from "../../../../history";
import { SetLocalStorage } from "../../../misc/UseLocalStorage";

const Redirect = () => {
  const signIn = useSignIn();
  const toast = useToast();

  const getMe = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token") ?? "";
    const expiresIn = Number(searchParams.get("expires_in"));
    const appCode = searchParams.get("appCode") ?? "";

    const baseUrl = process.env.REACT_APP_BE_PROD_BASE_URL;

    const url = appCode === "pintar" ? "public/redirect" : "auth/me";

    try {
      const config =
        appCode === "pintar"
          ? {
              // Jika appCode adalah "pintar", tidak gunakan header Authorization
              params: {
                token: token,
              },
            }
          : {
              // Jika appCode bukan "pintar", gunakan header Authorization
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

      const res = await axios.get(baseUrl + url, config);

      const { data } = res.data;

      // set foto profile
      SetLocalStorage({
        name: "path_photo",
        value: data.path_foto,
      });

      // set level
      SetLocalStorage({ name: "level", value: data.level });

      // set status pengisian
      SetLocalStorage({
        name: "status_pengisian",
        value: data.m_peserta.status_pengisian,
      });

      const user = {
        id: data.id,
        username: data.name,
        nip: data?.nip,
        nik: data?.nik,
        email: data?.email,
        level: data?.level,
        jenis_user: data?.jenis_user,
      };

      if (
        signIn({
          token: appCode === "pintar" ? data?.access_token : token,
          expiresIn: appCode === "pintar" ? data?.expires_in : expiresIn,
          tokenType: "Bearer",
          authState: user,
        })
      ) {
        switch (data.level) {
          case "superadmin":
            history.replace(`/admin/dashboard`);
            break;
          case "admin":
            history.replace(`/admin/dashboard`);
            break;
          case "peserta":
            history.replace("/");
            break;

          default:
            break;
        }

        window.location.reload();
      } else {
        toast({
          title: `Gagal Autentikasi, silahkan hubungi admin!`,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error: any) {
      const dataError = error?.response?.data;
      const { nama, nip, nik, email, path_foto, jenis_user } = dataError?.data;

      toast({
        title:
          error?.response?.data?.message?.error ??
          "Gagal Autentikasi, silahkan coba lagi!",
        position: "top-right",
        isClosable: true,
        status: "error",
      });

      setTimeout(() => {
        window.location.href = `/register?name=${nama}&nip=${nip}&nik=${nik}&email=${email}&pathFoto=${path_foto}&jenisUser=${jenis_user}&autoFill=${true}`;
      }, 2000);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <Text mt={"12"} ml={"12"} fontSize="md">
      Sedang dalam proses autentikasi. Mohon tunggu sebentar...
    </Text>
  );
};

export default Redirect;
