/**
 * ListPodcast component displays a list of podcasts with their details and controls.
 */

import {
  Box,
  Divider,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsDot, BsThreeDots } from "react-icons/bs";
import { GetAuthData } from "../../../misc/GetAuthData";
import { usePodcastContext } from "../../controller/podcast";
import Pagination from "../Pagination";
import DownloadPodcast from "./DownloadPodcast";
import ModalShareAudio from "./ModalShareAudio";
import SharePodcast from "./SharePodcast";

/**
 * ListPodcast component.
 * @component
 * @returns {React.FC} The ListPodcast component.
 * @author Muhammad Farras Jibran
 */

const Loader = () => {
  return (
    <Box display="flex" mb={2}>
      <Skeleton h="`100px" w="80px" mr={4} />
      <Box display="flex" flexDirection="column">
        <Skeleton h="20px" w={{ base: "100px", md: "500px" }} mb={2} />
        <Skeleton h="20px" w={{ base: "150px", md: "700px" }} mb={2} />
        <Skeleton h="20px" w={{ base: "200px", md: "900px" }} />
      </Box>
    </Box>
  );
};

const ListPodcast: React.FC = () => {
  const [expandedItem, setExpandedItem] = useState<number | null>(null);

  const {
    renderPlayPauseIcon,
    togglePlayPause,
    fetchData,
    getDetailPodcast,
    setPodcastState,
  } = usePodcastContext();
  const {
    listData,
    loadingData,
    page,
    pagination,
    perPage,
    listCategorySelected,
  } = usePodcastContext().state;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const useAuth = GetAuthData();

  const formattedDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePageChange = (e) => {
    setPodcastState("page", e.selected + 1);
  };

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const trackId = searchParams.get("track");

    if (trackId) {
      getDetailPodcast(trackId);
    } else {
      fetchData();
    }
  }, [window.location.href, page, listCategorySelected]);

  return (
    <>
      <Box my={4} mx={{ base: 8, md: 12 }}>
        {loadingData ? (
          <>
            <Loader />
            <Loader />
          </>
        ) : !listData.length ? (
          <Box mx="auto" textAlign="center" my={20}>
            <Text fontSize="xl" color="gray.400">
              Belum Ada Podcast
            </Text>
          </Box>
        ) : (
          <>
            {listData.map((item, i) => (
              <Box key={i}>
                <Flex>
                  <Box w={{ base: "70px", md: "200px" }}>
                    <Image
                      src={item?.thumbnail}
                      alt={`Gambar ${item.title}`}
                      objectFit="cover"
                      w="full"
                      h={{ base: "70px", md: "200px" }}
                      mb={4}
                      rounded="md"
                      fallback={<Skeleton h="full" w="full" />}
                    />
                  </Box>
                  <Box w="70%" mx={4} mt={{ base: 0, md: 4 }}>
                    <Text
                      fontSize={{ base: "md", md: "xl" }}
                      fontWeight="semibold"
                      color="black"
                    >
                      {item.title}
                    </Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description ?? "",
                      }}
                    />

                    <Flex
                      alignItems="center"
                      py={4}
                      justifyContent="space-between"
                    >
                      <Box as={Flex} alignItems="center">
                        <Icon
                          id="play-pause-podcast"
                          as={renderPlayPauseIcon(item)}
                          boxSize={8}
                          mr={4}
                          onClick={() => {
                            if (useAuth !== null) {
                              togglePlayPause(item);
                            } else {
                              window.location.href = "/login";
                            }
                          }}
                          _hover={{ cursor: "pointer" }}
                          color="black"
                        />
                        <Box display="flex" alignItems="center">
                          <Text color="black">
                            {moment(item?.created_at).format("MMM YYYY")}
                          </Text>
                          <Icon as={BsDot} boxSize={4} color="black" />
                          <Text color="black">
                            {formattedDuration(item?.duration)} min
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        display={{ base: "none", md: "flex" }}
                        alignItems="center"
                      >
                        <Box mr={4}>
                          <SharePodcast id={item.id} onOpen={onOpen} />
                        </Box>
                        <Box>
                          <DownloadPodcast
                            title={item.title}
                            audio={item.audio}
                          />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box
                    display={{ base: "block", md: "none" }}
                    position="relative"
                  >
                    <Icon
                      as={BsThreeDots}
                      boxSize={5}
                      color="black"
                      onClick={() => {
                        setExpandedItem(
                          item.id === expandedItem ? null : item.id,
                        );
                      }}
                    />
                    {item.id === expandedItem && (
                      <Box
                        position="absolute"
                        boxShadow="sm"
                        borderRadius="md"
                        p={4}
                        top={4}
                        right={1}
                      >
                        <Box mb={3}>
                          <SharePodcast id={item.id} onOpen={onOpen} />
                        </Box>
                        <DownloadPodcast
                          title={item.title}
                          audio={item.audio}
                        />
                      </Box>
                    )}
                  </Box>
                </Flex>
                {i !== listData.length - 1 && <Divider mb={4} />}
              </Box>
            ))}
            {pagination && totalPage > 1 && (
              <Flex justifyContent="end" mt={2}>
                <Pagination
                  handleChange={handlePageChange}
                  totalPage={totalPage}
                  page={page}
                />
              </Flex>
            )}
          </>
        )}
      </Box>

      <ModalShareAudio isOpen={isOpen} onClose={onClose} />
    </>
  );
};
export default ListPodcast;
