import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import parse from "html-react-parser";
import React from "react";
import { useDetailPelatihanKontenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/detail/konten_pelatihan";

/**
 * The TabsMateri component is used to display detailed information about the training material (materi) of a sub-module.
 * This component utilizes information obtained from `useDetailPelatihanKontenPelatihanContext`.
 * @component
 * @author Muhammad Farras Jibran
 */

const TabsMateri = () => {
  const { detailMateri } = useDetailPelatihanKontenPelatihanContext().state;

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={2}>
        {detailMateri?.judul_materi}
      </Text>
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={{ base: 3, md: 2 }}>
          <Text fontSize="sm" color="blue" fontWeight="semibold">
            Jenis Pelatihan
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 5, md: 10 }}>
          <Text fontSize="sm">: {detailMateri?.jenis_pelatihan}</Text>
        </GridItem>
        <GridItem colSpan={{ base: 3, md: 2 }} fontWeight="semibold">
          <Text fontSize="sm" color="blue">
            Jam Pelatihan
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 5, md: 10 }}>
          <Text fontSize="sm">
            : {detailMateri?.jam_pelatihan} Jam Pelatihan
          </Text>
        </GridItem>
      </Grid>
      <Text
        fontSize="sm"
        textAlign="justify"
        color="gray.500"
        mt={3}
        mb={5}
        fontWeight="normal"
      >
        {detailMateri != null ? parse(detailMateri.uraian_deskripsi) : null}
      </Text>
    </Box>
  );
};

export default TabsMateri;
