import { Switch, Text } from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import AWS from "aws-sdk";
import React, { useEffect } from "react";
import { Else, If, Then } from "react-if";
import ReactLoading from "react-loading";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { PelatihanSimdiklat } from "../../../../../../entities/Simdiklat";
import EditorComponent from "../../../../components/Admin/Editor";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import ManajemenPelatihanCreateController, {
  useManajemenPelatihanCreateContext,
} from "../../../../controller/admin/manajemen_pelatihan/create";
import SimdiklatController, {
  useSimdiklatContext,
} from "../../../../controller/simdiklat";

const BottomHeader: React.FC = () => {
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Tambah Pelatihan</a>
      </div>
    </div>
  );
};

const Form: React.FC = () => {
  // use manajemen jenis pelatihan create context
  const {
    createPelatihan,
    setPathThumbnail,
    setJenisPelatihanId,
    setSubMenuJenisPelatihanId,
    setWaktuMulai,
    setWaktuSelesai,
    setWaktuMulaiPendaftaran,
    setWaktuSelesaiPendaftaran,
    setPenyelenggara,
    setInformasiUmum,
    setKompetensiTeknis,
    setPersyaratan,
    setAngkatan,
    getJenisPelatihan,
    getSubMenuJenisPelatihan,
    setIsPPKB,
    setCreateManajemenPelatihan,
  } = useManajemenPelatihanCreateContext();

  const {
    isLoading,
    jenisPelatihan,
    subMenuJenisPelatihan,
    jenis_pelatihan_id,
    angkatan,
    waktu_mulai,
    waktu_selesai,
    penyelenggara,
    msgError,
    isPPKB,
    materiInti,
  } = useManajemenPelatihanCreateContext().state;

  // use simdiklat context
  const { getListPelatihanByIdSimdiklat } = useSimdiklatContext();

  let optionsJenisPelatihan;

  if (jenisPelatihan.length > 0) {
    optionsJenisPelatihan = jenisPelatihan.map((item) => ({
      value: item.id,
      label: item.nama_pelatihan,
    }));
  } else {
    optionsJenisPelatihan = [];
  }

  let optionsSubMenuJenisPelatihan;

  if (subMenuJenisPelatihan.length > 0) {
    optionsSubMenuJenisPelatihan = subMenuJenisPelatihan.map((item) => ({
      value: item.id,
      label: item.judul_menu,
    }));
  } else {
    optionsSubMenuJenisPelatihan = [];
  }

  useEffect(() => {
    getSubMenuJenisPelatihan();
  }, []);

  const checkJudulPelatihan = async (event) => {
    setJenisPelatihanId(event.value);
    const jenisPelatihanData = jenisPelatihan.filter(
      (val) => val.id === event.value,
    );

    if (jenisPelatihanData[0].metode_penambahan === 2) {
      await getListPelatihanByIdSimdiklat(jenisPelatihanData[0].kode).then(
        (data: PelatihanSimdiklat) => {
          setAngkatan(data.angkatan);
          setWaktuMulai(data.tanggal_mulai + "T00:00");
          setWaktuSelesai(data.tanggal_selesai + "T00:00");
          setPenyelenggara(data.balai);
        },
      );
    }
  };

  return (
    <div className="bg-white rounded-lg px-8 py-5 mt-5">
      {msgError.length > 0 && (
        <div
          className="mb-4 text-sm text-red-700 bg-red-100 rounded-lg p-4 text-left"
          role="alert"
        >
          <ul className="list-disc px-5">
            {msgError.map((val) =>
              Object.keys(val).map((u, key) => {
                return <li key={key}>{val[u]}</li>;
              }),
            )}
          </ul>
        </div>
      )}
      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Jenis Pelatihan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
          <Select
            options={optionsSubMenuJenisPelatihan}
            onChange={(val: any) => {
              setSubMenuJenisPelatihanId(val.value);
              getJenisPelatihan(val.value);
              setJenisPelatihanId(0);
            }}
            placeholder="Pilih Jenis Pelatihan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Cover thumbnail
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
          <input
            type="file"
            className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            id="exampleInputEmail1"
            onChange={(e) => setPathThumbnail(e.target.files)}
            placeholder="Masukkan Judul Pelatihan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Judul Pelatihan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <Select
            options={optionsJenisPelatihan}
            onChange={(val: any) => checkJudulPelatihan(val)}
            value={optionsJenisPelatihan.filter(
              (val) => val.value === jenis_pelatihan_id,
            )}
            placeholder="Pilih Judul Pelatihan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Angkatan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
          <input
            type="text"
            className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setAngkatan(e.target.value)}
            value={angkatan}
            placeholder="Masukkan Angkatan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Waktu Pelaksanaan
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="datetime-local"
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setWaktuMulai(e.target.value)}
            value={waktu_mulai}
            placeholder="Masukkan Waktu Pelatihan"
          />
        </div>

        <div className="col-span-1 text-center">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            sd
          </label>
        </div>

        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="datetime-local"
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setWaktuSelesai(e.target.value)}
            value={waktu_selesai}
            placeholder="Masukkan Waktu Pelatihan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 ">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Waktu Pendaftaran
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="datetime-local"
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setWaktuMulaiPendaftaran(e.target.value)}
            placeholder="Masukkan Waktu Pelatihan"
          />
        </div>

        <div className="col-span-1 text-center">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            sd
          </label>
        </div>

        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="datetime-local"
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setWaktuSelesaiPendaftaran(e.target.value)}
            placeholder="Masukkan Waktu Pelatihan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Penyelenggara
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <input
            type="text"
            className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setPenyelenggara(e.target.value)}
            value={penyelenggara}
            placeholder="Masukkan Penyelenggara"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Bimtek PPKB
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <div className="flex items-center h-full">
            <Switch
              colorScheme="blue"
              isChecked={isPPKB}
              onChange={() => setIsPPKB(!isPPKB)}
            />
            <Text fontSize="sm" ml={3}>
              {isPPKB ? "Ya" : "Tidak"}
            </Text>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-8">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Informasi Umum
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <Editor
            initialValue=""
            init={{
              height: 180,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help | image",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              images_upload_handler: async function (
                blobInfo: any,
                success: any,
                failure: any,
              ) {
                const S3_BUCKET = "pusdiklat-lms";
                const REGION = "sgp1";
                const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                const s3Credentials = new AWS.Credentials({
                  accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                  secretAccessKey:
                    "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                });

                const myBucket = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  endpoint: ENDPOINT,
                  region: REGION,
                  credentials: s3Credentials,
                });

                function makeid(length) {
                  let result = "";
                  const characters =
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                  const charactersLength = characters.length;
                  for (let i = 0; i < length; i++) {
                    result += characters.charAt(
                      Math.floor(Math.random() * charactersLength),
                    );
                  }
                  return result;
                }

                try {
                  const nameFile =
                    makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                  const params = {
                    ACL: "public-read",
                    Body: blobInfo.blob(),
                    Bucket: S3_BUCKET,
                    Key: nameFile,
                  };

                  myBucket
                    .putObject(params)
                    .on("httpUploadProgress", (evt) => {
                      console.log(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .send((err, data) => {
                      if (err) {
                        failure(err);
                      } else {
                        success(
                          "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                            nameFile,
                        );
                      }
                    });
                } catch (error: any) {
                  console.log(error);
                  failure(error);
                  return;
                }
              },
            }}
            onEditorChange={(val) => setInformasiUmum(val)}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Persyaratan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <Editor
            initialValue=""
            init={{
              height: 180,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help | image",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              images_upload_handler: async function (
                blobInfo: any,
                success: any,
                failure: any,
              ) {
                const S3_BUCKET = "pusdiklat-lms";
                const REGION = "sgp1";
                const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                const s3Credentials = new AWS.Credentials({
                  accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                  secretAccessKey:
                    "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                });

                const myBucket = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  endpoint: ENDPOINT,
                  region: REGION,
                  credentials: s3Credentials,
                });

                function makeid(length) {
                  let result = "";
                  const characters =
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                  const charactersLength = characters.length;
                  for (let i = 0; i < length; i++) {
                    result += characters.charAt(
                      Math.floor(Math.random() * charactersLength),
                    );
                  }
                  return result;
                }

                try {
                  const nameFile =
                    makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                  const params = {
                    ACL: "public-read",
                    Body: blobInfo.blob(),
                    Bucket: S3_BUCKET,
                    Key: nameFile,
                  };

                  myBucket
                    .putObject(params)
                    .on("httpUploadProgress", (evt) => {
                      console.log(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .send((err, data) => {
                      if (err) {
                        failure(err);
                      } else {
                        success(
                          "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                            nameFile,
                        );
                      }
                    });
                } catch (error: any) {
                  console.log(error);
                  failure(error);
                  return;
                }
              },
            }}
            onEditorChange={(val) => setPersyaratan(val)}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Kompetensi Teknis
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <Editor
            initialValue=""
            init={{
              height: 180,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help | image",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              images_upload_handler: async function (
                blobInfo: any,
                success: any,
                failure: any,
              ) {
                const S3_BUCKET = "pusdiklat-lms";
                const REGION = "sgp1";
                const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                const s3Credentials = new AWS.Credentials({
                  accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                  secretAccessKey:
                    "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                });

                const myBucket = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  endpoint: ENDPOINT,
                  region: REGION,
                  credentials: s3Credentials,
                });

                function makeid(length) {
                  let result = "";
                  const characters =
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                  const charactersLength = characters.length;
                  for (let i = 0; i < length; i++) {
                    result += characters.charAt(
                      Math.floor(Math.random() * charactersLength),
                    );
                  }
                  return result;
                }

                try {
                  const nameFile =
                    makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                  const params = {
                    ACL: "public-read",
                    Body: blobInfo.blob(),
                    Bucket: S3_BUCKET,
                    Key: nameFile,
                  };

                  myBucket
                    .putObject(params)
                    .on("httpUploadProgress", (evt) => {
                      console.log(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .send((err, data) => {
                      if (err) {
                        failure(err);
                      } else {
                        success(
                          "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                            nameFile,
                        );
                      }
                    });
                } catch (error: any) {
                  console.log(error);
                  failure(error);
                  return;
                }
              },
            }}
            onEditorChange={(val) => setKompetensiTeknis(val)}
          />
        </div>
      </div>

      {isPPKB && (
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              Materi Inti
            </label>
          </div>
          <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
            <EditorComponent
              description={materiInti}
              setDescription={setCreateManajemenPelatihan}
              keyState="materiInti"
              bgColor="#fff"
            />
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <button
          className="px-6 py-2.5 bg-green-600 text-white font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 "
          onClick={() => createPelatihan()}
        >
          <If condition={isLoading}>
            <Then>
              <ReactLoading
                type={"bars"}
                color={"white"}
                height={15}
                width={20}
              />
            </Then>
            <Else>Selanjutnya</Else>
          </If>
        </button>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <div className="app">
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <BottomHeader />
        </header>

        <div className="container px-4 md:px-12 mb-10">
          <div className="bg-white rounded-lg ">
            <ul className="stepper pb-3 rounded-lg" data-mdb-stepper="stepper">
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{
                      width: "37px",
                      height: "37px",
                      backgroundColor: "#0DBD7F",
                    }}
                  >
                    01
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Data Pelatihan
                  </span>
                </div>
              </li>
              <li className="stepper-step">
                <a>
                  <div className="stepper-head">
                    <span
                      className="stepper-head-icon font-semibold"
                      style={{ width: "37px", height: "37px" }}
                    >
                      02
                    </span>
                    <span className="stepper-head-text font-semibold">
                      Kelola Konten
                    </span>
                  </div>
                </a>
              </li>
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{ width: "37px", height: "37px" }}
                  >
                    03
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Penilaian
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <Form />
        </div>
      </main>
    </div>
  );
};

const CreateManajemenPelatihan: React.FC = () => {
  return (
    <ManajemenPelatihanCreateController.Provider>
      <SimdiklatController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <SidebarWithHeader title="Manajemen Pelatihan">
          <WrapperMain />
        </SidebarWithHeader>
      </SimdiklatController.Provider>
    </ManajemenPelatihanCreateController.Provider>
  );
};

export default CreateManajemenPelatihan;
