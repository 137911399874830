import "@fontsource/poppins";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import moment from "moment";
import "moment/locale/id";
import React from "react";
import ReactDOM from "react-dom";
import "react-multi-carousel/lib/styles.css";
import "reflect-metadata";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "tw-elements";
import { AppComponent } from "./app/di/AppComponent";
import "./app/ui/assets/admin/style.scss";
import "./app/ui/assets/css/index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./router";

moment.locale("id");

// Allow console
console.warn = () => {};
// console.log = () => {};

AppComponent.init();

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
