import { injectable } from "tsyringe";
import { UsersApiRepository } from "../../app/repository/api/UsersApiRepository";
import { Users } from "../../entities/User";

@injectable()
export class UsersPresenter {
  private repository: UsersApiRepository;
  constructor(repository: UsersApiRepository) {
    this.repository = repository;
  }

  public getAllUsers(
    page: number,
    rows: number,
    keyword: string,
    level: string,
  ): Promise<Users[]> {
    return this.repository.getAllUsers(page, rows, keyword, level);
  }

  public getUser(): Promise<any> {
    return this.repository.getUser();
  }

  public updateProfile(
    name: string,
    email: string,
    pangkat: string,
    golongan: string,
    jabatan: string,
    instansi: string,
    tempat_tugas: string,
    nik: string,
    jenis_kelamin: string,
    tempat_lahir: string,
    tanggal_lahir: string,
    provinsi_id: number,
    kabupaten_id: number,
    kecamatan_id: number,
    kelurahan_id: number,
    alamat: string,
    no_hp: string,
    agama: string,
    status_kawin: string,
    status_pegawai: string,
  ): Promise<any> {
    return this.repository.updateProfile(
      name,
      email,
      pangkat,
      golongan,
      jabatan,
      instansi,
      tempat_tugas,
      nik,
      jenis_kelamin,
      tempat_lahir,
      tanggal_lahir,
      provinsi_id,
      kabupaten_id,
      kecamatan_id,
      kelurahan_id,
      alamat,
      no_hp,
      agama,
      status_kawin,
      status_pegawai,
    );
  }

  public updatePassword(old_password: string, password: string): Promise<any> {
    return this.repository.updatePassword(old_password, password);
  }

  public updatePhoto(path_photo: File): Promise<any> {
    return this.repository.updatePhoto(path_photo);
  }

  public updateProfileSimpeg(): Promise<any> {
    return this.repository.updateProfileSimpeg();
  }

  public checkNip(nip: string): Promise<any> {
    return this.repository.checkNip(nip);
  }

  public getAllManagemenUser(
    page: number,
    rows: number,
    keyword: string,
    internal: boolean,
    peserta: boolean,
  ): Promise<any> {
    return this.repository.getAllManagemenUser(
      page,
      rows,
      keyword,
      internal,
      peserta,
    );
  }

  public createManagemenUser(
    name: string,
    email: string,
    nik: string,
    nip: string,
    jabatan: string,
    level: string,
    phone_number: string,
    password: string,
    password_confirmation: string,
    path_foto: File,
    is_kemenag: any,
  ): Promise<any> {
    return this.repository.createManagemenUser(
      name,
      email,
      nik,
      nip,
      jabatan,
      level,
      phone_number,
      password,
      password_confirmation,
      path_foto,
      is_kemenag,
    );
  }

  public updateManagemenUser(
    id: number,
    name: string,
    email: string,
    nik: string,
    nip: string,
    jabatan: string,
    level: string,
    phone_number: string,
    password: string,
    password_confirmation: string,
    path_foto: File,
    is_kemenag: any,
  ): Promise<any> {
    return this.repository.updateManagemenUser(
      id,
      name,
      email,
      nik,
      nip,
      jabatan,
      level,
      phone_number,
      password,
      password_confirmation,
      path_foto,
      is_kemenag,
    );
  }

  public deleteManagemenUser(id: number): Promise<any> {
    return this.repository.deleteManagemenUser(id);
  }

  public resetPasswordManagemenUser(user_id: number): Promise<any> {
    return this.repository.resetPasswordManagemenUser(user_id);
  }
}
