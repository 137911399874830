import React from "react";
import { container } from "tsyringe";
import { KecamatanPresenter } from "../../../../../data/presenters/KecamatanPresenter";
import { Kecamatan } from "../../../../../entities/Kecamatan";

interface IState {
  isLoading: boolean;
  kecamatan: Kecamatan[];
  id: number;
  page: number;
  keyword: string;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  getKecamatanById: Function;
  getAllKecamatan: Function;
  onChangeKabupaten: Function;
}

const initialState = {
  state: {
    isLoading: false,
    kecamatan: [],
    id: 0,
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  },
  getKecamatanById: () => {},
  getAllKecamatan: () => {},
  onChangeKabupaten: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: KecamatanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    kecamatan: [],
    id: 0,
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  // Get Data kecamatan
  const getAllKecamatan = async () => {
    setLoading(true);

    try {
      const kecamatanPresenter = container.resolve(KecamatanPresenter);
      const get = await kecamatanPresenter.getAllKecamatan(
        state.page,
        999,
        state.keyword,
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        kecamatan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data kecamatan:", error);
      setLoading(false);
    }
  };

  // Get Data kecamatan By ID
  const getKecamatanById = async (id: number) => {
    setLoading(true);

    try {
      const kecamatanPresenter = container.resolve(KecamatanPresenter);
      const get = await kecamatanPresenter.getKecamatanById(id);

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        kecamatan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data kecamatan:", error);
      setLoading(false);
    }
  };

  // Handle On Change Kabupaten
  const onChangeKabupaten = (value) => {
    getKecamatanById(value);
  };

  return (
    <KecamatanProvider
      value={{
        state,
        getKecamatanById,
        getAllKecamatan,
        onChangeKabupaten,
      }}
    >
      {children}
    </KecamatanProvider>
  );
};

export const useKecamatanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useKecamatanContext,
  Provider,
};
