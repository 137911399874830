import { AxiosResponse } from "axios";
import {
  AgamaMasterSimdiklat,
  JabatanMasterSimdiklat,
  PangkatMasterSimdiklat,
  PelatihanSimdiklat,
  StatusKawinMasterSimdiklat,
  StatusPegawaiMasterSimdiklat,
} from "../../entities/Simdiklat";
import { LogTTE } from "../../entities/LogTTE";

export class SimdiklatDataMapper {
  /**
   *
   * @param result
   * @returns
   */
  public convertPelatihanSimdiklatDataFromApi(
    result: AxiosResponse<any>,
  ): PelatihanSimdiklat[] {
    const { data } = result;

    const pelatihanSimdiklat: PelatihanSimdiklat[] = [];

    data.data.map((s: any) => {
      return pelatihanSimdiklat.push(
        new PelatihanSimdiklat(
          s.nama_diklat_id,
          s.nama_diklat_nama,
          s.nama_diklat_jenis_diklat_id,
          s.nama_diklat_nama,
          s.tahun,
          s.tanggal_mulai,
          s.tanggal_selesai,
          s.tempat_penyelenggara_id,
          s.tempat_penyelenggara_nama,
          s.lokasi,
          s.pola_diklat_tipe_id,
          s.pola_diklat_tipe_value,
          s.penyelenggara_diklat_id,
          s.penyelenggara_diklat_value,
          s.rencana_anggaran,
          s.realisasi_anggaran,
          s.surat_panggilan,
          s.angkatan,
          s.balai_id,
          s.balai,
          s.sistem_penilaian_peserta_id,
          s.sistem_penilaian_penyelenggara_id,
          s.sistem_penilaian_narasumber_id,
          s.master_template_sertifikat_pelatihan_id,
          s.master_template_surat_keterangan_id,
          s.master_template_sttp_id,
          s.master_template_dikjartih_pernyataan_id,
          s.master_template_dikjartih_penugasan_id,
          s.branches,
          s.diklat_panitia,
          s.id,
          s.created_by,
          s.updated_by,
          s.active,
          s.created_at,
          s.updated_at,
          s.allow_edit,
          s.allow_delete,
        ),
      );
    });

    return pelatihanSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertPelatihanDetailSimdiklatDataFromApi(
    result: AxiosResponse<any>,
  ): PelatihanSimdiklat {
    const { data } = result;

    let pelatihanSimdiklat: PelatihanSimdiklat;

    pelatihanSimdiklat = new PelatihanSimdiklat(
      data.data[0].nama_diklat_id,
      data.data[0].nama_diklat_nama,
      data.data[0].nama_diklat_jenis_diklat_id,
      data.data[0].nama_diklat_nama,
      data.data[0].tahun,
      data.data[0].tanggal_mulai,
      data.data[0].tanggal_selesai,
      data.data[0].tempat_penyelenggara_id,
      data.data[0].tempat_penyelenggara_nama,
      data.data[0].lokasi,
      data.data[0].pola_diklat_tipe_id,
      data.data[0].pola_diklat_tipe_value,
      data.data[0].penyelenggara_diklat_id,
      data.data[0].penyelenggara_diklat_value,
      data.data[0].rencana_anggaran,
      data.data[0].realisasi_anggaran,
      data.data[0].surat_panggilan,
      data.data[0].angkatan,
      data.data[0].balai_id,
      data.data[0].balai,
      data.data[0].sistem_penilaian_peserta_id,
      data.data[0].sistem_penilaian_penyelenggara_id,
      data.data[0].sistem_penilaian_narasumber_id,
      data.data[0].master_template_sertifikat_pelatihan_id,
      data.data[0].master_template_surat_keterangan_id,
      data.data[0].master_template_sttp_id,
      data.data[0].master_template_dikjartih_pernyataan_id,
      data.data[0].master_template_dikjartih_penugasan_id,
      data.data[0].branches,
      data.data[0].diklat_panitia,
      data.data[0].id,
      data.data[0].created_by,
      data.data[0].updated_by,
      data.data[0].active,
      data.data[0].created_at,
      data.data[0].updated_at,
      data.data[0].allow_edit,
      data.data[0].allow_delete,
    );

    return pelatihanSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertMasterDataPangkatFromApi(
    result: AxiosResponse<any>,
  ): PangkatMasterSimdiklat[] {
    const { data } = result;

    const pangkatMasterSimdiklat: PangkatMasterSimdiklat[] = [];

    data.data.map((s: any) => {
      return pangkatMasterSimdiklat.push(
        new PangkatMasterSimdiklat(
          s.kode,
          s.pangkat,
          s.ruang,
          s.id,
          s.created_by,
          s.updated_by,
          s.active,
          s.created_at,
          s.updated_at,
          s.allow_edit,
          s.allow_delete,
        ),
      );
    });

    return pangkatMasterSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertMasterDataJabatanFromApi(
    result: AxiosResponse<any>,
  ): JabatanMasterSimdiklat[] {
    const { data } = result;

    const jabatanMasterSimdiklat: JabatanMasterSimdiklat[] = [];

    data.data.map((s: any) => {
      return jabatanMasterSimdiklat.push(
        new JabatanMasterSimdiklat(
          s.kode,
          s.nama,
          s.singkatan,
          s.id,
          s.created_by,
          s.updated_by,
          s.active,
          s.created_at,
          s.updated_at,
          s.allow_edit,
          s.allow_delete,
        ),
      );
    });

    return jabatanMasterSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertMasterDataAgamaFromApi(
    result: AxiosResponse<any>,
  ): AgamaMasterSimdiklat[] {
    const { data } = result;

    const agamaMasterSimdiklat: AgamaMasterSimdiklat[] = [];

    data.data.map((s: any) => {
      return agamaMasterSimdiklat.push(
        new AgamaMasterSimdiklat(
          s.key,
          s.value,
          s.enumeration_group,
          s.id,
          s.created_by,
          s.updated_by,
          s.active,
          s.created_at,
          s.updated_at,
          s.allow_edit,
          s.allow_delete,
        ),
      );
    });

    return agamaMasterSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertMasterDataStatusKawinFromApi(
    result: AxiosResponse<any>,
  ): StatusKawinMasterSimdiklat[] {
    const { data } = result;

    const statusKawinMasterSimdiklat: StatusKawinMasterSimdiklat[] = [];

    data.data.map((s: any) => {
      return statusKawinMasterSimdiklat.push(
        new StatusKawinMasterSimdiklat(
          s.key,
          s.value,
          s.enumeration_group,
          s.id,
          s.created_by,
          s.updated_by,
          s.active,
          s.created_at,
          s.updated_at,
          s.allow_edit,
          s.allow_delete,
        ),
      );
    });

    return statusKawinMasterSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertMasterDataStatusPegawaiFromApi(
    result: AxiosResponse<any>,
  ): StatusPegawaiMasterSimdiklat[] {
    const { data } = result;

    const statusPegawaiMasterSimdiklat: StatusPegawaiMasterSimdiklat[] = [];

    data.data.map((s: any) => {
      return statusPegawaiMasterSimdiklat.push(
        new StatusPegawaiMasterSimdiklat(
          s.key,
          s.value,
          s.enumeration_group,
          s.id,
          s.created_by,
          s.updated_by,
          s.active,
          s.created_at,
          s.updated_at,
          s.allow_edit,
          s.allow_delete,
        ),
      );
    });

    return statusPegawaiMasterSimdiklat;
  }

  /**
   *
   * @param result
   * @returns
   */
  public convertListLogTTEData(result: AxiosResponse<any>): LogTTE[] {
    const { data } = result.data;

    const logTTE: LogTTE[] = [];

    data.map((d: any) => {
      return logTTE.push(
        new LogTTE(
          d.total,
          d.diproses,
          d.berhasil,
          d.gagal,
          d.judul_pelatihan,
          result.data.pagination.total_rows,
          d.created_at,
          d.updated_at,
          d.waktu_mulai,
          d.waktu_selesai,
        ),
      );
    });

    return logTTE;
  }
}
