import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { db } from "../../../services/firebase";

interface IState {
  isLoadingGetNotifications: boolean;
  notifData: any[];
  idLogChat: string;
}

interface InitialState {
  state: IState;
  getNotifications: Function;
  readAllNotifications: Function;
}

const initialState = {
  state: {
    isLoadingGetNotifications: false,
    notifData: [],
    idLogChat: "",
  },
  getNotifications: () => {},
  readAllNotifications: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: NotificationsProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoadingGetNotifications: false,
    notifData: [],
    idLogChat: "",
  });

  /**
   *
   * @param value
   * @returns
   */
  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingGetNotifications: value,
    }));
    return;
  };

  /**
   *
   * @param id_user
   * @returns
   */
  const getNotifications = (id_user: number) => {
    setLoading(true);

    let logChatRefById;

    if (id_user != null) {
      logChatRefById = query(
        collection(db, "log_chat"),
        orderBy("timestamp", "asc"),
        where("id_user", "==", id_user),
      );
    }

    try {
      // Fetch Docs
      onSnapshot(logChatRefById, (snapshot) => {
        snapshot.docs.map((val) => {
          setState((prevstate) => ({
            ...prevstate,
            idLogChat: val.id,
          }));
          const chatRef = query(
            collection(db, "chat"),
            orderBy("timestamp", "desc"),
            limit(5),
            where("id_log_chat", "==", val.id),
            where("is_admin", "==", true),
          );

          // Get Chat For Notifications
          return onSnapshot(chatRef, (snapshot) => {
            setState((prevstate) => ({
              ...prevstate,
              notifData: [],
            }));

            snapshot.docs.map((doc) => {
              return setState((prevstate) => ({
                ...prevstate,
                notifData: [
                  ...prevstate.notifData,
                  { id: doc.id, data: doc.data() },
                ],
              }));
            });
          });
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      return;
    }
  };

  const readAllNotifications = () => {
    try {
      const is_read = true;
      const chatRef = query(
        collection(db, "chat"),
        where("id_log_chat", "==", state.idLogChat),
        where("is_read", "==", false),
      );

      getDocs(chatRef)
        .then((response) => {
          response.docs.map((val) => {
            return updateDoc(doc(db, "chat", val.id), { is_read })
              .then(() => {})
              .catch((err) => console.log(err));
          });
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return (
    <NotificationsProvider
      value={{
        state,
        getNotifications,
        readAllNotifications,
      }}
    >
      {children}
    </NotificationsProvider>
  );
};

export const useNotificationsContext = () => React.useContext(Context);

export default {
  useNotificationsContext,
  Provider,
};
