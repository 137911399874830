import { AxiosResponse } from "axios";
import { JawabanQuiz, Quiz } from "../../entities/Quiz";

export class QuizDataMapper {
  public convertListQuizDataFromApi(result: AxiosResponse<any>): Quiz[] {
    const { data } = result.data;

    const quiz: Quiz[] = [];

    data.map((q: any) => {
      const jawaban: JawabanQuiz[] = [];

      q.m_jawaban_quiz.map((j: any) => {
        return jawaban.push(
          new JawabanQuiz(
            j.id,
            j.quiz_id,
            j.pilihan,
            j.jawaban_benar,
            j.created_at,
            j.updated_at,
          ),
        );
      });

      return quiz.push(
        new Quiz(
          q.id,
          q.sub_modul_pelatihan_id,
          q.materi_id,
          q.waktu_ke,
          q.pertanyaan,
          q.created_at,
          q.updated_at,
          jawaban,
        ),
      );
    });

    return quiz;
  }

  public convertDetailQuizDataFromApi(result: AxiosResponse<any>): Quiz {
    const { data } = result.data;

    let quiz: Quiz;

    const jawaban: JawabanQuiz[] = [];

    data.m_jawaban_quiz.map((j: any) => {
      return jawaban.push(
        new JawabanQuiz(
          j.id,
          j.quiz_id,
          j.pilihan,
          j.jawaban_benar,
          j.created_at,
          j.updated_at,
        ),
      );
    });

    quiz = new Quiz(
      data.id,
      data.sub_modul_pelatihan_id,
      data.materi_id,
      data.waktu_ke,
      data.pertanyaan,
      data.created_at,
      data.updated_at,
      jawaban,
    );

    return quiz;
  }
}
