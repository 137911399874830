import { Box, Switch, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { GetAuthData } from "../../../../misc/GetAuthData";

interface JoyrideComponentProps {
  steps: any[];
  textColor?: string;
}

/**
 * The JoyrideComponent is a component for displaying interactive guided tours.
 * It allows users to navigate through a sequence of steps with explanations.
 *
 * @param {Props} steps - An array of step objects for the guided tour.
 * @param {string} textColor - The text color for the guided tour.
 * @author Muhammad Farras Jibran
 */

const JoyrideComponent = ({
  steps,
  textColor = "#3c4952",
}: JoyrideComponentProps) => {
  const [run, setRun] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const useAuth = GetAuthData();

  /**
   * Handles callback events from the Joyride component, such as finishing the tour.
   * Scrolls to the top of the page when the tour is finished.
   *
   * @param {CallBackProps} data - Data from the Joyride callback.
   */

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const customStyles = {
    options: {
      zIndex: 10000,
      width: isMobile ? "350px" : "500px",
    },
    buttonBack: {
      backgroundColor: "#ff0000",
      color: "#fff",
      borderRadius: "4px",
    },
    buttonNext: {
      backgroundColor: "#0DBD7F",
    },
    buttonClose: {
      display: "none",
    },
  };

  useEffect(() => {
    const navbar = document.querySelector(".navbarMenu") as HTMLElement;
    if (navbar !== null && run) {
      navbar.style.position = "relative";
    }

    // Cleanup function
    return () => {
      if (navbar !== null) {
        navbar.style.position = "sticky";
      }
    };
  }, [run]);

  if (useAuth === null) {
    return null;
  }

  return (
    <>
      <Box display="flex">
        {isMobile && (
          <Switch
            isChecked={run}
            colorScheme="blue"
            onChange={() => setRun(!run)}
            mr={{ base: 4, md: 0 }}
          />
        )}
        <Text
          fontWeight="semibold"
          fontSize="sm"
          mr={{ base: 0, md: 4 }}
          color={textColor}
        >
          Aktifkan Tutorial
        </Text>
        {!isMobile && (
          <Switch
            isChecked={run}
            colorScheme="blue"
            onChange={() => setRun(!run)}
          />
        )}
      </Box>
      <Box display="none">
        <Joyride
          steps={steps}
          run={run}
          continuous={true}
          showProgress={true}
          callback={handleJoyrideCallback}
          styles={customStyles}
        />
      </Box>
    </>
  );
};

export default JoyrideComponent;
