import { Users, detailUser, ManagemenUser } from "../../entities/User";
import { AxiosResponse } from "axios";

export class UsersDataMapper {
  public convertListUserDataFromApi(result: AxiosResponse<any>): Users[] {
    const { data } = result.data;
    const users: Users[] = [];

    data.map((u: any) => {
      return users.push(
        new Users(
          u.id,
          u.name,
          u.email,
          u.nik,
          u.nip,
          u.phone_number,
          u.level,
          u.email_verified_at,
          u.remember_token,
          u.path_foto,
          u.created_at,
          u.updated_at,
          result.data.pagination.total_rows,
        ),
      );
    });

    return users;
  }

  public convertListManagemenUserDataFromApi(
    result: AxiosResponse<any>,
  ): ManagemenUser[] {
    const { data } = result.data;
    const users: ManagemenUser[] = [];

    data.map((u: any) => {
      return users.push(
        new ManagemenUser(
          u.id,
          u.name,
          u.email,
          u.nik,
          u.nip,
          u.phone_number,
          u.level,
          u.email_verified_at,
          u.remember_token,
          u.path_foto,
          u.jenis_user,
          u.created_at,
          u.updated_at,
          result.data.pagination.total_rows,
        ),
      );
    });

    return users;
  }

  public convertListUserDetailFromApi(
    result: AxiosResponse<any>,
  ): detailUser[] {
    const { data } = result.data;
    const dataMPeserta = data.m_peserta;

    const user: detailUser[] = [];

    user.push(
      new detailUser(
        data.id,
        dataMPeserta.nama_lengkap,
        data.nip,
        dataMPeserta.pangkat,
        dataMPeserta.gol_ruang,
        dataMPeserta.level_jabatan,
        data.jenis_user === 2 ? dataMPeserta.satker : dataMPeserta.satker_1,
        dataMPeserta.tempat_tugas,
        data.nik,
        dataMPeserta.jenis_kelamin,
        dataMPeserta.tempat_lahir,
        dataMPeserta.tanggal_lahir,
        dataMPeserta.provinsi_id,
        dataMPeserta.kabupaten_id,
        dataMPeserta.kelurahan_id,
        dataMPeserta.kecamatan_id,
        dataMPeserta.alamat_1,
        dataMPeserta.no_hp,
        dataMPeserta.email,
        dataMPeserta.status_pengisian,
        data.path_foto,
        data.jenis_user,
        dataMPeserta.agama,
        dataMPeserta.status_kawin,
        dataMPeserta.status_pegawai,
        dataMPeserta.last_sync_simpeg,
        data.is_user_sso,
        dataMPeserta.is_update_profile,
      ),
    );

    return user;
  }
}
