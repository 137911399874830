import { AxiosResponse } from "axios";
import { SproutVideo } from "../../entities/SproutVideo";

export class SproutVideoDataMapper {
  public convertSproutVideoDataFromApi(
    result: AxiosResponse<any>,
  ): SproutVideo[] {
    const { data } = result;

    const sproutVideo: SproutVideo[] = [];

    data.videos.map((s: any) => {
      return sproutVideo.push(new SproutVideo(s.id, s.security_token, s.title));
    });

    return sproutVideo;
  }
}
