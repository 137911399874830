import { injectable } from "tsyringe";
import { PelatihanSayaApiRepository } from "../../app/repository/api/PelatihanSayaApiRepository";
import { PelatihanSaya } from "../../entities/PelatihanSaya";

@injectable()
export class PelatihanSayaPresenter {
  private repository: PelatihanSayaApiRepository;
  constructor(repository: PelatihanSayaApiRepository) {
    this.repository = repository;
  }

  public getAllPelatihanSaya(
    page: number,
    rows: number,
    keyword: string,
    pelatihan_id: string,
  ): Promise<PelatihanSaya[]> {
    return this.repository.getAllPelatihanSaya(
      page,
      rows,
      keyword,
      pelatihan_id,
    );
  }

  public storePelatihanSaya(pelatihan_id: number): Promise<any> {
    return this.repository.storePelatihanSaya(pelatihan_id);
  }

  public deletePelatihanSaya(pelatihan_id: number): Promise<any> {
    return this.repository.deletePelatihanSaya(pelatihan_id);
  }
}
