/* eslint-disable eqeqeq */

import { Editor } from "@tinymce/tinymce-react";
import AWS from "aws-sdk";
import React, { useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { AiOutlineSave } from "react-icons/all";
import { Else, If, Then } from "react-if";
import ReactLoading from "react-loading";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Switch from "react-switch";
import { ToastContainer } from "react-toastify";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import ManajemenMateriUpdateController, {
  useManajemenMateriUpdateContext,
} from "../../../../controller/admin/manajemen_materi/update";
import YoutubeController, {
  useYoutubeContext,
} from "../../../../controller/youtube/index";

const fileTypesVideo = ["MP4", "mov"];
const fileTypesAudio = ["MP3"];
const fileTypesPPT = ["pptx", "pptm", "ppt"];
const fileTypesPDF = ["pdf"];

const BottomHeader: React.FC = () => {
  const { updateMateri } = useManajemenMateriUpdateContext();
  const { isLoading } = useManajemenMateriUpdateContext().state;

  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Ubah Materi</a>
      </div>
      <button
        type="button"
        className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
        onClick={() => updateMateri(window.location.pathname.split("/", 5)[4])}
      >
        <If condition={isLoading}>
          <Then>
            <ReactLoading
              type={"bars"}
              color={"white"}
              height={15}
              width={20}
            />
          </Then>
          <Else>Simpan</Else>
        </If>
        <AiOutlineSave className="ml-2" style={{ marginTop: "2px" }} />
      </button>
    </div>
  );
};

const ItemFileMateri = ({ item }: { item: Array<any> }): JSX.Element => {
  const {
    setJenisMateri,
    setPathMateri,
    setMetodePilihan,
    setPilihanVideo,
    deleteFileMateri,
  } = useManajemenMateriUpdateContext();

  const { listVideo } = useYoutubeContext().state;

  const handleChangePathMateri = (file: File, idx: number) => {
    setPathMateri(file, idx);
  };

  let optionsVideos;

  if (listVideo.length > 0) {
    optionsVideos = listVideo.map((item) => ({
      value: item.videoId,
      label: item.title,
    }));
  } else {
    optionsVideos = [];
  }

  const data: JSX.Element[] = [];

  item.map((val, idx) => {
    let fileType: string[] = [];

    if (val.jenis_materi == "video") {
      fileType = fileTypesVideo;
    } else if (val.jenis_materi == "audio") {
      fileType = fileTypesAudio;
    } else if (val.jenis_materi == "ppt") {
      fileType = fileTypesPPT;
    } else if (val.jenis_materi == "pdf") {
      fileType = fileTypesPDF;
    }

    console.log(val);

    return data.push(
      <div className="bg-gray-100 rounded-lg px-8 py-8 mt-5">
        {/* Header Row */}
        <div className="flex flex-row justify-between items-center">
          <p className="text-lg font-semibold">File Materi {idx + 1}</p>
          <button
            type="button"
            className="px-3 py-2 bg-red-500 text-white font-semibold text-md leading-tight rounded shadow-md hover:bg-red-700"
            onClick={() => deleteFileMateri(idx)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>

        {/* Body Row */}
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              Jenis Materi
            </label>
          </div>
          <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
            <select
              className="form-select form-select-xs
              w-full
              text-sm
              font-normal
              text-gray-700
              bg-white bg-clip-padding bg-no-repeat
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              focus:text-gray-700 focus:bg-white focus:border-gren-600 focus:outline-none"
              aria-label="Default select example"
              onChange={(e) => setJenisMateri(e.target.value, idx)}
            >
              <option selected>Jenis Materi</option>
              <option
                value="video"
                selected={val.jenis_materi == "video" ? true : false}
              >
                Video
              </option>
              <option
                value="audio"
                selected={val.jenis_materi == "audio" ? true : false}
              >
                Audio
              </option>
              <option
                value="ppt"
                selected={val.jenis_materi == "ppt" ? true : false}
              >
                PPT
              </option>
              <option
                value="pdf"
                selected={val.jenis_materi == "pdf" ? true : false}
              >
                PDF
              </option>
            </select>
          </div>
        </div>

        <If condition={val.jenis_materi == "video"}>
          <Then>
            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Pilih Metode
                </label>
              </div>
              <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                <select
                  className="w-full h-10 pl-3 pr-8 text-sm placeholder-gray-300 border-3 border-gray-300 rounded focus:border-green-600 focus:ring-1 focus:ring-green-600"
                  onChange={(e) => setMetodePilihan(e.target.value, idx)}
                >
                  <option selected>Pilih Metode</option>
                  <option
                    value="1"
                    selected={val.metode_pilihan == "1" ? true : false}
                  >
                    Pilih Video Yang Sudah Ada
                  </option>
                </select>
              </div>
            </div>
          </Then>
          <Else>
            <If condition={val.jenis_materi != null}>
              <Then>
                <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
                  <div className="col-span-2 md:col-span-2 sm:grid-cols-1"></div>
                  <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                    <FileUploader
                      multiple={false}
                      handleChange={(val) => handleChangePathMateri(val, idx)}
                      name="file"
                      types={fileType}
                    />
                    <p>
                      {val.path_materi
                        ? `File name: ${val.path_materi.name}`
                        : "no files uploaded yet"}
                    </p>
                  </div>
                </div>
              </Then>
            </If>
          </Else>
        </If>

        <If condition={val.metode_pilihan == 0}>
          <Then>
            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1"></div>
              <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                <FileUploader
                  multiple={false}
                  handleChange={(val) => handleChangePathMateri(val, idx)}
                  name="file"
                  types={fileType}
                />
                <p>
                  {val.path_materi
                    ? `File name: ${val.path_materi.name}`
                    : "no files uploaded yet"}
                </p>
              </div>
            </div>
          </Then>
          <Else>
            <If condition={val.metode_pilihan != null}>
              <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
                <div className="col-span-2 md:col-span-2 sm:grid-cols-1"></div>
                <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                  <Select
                    options={optionsVideos}
                    onChange={(val: any) => setPilihanVideo(val.value, idx)}
                    value={
                      val.path_materi &&
                      optionsVideos.filter(
                        (opt) => opt.value == val.path_materi,
                      )
                    }
                    placeholder="Pilih Materi Video Yang Sudah Di Upload"
                  />
                </div>
              </div>
            </If>
          </Else>
        </If>
      </div>,
    );
  });

  return (
    <>
      {data.map((v) => (
        <div>{v}</div>
      ))}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const {
    setPathThumbnail,
    setJenisPelatihan,
    setJudulMateri,
    setUraianDeskripsi,
    setKompetensi,
    setJamPelatihan,
    setStatusKosultasi,
    setWidyaiswara,
    setStatusPublish,
    getListWidyaiswara,
    getJenisPelatihan,
    setPenyusun,
    addFileMateri,
  } = useManajemenMateriUpdateContext();
  const {
    status_konsultasi,
    widyaiswara,
    status_publish,
    listWidyaiswara,
    materi,
    jenisPelatihan,
    penyusun,
    fileMateriArray,
  } = useManajemenMateriUpdateContext().state;

  const { getListVideo } = useYoutubeContext();

  useEffect(() => {
    getListWidyaiswara(window.location.pathname.split("/", 5)[4]);
    getJenisPelatihan();
    getListVideo();
  }, []);

  let options;

  if (listWidyaiswara.length > 0) {
    options = listWidyaiswara.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } else {
    options = [];
  }

  let optionsJenisPelatihan;
  let valueJenisPelatihan;

  if (jenisPelatihan.length > 0) {
    optionsJenisPelatihan = jenisPelatihan.map((item) => ({
      value: item.id,
      label: item.nama_pelatihan,
    }));
    if (materi != null) {
      jenisPelatihan.map((item) => {
        if (item.id == materi.jenis_pelatihan_id) {
          valueJenisPelatihan = { value: item.id, label: item.nama_pelatihan };
        }

        return valueJenisPelatihan;
      });
    }
  } else {
    optionsJenisPelatihan = [];
  }

  return (
    <div className="app">
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-50 drop-shadow-md">
          <BottomHeader />
        </header>
        <div className="container px-7 mb-10">
          {/* Row 1 */}
          <div className="bg-white rounded-lg px-8 py-5 ">
            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Upload thumbnail
                </label>
              </div>
              <div className="col-span-3 md:col-span-3 sm:grid-cols-1">
                <input
                  type="file"
                  className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => setPathThumbnail(e.target.files)}
                  placeholder="Masukkan File"
                />
              </div>
            </div>

            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 ">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Nama Pelatihan (Jenis Pelatihan)
                </label>
              </div>
              <div className="col-span-5 md:col-span-5 sm:grid-cols-1">
                <Select
                  options={optionsJenisPelatihan}
                  value={valueJenisPelatihan}
                  onChange={(val: any) => setJenisPelatihan(val.value)}
                  placeholder="Pilih Nama Pelatihan (Jenis Pelatihan)"
                />
              </div>
            </div>

            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Judul Materi
                </label>
              </div>
              <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                <input
                  type="text"
                  className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => setJudulMateri(e.target.value)}
                  defaultValue={materi != null ? materi.judul_materi : ""}
                  placeholder="Masukkan Judul Materi"
                />
              </div>
            </div>

            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mb-5">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Uraian Deskripsi
                </label>
              </div>
              <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                <Editor
                  initialValue={materi != null ? materi.uraian_deskripsi : ""}
                  init={{
                    height: 180,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help | image",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    images_upload_handler: async function (
                      blobInfo: any,
                      success: any,
                      failure: any,
                    ) {
                      const S3_BUCKET = "pusdiklat-lms";
                      const REGION = "sgp1";
                      const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                      const s3Credentials = new AWS.Credentials({
                        accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                        secretAccessKey:
                          "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                      });

                      const myBucket = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        endpoint: ENDPOINT,
                        region: REGION,
                        credentials: s3Credentials,
                      });

                      function makeid(length) {
                        let result = "";
                        const characters =
                          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                        const charactersLength = characters.length;
                        for (let i = 0; i < length; i++) {
                          result += characters.charAt(
                            Math.floor(Math.random() * charactersLength),
                          );
                        }
                        return result;
                      }

                      try {
                        const nameFile =
                          makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                        const params = {
                          ACL: "public-read",
                          Body: blobInfo.blob(),
                          Bucket: S3_BUCKET,
                          Key: nameFile,
                        };

                        myBucket
                          .putObject(params)
                          .on("httpUploadProgress", (evt) => {
                            console.log(
                              Math.round((evt.loaded / evt.total) * 100),
                            );
                          })
                          .send((err, data) => {
                            if (err) {
                              failure(err);
                            } else {
                              success(
                                "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                                  nameFile,
                              );
                            }
                          });
                      } catch (error: any) {
                        console.log(error);
                        failure(error);
                        return;
                      }
                    },
                  }}
                  onEditorChange={(val) => setUraianDeskripsi(val)}
                />
              </div>
            </div>

            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mb-5">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Kompetensi
                </label>
              </div>
              <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
                <Editor
                  initialValue={materi != null ? materi.kompetensi : ""}
                  init={{
                    height: 180,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help | image",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    images_upload_handler: async function (
                      blobInfo: any,
                      success: any,
                      failure: any,
                    ) {
                      const S3_BUCKET = "pusdiklat-lms";
                      const REGION = "sgp1";
                      const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                      const s3Credentials = new AWS.Credentials({
                        accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                        secretAccessKey:
                          "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                      });

                      const myBucket = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        endpoint: ENDPOINT,
                        region: REGION,
                        credentials: s3Credentials,
                      });

                      function makeid(length) {
                        let result = "";
                        const characters =
                          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                        const charactersLength = characters.length;
                        for (let i = 0; i < length; i++) {
                          result += characters.charAt(
                            Math.floor(Math.random() * charactersLength),
                          );
                        }
                        return result;
                      }

                      try {
                        const nameFile =
                          makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                        const params = {
                          ACL: "public-read",
                          Body: blobInfo.blob(),
                          Bucket: S3_BUCKET,
                          Key: nameFile,
                        };

                        myBucket
                          .putObject(params)
                          .on("httpUploadProgress", (evt) => {
                            console.log(
                              Math.round((evt.loaded / evt.total) * 100),
                            );
                          })
                          .send((err, data) => {
                            if (err) {
                              failure(err);
                            } else {
                              success(
                                "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                                  nameFile,
                              );
                            }
                          });
                      } catch (error: any) {
                        console.log(error);
                        failure(error);
                        return;
                      }
                    },
                  }}
                  onEditorChange={(val) => setKompetensi(val)}
                />
              </div>
            </div>

            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                  Jam Pembelajaran
                </label>
              </div>
              <div className="col-span-3 md:col-span-3 sm:grid-cols-1">
                <input
                  type="number"
                  className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => setJamPelatihan(e.target.value)}
                  defaultValue={materi != null ? materi.jam_pelatihan : ""}
                  placeholder="Masukkan Jam Pembelajaran"
                />
              </div>
            </div>

            <If condition={materi != null}>
              <Then>
                <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
                  <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                      Penyusun
                    </label>
                  </div>
                  <div className="col-span-3 md:col-span-3 sm:grid-cols-1">
                    <input
                      type="text"
                      className="form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => setPenyusun(e.target.value)}
                      defaultValue={penyusun}
                      placeholder="Masukkan Penyusun"
                    />
                  </div>
                </div>
              </Then>
            </If>

            <If condition={materi != null}>
              <Then>
                <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
                  <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                      Ada Sesi Konsultasi?
                    </label>
                  </div>
                  <div className="col-span-3 md:col-span-3 sm:grid-cols-1">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      defaultChecked={
                        materi?.status_konsultasi == 1 ? true : false
                      }
                      onChange={() => setStatusKosultasi(!status_konsultasi)}
                    />
                  </div>
                </div>
              </Then>
            </If>

            <If condition={materi != null}>
              <Then>
                <If condition={status_konsultasi}>
                  <Then>
                    <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
                      <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                          Widyaiswara
                        </label>
                      </div>
                      <div className="col-span-5 md:col-span-5 sm:grid-cols-1">
                        <MultiSelect
                          options={options}
                          value={widyaiswara}
                          onChange={setWidyaiswara}
                          labelledBy="Select"
                        />
                      </div>
                    </div>
                  </Then>
                </If>
              </Then>
            </If>

            <If condition={materi != null}>
              <Then>
                <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
                  <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                      Publikasi
                    </label>
                  </div>
                  <div className="col-span-5 md:col-span-5 sm:grid-cols-1">
                    <Switch
                      className="ml-2 mt-2"
                      height={20}
                      onChange={() => setStatusPublish(!status_publish)}
                      checked={status_publish}
                    />
                  </div>
                </div>
              </Then>
            </If>
          </div>

          {/* Row 2 */}
          <div className="bg-white rounded-lg px-8 py-8 mt-5">
            <If condition={fileMateriArray.length > 0}>
              <Then>
                <ItemFileMateri item={fileMateriArray} />
              </Then>
            </If>

            <div className="text-center py-10 mt-5">
              <button
                type="button"
                className="px-6 py-2 bg-yellow-500 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 align-left mb-5"
                onClick={() => addFileMateri()}
              >
                Tambah File Materi
              </button>
              <p className="text-2xl">
                Silahkan klik tombol diatas untuk menambahkan file materi.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const ManajemenMateriUbah: React.FC = () => {
  return (
    <ManajemenMateriUpdateController.Provider>
      <YoutubeController.Provider>
        <ToastContainer />
        <SidebarWithHeader title="Manajemen Materi">
          <WrapperMain />
        </SidebarWithHeader>
      </YoutubeController.Provider>
    </ManajemenMateriUpdateController.Provider>
  );
};

export default ManajemenMateriUbah;
