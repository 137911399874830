import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaKey, FaUser } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { Wrapper } from "../../../components/Wrapper";
import LoginController, {
  useLoginContext,
} from "../../../controller/auth/login";

const LoginForm: React.FC = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { handleDoLogin, changeEmail, changePassword } = useLoginContext();

  const { isLoading } = useLoginContext().state;

  const toast = useToast();

  const handleErrorSSO = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const errorTitle = searchParams.get("errorTitle") ?? "";
    const errorMessage = searchParams.get("errorMessage") ?? "";

    if (!errorTitle) return;

    toast({
      title: decodeURIComponent(errorTitle),
      description: decodeURIComponent(errorMessage),
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const doLogin = (e) => {
    e.preventDefault();
    handleDoLogin();
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const urlRedirectSSOPusaka = process.env.REACT_APP_BE_PROD_BASE_URL?.replace(
    "/api/v1/",
    "/oauth2/redirect",
  );

  const isStaging =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === "staging";

  useEffect(() => {
    handleErrorSSO();
  }, []);

  return (
    <Box textAlign="center" pb={18} pt={6} my={5}>
      <Heading
        fontSize={{ base: "2xl", md: "3xl" }}
        fontWeight="semibold"
        as="h1"
      >
        Selamat Datang di i-Course
      </Heading>
      {/* Form Login */}
      <Box maxW={{ base: "85%", md: "33.3%" }} mx="auto" mt={8}>
        <Box bg="white" rounded="xl" overflow="hidden" mb={10}>
          <Box p={{ base: 4, md: 8 }}>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight={{ base: "normal", md: "semibold" }}
              color="#9ca3af"
              pb={5}
            >
              Masukkan NIP / NIK & password pada <br /> form di bawah ini dengan
              benar
            </Text>

            {/* Divider */}
            <Box borderBottom={2} borderStyle="dashed" color="gray.200"></Box>
            {/* /Divider */}

            {/* Form */}
            <form onSubmit={(e) => doLogin(e)}>
              <Box borderColor="gray.400" mt={4}>
                {/* Email */}
                <Box>
                  <Text textAlign="start" mb={2} fontSize="sm" color="blue.600">
                    Email
                  </Text>
                  <InputGroup size="md" mb={4}>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FaUser} color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type="email"
                      placeholder="Masukkan Email"
                      _placeholder={{ color: "gray.300" }}
                      onChange={(e) => changeEmail(e.target.value)}
                      rounded="lg"
                      borderColor="gray.300"
                      _focus={{
                        ring: 1,
                        borderColor: "blue.600",
                        ringColor: "blue.600",
                      }}
                      className="nipInput"
                    />
                  </InputGroup>
                </Box>
                {/* Password */}
                <Box>
                  <Text textAlign="start" mb={2} fontSize="sm" color="blue.600">
                    Password
                  </Text>
                  <InputGroup size="md" mb={4}>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FaKey} color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Masukkan Password"
                      _placeholder={{ color: "gray.300" }}
                      onChange={(e) => changePassword(e.target.value)}
                      rounded="lg"
                      borderColor="gray.300"
                      _focus={{
                        ring: 1,
                        borderColor: "blue.600",
                        ringColor: "blue.600",
                      }}
                      pr="4.5rem"
                      className="passwordInput"
                    />
                    <IconButton
                      aria-label={
                        showPassword
                          ? "Sembunyikan kata sandi"
                          : "Perlihatkan kata sandi"
                      }
                      icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      variant="ghost"
                      size="sm"
                      position="absolute"
                      right="0.5rem"
                      top="50%"
                      transform="translateY(-50%)"
                      _focus={{
                        ring: 0,
                      }}
                      onClick={handleShowPassword}
                    />
                  </InputGroup>
                </Box>
              </Box>
              {/* /Form */}

              <Box textAlign="left" my={4}>
                <Link
                  href="/reset_password"
                  fontSize="sm"
                  color="blue.600"
                  _hover={{ color: "BluePrimary.900" }}
                >
                  Lupa Password?
                </Link>
              </Box>

              {/* Divider */}
              <Box borderBottom={2} borderStyle="dashed" color="gray.200"></Box>
              {/* /Divider */}

              {/* Footer Sign In */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={5}
              >
                <Checkbox
                  id="flexCheckDefault"
                  mt={1}
                  isChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  colorScheme="blue"
                >
                  <Text fontSize="sm">Remember Me</Text>
                </Checkbox>
              </Box>

              <Button
                type="submit"
                w="full"
                mt={4}
                border="transparent"
                rounded="md"
                color="white"
                bg="BluePrimary.900"
                _hover={{ bg: "BluePrimary.900" }}
                _active={{ bg: "BluePrimary.900" }}
                className="buttonLogin"
                isLoading={isLoading}
                loadingText="Loading..."
                fontSize={{ base: "sm", md: "md" }}
                fontWeight="medium"
              >
                Masuk
              </Button>

              {isStaging && (
                <>
                  <Box position="relative" p={8}>
                    <Divider />
                    <AbsoluteCenter bg="white" px={4}>
                      Atau
                    </AbsoluteCenter>
                  </Box>

                  <Link
                    w="full"
                    bg="facebook.500"
                    color="white"
                    href={urlRedirectSSOPusaka}
                    borderRadius="md"
                    display="inline-block"
                    py={2}
                    fontSize={{ base: "sm", md: "md" }}
                    _hover={{
                      textDecoration: "none",
                      bg: "facebook.600",
                    }}
                  >
                    Masuk dengan SSO Pusaka
                  </Link>
                </>
              )}

              {/* /Footer Sign In */}
            </form>

            {/* Sign Up Link */}
            <Box mt={5}>
              <Text fontSize="md" color="gray.400" fontWeight="semibold">
                Belum memiliki akun pintar?
              </Text>
              <Link
                href="/register"
                fontSize="md"
                color="blue.600"
                _hover={{ color: "BluePrimary.900" }}
                fontWeight="semibold"
              >
                Buat Akun
              </Link>
            </Box>
            {/* /Sign Up Link */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <Wrapper>
      {/* Content */}
      <LoginForm />
    </Wrapper>
  );
};

const Login: React.FC = () => {
  return (
    <LoginController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </LoginController.Provider>
  );
};

export default Login;
