/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MateriPresenter } from "../../../../../data/presenters/MateriPresenter";
import "react-toastify/dist/ReactToastify.css";
import { Materi } from "../../../../../entities/Materi";
import { JenisPelatihan } from "../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../data/presenters/JenisPelatihanPresenter";
import axiosInstance from "../../../../api/axiosInstance";

interface PengetahuanItem {
  id: number;
  title: string;
  description: string;
  category: string;
  path_file: string;
  total_view: number | null;
  review_score: number | null;
  status_publish: 1;
  created_at: string;
  updated_at: string;
  thumbnail: string;
}
interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  perPage: number;
  keyword: string;
  tahun: string;
  kategori_soal: string;
  jenis_soal: string;
  totalMateri: number;
  materi: Materi[];
  jenis_materi_id: string;
  jenis_materi: string[];
  jenisPelatihan: JenisPelatihan[];
  checkVideo: boolean;
  checkPDF: boolean;
  checkPPT: boolean;
  checkAudio: boolean;
  checkAll: boolean;
  url: string;
  listData: PengetahuanItem[];
  category: string;
  pagination: {
    current_page: number;
    total_rows: number;
  } | null;
}

interface InitialState {
  state: IState;
  getMateri: Function;
  setPage: Function;
  setKeyword: Function;
  searchMateri: Function;
  getJenisPelatihan: Function;
  filterJenisPelatihan: Function;
  filterJenisMateri: Function;
  filterSemuaJenisMateri: Function;
  fetchData: Function;
  setState: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    perPage: 9,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalMateri: 1,
    materi: [],
    jenis_materi_id: "",
    jenis_materi: [],
    jenisPelatihan: [],
    checkVideo: false,
    checkPDF: false,
    checkPPT: false,
    checkAudio: false,
    checkAll: false,
    url: "",
    listData: [],
    category: "",
    pagination: null,
  },
  getMateri: () => {},
  searchMateri: () => {},
  setPage: () => {},
  setKeyword: () => {},
  getJenisPelatihan: () => {},
  filterJenisPelatihan: () => {},
  filterJenisMateri: () => {},
  filterSemuaJenisMateri: () => {},
  fetchData: () => {},
  setState: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: PengetahuanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    perPage: 9,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalMateri: 0,
    materi: [],
    jenis_materi_id: "",
    jenis_materi: [],
    jenisPelatihan: [],
    checkVideo: false,
    checkPDF: false,
    checkPPT: false,
    checkAudio: false,
    checkAll: false,
    url: "public/pengetahuan",
    listData: [],
    category: "",
    pagination: null,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setLoading(true);

    getMateri(
      value + 1,
      state.keyword,
      state.jenis_materi_id,
      state.jenis_materi,
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const setKeyword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const getMateri = async (
    pagee: number,
    keyword: string,
    jenis_materi_id: string,
    jenis_materi: string[],
  ) => {
    setLoading(true);

    try {
      const materiPresenter = container.resolve(MateriPresenter);
      const getAllMateri = await materiPresenter.getAllMateri(
        pagee,
        12,
        keyword,
        jenis_materi_id,
        jenis_materi,
      );

      const data = getAllMateri;

      setJumlahPage(data[0].jumlahData / 12);

      setState((prevstate) => ({
        ...prevstate,
        materi: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchMateri = () => {
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, state.jenis_materi);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
    }));
  };

  const getJenisPelatihan = async () => {
    try {
      const jenisPelatihan = container.resolve(JenisPelatihanPresenter);

      const get = await jenisPelatihan.getAllJenisPelatihan(
        1,
        10000000,
        "",
        "",
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const filterJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, value, state.jenis_materi);
    setState((prevstate) => ({
      ...prevstate,
      jenis_materi_id: value,
      page: 1,
    }));
  };

  const filterJenisMateri = (type: string) => {
    let jns_materi;
    if (type == "video") {
      jns_materi = ["video"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["video"],
      }));
    } else if (type == "pdf") {
      jns_materi = ["pdf"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["pdf"],
      }));
    } else if (type == "ppt") {
      jns_materi = ["ppt"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["ppt"],
      }));
    } else if (type == "audio") {
      jns_materi = ["audio"];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: ["audio"],
      }));
    } else {
      jns_materi = [];
      setState((prevstate) => ({
        ...prevstate,
        jenis_materi: [],
      }));
    }
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, jns_materi);
  };

  const filterSemuaJenisMateri = (value: boolean) => {
    let jns_materi: string[];
    if (value) {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: true,
        checkPDF: true,
        checkPPT: true,
        checkAudio: true,
      }));
      jns_materi = ["video", "pdf", "ppt", "audio"];
    } else {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: false,
        checkPDF: false,
        checkPPT: false,
        checkAudio: false,
      }));
      jns_materi = [];
    }
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, jns_materi);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      jenis_materi: jns_materi,
      checkAll: value,
    }));
  };

  const fetchData = async (status?: string) => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(state.url, {
        params: {
          status_publish: status ?? "1",
          page: state.page,
          rows: state.perPage,
          category: state.category,
          keyword: state.keyword,
        },
      });

      setState((prevstate) => ({
        ...prevstate,
        listData: response.data.data,
      }));
      setState((prevstate) => ({
        ...prevstate,
        pagination: response.data?.pagination,
      }));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return (
    <PengetahuanProvider
      value={{
        state,
        getMateri,
        setPage,
        searchMateri,
        setKeyword,
        getJenisPelatihan,
        filterJenisPelatihan,
        filterJenisMateri,
        filterSemuaJenisMateri,
        fetchData,
        setState,
      }}
    >
      {children}
    </PengetahuanProvider>
  );
};

export const usePengetahuanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  usePengetahuanContext,
  Provider,
};
