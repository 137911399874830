export class JenisPelatihan {
  id: number;
  sub_menu_jenis_pelatihan_id: number;
  kode: string;
  nama_pelatihan: string;
  kompetensi: string;
  jp: string;
  keterangan: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;
  metode_penambahan: number;

  constructor(
    id: number,
    sub_menu_jenis_pelatihan_id: number,
    kode: string,
    nama_pelatihan: string,
    kompetensi: string,
    jp: string,
    keterangan: string,
    created_at: string,
    updated_at: string,
    jumlahData: number,
    metode_penambahan: number,
  ) {
    this.id = id;
    this.sub_menu_jenis_pelatihan_id = sub_menu_jenis_pelatihan_id;
    this.kode = kode;
    this.nama_pelatihan = nama_pelatihan;
    this.kompetensi = kompetensi;
    this.jp = jp;
    this.keterangan = keterangan;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
    this.metode_penambahan = metode_penambahan;
  }
}
