import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface EventPintarItem {
  id: number;
  title: string;
  training_start_date: string;
  training_end_date: string;
  location: string;
  image: string;
  description: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}
interface IState {
  state: {
    listData: EventPintarItem[];
    loadingData: boolean;
    loadingDetail: boolean;
    url: string;
    data: EventPintarItem | null;
  };
  setEventPintarState: Function;
  getEventPintar: Function;
  getDetailEventPintar: Function;
}

const initialState: IState = {
  state: {
    listData: [],
    loadingData: false,
    loadingDetail: false,
    url: "public/event_pintar",
    data: null,
  },
  setEventPintarState: () => {},
  getEventPintar: () => {},
  getDetailEventPintar: () => {},
};

/**
 * The EventPintarPublicContext provides a context for managing the state related to the event pintar in landing page.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: EventPintarPublicProvider } = Context;

/**
 * The provider component that wraps its children with the EventPintarPublicContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const { url } = state.state;

  const setEventPintarState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const getEventPintar = async () => {
    setEventPintarState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: "1",
        },
      });

      setEventPintarState("listData", response.data?.data);
      setEventPintarState("loadingData", false);
    } catch (error) {
      setEventPintarState("loadingData", false);
      throw error;
    }
  };

  const getDetailEventPintar = async (id) => {
    setEventPintarState("loadingDetail", true);
    try {
      const response = await axiosInstance.get(`${url}/${id}`);

      setEventPintarState("data", response.data?.data);
      setEventPintarState("loadingDetail", false);
    } catch (error) {
      setEventPintarState("loadingDetail", false);
      throw error;
    }
  };

  return (
    <EventPintarPublicProvider
      value={{
        state: state.state,
        setEventPintarState,
        getEventPintar,
        getDetailEventPintar,
      }}
    >
      {children}
    </EventPintarPublicProvider>
  );
};

export const useEventPintarPublicContext = () => useContext(Context);

const EventPintarPublicContext = {
  useEventPintarPublicContext,
  Provider,
};

export default EventPintarPublicContext;
