/* eslint-disable eqeqeq */
import { Skeleton } from "@chakra-ui/react";
import parse from "html-react-parser";
import If, { Else } from "if-else-react";
import React, { useEffect, useState } from "react";
import CountdownTimer from "react-component-countdown-timer";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import SetInterval from "set-interval";
import { MJawabanSoal } from "../../../../../entities/Exam";
import { Modal } from "../../../components/Modal";
import { Wrapper } from "../../../components/Wrapper";
import UjianController, {
  useUjianContext,
} from "../../../controller/ujian/index";

const HeaderContent: React.FC = () => {
  // Call ujian Controller
  const { ujian } = useUjianContext().state;

  return (
    <header>
      <div className="container-fluid bg-blue-500 p-5 text-center">
        <h1 className="text-lg font-semibold text-white">
          Ujian Akhir pada modul {ujian?.judul_sub_modul}
        </h1>
      </div>
    </header>
  );
};

const Soal = ({ soal }: { soal: any }) => {
  // Call State On Controller Exam
  const { soalDipilih, indexSoal } = useUjianContext().state;

  return (
    <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg">
      <If condition={soal != null}>
        <span className="text-blue-500 font-semibold">
          {indexSoal + 1} dari {soal.length} soal
        </span>
        <div className="mt-1">{parse(soalDipilih)}</div>
      </If>
    </div>
  );
};

const ComponentJawaban = ({
  data_jawaban,
  index,
  opsi,
}: {
  data_jawaban: MJawabanSoal;
  index: number;
  opsi: string;
}) => {
  // Call ujian Controller
  const { onChangeAnswer } = useUjianContext();

  const { jawabanPeserta } = useUjianContext().state;

  return (
    <>
      <div className="radio-jawaban mb-3">
        <input
          type="radio"
          id={index.toString()}
          name="answer"
          value={data_jawaban.id}
          onChange={(input) => onChangeAnswer(input.currentTarget.value)}
          checked={data_jawaban.id == Number(jawabanPeserta)}
        />
        <label
          htmlFor={index.toString()}
          className="flex flex-row border border-2 rounded-lg p-3"
        >
          <p className="text-sm mb-2 border border-2 p-3 mr-3 font-semibold">
            {opsi}
          </p>
          <div className="text-sm mt-3">{parse(data_jawaban.pilihan)}</div>
        </label>
      </div>
    </>
  );
};

const Jawaban = ({ jawaban }: { jawaban: any }) => {
  const opsi = "ABCDEFGHIJKLMNOPQRSTUFWXYZ".split("");

  return (
    <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg flex flex-col">
      {jawaban.map((v, idx) => {
        return (
          <ComponentJawaban
            data_jawaban={v}
            index={idx}
            key={idx}
            opsi={opsi[idx]}
          />
        );
      })}
    </div>
  );
};

const ModalTiming = ({ showModal }: { showModal: boolean }) => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  // Call Latihan Controller
  const { finishUjian } = useUjianContext();

  const finish = () => {
    finishUjian(id);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-non w-full ">
              {/*content*/}
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                {/*header*/}
                <div className="modal-header flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-red-400">
                    Waktu Anda Telah Habis
                  </h3>
                </div>

                {/*body*/}
                <div className="modal-body relative p-6">
                  Waktu Ujian mu telah habis silahkan tekan tombol kirim .
                </div>

                {/*footer*/}
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  <button
                    className="bg-yellow-400 hover:bg-yellow-500 px-4 py-2 rounded-md text-white"
                    style={{ marginLeft: 10 + "px" }}
                    onClick={() => finish()}
                  >
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-50 bg-black w-full"></div>
        </>
      ) : null}
    </>
  );
};

const SoalPilihan = ({ soal }: { soal: any }) => {
  // Call ujian Controller
  const { ujian, duration } = useUjianContext().state;

  const { setIndexSoal, setSoalDipilih, setBankJawaban, setJawabanPeserta } =
    useUjianContext();

  const onClickChangeSoal = (value) => {
    if (ujian != null) {
      const soal = ujian.m_soal[value];
      setIndexSoal(value);
      setSoalDipilih(soal.soal);
      setBankJawaban(soal.m_jawaban_soal);
      setJawabanPeserta(soal.jawaban_peserta);
    }
  };

  const [timingFinish, setTimingFinish] = useState(false);
  const onTimingFinish = () => setTimingFinish(true);

  return (
    <>
      <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg flex flex-col text-center">
        <p className="text-base font-semibold">Waktu Tersisa</p>
        <If condition={duration != 0}>
          <CountdownTimer
            count={duration}
            hideDay={true}
            size={40}
            color={"#0DBD7F"}
            onEnd={() => {
              onTimingFinish();
              SetInterval.clear();
            }}
          />
          <Else />
          <Skeleton height={50} />
        </If>

        {/* Pilihan Soal */}
        <div className="grid grid-cols-5 gap-2 text-white mt-3 justify-items-center">
          {soal != null
            ? soal.map((v, idx) => {
                if (v.jawaban_peserta == "0") {
                  return (
                    <button
                      key={idx}
                      className="bg-blue-300 text-2xl font-semibold rounded-full hover:bg-blue-400 w-10 h-10"
                      onClick={() => onClickChangeSoal(idx)}
                    >
                      {idx + 1}
                    </button>
                  );
                }

                if (v.jawaban_peserta != "0") {
                  return (
                    <button
                      key={idx}
                      className="bg-blue-400 text-2xl font-semibold rounded-full hover:bg-blue-500 w-10 h-10"
                      onClick={() => onClickChangeSoal(idx)}
                    >
                      {idx + 1}
                    </button>
                  );
                }

                return true;
              })
            : null}
        </div>
      </div>

      {/* ModalTiming */}
      <ModalTiming showModal={timingFinish} />

      {/* WebCam */}
      <Webcam mirrored className="mt-5 rounded-lg" />
    </>
  );
};

const ContentExam: React.FC = () => {
  // Call ujian Controller
  const { setIndexSoal, setSoalDipilih, setBankJawaban, setJawabanPeserta } =
    useUjianContext();

  const { bankJawaban, indexSoal, bankSoal } = useUjianContext().state;

  const handleNext = () => {
    if (bankSoal.length > 0) {
      setIndexSoal((indexSoal + 1) % bankSoal.length);
    }
  };

  const handleBack = () => {
    if (bankSoal.length > 0) {
      setIndexSoal((indexSoal - 1) % bankSoal.length);
    }
  };

  const fetchSoal = () => {
    if (bankSoal.length > 0) {
      const soal = bankSoal[indexSoal];
      setSoalDipilih(soal.soal);
      setBankJawaban(soal.m_jawaban_soal);
      setJawabanPeserta(soal.jawaban_peserta);
    }
  };

  useEffect(() => {
    fetchSoal();
  }, [indexSoal]);

  return (
    <div className="max-w-2xl mx-auto lg:max-w-7xl lg:px-7 mb-5 w-full mt-5 grid grid-cols-4 gap-5">
      <div className="col-span-3">
        {/* Soal */}
        <div className="mb-5">
          <Soal soal={bankSoal} />
        </div>
        {/* Jawaban */}
        <div className="mb-5">
          <Jawaban jawaban={bankJawaban} />
        </div>
        {/* Navigation Soal */}
        <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg flex flex-row justify-end">
          {/* Back Ujian */}
          <If condition={indexSoal != 0}>
            <button
              type="button"
              className="text-white bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2"
              onClick={handleBack}
            >
              Soal Sebelumnya
            </button>
          </If>
          {/* Finish Ujian */}
          <button
            type="button"
            className={`text-white bg-purple-400 hover:bg-purple-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2 ${
              bankSoal.length == indexSoal + 1 ? "" : "hidden"
            }`}
            data-modal-toggle="modalFinishujian"
          >
            Selesai Ujian
          </button>
          {/* Next Ujian */}
          <If condition={bankSoal.length != indexSoal + 1}>
            <button
              type="button"
              className="text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2"
              onClick={handleNext}
            >
              Soal Selanjutnya
            </button>
          </If>
        </div>
      </div>
      {/* Soal Pilihan Siswa */}
      <div>
        <SoalPilihan soal={bankSoal} />
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  // Call ujian Controller
  const { getUjian, setTime, finishUjian } = useUjianContext();

  const { ujian } = useUjianContext().state;

  useEffect(() => {
    getUjian(id);
  }, []);

  if (ujian != null) {
    SetInterval.start(() => {
      setTime(id);
    }, 1000 * 60);
  }

  const handleFinish = () => {
    finishUjian(id);
  };

  return (
    <Wrapper>
      {/* Hero */}
      <HeaderContent />

      {/* Content */}
      <ContentExam />

      <Modal id_modal="modalFinishujian">
        <div className="flex items-start justify-between p-6 rounded-t">
          <h3 className="text-xl font-semibold text-red-400">Finish ujian</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="modalFinishujian"
            style={{ color: "white" }}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="p-6 space-y-6 -mt-5">
          <div
            className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
            <div>
              <span className="font-medium">Perhatian!</span> Apakah anda yakin
              untuk menyudahi ujian ini?
            </div>
          </div>
        </div>
        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            data-modal-toggle="modalFinishujian"
            type="button"
            className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            onClick={handleFinish}
          >
            Yakin
          </button>
          <button
            data-modal-toggle="modalFinishujian"
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
          >
            Tidak
          </button>
        </div>
      </Modal>
    </Wrapper>
  );
};

const UjianExam: React.FC = () => {
  return (
    <UjianController.Provider>
      <WrapperMain />
    </UjianController.Provider>
  );
};

export default UjianExam;
