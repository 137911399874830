import { injectable } from "tsyringe";
import { KabupatenApiRepository } from "../../app/repository/api/KabupatenApiRepository";
import { Kabupaten } from "../../entities/Kabupaten";

@injectable()
export class KabupatenPresenter {
  private repository: KabupatenApiRepository;
  constructor(repository: KabupatenApiRepository) {
    this.repository = repository;
  }

  public getAllKabupaten(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<Kabupaten[]> {
    return this.repository.getAllKabupaten(page, rows, keyword);
  }

  public getKabupatenById(id: number): Promise<Kabupaten[]> {
    return this.repository.getKabupatenById(id);
  }

  public createKabupaten(
    provinsi_id: string,
    nama_kabupaten: string,
  ): Promise<any> {
    return this.repository.createKabupaten(provinsi_id, nama_kabupaten);
  }

  public updateKabupaten(
    id: number,
    provinsi_id: string,
    nama_kabupaten: string,
  ): Promise<any> {
    return this.repository.updateKabupaten(id, provinsi_id, nama_kabupaten);
  }

  public deleteKabupaten(id: number): Promise<any> {
    return this.repository.deleteKabupaten(id);
  }
}
