import { injectable } from "tsyringe";
import { PaketSoalApiRepository } from "../../app/repository/api/PaketSoalApiRepository";
import { PaketSoal } from "../../entities/PaketSoal";

@injectable()
export class PaketSoalPresenter {
  private repository: PaketSoalApiRepository;
  constructor(repository: PaketSoalApiRepository) {
    this.repository = repository;
  }

  public getAllPaketSoal(
    page: number,
    rows: number,
    keyword: string,
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
  ): Promise<PaketSoal[]> {
    return this.repository.getAllPaketSoal(
      page,
      rows,
      keyword,
      tahun,
      kategori_soal,
      jenis_soal,
    );
  }

  public getDetailPaketSoal(id: number): Promise<PaketSoal> {
    return this.repository.getDetailPaketSoal(id);
  }

  public storePaketSoal(
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
    judul_soal: string,
    petunjuk_soal: string,
  ): Promise<any> {
    return this.repository.storePaketSoal(
      tahun,
      kategori_soal,
      jenis_soal,
      judul_soal,
      petunjuk_soal,
    );
  }

  public updatePaketSoal(
    id: number,
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
    judul_soal: string,
    petunjuk_soal: string,
  ): Promise<any> {
    return this.repository.updatePaketSoal(
      id,
      tahun,
      kategori_soal,
      jenis_soal,
      judul_soal,
      petunjuk_soal,
    );
  }

  public deletePaketSoal(id: number): Promise<any> {
    return this.repository.deletePaketSoal(id);
  }
}
