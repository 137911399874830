/* eslint-disable eqeqeq */
import React from "react";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { ModulPelatihanPresenter } from "../../../../../../../data/presenters/ModulPelatihanPresenter";
import { PelatihanPresenter } from "../../../../../../../data/presenters/PelatihanPresenter";
import { ModulPelatihan } from "../../../../../../../entities/ModulPelatihan";
import { Pelatihan } from "../../../../../../../entities/Pelatihan";
import history from "../../../../../../../history";

interface IState {
  isLoading: boolean;
  pelatihan: Pelatihan | null;
  showDialogDelete: boolean;
  selectedId: number;
  isLoadingDelete: boolean;
  modulPelatihan: ModulPelatihan[];
}

interface InitialState {
  state: IState;
  getDetailPelatihan: Function;
  setShowDialogDelete: Function;
  setSelectedId: Function;
  deletePelatihan: Function;
  getModulPelatihan: Function;
}

const initialState = {
  state: {
    isLoading: true,
    pelatihan: null,
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDelete: false,
    modulPelatihan: [],
  },
  getDetailPelatihan: () => {},
  setShowDialogDelete: () => {},
  setSelectedId: () => {},
  deletePelatihan: () => {},
  getModulPelatihan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenPelatihanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    isLoadingDelete: false,
    pelatihan: null,
    showDialogDelete: false,
    selectedId: 0,
    modulPelatihan: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const getDetailPelatihan = async (id: number) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        pelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setSelectedId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedId: value,
    }));
  };

  const deletePelatihan = async () => {
    setLoadingDelete(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const deletePelatihan = await pelatihanPresenter.deletePelatihan(
        state.selectedId,
      );

      const data = deletePelatihan;

      if (data.data.code == 200) {
        toast.success("Berhasil Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_pelatihan`);
        window.location.reload();
      } else {
        toast.error("Gagal Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingDelete(false);
      setShowDialogDelete(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setLoadingDelete(false);
    }
  };

  const getModulPelatihan = async (pelatihan_id: number) => {
    setLoading(true);

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter,
      );
      const getAllModulPelatihan =
        await modulPelatihanPresenter.getAllModulPelatihan(
          1,
          1000,
          "",
          pelatihan_id,
        );

      const data = getAllModulPelatihan;

      setState((prevstate) => ({
        ...prevstate,
        modulPelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error modul pelatihan:", error);
      setLoading(false);
    }
  };

  return (
    <ManajemenPelatihanDetailProvider
      value={{
        state,
        getDetailPelatihan,
        setShowDialogDelete,
        setSelectedId,
        deletePelatihan,
        getModulPelatihan,
      }}
    >
      {children}
    </ManajemenPelatihanDetailProvider>
  );
};

export const useDetailPenilaianPelatihanContext = () =>
  React.useContext(Context);

export default {
  useDetailPenilaianPelatihanContext,
  Provider,
};
