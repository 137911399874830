import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Spinner,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiFillPlusCircle, AiOutlineArrowLeft } from "react-icons/ai";
import Select, { StylesConfig } from "react-select";
import CardItem from "../../../components/Admin/Card/CardItem";
import EditorComponent from "../../../components/Admin/Editor";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import BoxUploadFile from "../../../components/Admin/UploadFile/BoxUploadFile";
import Pagination from "../../../components/Pagination";
import PodcastCategoryContext, {
  usePodcastCategoryContext,
} from "../../../controller/admin/data_master/kategori_podcast";
import ManajemenPodcastController, {
  useManajemenPodcastContext,
} from "../../../controller/admin/manajemen_podcast";

/**
 * ManajemenPodcast is a component that allows users to manage podcast episodes.
 * It provides a form to create new episodes and displays a list of existing episodes.
 * Uses TinyMCE editor for description.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const ManajemenPodcast = () => {
  const {
    setManajemenPodcastState,
    postData,
    fetchData,
    handleEdit,
    handleDelete,
    clearState,
  } = useManajemenPodcastContext();
  const state = useManajemenPodcastContext().state;

  const {
    publish,
    mode,
    audio,
    image,
    loadingData,
    listData,
    description,
    title,
    loadingAction,
    category,
    loadingEdit,
    pagination,
    perPage,
    page,
  } = state;

  const { fetchData: getCategoryPodcast } = usePodcastCategoryContext();
  const { listDataOption: listCategoryPodcast } =
    usePodcastCategoryContext().state;

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: "#F6F5F8",
        padding: "2px 8px",
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = "#38A169";

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? color
            : isFocused
              ? "#C6F6D5"
              : undefined,
        color: isDisabled ? "white" : isSelected ? "white" : "black",

        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "white"
              : color
            : undefined,
          color: isDisabled ? "white" : isSelected ? "black" : "white",
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const handlePageChange = (e) => {
    setManajemenPodcastState("page", e.selected + 1);
  };

  useEffect(() => {
    getCategoryPodcast();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="#EDF2F7"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            {mode !== "" && (
              <IconButton
                aria-label="Previous Page"
                icon={<AiOutlineArrowLeft />}
                fontSize="md"
                mr={2}
                onClick={() => setManajemenPodcastState("mode", "")}
              />
            )}
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              Podcast
            </Heading>
          </Flex>
          {(mode === "create" || mode === "edit") && publish ? (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Publish"
              onClick={() => {
                postData();
              }}
            >
              Publish
            </Button>
          ) : (mode === "create" || mode === "edit") && !publish ? (
            <Button
              color="white"
              bg="#3C4952"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Simpan Draft"
              _hover={{ bg: "#3C4952" }}
              onClick={() => {
                postData();
              }}
            >
              Simpan Draft
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              onClick={() => {
                clearState();
                setManajemenPodcastState("mode", "create");
              }}
            >
              Tambah Baru
            </Button>
          )}
        </Flex>
      </Box>

      {mode === "create" || mode === "edit" ? (
        <Box m={4} px={4} py={8} boxShadow="lg" borderRadius="xl" bg="white">
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* judul */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Judul
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={title}
                fontSize="sm"
                onChange={(e) =>
                  setManajemenPodcastState("title", e.target.value)
                }
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "blue",
                  borderColor: "blue",
                }}
              />
            </GridItem>

            {/* Kategori */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Kategori
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Select
                placeholder="Pilih Kategori"
                options={listCategoryPodcast}
                isClearable
                onChange={(e) => setManajemenPodcastState("category", e)}
                value={category}
                styles={colourStyles}
              />
            </GridItem>

            {/* Upload Video */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Audio Podcast
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <BoxUploadFile
                setFile={setManajemenPodcastState}
                typeFile="audio"
                defaultFile={audio}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Cover Thumbnail
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <BoxUploadFile
                setFile={setManajemenPodcastState}
                typeFile="image"
                defaultFile={image}
              />
            </GridItem>

            {/* Deskripsi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Deskripsi
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <EditorComponent
                description={description}
                setDescription={setManajemenPodcastState}
              />
            </GridItem>

            {/* Publikasi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Publikasi
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Switch
                defaultChecked={publish}
                colorScheme="blue"
                mr={2}
                onChange={(e) =>
                  setManajemenPodcastState("publish", e.target.checked)
                }
              />
              <Text fontWeight="semibold" fontSize="sm">
                {publish ? "Publikasi" : "Draft"}
              </Text>
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={4} m={4}>
          {loadingData ? (
            <>
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
            </>
          ) : loadingEdit ? (
            <Spinner />
          ) : (
            <>
              {listData.map((item) => (
                <GridItem colSpan={{ base: 3, md: 1 }} key={item.id}>
                  <CardItem
                    item={item}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    image={item.thumbnail}
                    module="podcast"
                  />
                </GridItem>
              ))}
            </>
          )}
        </Grid>
      )}
      {pagination && totalPage > 1 && (
        <Flex justifyContent="end" mt={2} mr={3}>
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={page}
          />
        </Flex>
      )}
    </SidebarWithHeader>
  );
};

const ManajemenPodcastoMain: React.FC = () => {
  return (
    <ManajemenPodcastController.Provider>
      <PodcastCategoryContext.Provider>
        <ManajemenPodcast />
      </PodcastCategoryContext.Provider>
    </ManajemenPodcastController.Provider>
  );
};

export default ManajemenPodcastoMain;
