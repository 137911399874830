import React from "react";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { UjianPresenter } from "../../../../../data/presenters/UjianPresenter";
import { Exam } from "../../../../../entities/Exam";
import update from "immutability-helper";

interface IState {
  isLoading: boolean;
  isLoadingStartUjian: boolean;
  ujian: Exam | null;
  soalDipilih: string;
  bankSoal: any;
  bankJawaban: any;
  indexSoal: number;
  jawabanPeserta: string;
  duration: number;
}

interface InitialState {
  state: IState;
  getUjian: Function;
  startUjian: Function;
  setIndexSoal: Function;
  setSoalDipilih: Function;
  setBankJawaban: Function;
  onChangeAnswer: Function;
  setJawabanPeserta: Function;
  setTime: Function;
  finishUjian: Function;
}

const initialState = {
  state: {
    isLoading: true,
    isLoadingStartUjian: true,
    ujian: null,
    soalDipilih: "",
    bankSoal: [],
    bankJawaban: [],
    indexSoal: 0,
    jawabanPeserta: "",
    duration: 0,
  },
  getUjian: () => {},
  startUjian: () => {},
  setIndexSoal: () => {},
  setSoalDipilih: () => {},
  setBankJawaban: () => {},
  onChangeAnswer: () => {},
  setJawabanPeserta: () => {},
  setTime: () => {},
  finishUjian: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: UjianProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingStartUjian: false,
    ujian: null,
    soalDipilih: "",
    bankSoal: [],
    bankJawaban: [],
    indexSoal: 0,
    jawabanPeserta: "",
    duration: 0,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingStartUjian = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingStartUjian: value,
    }));
  };

  const setIndexSoal = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexSoal: value,
    }));
  };

  const setSoalDipilih = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      soalDipilih: value,
    }));
  };

  const setBankJawaban = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      bankJawaban: value,
    }));
  };

  const setJawabanPeserta = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jawabanPeserta: value,
    }));
  };

  const startUjian = async (sub_modul_pesub_modul_pelatihan_id: number) => {
    setLoadingStartUjian(true);

    try {
      const ujianPresenter = container.resolve(UjianPresenter);
      await ujianPresenter.startUjian(sub_modul_pesub_modul_pelatihan_id);

      window.location.href = "/ujian/" + sub_modul_pesub_modul_pelatihan_id;
      setLoadingStartUjian(false);
    } catch (error: any) {
      console.log("error ujian:", error);
      toast.error(`Error saat memulai ujian : ${error}`, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingStartUjian(false);
    }
  };

  const getUjian = async (sub_modul_pelatihan_id: number) => {
    setLoading(true);

    try {
      const ujianPresenter = container.resolve(UjianPresenter);
      const getUjian = await ujianPresenter.getUjian(sub_modul_pelatihan_id);

      const data = getUjian;

      setState((prevstate) => ({
        ...prevstate,
        ujian: data,
        soalDipilih: data.m_soal[0].soal,
        bankSoal: data.m_soal,
        bankJawaban: data.m_soal[0].m_jawaban_soal,
        jawabanPeserta: data.m_soal[0].jawaban_peserta,
        duration: data.sisa_waktu,
      }));

      localStorage.setItem("passing_grade", data.passing_grade);
      localStorage.setItem("judul_sub_modul", data.judul_sub_modul);

      setLoading(false);
    } catch (error: any) {
      console.log("error latihan:", error);
      setLoading(false);
    }
  };

  const onChangeAnswer = (id_jawaban: string) => {
    saveAnswer(id_jawaban);

    const updatedData = update(state.bankSoal[state.indexSoal], {
      jawaban_peserta: { $set: id_jawaban },
    });

    const newData = update(state.bankSoal, {
      $splice: [[state.indexSoal, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      bankSoal: newData,
      jawabanPeserta: id_jawaban,
    }));

    return;
  };

  const saveAnswer = async (value: string) => {
    try {
      const ujianPresenter = container.resolve(UjianPresenter);
      await ujianPresenter.answerUjian(
        state.bankSoal[state.indexSoal].id,
        state.bankSoal[state.indexSoal].id_jawaban_latihan,
        value,
      );
    } catch (error: any) {
      console.log("error save answer:", error);
    }
  };

  const setTime = async (sub_modul_pelatihan_id: number) => {
    try {
      const ujianPresenter = container.resolve(UjianPresenter);
      await ujianPresenter.updateWaktu(sub_modul_pelatihan_id);
    } catch (error: any) {
      console.log("error update duration:", error.message);
    }
  };

  const finishUjian = async (sub_modul_pelatihan_id: number) => {
    try {
      const ujianPresenter = container.resolve(UjianPresenter);
      const finishUjian = await ujianPresenter.finishUjian(
        sub_modul_pelatihan_id,
      );
      const response = finishUjian;

      const data = response.data.data;

      localStorage.setItem("nilai_peserta", data.nilai);
      localStorage.setItem("ujian_akhir", "true");

      window.location.href = "/skor/" + sub_modul_pelatihan_id;
    } catch (error: any) {
      console.log("error finish latihan:", error.message);
    }
  };

  return (
    <UjianProvider
      value={{
        state,
        getUjian,
        startUjian,
        setIndexSoal,
        setSoalDipilih,
        setBankJawaban,
        onChangeAnswer,
        setJawabanPeserta,
        setTime,
        finishUjian,
      }}
    >
      {children}
    </UjianProvider>
  );
};

export const useUjianContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useUjianContext,
  Provider,
};
