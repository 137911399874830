import React from "react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { container } from "tsyringe";
import { AuthPresenter } from "../../../../../data/presenters/AuthPresenter";
import history from "../../../../../history";
import { SetLocalStorage } from "../../../../misc/UseLocalStorage";

interface IState {
  isLoading: boolean;
  email: string;
  password: string;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  handleDoLogin: Function;
  changeEmail: Function;
  changePassword: Function;
  checkSession: Function;
}

const initialState = {
  state: {
    isLoading: false,
    email: "",
    password: "",
    error: false,
    msgError: "",
  },
  handleDoLogin: () => {},
  changeEmail: () => {},
  changePassword: () => {},
  checkSession: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: LoginProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    email: "",
    password: "",
    error: false,
    msgError: "",
  });

  // use sign in function
  const signIn = useSignIn();

  // use check session
  const isAuthenticated = useIsAuthenticated();

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const handleDoLogin = async () => {
    setLoading(true);

    if (state.email === "") {
      toast.error("Email harus di isi.", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    if (state.password === "") {
      toast.error("Password harus di isi.", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const authPresenter = container.resolve(AuthPresenter);
      const logins = await authPresenter.doLoginPresenter(
        state.email,
        state.password,
      );

      const mainData = logins;

      const user = {
        id: mainData.id,
        username: mainData.name,
        email: mainData?.email,
        level: mainData?.level,
        jenis_user: mainData?.jenis_user,
      };

      // set image foto
      SetLocalStorage({ name: "path_photo", value: mainData.path_foto });

      // set level
      SetLocalStorage({ name: "level", value: mainData.level });

      // set status pengisian
      SetLocalStorage({
        name: "status_pengisian",
        value: mainData.status_pengisian,
      });

      if (
        signIn({
          token: mainData.token,
          expiresIn: mainData.expired_in,
          tokenType: "Bearer",
          authState: user,
        })
      ) {
        switch (mainData.level) {
          case "superadmin":
            history.replace(`/admin/dashboard`);
            break;
          case "admin":
            history.replace(`/admin/dashboard`);
            break;
          case "peserta":
            history.replace("/");
            break;

          default:
            break;
        }

        setLoading(false);
        window.location.reload();
      } else {
        toast.error("Gagal login, silahkan hubungi admin!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setLoading(false);
        return;
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message?.error, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      console.log("error login:", error.response?.data?.message?.error);
      setLoading(false);
      return;
    }
  };

  const changeEmail = (email: string) => {
    setState((prevstate) => ({
      ...prevstate,
      email: email,
    }));
  };

  const changePassword = (password: string) => {
    setState((prevstate) => ({
      ...prevstate,
      password: password,
    }));
  };

  const checkSession = async () => {
    if (isAuthenticated() !== false) {
      history.replace(`/`);
      window.location.reload();
    } else {
      history.replace("/login");
      window.location.reload();
      toast.error("Session anda telah habis, silahkan login kembali!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  return (
    <LoginProvider
      value={{
        state,
        handleDoLogin,
        changeEmail,
        changePassword,
        checkSession,
      }}
    >
      {children}
    </LoginProvider>
  );
};

export const useLoginContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useLoginContext,
  Provider,
};
