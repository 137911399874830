import { injectable } from "tsyringe";
import { KelurahanApiRepository } from "../../app/repository/api/KelurahanApiRepository";
import { Kelurahan } from "../../entities/Kelurahan";

@injectable()
export class KelurahanPresenter {
  private repository: KelurahanApiRepository;
  constructor(repository: KelurahanApiRepository) {
    this.repository = repository;
  }

  public getAllKelurahan(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<Kelurahan[]> {
    return this.repository.getAllKelurahan(page, rows, keyword);
  }

  public getKelurahanById(id: number): Promise<Kelurahan[]> {
    return this.repository.getKelurahanById(id);
  }

  public createKelurahan(
    kecamatan_id: string,
    nama_kelurahan: string,
  ): Promise<any> {
    return this.repository.createKelurahan(kecamatan_id, nama_kelurahan);
  }

  public updateKelurahan(
    id: number,
    kecamatan_id: string,
    nama_kelurahan: string,
  ): Promise<any> {
    return this.repository.updateKelurahan(id, kecamatan_id, nama_kelurahan);
  }

  public deleteKelurahan(id: number): Promise<any> {
    return this.repository.deleteKelurahan(id);
  }
}
