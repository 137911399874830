import { Box } from "@chakra-ui/react";
import React from "react";
import { If, Then } from "react-if";
import { useDetailPelatihanKontenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/detail/konten_pelatihan";
import ItemSoal from "./ItemSoal";

/**
 * The TabsQuiz component is used to display quiz questions and answers for a sub-module.
 * This component utilizes information obtained from `useDetailPelatihanKontenPelatihanContext`.
 * @component
 * @author Muhammad Farras Jibran
 */

const TabsQuiz = () => {
  const { quiz } = useDetailPelatihanKontenPelatihanContext().state;
  return (
    <>
      <If condition={quiz.length > 0}>
        <Then>
          <Box overflowY="scroll" pr={3} height="750px" w="full">
            {quiz.map((q, i) => {
              return (
                <ItemSoal
                  idAccordion={"Soal" + (i + 1)}
                  nomor={i + 1}
                  soal={q.pertanyaan}
                  jawaban={q.JawabanQuiz}
                />
              );
            })}
          </Box>
        </Then>
      </If>
    </>
  );
};

export default TabsQuiz;
