import { injectable } from "tsyringe";
import { HelpdeskBantuanApiRepository } from "../../app/repository/api/HelpdeskBantuanApiRepository";
import { HelpdeskBantuan } from "../../entities/HelpdeskBantuan";

@injectable()
export class HelpdeskBantuanPresenter {
  private repository: HelpdeskBantuanApiRepository;
  constructor(repository: HelpdeskBantuanApiRepository) {
    this.repository = repository;
  }

  public getAllHelpdeskBantuan(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<HelpdeskBantuan[]> {
    return this.repository.getAllHelpdeskBantuan(page, rows, keyword);
  }

  public updateHelpdeskBantuan(id: number, tanggapan: string): Promise<any> {
    return this.repository.updateHelpdeskBantuan(id, tanggapan);
  }

  public deleteHelpdeskBantuan(id: number): Promise<any> {
    return this.repository.deleteHelpdeskBantuan(id);
  }
}
