import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { FileUploader } from "react-drag-drop-files";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsSearch, BsTrashFill } from "react-icons/bs";
import { Else, If, Then } from "react-if";
import ReactLoading from "react-loading";
import Switch from "react-switch";
import { ToastContainer } from "react-toastify";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import MasterSliderController, {
  useMasterSliderContext,
} from "../../../../controller/admin/data_master/slider/index";

const ModalCreateData: React.FC = () => {
  const {
    formDialog,
    formNamaUserTestimoni,
    formTestimoni,
    formJabatanTestimoni,
    formLoading,
    showDialog,
    formPathSlider,
    formStatusPublish,
  } = useMasterSliderContext().state;
  const {
    actionFormDialog,
    setformNamaUserTestimoni,
    setformTestimoni,
    setformJabatanTestimoni,
    setShowDialog,
    setFormPathSlider,
    setFormStatusPublish,
  } = useMasterSliderContext();

  const tahun: number[] = [];

  for (let i = 2000; i <= 2099; i++) {
    tahun.push(i);
  }

  const fileTypes = ["jpg", "jpeg", "png"];

  const handleChangePathSlider = (file: File) => {
    setFormPathSlider(file);
  };

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {formDialog} Slider
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialog(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Nama User Testimoni
                    </label>
                    <input
                      type="text"
                      className="
                            form-control
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            col-span-4
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                      placeholder="Masukkan Nama User Testimoni"
                      value={formNamaUserTestimoni}
                      onChange={(e) => setformNamaUserTestimoni(e.target.value)}
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Testimoni
                    </label>
                    <input
                      type="text"
                      className="
                            form-control
                            col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                      placeholder="Masukkan Testimoni"
                      value={formTestimoni}
                      onChange={(e) => setformTestimoni(e.target.value)}
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Jabatan User Testimoni
                    </label>
                    <input
                      type="text"
                      className="
                            form-control
                            col-span-4
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                      placeholder="Masukkan Jabatan User Testimoni"
                      value={formJabatanTestimoni}
                      onChange={(e) => setformJabatanTestimoni(e.target.value)}
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Path Slider
                    </label>
                    <div className="col-span-4">
                      <FileUploader
                        multiple={false}
                        handleChange={handleChangePathSlider}
                        name="file"
                        types={fileTypes}
                      />
                      <p>
                        {formPathSlider
                          ? `File name: ${formPathSlider.name}`
                          : "no files uploaded yet"}
                      </p>
                    </div>
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Publish Slider
                    </label>
                    <Switch
                      className="col-span-4"
                      height={20}
                      onChange={() => setFormStatusPublish(!formStatusPublish)}
                      checked={formStatusPublish}
                    />
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialog(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => actionFormDialog()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, formLoading } = useMasterSliderContext().state;
  const { setShowDialogDelete, deleteSlider } = useMasterSliderContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Slider
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteSlider()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const {
    getSlider,
    setFormDialog,
    setShowDialog,
    setUpdate,
    setShowDialogDelete,
    setPageRow,
    searchSlider,
  } = useMasterSliderContext();
  const { slider, isLoading, keyword } = useMasterSliderContext().state;
  const [perPage, setPerPage] = React.useState(10);
  const [filterText] = React.useState("");
  const [resetPaginationToggle] = React.useState(false);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Nama User Testimoni",
      selector: (row) => row.nama_user_testimoni,
      sortable: true,
    },
    {
      name: "Testimoni",
      selector: (row) => row.testimoni,
      sortable: true,
    },
    {
      name: "Jabatan Testimoni",
      selector: (row) => row.jabatan_user_testimoni,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            {/* Edit Button */}
            <button
              className="ml-2 px-2 py-1 text-xs text-green-700 bg-green-100 hover:bg-green-200 rounded-full"
              onClick={() => {
                setFormDialog("Ubah");
                setShowDialog(true);
                setUpdate(row);
              }}
            >
              <BiEdit
                style={{ marginTop: "2px", fontSize: "20px", color: "#ffffff" }}
              />
            </button>
            {/* Delete Button */}
            <button
              className="ml-2 px-2 py-1 text-xs text-red-700 bg-red-100 hover:bg-red-200 rounded-full"
              onClick={() => {
                setShowDialogDelete(true);
                setUpdate(row);
              }}
            >
              <BsTrashFill
                style={{ marginTop: "2px", fontSize: "20px", color: "#ffffff" }}
              />
            </button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getSlider(1, "");
  }, []);

  const handlePageChange = (page) => {
    getSlider(page, keyword, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getSlider(page, keyword, newPerPage);
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-row justify-between w-full items-center">
        <p className="font-semibold text-base">Slider</p>
        <div className="flex flex-wrap">
          <div className="input-group relative flex flex-wrap mr-2">
            <button
              className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              style={{ height: "38px" }}
            >
              <BsSearch />
            </button>
            <input
              type="search"
              className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
              placeholder="Search"
              onChange={(event) => searchSlider(event.target.value)}
            />
          </div>
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs rounded flex flex-row items-center hover:bg-green-500"
            style={{ height: "38px" }}
            onClick={() => {
              setFormDialog("Tambah");
              setShowDialog(true);
            }}
          >
            Tambah Baru <AiFillPlusCircle className="ml-3" />
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"blue"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  return (
    <div className="app">
      <ModalCreateData />
      <ModalDelete />

      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40"></header>

        <div className="container px-5 py5">
          <div className="bg-white px-5 py-5 rounded-lg mb-5 shadow-lg">
            <DataTable
              columns={columns}
              data={slider}
              progressPending={isLoading}
              pagination
              paginationServer
              paginationTotalRows={
                slider.length > 0 ? slider[0].jumlahDataSlider : 0
              }
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={customStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              progressComponent={<CustomLoader />}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

const MasterSlider: React.FC = () => {
  return (
    <MasterSliderController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <SidebarWithHeader title="Master - Slider">
        <WrapperMain />
      </SidebarWithHeader>
    </MasterSliderController.Provider>
  );
};

export default MasterSlider;
