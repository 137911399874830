import { ReviewMateriRepositoryInterface } from "../../../data/contracts/ReviewMateri";
import { ReviewMateriDataMapper } from "../../../data/mappers/ReviewMateri";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ReviewMateri } from "../../../entities/ReviewMateri";
import { GetLocalStorage } from "../../misc/UseLocalStorage";

export class ReviewMateriApiRepository
  implements ReviewMateriRepositoryInterface
{
  private apiService: ApiService;
  private mapper: ReviewMateriDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: ReviewMateriDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   *
   * @param page
   * @param rows
   * @param keyword
   * @param materi_id
   * @param rating
   * @param foto
   * @returns
   */
  public async getReviewMateri(
    page: number,
    rows: number,
    keyword: string,
    materi_id: number,
    rating: string,
    foto: boolean,
  ): Promise<ReviewMateri[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.reviewMateri()
        : "public/" + this.endpoints.reviewMateri(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        materi_id: materi_id,
        rating: rating,
        foto: foto,
      },
      null,
      headers,
    );
    return this.mapper.convertListReviewMateriDataFromApi(dataResponse);
  }

  /**
   *
   * @param materi_id
   * @param komentar
   * @param path_foto_review_pelatihan
   * @param rating
   * @returns
   */
  public async storeReviewMateri(
    materi_id: number,
    komentar: string,
    path_foto_review_pelatihan: any[],
    rating: number,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("materi_id", materi_id.toString());
    formData.append("komentar", komentar);
    path_foto_review_pelatihan.forEach((v) =>
      formData.append("path_foto_review_materi[]", v),
    );
    formData.append("rating", rating.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.reviewMateri(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }
}
