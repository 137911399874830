import { injectable } from "tsyringe";
import { SoalApiRepository } from "../../app/repository/api/SoalApiRepository";
import { Soal } from "../../entities/PaketSoal";

@injectable()
export class SoalPresenter {
  private repository: SoalApiRepository;
  constructor(repository: SoalApiRepository) {
    this.repository = repository;
  }

  public getDetailSoal(id: number): Promise<Soal> {
    return this.repository.getDetailSoal(id);
  }

  public storeSoal(paket_soal_id: number, soal: string): Promise<any> {
    return this.repository.storeSoal(paket_soal_id, soal);
  }

  public updateSoal(paket_soal_id: number, soal: string): Promise<any> {
    return this.repository.updateSoal(paket_soal_id, soal);
  }

  public deleteSoal(id: number): Promise<any> {
    return this.repository.deleteSoal(id);
  }
}
