import { injectable } from "tsyringe";
import { ExamApiRepository } from "../../app/repository/api/ExamApiRepository";
import { Exam } from "../../entities/Exam";

@injectable()
export class ExamPresenter {
  private repository: ExamApiRepository;
  constructor(repository: ExamApiRepository) {
    this.repository = repository;
  }

  /**
   * getLatihan
   */
  public getLatihan(latihan_id: number): Promise<Exam> {
    return this.repository.getLatihan(latihan_id);
  }

  /**
   * startLatihan
   */
  public startLatihan(latihan_id: number): Promise<any> {
    return this.repository.startLatihan(latihan_id);
  }

  /**
   * finishLatihan
   */
  public finishLatihan(latihan_id: number): Promise<any> {
    return this.repository.finishLatihan(latihan_id);
  }

  /**
   * answerLatihan
   */
  public answerLatihan(
    soal_id: number,
    id_jawaban_peserta: number,
    jawaban_peserta: string,
  ): Promise<any> {
    return this.repository.answerLatihan(
      soal_id,
      id_jawaban_peserta,
      jawaban_peserta,
    );
  }

  /**
   * updateWaktu
   */
  public updateWaktu(latihan_id: number): Promise<any> {
    return this.repository.updateWaktu(latihan_id);
  }

  /**
   * reattempLatihan
   */
  public reattempLatihan(latihan_id: number): Promise<any> {
    return this.repository.reattemptLatihan(latihan_id);
  }
}
