import { Box, Image, Link, Skeleton } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import { useSliderBannerPublicContext } from "../../../controller/public/slider_banner";

/**
 * Renders a Hero Carousel component for mobile.
 *
 * @returns {React.FC} The HeroCarouselMobile component.
 * @author Muhammad Farras Jibran
 */

const settingsReactSlick = {
  arrows: false,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 1,
  speed: 1500,
  autoplay: true,
  dots: true,
};

const HeroCarouselMobile: React.FC = () => {
  const { sliderBannerData: data, loadingData } =
    useSliderBannerPublicContext().state;

  return (
    <>
      {loadingData ? (
        <Box display="flex" justifyContent="center" my={8}>
          <Skeleton height="350px" width="80%" />
        </Box>
      ) : (
        <Box
          bg="BlueSage"
          display="flex"
          justifyContent="center"
          py="10"
          px="4"
          flexDirection="column"
        >
          <Box w="full" className="slider-container">
            <Slider {...settingsReactSlick}>
              {data.map((item) =>
                item.link ? (
                  <Link
                    href={item.link}
                    isExternal
                    display="flex"
                    position="relative"
                    px={2}
                  >
                    <Image
                      fallback={<Skeleton h="full" w="full" />}
                      borderRadius="xl"
                      src={item.file}
                      alt={`Hero ${item.title}`}
                      w="full"
                      objectFit="cover"
                    />
                  </Link>
                ) : (
                  <Box display="flex" position="relative" px={2}>
                    <Image
                      fallback={<Skeleton h="full" w="full" />}
                      borderRadius="lg"
                      src={item.file}
                      alt={`Hero ${item.title}`}
                      w="full"
                      objectFit="cover"
                    />
                  </Box>
                ),
              )}
            </Slider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default HeroCarouselMobile;
