import { Dashboard } from "../../entities/Dashboard";
import { AxiosResponse } from "axios";

export class DashboardDataMapper {
  public convertListDataDasboard(result: AxiosResponse<any>): Dashboard {
    const { data } = result.data;

    return new Dashboard(
      data.peserta_pertahun,
      data.total_pelatihan,
      data.total_pelatihan_on_progress,
      data.total_pelatihan_finish,
      data.total_pengguna,
      data.total_pengguna_online,
      data.total_peserta,
      data.total_peserta_lulus,
      data.total_peserta_tidak_lulus,
      data.total_peserta_predikat_sangat_kompeten,
      data.total_peserta_predikat_kompeten,
      data.total_peserta_predikat_cukup_kompeten,
      data.total_peserta_predikat_tidak_lulus,
      data.peserta_perjenis_pelatihan,
      data.pengguna_aktif_perhari,
      data.total_asn,
      data.total_non_asn,
    );
  }
}
