/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { SoalPresenter } from "../../../../../../data/presenters/SoalPresenter";
import { JawabanSoalPresenter } from "../../../../../../data/presenters/JawabanSoalPresenter";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import update from "immutability-helper";
import SetInterval from "set-interval";
interface IState {
  isLoading: boolean;
  isLoadingCreateSoal: boolean;
  soal: string;
  increment: string;
  opsiJawaban: any;
  pilihanJawaban: any;
  jawabanData: any;
}

interface InitialState {
  state: IState;
  savePertanyaan: Function;
  setSoal: Function;
  setIncrement: Function;
  setPilihan: Function;
  setJawabanBenar: Function;
  deleteJawaban: Function;
  createSoal: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingCreateSoal: false,
    soal: "",
    actionStore: "",
    increment: "a",
    opsiJawaban: ["a"],
    pilihanJawaban: [],
    jawabanData: [],
  },
  savePertanyaan: () => {},
  setSoal: () => {},
  setIncrement: () => {},
  setPilihan: () => {},
  setJawabanBenar: () => {},
  deleteJawaban: () => {},
  createSoal: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenSoalCreateProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingCreateSoal: false,
    soal: "",
    increment: "a",
    opsiJawaban: ["a"],
    pilihanJawaban: [],
    jawabanData: [],
  });

  const setLoadingCreateSoal = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingCreateSoal: value,
    }));
  };

  const setSoal = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      soal: value,
    }));
  };

  const setIncrement = (value: string) => {
    if (value == "z") {
      toast.error("Anda sudah sampai batas maksimum penambahan jawaban!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    } else {
      setState((prevstate) => ({
        ...prevstate,
        increment: String.fromCharCode(value.charCodeAt(0) + 1),
        opsiJawaban: prevstate.opsiJawaban.concat(
          String.fromCharCode(value.charCodeAt(0) + 1),
        ),
        pilihanJawaban: [
          ...prevstate.pilihanJawaban,
          {
            pilihan: String.fromCharCode(value.charCodeAt(0) + 1),
            value: "",
            jawaban_benar: false,
          },
        ],
      }));
      if (state.pilihanJawaban.length > 0) {
        setState((prevstate) => ({
          ...prevstate,
          jawabanData: state.pilihanJawaban,
        }));
      }
    }
  };

  const savePertanyaan = async (id: number, action: string) => {
    setLoadingCreateSoal(true);

    if (state.soal == "") {
      toast.error("Soal Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingCreateSoal(false);
      return;
    }

    try {
      const soalPresenter = container.resolve(SoalPresenter);
      const store = await soalPresenter.storeSoal(id, state.soal);

      const data = store.data;

      if (data.code == 200) {
        createSoal(id, action, data.data.id);
      }
    } catch (error: any) {
      toast.error(`Error saat menyimpan soal : ${error.message}`, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingCreateSoal(false);
    }
  };

  const createSoal = (id: number, actionStore: string, idSoal: number) => {
    const getIndexPilihan = state.pilihanJawaban.findIndex(
      (v) => v.jawaban_benar == true,
    );

    if (getIndexPilihan == -1) {
      toast.error("Anda belum memilih jawaban benar", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingCreateSoal(false);
      return;
    }

    try {
      const jawabanSoalPresenter = container.resolve(JawabanSoalPresenter);
      state.pilihanJawaban.map(async (val) => {
        await jawabanSoalPresenter.storeJawabanSoal(
          idSoal,
          val.value,
          val.jawaban_benar,
        );
      });

      SetInterval.start(() => {
        toast.success("Berhasil Menyimpan Soal", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        if (actionStore == "simpan") {
          setLoadingCreateSoal(false);
          history.replace(`/admin/detail/manajemen_soal/${id}`);
          window.location.reload();
        } else {
          setLoadingCreateSoal(false);
          history.replace(`/admin/tambah/manajemen_soal/${id}`);
          window.location.reload();
        }
      }, 1000);
    } catch (error: any) {
      console.log("error when create soal :", error.data.message);
      toast.error(`Error saat create soal : ${error.data.message}`, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingCreateSoal(false);
    }
  };

  const setPilihan = (value: string, pilihan: string) => {
    if (state.pilihanJawaban.length == 0) {
      setState((prevstate) => ({
        ...prevstate,
        pilihanJawaban: [
          ...prevstate.pilihanJawaban,
          { pilihan: pilihan, value: value, jawaban_benar: false },
        ],
      }));
    } else {
      const getIndexPilihan = state.pilihanJawaban.findIndex(
        (x) => x.pilihan == pilihan,
      );
      if (getIndexPilihan != -1) {
        const updatedData = update(state.pilihanJawaban[getIndexPilihan], {
          pilihan: { $set: pilihan },
          value: { $set: value },
        });

        const newData = update(state.pilihanJawaban, {
          $splice: [[getIndexPilihan, 1, updatedData]],
        });

        setState((prevstate) => ({
          ...prevstate,
          pilihanJawaban: newData,
        }));
      } else {
        setState((prevstate) => ({
          ...prevstate,
          pilihanJawaban: [
            ...prevstate.pilihanJawaban,
            { pilihan: pilihan, value: value, jawaban_benar: false },
          ],
        }));
      }
    }
  };

  const setJawabanBenar = (idx: number, jenis_soal: string) => {
    if (jenis_soal == "Pilihan Ganda Kompleks") {
      const checkJawabanBefore = state.pilihanJawaban[idx];
      if (checkJawabanBefore.jawaban_benar == true) {
        // Set if jawaban same to false
        const jawabanBefore = update(state.pilihanJawaban[idx], {
          jawaban_benar: { $set: false },
        });
        const dataJawabanBefore = update(state.pilihanJawaban, {
          $splice: [[idx, 1, jawabanBefore]],
        });

        setState((prevstate) => ({
          ...prevstate,
          pilihanJawaban: dataJawabanBefore,
        }));
      } else {
        const updateJawabanData = update(state.pilihanJawaban[idx], {
          jawaban_benar: { $set: true },
        });
        const newDataJawaban = update(state.pilihanJawaban, {
          $splice: [[idx, 1, updateJawabanData]],
        });

        setState((prevstate) => ({
          ...prevstate,
          pilihanJawaban: newDataJawaban,
        }));
      }
    } else {
      const checkJawabanBefore = state.pilihanJawaban.findIndex(
        (v) => v.jawaban_benar == true,
      );
      if (checkJawabanBefore != -1) {
        // Set Jawaban Before To False
        const jawabanBefore = update(state.pilihanJawaban[checkJawabanBefore], {
          jawaban_benar: { $set: false },
        });
        const dataJawabanBefore = update(state.pilihanJawaban, {
          $splice: [[checkJawabanBefore, 1, jawabanBefore]],
        });

        // Set Jawaban New To True
        const updateJawabanData = update(state.pilihanJawaban[idx], {
          jawaban_benar: { $set: true },
        });
        const newDataJawaban = update(dataJawabanBefore, {
          $splice: [[idx, 1, updateJawabanData]],
        });

        setState((prevstate) => ({
          ...prevstate,
          pilihanJawaban: newDataJawaban,
        }));
      } else {
        const updateJawabanData = update(state.pilihanJawaban[idx], {
          jawaban_benar: { $set: true },
        });
        const newDataJawaban = update(state.pilihanJawaban, {
          $splice: [[idx, 1, updateJawabanData]],
        });

        setState((prevstate) => ({
          ...prevstate,
          pilihanJawaban: newDataJawaban,
        }));
      }
    }
  };

  const deleteJawaban = (idx: number) => {
    const newDataPilihan = update(state.pilihanJawaban, {
      $splice: [[idx, 1]],
    });
    const newDataOpsi = update(state.opsiJawaban, { $splice: [[idx, 1]] });

    setState((prevstate) => ({
      ...prevstate,
      pilihanJawaban: newDataPilihan,
      opsiJawaban: newDataOpsi,
      jawabanData: newDataPilihan,
    }));
  };

  return (
    <ManajemenSoalCreateProvider
      value={{
        state,
        savePertanyaan,
        setSoal,
        setIncrement,
        setPilihan,
        setJawabanBenar,
        deleteJawaban,
        createSoal,
      }}
    >
      {children}
    </ManajemenSoalCreateProvider>
  );
};

export const useManajemenSoalCreateContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenSoalCreateContext,
  Provider,
};
