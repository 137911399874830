import { injectable } from "tsyringe";
import { SliderApiRepository } from "../../app/repository/api/SliderApiRepository";
import { Slider } from "../../entities/Slider";

@injectable()
export class SliderPresenter {
  private repository: SliderApiRepository;
  constructor(repository: SliderApiRepository) {
    this.repository = repository;
  }

  public getAllSlider(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<Slider[]> {
    return this.repository.getAllSlider(page, rows, keyword);
  }

  public storeSlider(
    nama_user_testimoni: string,
    testimoni: string,
    jabatan_user_testimoni: string,
    path_slider: File,
    status_publish: boolean,
  ): Promise<any> {
    return this.repository.storeSlider(
      nama_user_testimoni,
      testimoni,
      jabatan_user_testimoni,
      path_slider,
      status_publish,
    );
  }

  public updateSlider(
    id: number,
    nama_user_testimoni: string,
    testimoni: string,
    jabatan_user_testimoni: string,
    path_slider: File,
    status_publish: boolean,
  ): Promise<any> {
    return this.repository.updateSlider(
      id,
      nama_user_testimoni,
      testimoni,
      jabatan_user_testimoni,
      path_slider,
      status_publish,
    );
  }

  public deleteSlider(id: number): Promise<any> {
    return this.repository.deleteSlider(id);
  }
}
