/* eslint-disable eqeqeq */
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { container } from "tsyringe";
import { KecamatanPresenter } from "../../../../../../data/presenters/KecamatanPresenter";
import { KelurahanPresenter } from "../../../../../../data/presenters/KelurahanPresenter";
import { Kecamatan } from "../../../../../../entities/Kecamatan";
import { Kelurahan } from "../../../../../../entities/Kelurahan";
import axiosInstance from "../../../../../api/axiosInstance";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalKelurahan: number;
  kelurahan: Kelurahan[];
  kecamatan: Kecamatan[];
  formId: number;
  formDialog: string;
  formKecamatanId: string;
  formNameKelurahan: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  valueKabupaten: {
    label: string;
    value: string;
  } | null;
}

interface InitialState {
  state: IState;
  getKelurahan: Function;
  setPage: Function;
  setPageRow: Function;
  searchKelurahan: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormKecamatanId: Function;
  setFormNameKelurahan: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deleteKelurahan: Function;
  getKecamatan: Function;
  getKecamatanByKabupatenId: Function;
  setValueKabupaten: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKelurahan: 1,
    kelurahan: [],
    kecamatan: [],
    formDialog: "",
    formId: 0,
    formKecamatanId: "",
    formNameKelurahan: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    valueKabupaten: null,
  },
  getKelurahan: () => {},
  searchKelurahan: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormKecamatanId: () => {},
  setFormNameKelurahan: () => {},
  setShowDialog: () => {},
  deleteKelurahan: () => {},
  setShowDialogDelete: () => {},
  getKecamatan: () => {},
  getKecamatanByKabupatenId: () => {},
  setValueKabupaten: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: MasterKelurahanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKelurahan: 0,
    kelurahan: [],
    kecamatan: [],
    formDialog: "",
    formId: 0,
    formKecamatanId: "",
    formNameKelurahan: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    valueKabupaten: null,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getKelurahan = async (pagee: number, keyword: string, row: number) => {
    setLoading(true);

    try {
      const kelurahanPresenter = container.resolve(KelurahanPresenter);
      const getAllKelurahan = await kelurahanPresenter.getAllKelurahan(
        pagee,
        row,
        keyword,
      );

      const data = getAllKelurahan;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        kelurahan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchKelurahan = (value: string) => {
    setLoading(true);
    getKelurahan(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: Kelurahan) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formKecamatanId: value.kecamatan_id.toString(),
      formNameKelurahan: value.nama_kelurahan,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formNameKelurahan == "") {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const kelurahanPresenter = container.resolve(KelurahanPresenter);
      if (state.formDialog == "Tambah") {
        const action = await kelurahanPresenter.createKelurahan(
          state.formKecamatanId,
          state.formNameKelurahan,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKelurahan(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await kelurahanPresenter.updateKelurahan(
          state.formId,
          state.formKecamatanId,
          state.formNameKelurahan,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKelurahan(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormKecamatanId = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKecamatanId: value,
    }));
  };

  const setFormNameKelurahan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNameKelurahan: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formKecamatanId: "",
      formNameKelurahan: "",
    }));
  };

  const deleteKelurahan = async () => {
    setFormLoading(true);

    try {
      const kelurahanPresenter = container.resolve(KelurahanPresenter);
      const action = await kelurahanPresenter.deleteKelurahan(state.formId);
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getKelurahan(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const getKecamatan = async (value) => {
    setLoading(true);

    try {
      const kecamatanPresenter = container.resolve(KecamatanPresenter);
      const getAllKecamatan = await kecamatanPresenter.getAllKecamatan(
        1,
        100000000,
        value,
      );

      const data = getAllKecamatan;

      setState((prevstate) => ({
        ...prevstate,
        kecamatan: data,
      }));
    } catch (error: any) {
      console.log("error kecamatan:", error);
      setLoading(false);
    }
  };

  const getKecamatanByKabupatenId = async (kabupatenId) => {
    try {
      const res = await axiosInstance.get("/kecamatan/" + kabupatenId);
      setState((prevstate) => ({
        ...prevstate,
        kecamatan: res.data.data,
      }));
    } catch (error: any) {
      toast.error(error?.message, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setValueKabupaten = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      valueKabupaten: value,
    }));
  };

  return (
    <MasterKelurahanProvider
      value={{
        state,
        getKelurahan,
        setPage,
        setPageRow,
        searchKelurahan,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormKecamatanId,
        setFormNameKelurahan,
        setShowDialog,
        deleteKelurahan,
        setShowDialogDelete,
        getKecamatan,
        getKecamatanByKabupatenId,
        setValueKabupaten,
      }}
    >
      {children}
    </MasterKelurahanProvider>
  );
};

export const useMasterKelurahanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterKelurahanContext,
  Provider,
};
