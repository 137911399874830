export class PelatihanSimdiklat {
  nama_diklat_id: number;
  nama_diklat_nama: string;
  nama_diklat_jenis_diklat_id: number;
  nama_diklat_jenis_diklat_nama: string;
  tahun: string;
  tanggal_mulai: string;
  tanggal_selesai: string;
  tempat_penyelenggara_id: number;
  tempat_penyelenggara_nama: string;
  lokasi: string;
  pola_diklat_tipe_id: number;
  pola_diklat_tipe_value: string;
  penyelenggara_diklat_id: number;
  penyelenggara_diklat_value: string;
  rencana_anggaran: string;
  realisasi_anggaran: string;
  surat_panggilan: string;
  angkatan: string;
  balai_id: number;
  balai: string;
  sistem_penilaian_peserta_id: number;
  sistem_penilaian_penyelenggara_id: number;
  sistem_penilaian_narasumber_id: number;
  master_template_sertifikat_pelatihan_id: number;
  master_template_surat_keterangan_id: number;
  master_template_sttp_id: number;
  master_template_dikjartih_pernyataan_id: number;
  master_template_dikjartih_penugasan_id: number;
  branches: string;
  diklat_panitia: string;
  id: number;
  created_by: string;
  updated_by: string;
  active: string;
  created_at: string;
  updated_at: string;
  allow_edit: string;
  allow_delete: string;

  constructor(
    nama_diklat_id: number,
    nama_diklat_nama: string,
    nama_diklat_jenis_diklat_id: number,
    nama_diklat_jenis_diklat_nama: string,
    tahun: string,
    tanggal_mulai: string,
    tanggal_selesai: string,
    tempat_penyelenggara_id: number,
    tempat_penyelenggara_nama: string,
    lokasi: string,
    pola_diklat_tipe_id: number,
    pola_diklat_tipe_value: string,
    penyelenggara_diklat_id: number,
    penyelenggara_diklat_value: string,
    rencana_anggaran: string,
    realisasi_anggaran: string,
    surat_panggilan: string,
    angkatan: string,
    balai_id: number,
    balai: string,
    sistem_penilaian_peserta_id: number,
    sistem_penilaian_penyelenggara_id: number,
    sistem_penilaian_narasumber_id: number,
    master_template_sertifikat_pelatihan_id: number,
    master_template_surat_keterangan_id: number,
    master_template_sttp_id: number,
    master_template_dikjartih_pernyataan_id: number,
    master_template_dikjartih_penugasan_id: number,
    branches: string,
    diklat_panitia: string,
    id: number,
    created_by: string,
    updated_by: string,
    active: string,
    created_at: string,
    updated_at: string,
    allow_edit: string,
    allow_delete: string,
  ) {
    this.nama_diklat_id = nama_diklat_id;
    this.nama_diklat_nama = nama_diklat_nama;
    this.nama_diklat_jenis_diklat_id = nama_diklat_jenis_diklat_id;
    this.nama_diklat_jenis_diklat_nama = nama_diklat_jenis_diklat_nama;
    this.tahun = tahun;
    this.tanggal_mulai = tanggal_mulai;
    this.tanggal_selesai = tanggal_selesai;
    this.tempat_penyelenggara_id = tempat_penyelenggara_id;
    this.tempat_penyelenggara_nama = tempat_penyelenggara_nama;
    this.lokasi = lokasi;
    this.pola_diklat_tipe_id = pola_diklat_tipe_id;
    this.pola_diklat_tipe_value = pola_diklat_tipe_value;
    this.penyelenggara_diklat_id = penyelenggara_diklat_id;
    this.penyelenggara_diklat_value = penyelenggara_diklat_value;
    this.rencana_anggaran = rencana_anggaran;
    this.realisasi_anggaran = realisasi_anggaran;
    this.surat_panggilan = surat_panggilan;
    this.angkatan = angkatan;
    this.balai_id = balai_id;
    this.balai = balai;
    this.sistem_penilaian_peserta_id = sistem_penilaian_peserta_id;
    this.sistem_penilaian_penyelenggara_id = sistem_penilaian_penyelenggara_id;
    this.sistem_penilaian_narasumber_id = sistem_penilaian_narasumber_id;
    this.master_template_sertifikat_pelatihan_id =
      master_template_sertifikat_pelatihan_id;
    this.master_template_surat_keterangan_id =
      master_template_surat_keterangan_id;
    this.master_template_sttp_id = master_template_sttp_id;
    this.master_template_dikjartih_pernyataan_id =
      master_template_dikjartih_pernyataan_id;
    this.master_template_dikjartih_penugasan_id =
      master_template_dikjartih_penugasan_id;
    this.branches = branches;
    this.diklat_panitia = diklat_panitia;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.active = active;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.allow_edit = allow_edit;
    this.allow_delete = allow_delete;
  }
}

export class PangkatMasterSimdiklat {
  kode: string;
  pangkat: string;
  ruang: string;
  id: string;
  created_by: string;
  updated_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  allow_edit: boolean;
  allow_delete: boolean;

  constructor(
    kode: string,
    pangkat: string,
    ruang: string,
    id: string,
    created_by: string,
    updated_by: string,
    active: boolean,
    created_at: string,
    updated_at: string,
    allow_edit: boolean,
    allow_delete: boolean,
  ) {
    this.kode = kode;
    this.pangkat = pangkat;
    this.ruang = ruang;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.active = active;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.allow_edit = allow_edit;
    this.allow_delete = allow_delete;
  }
}

export class JabatanMasterSimdiklat {
  kode: string;
  nama: string;
  singkatan: string;
  id: string;
  created_by: string;
  updated_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  allow_edit: boolean;
  allow_delete: boolean;

  constructor(
    kode: string,
    nama: string,
    singkatan: string,
    id: string,
    created_by: string,
    updated_by: string,
    active: boolean,
    created_at: string,
    updated_at: string,
    allow_edit: boolean,
    allow_delete: boolean,
  ) {
    this.kode = kode;
    this.nama = nama;
    this.singkatan = singkatan;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.active = active;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.allow_edit = allow_edit;
    this.allow_delete = allow_delete;
  }
}

export class AgamaMasterSimdiklat {
  key: string;
  value: string;
  enumeration_group: string;
  id: string;
  created_by: string;
  updated_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  allow_edit: boolean;
  allow_delete: boolean;

  constructor(
    key: string,
    value: string,
    enumeration_group: string,
    id: string,
    created_by: string,
    updated_by: string,
    active: boolean,
    created_at: string,
    updated_at: string,
    allow_edit: boolean,
    allow_delete: boolean,
  ) {
    this.key = key;
    this.value = value;
    this.enumeration_group = enumeration_group;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.active = active;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.allow_edit = allow_edit;
    this.allow_delete = allow_delete;
  }
}

export class StatusKawinMasterSimdiklat {
  key: string;
  value: string;
  enumeration_group: string;
  id: string;
  created_by: string;
  updated_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  allow_edit: boolean;
  allow_delete: boolean;

  constructor(
    key: string,
    value: string,
    enumeration_group: string,
    id: string,
    created_by: string,
    updated_by: string,
    active: boolean,
    created_at: string,
    updated_at: string,
    allow_edit: boolean,
    allow_delete: boolean,
  ) {
    this.key = key;
    this.value = value;
    this.enumeration_group = enumeration_group;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.active = active;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.allow_edit = allow_edit;
    this.allow_delete = allow_delete;
  }
}

export class StatusPegawaiMasterSimdiklat {
  key: string;
  value: string;
  enumeration_group: string;
  id: string;
  created_by: string;
  updated_by: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  allow_edit: boolean;
  allow_delete: boolean;

  constructor(
    key: string,
    value: string,
    enumeration_group: string,
    id: string,
    created_by: string,
    updated_by: string,
    active: boolean,
    created_at: string,
    updated_at: string,
    allow_edit: boolean,
    allow_delete: boolean,
  ) {
    this.key = key;
    this.value = value;
    this.enumeration_group = enumeration_group;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.active = active;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.allow_edit = allow_edit;
    this.allow_delete = allow_delete;
  }
}
