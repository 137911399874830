export class Endpoints {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  /**
   * [login description]
   *
   * @return  {string}  [return description]
   */
  login(): string {
    return "auth/login";
  }

  /**
   * [register description]
   *
   * @return  {string}  [return description]
   */
  register(): string {
    return "auth/register";
  }

  /**
   * [profile description]
   *
   * @return  {string}  [return description]
   */
  profile(): string {
    return "auth/me";
  }

  /**
   * [updateProfile description]
   *
   * @return  {string}  [return description]
   */
  updateProfile(): string {
    return "user/update-profil";
  }

  /**
   * [updateProfilePhoto description]
   *
   * @return  {string}  [return description]
   */
  updateProfilePhoto(): string {
    return "user/update-profil-photo";
  }

  /**
   * [updatePassword description]
   *
   * @return  {string}  [return description]
   */
  updatePassword(): string {
    return "user/update-password";
  }

  /**
   * [updateProfileSimpeg description]
   *
   * @return  {string}  [return description]
   */
  updateProfileSimpeg(): string {
    return "user/update-profil-simpeg";
  }

  /**
   * [password description]
   *
   * @return  {string}  [return description]
   */
  password(): string {
    return "password";
  }

  /**
   * [getFaqPublic description]
   *
   * @return  {string}  [return description]
   */
  getFaqPublic(): string {
    return "public/faq";
  }

  /**
   * [getFaq description]
   *
   * @return  {string}  [return description]
   */
  getFaq(): string {
    return "superadmin/faq";
  }

  /**
   * [reviewPelatihan description]
   *
   * @return  {string}  [return description]
   */
  reviewPelatihan(): string {
    return "review_pelatihan";
  }

  /**
   * [reviewMateri description]
   *
   * @return  {string}  [return description]
   */
  reviewMateri(): string {
    return "review_materi";
  }

  /**
   * [paketSoal description]
   *
   * @return  {string}  [return description]
   */
  paketSoal(): string {
    return "paket_soal";
  }

  /**
   * [kategoriPaketSoal description]
   *
   * @return  {string}  [return description]
   */
  kategoriPaketSoal(): string {
    return "kategori_paket_soal";
  }

  /**
   * [finalProjectPeserta description]
   *
   * @return  {string}  [return description]
   */
  finalProjectPeserta(): string {
    return "final_project_peserta";
  }

  /**
   * [finalProject description]
   *
   * @return  {string}  [return description]
   */
  finalProject(): string {
    return "final_project";
  }

  /**
   * [soal description]
   *
   * @return  {string}  [return description]
   */
  soal(): string {
    return "soal";
  }

  /**
   * [materi description]
   *
   * @return  {string}  [return description]
   */
  materi(): string {
    return "materi";
  }

  /**
   * [user description]
   *
   * @return  {string}  [return description]
   */
  user(): string {
    return "user";
  }

  /**
   * [pelatihan description]
   *
   * @return  {string}  [return description]
   */
  pelatihan(): string {
    return "pelatihan";
  }

  /**
   * [provinsi description]
   *
   * @return  {string}  [return description]
   */
  provinsi(): string {
    return "provinsi";
  }

  /**
   * [kabupaten description]
   *
   * @return  {string}  [return description]
   */
  kabupaten(): string {
    return "kabupaten";
  }

  /**
   * [logLatihan description]
   *
   * @return  {string}  [return description]
   */
  logLatihan(): string {
    return "log_latihan";
  }

  /**
   * [kecamatan description]
   *
   * @return  {string}  [return description]
   */
  kecamatan(): string {
    return "kecamatan";
  }

  /**
   * [kelurahan description]
   *
   * @return  {string}  [return description]
   */
  kelurahan(): string {
    return "kelurahan";
  }

  /**
   * [cek_nip description]
   *
   * @return  {string}  [return description]
   */
  cek_nip(): string {
    return "cek_nip";
  }

  /**
   * [modulPelatihan description]
   *
   * @return  {string}  [return description]
   */
  modulPelatihan(): string {
    return "modul_pelatihan";
  }

  /**
   * [landingData description]
   *
   * @return  {string}  [return description]
   */
  landingData(): string {
    return "public/landing_data";
  }

  /**
   * [subModulPelatihan description]
   *
   * @return  {string}  [return description]
   */
  subModulPelatihan(): string {
    return "sub_modul_pelatihan";
  }

  /**
   * [menuJenisPelatihan description]
   *
   * @return  {string}  [return description]
   */
  menuJenisPelatihan(): string {
    return "menu_jenis_pelatihan";
  }

  /**
   * [subMenuJenisPelatihan description]
   *
   * @return  {string}  [return description]
   */
  subMenuJenisPelatihan(): string {
    return "sub_menu_jenis_pelatihan";
  }

  /**
   * [jenisPelatihan description]
   *
   * @return  {string}  [return description]
   */
  jenisPelatihan(): string {
    return "jenis_pelatihan";
  }

  /**
   * [dashboard description]
   *
   * @return  {string}  [return description]
   */
  dashboard(): string {
    return "dashboard";
  }

  /**
   * [latihanPeserta description]
   *
   * @return  {string}  [return description]
   */
  latihanPeserta(): string {
    return "latihan_peserta";
  }

  /**
   * [ujianPeserta description]
   *
   * @return  {string}  [return description]
   */
  ujianPeserta(): string {
    return "ujian_peserta";
  }

  /**
   * [sproutVideo description]
   *
   * @return  {string}  [return description]
   */
  sproutVideo(): string {
    return "sproutvideo";
  }

  /**
   * [settingPenilaian description]
   *
   * @return  {string}  [return description]
   */
  settingPenilaian(): string {
    return "setting_penilaian";
  }

  /**
   * [pelatihanSaya description]
   *
   * @return  {string}  [return description]
   */
  pelatihanSaya(): string {
    return "pelatihan_saya";
  }

  /**
   * [jawabanSoal description]
   *
   * @return  {string}  [return description]
   */
  jawabanSoal(): string {
    return "jawaban_soal";
  }

  /**
   * [quiz description]
   *
   * @return  {string}  [return description]
   */
  quiz(): string {
    return "quiz";
  }

  /**
   * [slider description]
   *
   * @return  {string}  [return description]
   */
  slider(): string {
    return "slider";
  }

  /**
   * [bobotPenilaian description]
   *
   * @return  {string}  [return description]
   */
  bobotPenilaian(): string {
    return "bobot_penilaian";
  }

  /**
   * [managemenUser description]
   *
   * @return  {string}  [return description]
   */
  managemenUser(): string {
    return "management_user";
  }

  /**
   * [pathMateri description]
   *
   * @return  {string}  [return description]
   */
  pathMateri(): string {
    return "path_materi";
  }

  /**
   * [latihan description]
   *
   * @return  {string}  [return description]
   */
  latihan(): string {
    return "latihan";
  }

  /**
   * [wilayahKabupaten description]
   *
   * @return  {string}  [return description]
   */
  wilayahKabupaten(): string {
    return "wilayah/kabupaten";
  }

  /**
   * [wilayahProvinsi description]
   *
   * @return  {string}  [return description]
   */
  wilayahProvinsi(): string {
    return "wilayah/provinsi";
  }

  /**
   * [wilayahKecamatan description]
   *
   * @return  {string}  [return description]
   */
  wilayahKecamatan(): string {
    return "wilayah/kecamatan";
  }

  /**
   * [wilayahKelurahan description]
   *
   * @return  {string}  [return description]
   */
  wilayahKelurahan(): string {
    return "wilayah/kelurahan";
  }

  /**
   * [helpdeskBantuan description]
   *
   * @return  {string}  [return description]
   */
  helpdeskBantuan(): string {
    return "helpdesk_bantuan";
  }

  /**
   * [getBaseUrl description]
   *
   * @return  {string}  [return description]
   */
  getBaseUrl(): string {
    return this.baseUrl;
  }

  /**
   * [dashboardRekapitulasi description]
   *
   * @return  {string}  [return description]
   */
  dashboardRekapitulasi(): string {
    return "dashboard/rekapitulasi";
  }

  /**
   * [logTTESertifikat description]
   *
   * @return  {string}  [return description]
   */
  logTTESertifikat(): string {
    return "log_tte_sertifikat";
  }

  /**
   * [logDataAlumniSimdiklat description]
   *
   * @return  {string}  [return description]
   */
  logDataAlumniSimdiklat(): string {
    return "log_post_data_to_simdiklat";
  }

  /**
   * [youtubeDataAPI description]
   *
   * @return  {string}  [return description]
   */
  youtubeDataAPI(): string {
    return "https://www.googleapis.com/youtube/v3";
  }

  /**
   * [simdiklatAPI description]
   *
   * @return  {string}  [return description]
   */
  simdiklatAPI(): string {
    return "simdiklat";
  }

  /**
   * [getServerTime description]
   *
   * @return  {string}  [return description]
   */
  getServerTime(): string {
    return "public/serverDateTime";
  }
}
