import { AxiosResponse } from "axios";
import { Jawaban, Soal } from "../../entities/PaketSoal";

export class SoalDataMapper {
  public convertDetailSoalDataFromApi(result: AxiosResponse<any>): Soal {
    const { data } = result.data;

    let soal: Soal;

    const jawaban: Jawaban[] = [];

    data.m_jawaban_soal.map((j: any) => {
      return jawaban.push(
        new Jawaban(
          j.id,
          j.soal_id,
          j.pilihan,
          j.jawaban_benar,
          j.created_at,
          j.updated_at,
        ),
      );
    });

    soal = new Soal(
      data.id,
      data.paket_soal_id,
      data.soal,
      data.created_at,
      data.updated_at,
      jawaban,
    );

    return soal;
  }
}
