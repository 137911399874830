import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import React from "react";
import {
  BsArrowDownCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { Jawaban } from "../../../../../entities/PaketSoal";
import { JawabanQuiz } from "../../../../../entities/Quiz";
import ItemOpsi from "./ItemOpsi";

/**
 * ItemSoal is a component that displays a single question along with its options and answers.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.idAccordion - The unique identifier for the accordion item.
 * @param {number} props.nomor - The question number.
 * @param {string} props.soal - The question text.
 * @param {Jawaban[] | JawabanQuiz[]} props.jawaban - The list of answer options and their correctness.
 * @returns {JSX.Element} The rendered ItemSoal component.
 * @author Muhammad Farras Jibran
 */

const ItemSoal = ({
  idAccordion,
  nomor,
  soal,
  jawaban,
}: {
  idAccordion: string;
  nomor: number;
  soal: string;
  jawaban: Jawaban[] | JawabanQuiz[];
}) => {
  const opsi = ["A", "B", "C", "D", "E"];

  return (
    <>
      <Accordion allowMultiple key={idAccordion}>
        <AccordionItem mb={2}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="full"
                  >
                    <Flex alignItems="center">
                      <Box
                        px={2}
                        py={1}
                        textTransform="uppercase"
                        fontWeight="semibold"
                        borderWidth="2px"
                        borderColor="sky.600"
                      >
                        {nomor}
                      </Box>
                      <Text ml={4} mr={2}>
                        {parse(soal)}
                      </Text>
                    </Flex>
                    <Icon
                      as={
                        isExpanded
                          ? BsArrowDownCircleFill
                          : BsFillArrowRightCircleFill
                      }
                      boxSize={5}
                    />
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Box px={5} py={3}>
                  {jawaban.map((j, i) => (
                    <ItemOpsi
                      jawabanBenar={j.jawaban_benar === 1}
                      opsi={opsi[i]}
                      jawaban={j.pilihan}
                    />
                  ))}
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default ItemSoal;
