import { Box, Button, IconButton } from "@chakra-ui/react";
import If from "if-else-react";
import React, { useEffect } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { FaHeadset } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import ManajemenIdentitasContext, {
  useManajemenIdentitasContext,
} from "../../controller/admin/landing_page/manajemen_identitas";
import ChatController, { useChatContext } from "../../controller/chat";
import PodcastController, { usePodcastContext } from "../../controller/podcast";
import { ChatDiv } from "../Chat";
import Footer from "../Footer";
import { Navbar } from "../Navbar";
import MobileBottomNavbar from "../Navbar/MobileBottomNavbar";

const ButtonFloatChat: React.FC = () => {
  const { setShowChat } = useChatContext();
  const { showChat } = useChatContext().state;
  const { showPodcastPlayer } = usePodcastContext().state;
  const { fetchData: getManajemenIndentitas } = useManajemenIdentitasContext();
  const {
    logoNavbarUrl,
    logoFooterLeftUrl,
    logoFooterCenterUrl,
    contact,
    urlFacebook,
    urlInstagram,
    urlTwitter,
    urlYoutube,
    copyright,
  } = useManajemenIdentitasContext().state;

  const handleShowChat = () => {
    setShowChat(!showChat);
  };

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getManajemenIndentitas();
  }, []);

  useEffect(() => {
    const updateLocalStorage = (key: string, value: string | undefined) => {
      value && localStorage.setItem(key, value);
    };

    updateLocalStorage("logoNavbar", logoNavbarUrl);
    updateLocalStorage("logoFooterLeft", logoFooterLeftUrl);
    updateLocalStorage("logoFooterCenter", logoFooterCenterUrl);
    updateLocalStorage("contact", contact);
    updateLocalStorage("urlFacebook", urlFacebook);
    updateLocalStorage("urlInstagram", urlInstagram);
    updateLocalStorage("urlTwitter", urlTwitter);
    updateLocalStorage("urlYoutube", urlYoutube);
    updateLocalStorage("copyright", copyright);
  }, [
    logoNavbarUrl,
    logoFooterLeftUrl,
    logoFooterCenterUrl,
    contact,
    copyright,
    urlFacebook,
    urlInstagram,
    urlTwitter,
    urlYoutube,
  ]);

  return (
    <>
      {showChat ? (
        <Box className="float-message" transition="all">
          <Button
            type="button"
            color="white"
            bgColor="red.400"
            bg="transparent"
            rounded="full"
            fontSize="sm"
            p={1.5}
            ml="auto"
            position="absolute"
            right={0}
            mt={-3}
            mr={-3}
            _hover={{ color: "white", bg: "red.600" }}
            _active={{ bg: "red.600" }}
            onClick={handleShowChat}
          >
            <svg
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
          <ChatDiv />
        </Box>
      ) : null}

      <If condition={isAuthenticated() !== false}>
        {/* Float Button */}
        <IconButton
          className="float"
          position="fixed"
          bottom={{
            base: showPodcastPlayer ? "170px" : "80px",
            md: showPodcastPlayer ? "100px" : "40px",
          }}
          right="40px"
          color="white"
          bgColor="#8029D1"
          aria-label="Toggle Chat"
          boxShadow="2px 2px 15px #8029D1"
          icon={<FaHeadset fontSize="30px" />}
          onClick={handleShowChat}
          transition="all"
          zIndex="40"
          rounded="full"
          h="60px"
          w="60px"
          _hover={{ bg: "#6D28D9" }}
          _active={{
            bg: "#6D28D9",
          }}
        />
      </If>
    </>
  );
};

export const Wrapper: React.FC = ({ children }) => {
  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  // use react dom
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated()) {
      if (location.pathname === "/register" || location.pathname === "/login") {
        window.location.href = "/";
      }
    }
  }, []);

  return (
    <ChatController.Provider>
      <PodcastController.Provider>
        <ManajemenIdentitasContext.Provider>
          <Box w="full" display="flex" flexDirection="column" transition="all">
            <Navbar />
            {children}

            <Footer />
            <MobileBottomNavbar />
            <ButtonFloatChat />
          </Box>
        </ManajemenIdentitasContext.Provider>
      </PodcastController.Provider>
    </ChatController.Provider>
  );
};
