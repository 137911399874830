import { Users } from "./User";

export class ReviewMateri {
  id: number;
  user_id: number;
  materi_id: number;
  komentar: string;
  rating: number;
  created_at: string;
  updated_at: string;
  user: Users;
  jumlahDataReview: number;
  path_foto_komentar_materi: PathFotoKomentar[];

  constructor(
    id: number,
    user_id: number,
    materi_id: number,
    komentar: string,
    rating: number,
    created_at: string,
    updated_at: string,
    user: Users,
    jumlahDataReview: number,
    path_foto_komentar_materi: PathFotoKomentar[],
  ) {
    this.id = id;
    this.user_id = user_id;
    this.materi_id = materi_id;
    this.komentar = komentar;
    this.rating = rating;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.user = user;
    this.jumlahDataReview = jumlahDataReview;
    this.path_foto_komentar_materi = path_foto_komentar_materi;
  }
}

export class PathFotoKomentar {
  id: number;
  label: string;

  constructor(id: number, label: string) {
    this.id = id;
    this.label = label;
  }
}
