/* eslint-disable eqeqeq */

import parse from "html-react-parser";
import React, { useEffect } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { Else, If, Then } from "react-if";
import ReactLoading from "react-loading";
import Shimmer from "react-shimmer-effect";
import { ToastContainer } from "react-toastify";
import { Jawaban } from "../../../../../../entities/PaketSoal";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import ManajemenSoalDetailController, {
  useManajemenSoalDetailContext,
} from "../../../../controller/admin/manajemen_soal/detail";

const BottomHeader: React.FC = () => {
  const { paketSoal } = useManajemenSoalDetailContext().state;
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>{paketSoal != null ? paketSoal.judul_soal : ""}</a>
      </div>
      <div className="flex flex-wrap items-center px-6 py-2">
        <span className="px-4 py-2 rounded-full border border-green-300 text-green-500 font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
          {paketSoal != null
            ? paketSoal.kategori_paket_soal.kategori_paket_soal
            : ""}
        </span>
      </div>
    </div>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, isLoadingDelete } =
    useManajemenSoalDetailContext().state;
  const { setShowDialogDelete, deleteSoal } = useManajemenSoalDetailContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Soal
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteSoal()}
                  >
                    <If condition={isLoadingDelete}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ItemSoal = ({
  idAccordion,
  id,
  nomor,
  soal,
  jawaban,
}: {
  idAccordion: string;
  id: number;
  nomor: number;
  soal: string;
  jawaban: Jawaban[];
}) => {
  const { setSelectedId, setShowDialogDelete } =
    useManajemenSoalDetailContext();

  const opsi = "ABCDEFGHIJKLMNOPQRSTUFWXYZ".split("");

  return (
    <div className="accordion-item bg-white border border-gray-200 mt-3 rounded-lg">
      <button
        className="
            accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            border-0
            transition
            focus
            focus:outline-none
          "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + idAccordion}
        aria-expanded="true"
        aria-controls={"#" + idAccordion}
      >
        <a className="px-2 py-1 text-sm font-semibold border-2  border-sky-600">
          {nomor}
        </a>
        <p className="ml-4 mr-2">{parse(soal)}</p>
      </button>
      <div id={idAccordion} className="accordion-collapse collapse rounded-lg">
        <div className="accordion-body rounded-lg">
          <div className="px-5 py-3 flex flex-row">
            Aksi :
            <a
              href={`/admin/ubah/manajemen_soal/${
                window.location.pathname.split("/", 5)[4]
              }/${id}`}
              className="ml-2 px-2 py-1 text-xs text-white bg-green-600 rounded-lg"
            >
              Ubah Data
            </a>
            <button
              className="ml-2 px-2 py-1 text-xs text-white bg-red-600 rounded-lg"
              onClick={() => {
                setSelectedId(id);
                setShowDialogDelete(true);
              }}
            >
              Hapus Data
            </button>
          </div>
          <hr />
          <div className="px-5 py-3">
            {jawaban.map((j, i) => {
              return (
                <ItemOpsi
                  jawabanBenar={j.jawaban_benar == 1 ? true : false}
                  opsi={opsi[i]}
                  jawaban={j.pilihan}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemOpsi = ({
  jawabanBenar,
  opsi,
  jawaban,
}: {
  jawabanBenar: boolean;
  opsi: string;
  jawaban: string;
}) => {
  return (
    <div
      className="flex relative px-3 py-3 mb-5 rounded-md"
      style={{ backgroundColor: jawabanBenar ? "#EFFFF9" : "#F3F3F3" }}
    >
      <a
        className="px-2 py-1 text-xs font-semibold rounded"
        style={{
          border: jawabanBenar ? "1px solid #0DBD7F" : "1px solid #4F4F4F",
          color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
          height: "25px",
        }}
      >
        {opsi}
      </a>
      <a
        className="px-2 py-1 text-xs font-normal rounded ml-3"
        style={{
          border: jawabanBenar ? "1px solid #0DBD7F" : "1px solid #4F4F4F",
          color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
          height: "25px",
        }}
      >
        Jawaban{jawabanBenar ? "Benar" : "Salah"}
      </a>
      <p
        className="px-2 py-1 text-xs font-normal rounded ml-3"
        style={{ color: jawabanBenar ? "#0DBD7F" : "#4F4F4F" }}
      >
        {parse(jawaban)}
      </p>
    </div>
  );
};

const ModalPetunjuk: React.FC = () => {
  const { showDialogPetunjuk, paketSoal, isLoading } =
    useManajemenSoalDetailContext().state;
  const { setShowDialogPetunjuk } = useManajemenSoalDetailContext();

  return (
    <>
      {showDialogPetunjuk ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Petunjuk Soal
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogPetunjuk(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <If condition={isLoading}>
                    <Then></Then>
                    <Else>
                      {paketSoal != null
                        ? parse(paketSoal.petunjuk_soal)
                        : null}
                    </Else>
                  </If>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogPetunjuk(false)}
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const { getDetailPaketSoal, setShowDialogPetunjuk } =
    useManajemenSoalDetailContext();
  const { paketSoal, isLoading } = useManajemenSoalDetailContext().state;

  useEffect(() => {
    getDetailPaketSoal(window.location.pathname.split("/", 5)[4]);
  }, []);

  return (
    <div className="app">
      <ModalDelete />
      <ModalPetunjuk />
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-50 drop-shadow-md">
          <BottomHeader />
        </header>
        <div className="container">
          <div className="border bg-white flex flex-wrap justify-between mx-6 px-4 py-3 rounded-lg ">
            <a className="mt-1">
              {paketSoal != null ? paketSoal.soal.length : ""} Soal{" "}
              {paketSoal != null ? paketSoal.jenis_soal : ""}
            </a>
            <div className="flex flex-wrap">
              <button
                type="button"
                className="px-6 py-2 bg-yellow-500 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 "
                onClick={() => setShowDialogPetunjuk(true)}
              >
                Petunjuk Soal
              </button>
              <a
                href={`/admin/tambah/manajemen_soal/${
                  window.location.pathname.split("/", 5)[4]
                }`}
                className="ml-2 flex px-6 py-2 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 "
              >
                Tambah Baru
                <BsFillPlusCircleFill
                  className="ml-2"
                  style={{ marginTop: "2px" }}
                />
              </a>
            </div>
          </div>

          <div className="accordion mx-10 mb-10">
            <If condition={isLoading}>
              <Then>
                <Shimmer>
                  <div
                    className="mt-3 w-full rounded-lg "
                    style={{ height: "80px" }}
                  ></div>
                  <div
                    className="mt-3 w-full rounded-lg "
                    style={{ height: "80px" }}
                  ></div>
                  <div
                    className="mt-3 w-full rounded-lg "
                    style={{ height: "80px" }}
                  ></div>
                  <div
                    className="mt-3 w-full rounded-lg "
                    style={{ height: "80px" }}
                  ></div>
                  <div
                    className="mt-3 w-full rounded-lg "
                    style={{ height: "80px" }}
                  ></div>
                  <div
                    className="mt-3 w-full rounded-lg "
                    style={{ height: "80px" }}
                  ></div>
                </Shimmer>
              </Then>
              <Else>
                {paketSoal?.soal.map((s, i) => {
                  return (
                    <ItemSoal
                      idAccordion={"Soal" + (i + 1)}
                      id={s.id}
                      nomor={i + 1}
                      soal={s.soal}
                      jawaban={s.jawaban}
                    />
                  );
                })}
              </Else>
            </If>
          </div>
        </div>
      </main>
    </div>
  );
};

const ManajemenSoalDetail: React.FC = () => {
  return (
    <ManajemenSoalDetailController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <SidebarWithHeader title="Manajemen Soal">
        <WrapperMain />
      </SidebarWithHeader>
    </ManajemenSoalDetailController.Provider>
  );
};

export default ManajemenSoalDetail;
