import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Spinner,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiFillPlusCircle, AiOutlineArrowLeft } from "react-icons/ai";
import Select, { StylesConfig } from "react-select";
import CardItem from "../../../components/Admin/Card/CardItem";
import EditorComponent from "../../../components/Admin/Editor";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import BoxUploadFile from "../../../components/Admin/UploadFile/BoxUploadFile";
import Pagination from "../../../components/Pagination";
import ManajemenPengetahuanController, {
  useManajemenPengetahuanContext,
} from "../../../controller/admin/manajemen_pengetahuan";
import YoutubeContext, {
  useYoutubeContext,
} from "../../../controller/youtube/index";

/**
 * ManajemenPengetahuan is a component that allows users to manage knowledge items.
 * It provides a form to create new knowledge items and displays a list of existing items.
 * Uses TinyMCE editor for description.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const ManajemenPengetahuan = () => {
  const {
    setManajemenPengetahuanState,
    postData,
    fetchData,
    handleDelete,
    handleEdit,
    clearState,
  } = useManajemenPengetahuanContext();
  const state = useManajemenPengetahuanContext().state;
  const { getListVideo } = useYoutubeContext();
  const { listVideo } = useYoutubeContext().state;

  const {
    publish,
    image,
    pdf,
    description,
    category,
    listData,
    loadingData,
    mode,
    title,
    listCategory,
    audio,
    loadingAction,
    ppt,
    youtubeVideoSelected,
    pagination,
    perPage,
    page,
  } = state;

  const loadingEdit = false;

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  const listOptionVideoYoutube = listVideo.map((item) => ({
    value: item.videoId,
    label: item.title,
  }));

  const handlePageChange = (e) => {
    setManajemenPengetahuanState("page", e.selected + 1);
  };

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: "#F6F5F8",
        padding: "2px 8px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 100,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = "#38A169";

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? color
            : isFocused
              ? "#C6F6D5"
              : undefined,
        color: isDisabled ? "white" : isSelected ? "white" : "black",

        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "white"
              : color
            : undefined,
          color: isDisabled ? "white" : isSelected ? "black" : "white",
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  useEffect(() => {
    getListVideo();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (mode === "edit" && typeof youtubeVideoSelected === "string") {
      const videSelected = listVideo.find(
        (item) => item.videoId === youtubeVideoSelected,
      );
      setManajemenPengetahuanState("youtubeVideoSelected", {
        label: videSelected?.title,
        value: videSelected?.videoId,
      });
    }
  }, [mode]);

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="#EDF2F7"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            {mode !== "" && (
              <IconButton
                aria-label="Previous Page"
                icon={<AiOutlineArrowLeft />}
                fontSize="md"
                mr={2}
                onClick={() => setManajemenPengetahuanState("mode", "")}
              />
            )}
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              Pengetahuan
            </Heading>
          </Flex>
          {(mode === "create" || mode === "edit") && publish ? (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Publish"
              onClick={() => postData()}
            >
              Publish
            </Button>
          ) : (mode === "create" || mode === "edit") && !publish ? (
            <Button
              color="white"
              bg="#3C4952"
              _hover={{ bg: "#3C4952" }}
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Simpan Draft"
              onClick={() => postData()}
            >
              Simpan Draft
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              onClick={() => {
                clearState();
                setManajemenPengetahuanState("mode", "create");
              }}
            >
              Tambah Baru
            </Button>
          )}
        </Flex>
      </Box>
      {mode === "create" || mode === "edit" ? (
        <Box m={4} px={4} py={8} boxShadow="lg" borderRadius="xl" bg="white">
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* judul */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Judul
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                onChange={(e) =>
                  setManajemenPengetahuanState("title", e.target.value)
                }
                fontSize="sm"
                value={title}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "blue",
                  borderColor: "blue",
                }}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Pilih Kategori
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Select
                placeholder="Pilih Kategori"
                options={listCategory}
                isClearable
                onChange={(e) => setManajemenPengetahuanState("category", e)}
                value={category}
                styles={colourStyles}
              />
            </GridItem>
            {/* Upload Video */}
            {category?.value === "video" && (
              <>
                <GridItem colSpan={1}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Pilih Video
                  </Text>
                </GridItem>
                <GridItem colSpan={5}>
                  <Select
                    placeholder="Pilih Video"
                    options={listOptionVideoYoutube}
                    isClearable
                    onChange={(e) =>
                      setManajemenPengetahuanState("youtubeVideoSelected", e)
                    }
                    value={youtubeVideoSelected}
                    styles={colourStyles}
                  />
                </GridItem>

                {youtubeVideoSelected?.value && (
                  <>
                    <GridItem colSpan={1}></GridItem>
                    <GridItem colSpan={5}>
                      <iframe
                        title="youtube video"
                        src={`https://www.youtube.com/embed/${youtubeVideoSelected.value}`}
                        allowFullScreen
                        style={{ width: "100%", height: "600px" }}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}

            {/* Upload Audio */}
            {category?.value === "audio" && (
              <>
                <GridItem colSpan={1}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Upload Audio
                  </Text>
                </GridItem>
                <GridItem colSpan={5}>
                  <BoxUploadFile
                    setFile={setManajemenPengetahuanState}
                    typeFile="audio"
                    defaultFile={audio}
                  />
                </GridItem>
              </>
            )}

            {/* Upload PDF */}
            {category?.value === "pdf" && (
              <>
                <GridItem colSpan={1}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Upload PDF
                  </Text>
                </GridItem>
                <GridItem colSpan={5}>
                  <BoxUploadFile
                    setFile={setManajemenPengetahuanState}
                    typeFile="pdf"
                    defaultFile={pdf}
                  />
                </GridItem>
              </>
            )}
            {/* Upload PPT */}
            {category?.value === "ppt" && (
              <>
                <GridItem colSpan={1}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Upload PPT
                  </Text>
                </GridItem>
                <GridItem colSpan={5}>
                  <BoxUploadFile
                    setFile={setManajemenPengetahuanState}
                    typeFile="ppt"
                    defaultFile={ppt}
                    mode={mode}
                  />
                </GridItem>
              </>
            )}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Cover Thumbnail
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <BoxUploadFile
                setFile={setManajemenPengetahuanState}
                typeFile="image"
                defaultFile={image}
              />
            </GridItem>

            {/* Deskripsi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Deskripsi
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <EditorComponent
                description={description}
                setDescription={setManajemenPengetahuanState}
              />
            </GridItem>
            {/* Publikasi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Publikasi
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Switch
                defaultChecked={publish}
                colorScheme="blue"
                mr={2}
                onChange={(e) =>
                  setManajemenPengetahuanState("publish", e.target.checked)
                }
              />
              <Text fontWeight="semibold" fontSize="sm">
                {publish ? "Publikasi" : "Draft"}
              </Text>
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={4} m={4}>
          {loadingData ? (
            <>
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
            </>
          ) : loadingEdit ? (
            <Spinner />
          ) : (
            <>
              {listData.map((item) => (
                <GridItem colSpan={{ base: 3, md: 1 }} key={item.id}>
                  <CardItem
                    item={item}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    image={item.thumbnail}
                    module="pengetahuan"
                  />
                </GridItem>
              ))}
            </>
          )}
        </Grid>
      )}
      {mode === "" && pagination && totalPage > 1 && (
        <Flex justifyContent="end" mt={2} mb={4} mr={3}>
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={page}
          />
        </Flex>
      )}
    </SidebarWithHeader>
  );
};

const ManajemenPengetahuanMain: React.FC = () => {
  return (
    <ManajemenPengetahuanController.Provider>
      <YoutubeContext.Provider>
        <ManajemenPengetahuan />
      </YoutubeContext.Provider>
    </ManajemenPengetahuanController.Provider>
  );
};

export default ManajemenPengetahuanMain;
