/* eslint-disable eqeqeq */
import { MateriRepositoryInterface } from "../../../data/contracts/Materi";
import { MateriDataMapper } from "../../../data/mappers/Materi";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Materi } from "../../../entities/Materi";
import { GetLocalStorage } from "../../misc/UseLocalStorage";

export class MateriApiRepository implements MateriRepositoryInterface {
  private apiService: ApiService;
  private mapper: MateriDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: MateriDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllMateri(
    page: number,
    rows: number,
    keyword: string,
    jenis_pelatihan_id: string,
    jenis_materi: string[],
  ): Promise<Materi[]> {
    const headers = new Map<string, string>();

    const params = {};
    params["page"] = page.toString();
    params["rows"] = rows.toString();
    params["keyword"] = keyword;
    params["jenis_pelatihan_id"] = jenis_pelatihan_id;
    if (jenis_materi != undefined) {
      jenis_materi.forEach(
        (v: string, idx) => (params[`jenis_materi[${idx}]`] = v),
      );
    }
    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.materi()
        : "public/" + this.endpoints.materi(),
      params,
      null,
      headers,
    );
    return this.mapper.convertMateriDataFromApi(dataResponse);
  }

  public async getDetailMateri(id: number): Promise<Materi> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.materi() + "/" + id
        : "public/" + this.endpoints.materi() + "/" + id,
      {},
      null,
      headers,
    );
    return this.mapper.convertDetailMateriDataFromApi(dataResponse);
  }

  public async storeMateri(
    path_thumbnail: File,
    jenis_pelatihan: string,
    judul_materi: string,
    uraian_deskripsi: string,
    kompetensi: string,
    jam_pelatihan: string,
    status_konsultasi: boolean,
    widyaiswara: number[],
    status_publish: boolean,
    penyusun: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("path_thumbnail", path_thumbnail);
    formData.append("jenis_pelatihan_id", jenis_pelatihan);
    formData.append("judul_materi", judul_materi);
    formData.append("uraian_deskripsi", uraian_deskripsi);
    formData.append("kompetensi", kompetensi);
    formData.append("jam_pelatihan", jam_pelatihan);
    formData.append("status_konsultasi", status_konsultasi.toString());
    widyaiswara.forEach((v: number) =>
      formData.append("widyaiswara[]", v.toString()),
    );
    formData.append("status_publish", status_publish.toString());
    formData.append("penyusun", penyusun);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.materi(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateMateri(
    id: number,
    path_thumbnail: File,
    jenis_pelatihan: string,
    judul_materi: string,
    uraian_deskripsi: string,
    kompetensi: string,
    jam_pelatihan: string,
    status_konsultasi: boolean,
    widyaiswara: number[],
    status_publish: boolean,
    penyusun: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("path_thumbnail", path_thumbnail);
    formData.append("jenis_pelatihan_id", jenis_pelatihan);
    formData.append("judul_materi", judul_materi);
    formData.append("uraian_deskripsi", uraian_deskripsi);
    formData.append("kompetensi", kompetensi);
    formData.append("jam_pelatihan", jam_pelatihan);
    formData.append("status_konsultasi", status_konsultasi.toString());
    widyaiswara.forEach((v: number) =>
      formData.append("widyaiswara[]", v.toString()),
    );
    formData.append("status_publish", status_publish.toString());
    formData.append("penyusun", penyusun);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.materi() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteMateri(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.materi() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
