/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PaketSoalPresenter } from "../../../../../../data/presenters/PaketSoalPresenter";
import { SoalPresenter } from "../../../../../../data/presenters/SoalPresenter";
import { PaketSoal } from "../../../../../../entities/PaketSoal";
import { toast } from "react-toastify";

interface IState {
  isLoading: boolean;
  paketSoal: PaketSoal | null;
  showDialogPetunjuk: boolean;
  showDialogDelete: boolean;
  selectedId: number;
  isLoadingDelete: boolean;
}

interface InitialState {
  state: IState;
  getDetailPaketSoal: Function;
  setShowDialogPetunjuk: Function;
  setShowDialogDelete: Function;
  setSelectedId: Function;
  deleteSoal: Function;
}

const initialState = {
  state: {
    isLoading: false,
    paketSoal: null,
    showDialogPetunjuk: false,
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDelete: false,
  },
  getDetailPaketSoal: () => {},
  setShowDialogPetunjuk: () => {},
  setShowDialogDelete: () => {},
  setSelectedId: () => {},
  deleteSoal: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenSoalDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingDelete: false,
    paketSoal: null,
    showDialogPetunjuk: false,
    showDialogDelete: false,
    selectedId: 0,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const getDetailPaketSoal = async (id: number) => {
    setLoading(true);

    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const getDetail = await paketSoalPresenter.getDetailPaketSoal(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        paketSoal: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const setShowDialogPetunjuk = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogPetunjuk: value,
    }));
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setSelectedId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedId: value,
    }));
  };

  const deleteSoal = async () => {
    setLoadingDelete(true);

    try {
      const soalPresenter = container.resolve(SoalPresenter);
      const deleteSoal = await soalPresenter.deleteSoal(state.selectedId);

      const data = deleteSoal;

      if (data.data.code == 200) {
        const id = state.paketSoal?.id;
        toast.success("Berhasil Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        getDetailPaketSoal(Number(id));
      } else {
        toast.error("Gagal Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingDelete(false);
      setShowDialogDelete(false);
    } catch (error: any) {
      setLoadingDelete(false);
    }
  };

  return (
    <ManajemenSoalDetailProvider
      value={{
        state,
        getDetailPaketSoal,
        setShowDialogPetunjuk,
        setShowDialogDelete,
        setSelectedId,
        deleteSoal,
      }}
    >
      {children}
    </ManajemenSoalDetailProvider>
  );
};

export const useManajemenSoalDetailContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenSoalDetailContext,
  Provider,
};
