import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface Option {
  label: string;
  value: string;
}
interface PengetahuanItem {
  id: number;
  title: string;
  description: string;
  category: string;
  path_file: string;
  total_view: number | null;
  review_score: number | null;
  status_publish: number;
  thumbnail: string;
  created_at: string;
  updated_at: string;
}
interface IState {
  state: {
    title: string;
    image: File | null;
    video: File | null;
    audio: File | null;
    pdf: File | null;
    ppt: File | null;
    description: string;
    link: string;
    publish: boolean;
    url: string;
    loadingData: boolean;
    loadingAction: boolean;
    listData: PengetahuanItem[];
    page: number;
    perPage: number;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    category: Option | null;
    mode: string;
    itemId: string;
    listCategory: Option[];
    youtubeVideoSelected: Option | null;
  };
  setManajemenPengetahuanState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
  handleEdit: Function;
  clearState: Function;
}

const initialState: IState = {
  state: {
    title: "",
    image: null,
    video: null,
    audio: null,
    pdf: null,
    ppt: null,
    description: "",
    link: "",
    publish: false,
    url: "management_content/pengetahuan",
    loadingData: false,
    loadingAction: false,
    listData: [],
    page: 1,
    perPage: 9,
    pagination: null,
    category: null,
    mode: "",
    itemId: "",
    listCategory: [
      { value: "video", label: "Video" },
      { value: "audio", label: "Audio" },
      { value: "ppt", label: "PPT" },
      { value: "pdf", label: "PDF" },
    ],

    youtubeVideoSelected: null,
  },
  setManajemenPengetahuanState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  clearState: () => {},
};

/**
 * The ManajemenPengetahuanContext provides a context for managing the state related to manajemen pengetahuan.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 * @author Muhammad Farras Jibran
 */

const Context = createContext<IState>(initialState);
const { Provider: ManajemenPengetahuanProvider } = Context;

/**
 * The provider component that wraps its children with the ManajemenPengetahuanContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 */
const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    perPage,
    page,
    url,
    title,
    description,
    category,
    publish,
    listData,
    listCategory,
    audio,
    itemId,
    mode,
    pdf,
    ppt,
    image,
    youtubeVideoSelected,
  } = state.state;

  const setManajemenPengetahuanState = (
    key: keyof IState["state"],
    value: any,
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async (status?: string) => {
    setManajemenPengetahuanState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: status ?? "0,1",
          rows: perPage,
          page: page,
        },
      });

      setManajemenPengetahuanState("listData", response.data?.data);
      setManajemenPengetahuanState("pagination", response?.data.pagination);
      setManajemenPengetahuanState("loadingData", false);
    } catch (error) {
      setManajemenPengetahuanState("loadingData", false);
      throw error;
    }
  };

  const validateInput = () => {
    if (title.length > 30) {
      toast({
        title: "Maksimal judul 30 karakter",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const createFormData = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("status_publish", publish ? "1" : "0");
    if (image) {
      formData.append("thumbnail", image);
    }
    if (category) {
      formData.append("category", category.value);
      if (youtubeVideoSelected && category.value === "video") {
        formData.append("path_file", youtubeVideoSelected.value);
      }

      if (audio && category.value === "audio") {
        formData.append("path_file", audio);
      }

      if (pdf && category.value === "pdf") {
        formData.append("path_file", pdf);
      }

      if (ppt && category.value === "ppt") {
        formData.append("path_file", ppt);
      }
    }

    return formData;
  };

  const postData = async () => {
    if (!validateInput()) {
      return;
    }

    setManajemenPengetahuanState("loadingAction", true);

    const formData = createFormData();

    try {
      const idParams = mode === "edit" ? "/" + itemId : "";
      const response = await axiosInstance.post(`${url}${idParams}`, formData);
      clearState();
      setManajemenPengetahuanState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      setManajemenPengetahuanState("mode", "");
      fetchData();

      return response.data;
    } catch (error: any) {
      setManajemenPengetahuanState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      if (responseData.code === 422) {
        Object.keys(responseData.errors).forEach((key) => {
          responseData.errors[key].forEach((errorMessage) => {
            toast({
              title: titleMessage,
              description: errorMessage,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          });
        });
      }

      toast({
        title: "Terjadi kesalahan saat menyimpan data",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      throw error;
    }
  };

  const handleEdit = (id) => {
    const dataEdit = listData.find((item) => item.id === id);

    if (dataEdit) {
      const categorytSelected = listCategory.find(
        (item) => item.value === dataEdit?.category,
      );

      setManajemenPengetahuanState("title", dataEdit?.title);
      setManajemenPengetahuanState("category", categorytSelected);
      setManajemenPengetahuanState("description", dataEdit?.description);
      setManajemenPengetahuanState("image", dataEdit?.thumbnail);

      setManajemenPengetahuanState(
        "publish",
        dataEdit?.status_publish === 0 ? false : true,
      );

      if (categorytSelected?.value === "audio") {
        setManajemenPengetahuanState("audio", dataEdit?.path_file);
      }

      if (categorytSelected?.value === "video") {
        setManajemenPengetahuanState(
          "youtubeVideoSelected",
          dataEdit?.path_file,
        );
      }

      if (categorytSelected?.value === "pdf") {
        setManajemenPengetahuanState("pdf", dataEdit?.path_file);
      }

      if (categorytSelected?.value === "ppt") {
        setManajemenPengetahuanState("ppt", dataEdit?.path_file);
      }
      setManajemenPengetahuanState("itemId", id);
      setManajemenPengetahuanState("mode", "edit");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const clearState = () => {
    setManajemenPengetahuanState("title", "");
    setManajemenPengetahuanState("description", "");
    setManajemenPengetahuanState("publish", false);
    setManajemenPengetahuanState("video", null);
    setManajemenPengetahuanState("audio", null);
    setManajemenPengetahuanState("pdf", null);
    setManajemenPengetahuanState("ppt", null);
    setManajemenPengetahuanState("image", null);
    setManajemenPengetahuanState("youtubeVideoSelected", null);
    setManajemenPengetahuanState("category", null);
  };

  return (
    <ManajemenPengetahuanProvider
      value={{
        state: state.state,
        setManajemenPengetahuanState,
        fetchData,
        postData,
        handleEdit,
        handleDelete,
        clearState,
      }}
    >
      {children}
    </ManajemenPengetahuanProvider>
  );
};

export const useManajemenPengetahuanContext = () => useContext(Context);

const ManajemenPengetahuanContext = {
  useManajemenPengetahuanContext,
  Provider,
};

export default ManajemenPengetahuanContext;
