import { KategoriPaketSoal } from "./KategoriPaketSoal";

export class PaketSoal {
  id: number;
  tahun: string;
  jenis_soal: string;
  judul_soal: string;
  petunjuk_soal: string;
  created_at: string;
  updated_at: string;
  jumlahDataPaketSoal: number;
  soal: Soal[];
  kategori_paket_soal: KategoriPaketSoal;

  constructor(
    id: number,
    tahun: string,
    jenis_soal: string,
    judul_soal: string,
    petunjuk_soal: string,
    created_at: string,
    updated_at: string,
    jumlahDataPaketSoal: number,
    soal: Soal[],
    kategori_paket_soal: KategoriPaketSoal,
  ) {
    this.id = id;
    this.tahun = tahun;
    this.jenis_soal = jenis_soal;
    this.judul_soal = judul_soal;
    this.petunjuk_soal = petunjuk_soal;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahDataPaketSoal = jumlahDataPaketSoal;
    this.soal = soal;
    this.kategori_paket_soal = kategori_paket_soal;
  }
}

export class Soal {
  id: number;
  paket_soal_id: string;
  soal: string;
  created_at: string;
  updated_at: string;
  jawaban: Jawaban[];
  constructor(
    id: number,
    paket_soal_id: string,
    soal: string,
    created_at: string,
    updated_at: string,
    jawaban: Jawaban[],
  ) {
    this.id = id;
    this.paket_soal_id = paket_soal_id;
    this.soal = soal;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jawaban = jawaban;
  }
}

export class Jawaban {
  id: number;
  soal_id: number;
  pilihan: string;
  jawaban_benar: number;
  created_at: string;
  updated_at: string;
  constructor(
    id: number,
    soal_id: number,
    pilihan: string,
    jawaban_benar: number,
    created_at: string,
    updated_at: string,
  ) {
    this.id = id;
    this.soal_id = soal_id;
    this.pilihan = pilihan;
    this.jawaban_benar = jawaban_benar;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
