/* eslint-disable eqeqeq */
import { Skeleton, Stack, useToast } from "@chakra-ui/react";
import parse from "html-react-parser/dist/html-react-parser";
import If, { Else } from "if-else-react";
import moment from "moment/moment";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { BiChat } from "react-icons/bi";
import { useParams } from "react-router-dom";
import {
  ModulPelatihan,
  SubModulPelatihan,
} from "../../../../../entities/Pelatihan";
import { Modal } from "../../../components/Modal";
import VideoPlayer from "../../../components/VideoPlayer";
import { Wrapper } from "../../../components/Wrapper";
import ChatController, { useChatContext } from "../../../controller/chat";
import LatihanController, {
  useLatihanContext,
} from "../../../controller/latihan/index";
import DetailPelatihanController, {
  useDetailPelatihanContext,
} from "../../../controller/pelatihan/detail";
import UjianController, {
  useUjianContext,
} from "../../../controller/ujian/index";

const stylePlayer = {
  borderRadius: 6,
  overflow: "hidden",
};

const ItemSubModul = ({
  sub_modul,
  idx,
  numbering,
}: {
  sub_modul: SubModulPelatihan;
  idx: number;
  numbering: number;
}) => {
  const number_second = idx + 1;

  const { indexMateri } = useDetailPelatihanContext().state;

  const materi = sub_modul.m_materi[indexMateri];

  const id_submodul = localStorage.getItem("id_subModul");

  const { setMateri } = useDetailPelatihanContext();

  return (
    <div className="flex flex-row items-center mb-3 mt-3">
      <If condition={sub_modul.status_pembelajaran == "Belum Mengikuti"}>
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          disabled
        />
        <button
          className={`ml-2 text-sm text-gray-900 dark:text-gray-300 text-justify flex flex-row items-center ${
            id_submodul == sub_modul.id.toString()
              ? "font-semibold"
              : "font-medium"
          }`}
          disabled
        >
          <p>
            {numbering}.{number_second}
          </p>
          <p className="ml-3">{sub_modul.judul}</p>
        </button>
      </If>

      <If condition={sub_modul.status_pembelajaran == "Sedang Mengikuti"}>
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          disabled
        />
        <button
          className={`ml-2 text-sm text-gray-900 dark:text-gray-300 text-justify flex flex-row ${
            id_submodul == sub_modul.id.toString()
              ? "font-semibold"
              : "font-medium"
          }`}
          onClick={() =>
            setMateri(
              materi.materi_id,
              sub_modul.id,
              materi.materi.judul_materi,
              materi.materi.uraian_deskripsi,
              materi.materi.views,
              materi.materi.tanggal_pembuatan,
              sub_modul.modul_pelatihan_id,
              materi.materi.status_konsultasi,
            )
          }
        >
          <p>
            {numbering}.{number_second}
          </p>
          <p className="ml-3">{sub_modul.judul}</p>
        </button>
      </If>

      <If condition={sub_modul.status_pembelajaran == "Selesai Dipelajari"}>
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          checked
          disabled
        />
        <button
          className={`ml-2 text-sm text-gray-900 dark:text-gray-300 text-justify flex flex-row items-center ${
            id_submodul == sub_modul.id.toString()
              ? "font-semibold"
              : "font-medium"
          }`}
          onClick={() =>
            setMateri(
              materi.materi_id,
              sub_modul.id,
              materi.materi.judul_materi,
              materi.materi.uraian_deskripsi,
              materi.materi.views,
              materi.materi.tanggal_pembuatan,
              sub_modul.modul_pelatihan_id,
              materi.materi.status_konsultasi,
            )
          }
        >
          <p>
            {numbering}.{number_second}
          </p>
          <p className="ml-3">{sub_modul.judul}</p>
        </button>
      </If>
    </div>
  );
};

const ItemModul = ({
  modul,
  idAccordion,
  idx,
}: {
  modul: ModulPelatihan;
  idAccordion: string;
  idx: number;
}) => {
  const numbering = idx + 1;
  const modul_id: any = localStorage.getItem("modul_id");

  return (
    <div className="accordion-item mt-3">
      <button
        className={`accordion-button collapsed relative flex items-center w-full py-4 px-5 text-sm text-gray-800 text-left rounded-full transition focus focus:outline-none ${
          modul.status_pembelajaran == "Selesai Dipelajari"
            ? "bg-green-100"
            : "bg-gray-100"
        }`}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + idAccordion}
        aria-expanded="true"
        aria-controls={"#" + idAccordion}
      >
        <span
          className={`font-semibold ${
            modul.status_pembelajaran == "Selesai Dipelajari"
              ? "text-green-400"
              : ""
          }`}
        >
          Section {numbering} :{" "}
          <span className="font-normal">{modul.judul_modul}</span>
        </span>
      </button>
      <div
        id={idAccordion}
        className={`accordion-collapse collapse ${
          modul.id === Number(modul_id) ? "show" : ""
        }`}
      >
        <div className="accordion-body py-4 px-4">
          {modul.sub_modul.map((v, idx) => {
            return (
              <ItemSubModul
                sub_modul={v}
                idx={idx}
                key={idx}
                numbering={numbering}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SidebarMenu: React.FC = () => {
  const { pelatihan, isLoading } = useDetailPelatihanContext().state;

  return (
    <>
      <div className="container mt-4 p-5 bg-white dark:bg-gray-700 rounded-lg">
        <h2 className="font-semibold text-base mb-4 text-black dark:text-white">
          Modul Pelatihan
        </h2>
        <div className="accordion-body rounded-lg">
          <If condition={!isLoading}>
            <If
              condition={
                pelatihan != null && pelatihan.modulPelatihan.length > 0
              }
            >
              {pelatihan?.modulPelatihan.map((v, idx) => {
                return (
                  <ItemModul
                    modul={v}
                    idAccordion={"Modul" + (idx + 1)}
                    idx={idx}
                    key={idx}
                  />
                );
              })}
              <Else />
              <p className="text-center">Modul Kosong</p>
            </If>
            <Else />
            <Skeleton height={50} style={{ borderRadius: "50px" }} />
            <Skeleton
              height={50}
              style={{ borderRadius: "50px" }}
              className="mt-4"
            />
            <Skeleton
              height={50}
              style={{ borderRadius: "50px" }}
              className="mt-4"
            />
            <Skeleton
              height={50}
              style={{ borderRadius: "50px" }}
              className="mt-4"
            />
          </If>
        </div>
      </div>
    </>
  );
};

// Pelatihan
const MateriPelatihan = ({
  isVideoEnd,
}: {
  isVideoEnd: (data: boolean) => void;
}) => {
  const { pathMateri, indexPathMateri } = useDetailPelatihanContext().state;
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // Set isVideoEnd to false when the video is played
    player.on("play", () => {
      isVideoEnd(false);
    });

    // if video ended
    player.on("ended", () => {
      // clearInterval(interval);
      isVideoEnd(true);
    });
  };

  //  video js options
  let urlVideo;

  if (pathMateri != null && pathMateri.length > 0) {
    urlVideo = `https://www.youtube.com/watch?v=${pathMateri[indexPathMateri].path_materi}`;
  } else {
    urlVideo = null;
  }

  const isStaging =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === "staging";

  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    controlBar: {
      children: [
        "playToggle",
        "currentTimeDisplay",
        "durationDisplay",
        "volumePanel",
        "fullscreenToggle",
        isStaging && "controlBar",
      ],
    },
    userActions: {
      hotkeys: false,
      click: false,
    },
    techOrder: ["youtube"],
    sources: [
      {
        src: urlVideo,
        type: "video/youtube",
      },
    ],
    youtube: {
      controls: 0,
      rel: 0,
      modestbranding: 1,
      disablekb: 1,
      fs: 0,
      enablejsapi: 1,
    },
  };

  return (
    <>
      {pathMateri != null && pathMateri.length > 0 ? (
        <>
          {/* if materi video */}
          <If condition={pathMateri[indexPathMateri].jenis_materi == "video"}>
            <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          </If>

          {/* if materi pdf */}
          <If condition={pathMateri[indexPathMateri].jenis_materi == "pdf"}>
            <iframe
              src={pathMateri[indexPathMateri].path_materi}
              width="100%"
              height="600px"
              style={stylePlayer}
            />
          </If>

          {/* if materi ppt */}
          <If condition={pathMateri[indexPathMateri].jenis_materi == "ppt"}>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${pathMateri[indexPathMateri].path_materi}`}
              width="100%"
              height="600px"
              style={stylePlayer}
            />
          </If>

          {/* if materi audio */}
          <If condition={pathMateri[indexPathMateri].jenis_materi == "audio"}>
            <ReactAudioPlayer
              src={pathMateri[indexPathMateri].path_materi}
              autoPlay
              controls
              style={{
                borderRadius: 10 + "px",
                width: "100%",
                color: "#111111",
              }}
            />
          </If>
        </>
      ) : null}
    </>
  );
};

const ContentPage: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };
  const toast = useToast();

  const {
    pelatihan,
    isLoading,
    indexData,
    judulMateri,
    uraianDeskripsi,
    views,
    tanggalPembuatan,
    ada_latihan,
    status_latihan,
    latihan_id,
    logLatihanData,
    isLoadingLog,
    indexMateri,
    pathMateri,
    statusKonsultasi,
    statusSubModul,
    indexPathMateri,
  } = useDetailPelatihanContext().state;

  const {
    setMateri,
    setIndexData,
    handleNextSubModul,
    getDetailPelatihan,
    getLogLatihan,
    setStatusLatihan,
    setIndexPathMateri,
  } = useDetailPelatihanContext();

  const subModul = pelatihan?.subModulPelatihan[indexData];

  const fetchMateri = () => {
    if (pelatihan != null) {
      const dataSubModul = pelatihan.subModulPelatihan;
      const sub_modul = dataSubModul[indexData];
      const materi = dataSubModul[indexData].m_materi[indexMateri];

      localStorage.setItem("modul_id", sub_modul.modul_pelatihan_id.toString());

      if (materi) {
        setMateri(
          materi.materi_id,
          sub_modul.id,
          materi.materi.judul_materi,
          materi.materi.uraian_deskripsi,
          materi.materi.views,
          materi.materi.tanggal_pembuatan,
          materi.materi.status_konsultasi,
        );
      } else {
        setMateri("", "", sub_modul.id, "", "", 0, "", 0);
      }

      setStatusLatihan(
        sub_modul.m_latihan.length > 0 ? sub_modul.m_latihan[0].status : "",
      );
    }
  };

  const handleNextMateri = async () => {
    if (pelatihan != null) {
      setIndexData((indexData + 1) % pelatihan.subModulPelatihan.length);

      const sub_modul = pelatihan.subModulPelatihan[indexData];

      if (sub_modul.status_pembelajaran == "Sedang Mengikuti") {
        const status = await handleNextSubModul(sub_modul.id);

        if (status === 200) {
          getDetailPelatihan(id);
          setIsVideoEnded(false);
        } else {
          toast({
            title: "Ada Kesalahan Saat Menuju Sub Modul Selanjutnya.",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    }
  };

  const handleBackMateri = () => {
    if (pelatihan != null) {
      setIndexData((indexData - 1) % pelatihan.subModulPelatihan.length);
    }
  };

  React.useEffect(() => {
    if (pelatihan != null) {
      fetchMateri();
    }
  }, [indexData]);

  React.useEffect(() => {
    if (latihan_id) getLogLatihan(1, 100, latihan_id);
  }, [latihan_id]);

  let optionsJenisMateri;

  if (pathMateri) {
    optionsJenisMateri = pathMateri.map((val, idx) => ({
      value: idx,
      label: val.jenis_materi.toUpperCase(),
    }));
  } else {
    optionsJenisMateri = [];
  }

  const { setShowChat } = useChatContext();
  const { showChat } = useChatContext().state;

  const handleShowChat = () => {
    setShowChat(!showChat);
  };

  const handleFinishMateri = async () => {
    if (
      (ada_latihan === 1 &&
        status_latihan === "2" &&
        statusSubModul === "Sedang Mengikuti") ||
      (pelatihan?.subModulPelatihan.length == indexData + 1 &&
        pelatihan?.subModulPelatihan[indexData].status_pembelajaran !==
          "Selesai Dipelajari")
    ) {
      if (pelatihan != null) {
        const dataSubModul = pelatihan.subModulPelatihan;
        const sub_modul = dataSubModul[indexData];
        const status = await handleNextSubModul(sub_modul.id);

        if (status === 200) {
          window.location.href = `/detail/pelatihan/${id}`;
        } else {
          toast({
            title: "Ada Kesalahan Saat Menyelesaikan Pelatihan.",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    } else {
      window.location.href = `/detail/pelatihan/${id}`;
    }
  };

  // use state
  const [isVideoEnded, setIsVideoEnded] = React.useState<boolean>(false);

  return (
    <div className="max-w-2xl mx-auto lg:max-w-7xl lg:px-4 px-8 mb-10 w-full">
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5">
        {/* Materi */}
        <If condition={isLoading && subModul?.jenis_sub_modul != ""}>
          <div className="col-span-3 md:col-span-3 mt-4">
            <Skeleton height={360} style={{ borderRadius: "8px" }} />
          </div>
        </If>

        <div
          className={`col-span-3 md:col-span-3 ${
            isLoading != true && subModul?.jenis_sub_modul == "materi"
              ? ""
              : "hidden"
          }`}
        >
          {/* Materi */}
          <div className="container mt-4 rounded-md">
            <MateriPelatihan isVideoEnd={(data) => setIsVideoEnded(data)} />
          </div>
          {/* Navigation */}
          <div className="flex flex-row mt-4 justify-between">
            <If condition={indexData != 0}>
              <button
                type="button"
                className="text-green-500 bg-white hover:bg-gray-100 hover:text-green-400 focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all"
                onClick={handleBackMateri}
              >
                <i className="fa-solid fa-chevron-left"></i> Sebelumnya
              </button>
            </If>
            {/* Button Show Modal */}
            <button
              className={`text-white focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all ${
                ada_latihan == 1 && status_latihan == "1"
                  ? ""
                  : ada_latihan == 1 && status_latihan == null
                    ? ""
                    : "hidden"
              } ${
                statusSubModul == "Selesai Dipelajari"
                  ? "bg-green-600 hover:bg-green-700"
                  : pathMateri[indexPathMateri] != undefined &&
                      pathMateri[indexPathMateri].jenis_materi != "video"
                    ? "bg-green-600 hover:bg-green-700"
                    : isVideoEnded
                      ? "bg-green-600 hover:bg-green-700"
                      : "bg-green-400 hover:bg-green-500 cursor-not-allowed"
              }`}
              data-modal-toggle="modalLatihan"
              disabled={
                statusSubModul == "Selesai Dipelajari"
                  ? false
                  : pathMateri[indexPathMateri] != undefined &&
                      pathMateri[indexPathMateri].jenis_materi != "video"
                    ? false
                    : isVideoEnded
                      ? false
                      : true
              }
            >
              Selanjutnya <i className="fa-solid fa-chevron-right"></i>
            </button>
            {/* Button Next Modul */}
            <button
              type="button"
              className={`text-white focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all ${
                ada_latihan == 0 &&
                status_latihan == "2" &&
                pelatihan?.subModulPelatihan.length != indexData + 1
                  ? ""
                  : ada_latihan == 0 &&
                      status_latihan == null &&
                      pelatihan?.subModulPelatihan.length != indexData + 1
                    ? ""
                    : ada_latihan == 0 &&
                        pelatihan?.subModulPelatihan.length != indexData + 1
                      ? ""
                      : ada_latihan == 1 &&
                          status_latihan == "2" &&
                          pelatihan?.subModulPelatihan.length != indexData + 1
                        ? ""
                        : "hidden"
              } ${
                statusSubModul == "Selesai Dipelajari"
                  ? "bg-green-600 hover:bg-green-700"
                  : pathMateri[indexPathMateri] != undefined &&
                      pathMateri[indexPathMateri].jenis_materi != "video"
                    ? "bg-green-600 hover:bg-green-700"
                    : isVideoEnded
                      ? "bg-green-600 hover:bg-green-700"
                      : "bg-green-400 hover:bg-green-500 cursor-not-allowed"
              }`}
              onClick={handleNextMateri}
              disabled={
                statusSubModul == "Selesai Dipelajari"
                  ? false
                  : pathMateri[indexPathMateri] != undefined &&
                      pathMateri[indexPathMateri].jenis_materi != "video"
                    ? false
                    : isVideoEnded
                      ? false
                      : true
              }
            >
              Selanjutnya <i className="fa-solid fa-chevron-right"></i>
            </button>
            {/* Button Selesai Pelatihan */}
            <button
              type="button"
              disabled={
                !isVideoEnded &&
                pelatihan?.subModulPelatihan.length === indexData + 1 &&
                pelatihan?.subModulPelatihan[indexData].status_pembelajaran !==
                  "Selesai Dipelajari"
                  ? true
                  : false
              }
              className={`text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all ${
                (pelatihan?.subModulPelatihan.length == indexData + 1 &&
                  status_latihan == "2") ||
                (pelatihan?.subModulPelatihan.length == indexData + 1 &&
                  ada_latihan === 0)
                  ? isVideoEnded || statusSubModul == "Selesai Dipelajari"
                    ? " cursor-pointer"
                    : " cursor-not-allowed"
                  : "hidden"
              }`}
              onClick={handleFinishMateri}
            >
              Selesai Pelatihan <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
          {/* Detail Pelatihan */}
          <div className="container mt-4 p-5 bg-green-600 rounded-md">
            <div className="flex flex-col">
              <h2 className="font-semibold text-xl text-white">
                {judulMateri}
              </h2>

              <div className="flex flex-col md:flex-row mt-2 text-white">
                {/* konsultasi button */}
                {statusKonsultasi == 1 ? (
                  <button
                    type="button"
                    className="py-1 px-3 border-2 border-yellow-400 mr-4 bg-green-600 text-xs rounded-lg duration-300 hover:bg-yellow-400 flex flex-row items-center"
                    onClick={handleShowChat}
                  >
                    <BiChat className="mr-1" /> Konsultasi
                  </button>
                ) : null}
                {/* views */}
                <span className="mr-4">
                  <i className="far fa-eye"></i>{" "}
                  <span className="text-gray-200 ml-1">{views}</span>
                </span>
                {/* created at */}
                <span className="mr-4">
                  <i className="far fa-clock"></i>{" "}
                  <span className="text-gray-200 ml-1">{tanggalPembuatan}</span>
                </span>
                <If condition={ada_latihan == 1}>
                  {/* latihan info */}
                  <span>
                    <i className="far fa-list-alt"></i>{" "}
                    <span className="text-gray-200 ml-1">1 Latihan</span>
                  </span>
                </If>
              </div>

              <div className="flex flex-col mt-4">
                <h2 className="text-lg font-semibold text-white">
                  Pilih Media Pembelajaran
                </h2>
                <div className="flex flex-row mt-2">
                  {optionsJenisMateri.map((val, idx) => {
                    return (
                      <button
                        className="text-white focus:ring-4 focus:ring-green-600 rounded-lg text-xs px-5 py-2.5 focus:outline-none transition-all bg-green-400 hover:bg-green-500 mr-3"
                        onClick={() => setIndexPathMateri(val.value)}
                        key={idx}
                      >
                        {val.label.toLowerCase().includes("video") ? (
                          <i className="fa-solid fa-clapperboard mr-3"></i>
                        ) : val.label.toLowerCase().includes("pdf") ? (
                          <i className="fa-solid fa-file-pdf mr-3"></i>
                        ) : val.label.toLowerCase().includes("ppt") ? (
                          <i className="fa-solid fa-file-powerpoint mr-3"></i>
                        ) : val.label.toLowerCase().includes("audio") ? (
                          <i className="fa-solid fa-music mr-3"></i>
                        ) : null}
                        {val.label}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* Informasi Umum */}
          <div className="container mt-4 p-5 bg-white rounded-md">
            <h2 className="font-semibold text-lg">Informasi Tentang Materi</h2>
            <div className="text-justify mt-2 text-sm">
              {parse(uraianDeskripsi)}
            </div>
          </div>
          {/* Informasi Umum */}
          <If condition={ada_latihan == 1 && logLatihanData.length > 0}>
            <div className="container mt-4 p-5 bg-white rounded-md">
              <h2 className="font-semibold text-lg">Riwayat Latihan</h2>
              <div className="relative overflow-x-auto mt-5">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase border-t border-b">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Tanggal
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Nilai
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadingLog ? (
                      logLatihanData.map((v, key) => (
                        <tr className="bg-white hover:bg-gray-50" key={key}>
                          <td className="px-6 py-4">
                            {new Date(v.created_at).toLocaleString()}
                          </td>
                          <td className="px-6 py-4">{v.nilai}</td>
                          <td
                            className={`px-6 py-4 ${
                              v.status == "Tidak Lulus"
                                ? "text-red-400"
                                : "text-green-400"
                            }`}
                          >
                            {v.status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <Stack>
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                      </Stack>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </If>
        </div>

        {/* If Jenis Ujian Akhir Proyek */}
        <div
          className={`col-span-3 md:col-span-3 ${
            subModul?.jenis_ujian == "project" ? "" : "hidden"
          }`}
        >
          {/* Detail Pelatihan */}
          <div className="container mt-4 p-5 bg-green-600 rounded-md">
            <div className="flex flex-row">
              <h2 className="font-semibold text-xl text-white">
                {subModul?.judul}
              </h2>
              <i className="far fa-file-code text-xl text-white ml-3"></i>
            </div>
          </div>
          <div className="container mt-4">
            {subModul?.m_final_project[0] ? (
              <iframe
                src={subModul?.m_final_project[0].path_panduan}
                width="100%"
                height="600px"
                style={stylePlayer}
              />
            ) : null}
          </div>
          {/* Informasi Umum */}
          <div className="container mt-4 p-5 bg-white rounded-md">
            <h2 className="font-semibold text-lg">Panduan Pengerjaan</h2>
            <p className="text-justify mt-2 text-sm">
              {subModul?.m_final_project[0]
                ? parse(
                    subModul?.m_final_project
                      ? subModul?.m_final_project[0].panduan_pengerjaan
                      : "",
                  )
                : null}
            </p>
            <div
              className="p-4 mt-4 text-center text-xl text-green-700 bg-green-100 rounded-md"
              role="alert"
            >
              <p>Kirim Project Untuk Menyelesaikan Pelatihan Ini</p>
              <div className="mt-4 mb-4">
                <a
                  href={`/send_project/${id}`}
                  className="focus:outline-none text-white bg-green-400 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Kirim Project
                </a>
              </div>
            </div>
          </div>
          {/* Navigation */}
          <div className="flex flex-row mt-4 justify-between">
            <If condition={indexData != 0}>
              <button
                type="button"
                className="text-green-500 bg-white hover:bg-gray-100 hover:text-green-400 focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all"
                onClick={handleBackMateri}
              >
                <i className="fa-solid fa-chevron-left"></i> Sebelumnya
              </button>
            </If>
          </div>
        </div>

        {/* If Jenis Ujian Akhir Ujian */}
        <div
          className={`col-span-3 md:col-span-3 ${
            subModul?.jenis_ujian == "ujian" ? "" : "hidden"
          }`}
        >
          {/* Detail Pelatihan */}
          <div className="container mt-4 p-5 bg-green-600 rounded-md">
            <div className="flex flex-row">
              <h2 className="font-semibold text-xl text-white">
                {subModul?.judul}
              </h2>
              <i className="far fa-file-code text-xl text-white ml-3"></i>
            </div>
          </div>
          {/* Informasi Umum */}
          <div className="container mt-4 p-5 bg-white rounded-md">
            <h2 className="font-semibold text-lg">Kata Pengantar</h2>
            <p className="text-justify mt-2 text-sm">
              Selamat anda telah sampai di penghujung pelatihan ini, anda dapat
              lulus dari pelatihan ini dengan mengerjakan ujian yang tersedia
              dengan menekan tombol{" "}
              <span className="font-semibold">Kerjakan Ujian</span> dibawah ini.
              Setelah anda dinyatakan lulus, anda akan menerima sertifikat
              sebagai tanda bahwa anda telah menyelesaikan pelatihan ini.
            </p>
            <div
              className="p-4 mt-4 text-center text-xl text-green-700 bg-green-100 rounded-md"
              role="alert"
            >
              <p>Kerjakan Ujian Untuk Dapat Menyelesaikan Pelatihan Ini</p>
              <div className="mt-4 mb-4">
                <button
                  data-modal-toggle="modalUjian"
                  className="focus:outline-none text-white bg-green-400 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Kerjakan Ujian
                </button>
              </div>
            </div>
          </div>
          {/* Navigation */}
          <div className="flex flex-row mt-4 justify-between">
            <If condition={indexData != 0}>
              <button
                type="button"
                className="text-green-500 bg-white hover:bg-gray-100 hover:text-green-400 focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all"
                onClick={handleBackMateri}
              >
                <i className="fa-solid fa-chevron-left"></i> Sebelumnya
              </button>
            </If>
          </div>
        </div>

        <div className="col-span-3 sm:col-span-2">
          <SidebarMenu />
        </div>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };
  const toast = useToast();

  // Get Pelatihan Detail
  const { getDetailPelatihan } = useDetailPelatihanContext();
  const { getLatihan } = useLatihanContext();
  const { latihan } = useLatihanContext().state;

  // Get Data Pelatihan
  const {
    judulSubModul,
    pelatihan,
    jenisSoal,
    jumlahSoal,
    latihan_id,
    indexData,
  } = useDetailPelatihanContext().state;

  const subModul = pelatihan?.subModulPelatihan[indexData];
  const idLatihan = subModul?.m_latihan[0]?.id;

  React.useEffect(() => {
    getDetailPelatihan(id);
    localStorage.setItem("pelatihan_id", id);
  }, []);

  React.useEffect(() => {
    if (idLatihan) getLatihan(idLatihan);
  }, [idLatihan]);

  // Call Controller Latihan
  const { startLatihan } = useLatihanContext();

  const totalSequence = latihan?.total_sequence ?? 0;
  const limitPengulangan = Number(latihan?.sequence) - Number(totalSequence);

  const handleMulaiLatihan = () => {
    if (pelatihan != null) {
      localStorage.setItem(
        "subModulLength",
        pelatihan.subModulPelatihan.length.toString(),
      );
      localStorage.setItem("lastIndexDataSubModul", indexData.toString());

      if (limitPengulangan <= 0) {
        toast({
          title: "Mohon maaf, Anda telah mencapai limit pengulangan",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      startLatihan(latihan_id);
    }
  };

  // Call Controller Ujian
  const { startUjian } = useUjianContext();

  const handleMulaiUjian = () => {
    startUjian(subModul?.id);
  };

  return (
    <Wrapper>
      <ContentPage />

      <Modal id_modal="modalLatihan">
        <div className="flex items-start justify-between p-6 rounded-t">
          <h3 className="text-xl font-semibold text-red-400">Mulai Latihan</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="modalLatihan"
            style={{ color: "white" }}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="p-6 space-y-6 -mt-5">
          {/* Alert Info */}
          <div
            className="p-4 mb-4 text-sm bg-yellow-100 rounded-lg flex flex-col"
            role="alert"
          >
            <span className="font-semibold text-base">
              Latihan Pada Modul {judulSubModul}
            </span>
            <div className="text-sm mt-2 flex flex-col">
              <span>{pelatihan?.judul_pelatihan}</span>
              <span>
                Tanggal Pelaksanaan :{" "}
                {moment(pelatihan?.waktu_mulai).format("LL")} sd{" "}
                {moment(pelatihan?.waktu_selesai).format("LL")}
              </span>
            </div>
          </div>

          {/* Info */}
          <div className="mt-4 flex flex-col text">
            <span className="text-sm">
              Latihan ini terdiri dari{" "}
              <span className="font-semibold">
                {" "}
                {jumlahSoal} Soal {jenisSoal}
              </span>
            </span>

            {limitPengulangan < 0 ? null : (
              <div className="text-sm">
                Limit Pengulangan:
                <span className="font-semibold"> {limitPengulangan}</span>
              </div>
            )}

            <div className="mt-3">
              <span className="text-sm">Petunjuk Pengisian :</span>
              <div className="font-semibold">
                {subModul?.m_latihan[0]
                  ? parse(subModul.m_latihan[0].m_paket_soal[0].petunjuk_soal)
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="items-center p-6">
          <button
            data-modal-toggle="modalLatihan"
            type="button"
            className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800 w-full"
            onClick={handleMulaiLatihan}
          >
            Mulai mengerjakan
          </button>
        </div>
      </Modal>

      {/* Modal Ujian */}
      <Modal id_modal="modalUjian">
        <div className="flex items-start justify-between p-6 rounded-t">
          <h3 className="text-xl font-semibold text-red-400">Mulai Ujian</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="modalUjian"
            style={{ color: "white" }}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="p-6 space-y-6 -mt-5">
          {/* Alert Info */}
          <div
            className="p-4 mb-4 text-sm bg-yellow-100 rounded-lg flex flex-col"
            role="alert"
          >
            <span className="font-semibold text-base">
              Ujian Pada Modul {judulSubModul}
            </span>
            <div className="text-sm mt-2 flex flex-col">
              <span>{pelatihan?.judul_pelatihan}</span>
              <span>
                Tanggal Pelaksanaan :{" "}
                {moment(pelatihan?.waktu_mulai).format("LL")} sd{" "}
                {moment(pelatihan?.waktu_selesai).format("LL")}
              </span>
            </div>
          </div>

          {/* Info */}
          <div className="mt-4 flex flex-col text">
            <span className="text-sm">
              Latihan ini terdiri dari{" "}
              <span className="font-semibold">
                {" "}
                {subModul?.m_paket_soal[0]
                  ? subModul?.m_paket_soal[0].m_soal.length
                  : null}{" "}
                Soal{" "}
                {subModul?.m_paket_soal[0]
                  ? subModul?.m_paket_soal[0].jenis_soal
                  : null}
              </span>
            </span>

            <div className="mt-3">
              <span className="text-sm">Petunjuk Pengisian :</span>
              <div className="font-semibold">
                {subModul?.m_paket_soal[0]
                  ? parse(subModul.m_paket_soal[0].petunjuk_soal)
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="items-center p-6">
          <button
            data-modal-toggle="modalUjian"
            type="button"
            className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800 w-full"
            onClick={handleMulaiUjian}
          >
            Mulai mengerjakan
          </button>
        </div>
      </Modal>
    </Wrapper>
  );
};

const PelatihanSayaDetails: React.FC = () => {
  return (
    <DetailPelatihanController.Provider>
      <LatihanController.Provider>
        <UjianController.Provider>
          <ChatController.Provider>
            <WrapperMain />
          </ChatController.Provider>
        </UjianController.Provider>
      </LatihanController.Provider>
    </DetailPelatihanController.Provider>
  );
};

export default PelatihanSayaDetails;
