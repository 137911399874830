import { injectable } from "tsyringe";
import { HelpDeskBantuanPublicApiRepository } from "../../app/repository/api/HelpDeskBantuanPublicApiRepository";

@injectable()
export class HelpDeskBantuanPublicPresenter {
  private repository: HelpDeskBantuanPublicApiRepository;
  constructor(repository: HelpDeskBantuanPublicApiRepository) {
    this.repository = repository;
  }

  public storeHelpDeskBantuan(
    nama: string,
    email: string,
    subject: string,
    asal_kantor: string,
    nip: string,
    pelatihan_diikuti: string,
    pesan: string,
    path_pendukung: File,
    g_recaptcha_response: string,
  ): Promise<any> {
    return this.repository.storeHelpDeskBantuan(
      nama,
      email,
      subject,
      asal_kantor,
      nip,
      pelatihan_diikuti,
      pesan,
      path_pendukung,
      g_recaptcha_response,
    );
  }
}
