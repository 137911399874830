import React from "react";
import {
  AiOutlinePlus,
  AiOutlineSave,
  BiArrowBack,
  HiDotsVertical,
  ImFilePlay,
} from "react-icons/all";
import ImgUsers from "../../../../assets/png/img_users.png";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";

const BottomHeader: React.FC = () => {
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Tambah Pelatihan</a>
      </div>
      <button
        type="button"
        className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
      >
        Simpan
        <AiOutlineSave className="ml-2" style={{ marginTop: "2px" }} />
      </button>
    </div>
  );
};

const Section = ({ urutan, nama }: { urutan: number; nama: string }) => {
  return (
    <div className="bg-gray-100 rounded-full flex justify-between mx-3 px-3 py-4 mt-3">
      <a className="text-xs font-semibold">
        Section {urutan} : <span className="font-normal">{nama}</span>
      </a>
      <div className="dropdown relative">
        <a
          className="dropdown-toggle flex items-center hidden-arrow"
          href="#"
          id="dropdownMenuButton2"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <HiDotsVertical />
        </a>
        <ul
          className="
                            dropdown-menu
                            min-w-max
                            absolute
                            hidden
                            bg-white
                            text-base
                            z-50
                            float-left
                            py-2
                            list-none
                            text-left
                            rounded-lg
                            shadow-lg
                            mt-1
                            hidden
                            m-0
                            bg-clip-padding
                            border-none
                            left-auto
                            right-0
                        "
          aria-labelledby="dropdownMenuButton2"
        >
          <li>
            <a
              className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                            "
              href="detail/manajemen_soal/1"
            >
              Ubah
            </a>
          </li>
          <li>
            <a
              className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                            "
              href="detail/manajemen_soal/1"
            >
              Hapus
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const SubSection = ({ nama }: { nama: string }) => {
  return (
    <div className="bg-gray-100 rounded-full flex justify-between mx-3 px-3 py-4 mt-3">
      <a className="text-xs font-semibold flex">
        <ImFilePlay className="mr-2" style={{ marginTop: "1px" }} />
        {nama}
      </a>
      <div className="dropdown relative">
        <a
          className="dropdown-toggle flex items-center hidden-arrow"
          href="#"
          id="dropdownMenuButton2"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <HiDotsVertical />
        </a>
        <ul
          className="
                            dropdown-menu
                            min-w-max
                            absolute
                            hidden
                            bg-white
                            text-base
                            z-50
                            float-left
                            py-2
                            list-none
                            text-left
                            rounded-lg
                            shadow-lg
                            mt-1
                            hidden
                            m-0
                            bg-clip-padding
                            border-none
                            left-auto
                            right-0
                        "
          aria-labelledby="dropdownMenuButton2"
        >
          <li>
            <a
              className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                            "
              href="detail/manajemen_soal/1"
            >
              Ubah
            </a>
          </li>
          <li>
            <a
              className="
                                dropdown-item
                                text-sm
                                py-2
                                px-4
                                font-normal
                                w-full
                                whitespace-nowrap
                                bg-transparent
                                text-gray-700
                                hover:bg-gray-100
                            "
              href="detail/manajemen_soal/1"
            >
              Hapus
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const Widyaiswara = ({ nama }: { nama: string }) => {
  return (
    <div className="bg-gray-100 rounded-full flex mx-3 px-3 py-2 mt-3">
      <img
        src={ImgUsers}
        className="rounded-full mb-1"
        style={{ width: "30px" }}
      />
      <a className="ml-3 my-auto">{nama}</a>
    </div>
  );
};

const ItemSoal = ({ id, nomor }: { id: string; nomor: number }) => {
  return (
    <div className="accordion-item bg-white border border-gray-200 mt-3 rounded-lg">
      <button
        className="
            accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            border-0
            transition
            focus
            focus:outline-none
          "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + id}
        aria-expanded="true"
        aria-controls={"#" + id}
      >
        <a className="px-2 py-1 text-sm font-semibold border-2  border-sky-600">
          {nomor}
        </a>
        <p className="ml-4 mr-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam aliquet
          tempus, faucibus volutpat posuere urna euismod. Enim dignissim commodo
          purus justo proin tellus volutpat, tincidunt odio. Felis maecenas mi
          ac blandit ac, blandit turpis proin.
        </p>
      </button>
      <div id={id} className="accordion-collapse collapse rounded-lg">
        <div className="accordion-body py-3 px-5 rounded-lg">
          <ItemOpsi jawabanBenar={true} opsi="A" />
          <ItemOpsi jawabanBenar={false} opsi="B" />
          <ItemOpsi jawabanBenar={false} opsi="C" />
          <ItemOpsi jawabanBenar={false} opsi="D" />
        </div>
      </div>
    </div>
  );
};

const ItemOpsi = ({
  jawabanBenar,
  opsi,
}: {
  jawabanBenar: boolean;
  opsi: string;
}) => {
  return (
    <div
      className="flex relative px-3 py-3 mb-5 rounded-md"
      style={{ backgroundColor: jawabanBenar ? "#EFFFF9" : "#F3F3F3" }}
    >
      <a
        className="px-2 py-1 text-xs font-semibold rounded"
        style={{
          border: jawabanBenar ? "1px solid #0DBD7F" : "1px solid #4F4F4F",
          color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
          height: "25px",
        }}
      >
        {opsi}
      </a>
      <a
        className="px-2 py-1 text-xs font-normal rounded ml-3"
        style={{
          border: jawabanBenar ? "1px solid #0DBD7F" : "1px solid #4F4F4F",
          color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
          height: "25px",
        }}
      >
        Jawaban{jawabanBenar ? "Benar" : "Salah"}
      </a>
      <p
        className="px-2 py-1 text-xs font-normal rounded ml-3"
        style={{ color: jawabanBenar ? "#0DBD7F" : "#4F4F4F" }}
      >
        Pertanyaan quiz - lorem ipsum Lorem Ipsum Generator.Generate Lorem Ipsum
        placeholder text?
      </p>
    </div>
  );
};

const Konten: React.FC = () => {
  return (
    <div className="bg-white rounded-lg  mt-5">
      <div
        className="grid md:grid-cols-11 sm:grid-cols-3"
        style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}
      >
        <div
          className="col-span-3 md:col-span-3 sm:grid-cols-1 "
          style={{ borderRight: "1px solid #E0E0E0", height: "100%" }}
        >
          <p className="text-center px-5 py-5">Total JP : 45 Jam Pelatihan</p>
          <div
            className="py-5"
            style={{ borderTop: "1px solid #E0E0E0", width: "100%" }}
          >
            <div className="flex justify-between">
              <a className="font-semibold ml-3">Section </a>
              <button
                type="button"
                className="mr-3 flex px-1 py-1 bg-green-600 text-white font-semibold text-xs leading-tight rounded-full shadow-md hover:bg-green-700"
              >
                <AiOutlinePlus />
              </button>
            </div>
            <Section urutan={1} nama="Pendahuluan" />
            <SubSection nama="Overview" />
            <SubSection nama="BLC" />
            <Section urutan={2} nama="Dasar" />
            <SubSection nama="Moderasi Beragama" />
            <SubSection nama="Moderasi Beragama" />
            <SubSection nama="Moderasi Beragama" />
            <Section urutan={3} nama="Inti" />
            <Section urutan={4} nama="Final Project" />
          </div>
          <div
            className="py-5"
            style={{ borderTop: "1px solid #E0E0E0", width: "100%" }}
          >
            <a className="font-semibold ml-3">Widyaiswara</a>
            <Widyaiswara nama="Widyaiswara 1" />
            <Widyaiswara nama="Widyaiswara 2" />
          </div>
        </div>

        <div className="col-span-8 md:col-span-8 sm:grid-cols-1 ">
          <div className="flex px-5 py-5">
            <a
              href="/admin/kelola_konten/manajemen_pelatihan/1"
              className="mr-3 flex px-1 py-1 bg-green-600 text-white font-semibold text-xs leading-tight rounded-full shadow-md hover:bg-green-700"
            >
              <BiArrowBack />
            </a>
            <a>Detail Soal Tes Latihan XXX</a>
          </div>
          <div
            className="py-5 px-5"
            style={{ borderTop: "1px solid #E0E0E0", width: "100%" }}
          >
            <ItemSoal id="Soal1" nomor={1} />
            <ItemSoal id="Soal2" nomor={2} />
            <ItemSoal id="Soal3" nomor={3} />
            <ItemSoal id="Soal4" nomor={4} />
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <a
          href="/admin/tambah/manajemen_pelatihan"
          className="px-6 py-2.5 border border-green-400 text-green-400 font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 mr-2"
        >
          Sebelumnya
        </a>
        <button
          type="button"
          className="px-6 py-2.5 bg-green-400 text-white font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 mr-4"
        >
          Selanjutnya
        </button>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <div className="app">
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <BottomHeader />
        </header>

        <div className="container px-4 md:px-12 mb-10">
          <div className="bg-white rounded-lg ">
            <ul className="stepper pb-3 rounded-lg" data-mdb-stepper="stepper">
              <li className="stepper-step">
                <a href="/admin/tambah/manajemen_pelatihan">
                  <div className="stepper-head">
                    <span
                      className="stepper-head-icon font-semibold"
                      style={{
                        width: "37px",
                        height: "37px",
                        backgroundColor: "#0DBD7F",
                      }}
                    >
                      01
                    </span>
                    <span className="stepper-head-text font-semibold">
                      Data Pelatihan
                    </span>
                  </div>
                </a>
              </li>
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{
                      width: "37px",
                      height: "37px",
                      backgroundColor: "#0DBD7F",
                    }}
                  >
                    02
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Kelola Konten
                  </span>
                </div>
              </li>
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{ width: "37px", height: "37px" }}
                  >
                    03
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Penilaian
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <Konten />
        </div>
      </main>
    </div>
  );
};

const DetailSoalManajemenPelatihan: React.FC = () => {
  return (
    <SidebarWithHeader title="Manajemen Pelatihan">
      <WrapperMain />;
    </SidebarWithHeader>
  );
};

export default DetailSoalManajemenPelatihan;
