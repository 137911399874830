import { injectable } from "tsyringe";
import { DashboardApiRepository } from "../../app/repository/api/DashboardApiRepository";
import { Dashboard } from "../../entities/Dashboard";

@injectable()
export class DashboardPresenter {
  private repository: DashboardApiRepository;
  constructor(repository: DashboardApiRepository) {
    this.repository = repository;
  }

  public getDashboardData(rows: number): Promise<Dashboard> {
    return this.repository.getDashboardData(rows);
  }
}
