/**
 * CardItem component displays a card with an image, title, and action icons.
 *
 * @param {CardItemProps} props - The props for the CardItem component.
 * @returns {JSX.Element} JSX.Element representing the CardItem component.
 */

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCalendar, AiOutlineStar } from "react-icons/ai";
import { FiEdit, FiTrash2 } from "react-icons/fi";

interface CardItemProps {
  item?: any;
  handleDelete: Function;
  handleEdit: Function;
  image: string;
  isEventPintar?: boolean;
  module: string;
}

interface DeleteButtonProps {
  onDelete: Function;
  module: string;
}

/**
 * CardItem component.
 * @component
 * @author Muhammad Farras Jibran
 */

const DeleteButton = ({ onDelete, module }: DeleteButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false; // Set the mounted state to false on component unmount
    };
  }, []);

  const deleteItem = async () => {
    setIsLoadingDelete(true);

    try {
      await onDelete();
      if (isMounted.current) {
        // Check if the component is still mounted before updating state
        setIsLoadingDelete(false);
        onClose();
      }
    } catch (error) {
      if (isMounted.current) {
        setIsLoadingDelete(false);
      }
    }
  };

  return (
    <>
      <IconButton
        aria-label="Delete Item"
        icon={<FiTrash2 />}
        color="#EB5757"
        bg="transparent"
        onClick={onOpen}
        fontSize="md"
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Konfirmasi Penghapusan
            </AlertDialogHeader>

            <AlertDialogBody>
              Apakah Anda yakin ingin menghapus {module} ini?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Batal</Button>
              <Button
                colorScheme="red"
                onClick={deleteItem}
                ml={3}
                isLoading={isLoadingDelete}
              >
                Yakin
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const CardItem = ({
  item,
  handleDelete,
  handleEdit,
  image,
  isEventPintar = false,
  module,
}: CardItemProps) => {
  const formatDate = (dateString) => {
    const dateObject = moment(dateString, "YYYY-MM-DD HH:mm:ss");

    const formattedDate = dateObject.format("DD MMM YYYY");

    return formattedDate;
  };

  const validateDateRange = (status, startDate, endDate) => {
    if (status === 0) {
      return ["Draft", "#3C4952"];
    }

    if (!isEventPintar) {
      return ["Aktif", "#0DBD7F"];
    }

    const today = moment();
    const start = moment(startDate, "YYYY-MM-DD HH:mm:ss");
    const end = moment(endDate, "YYYY-MM-DD HH:mm:ss");

    if (today.isBefore(start)) {
      return ["Aktif", "#0DBD7F"];
    } else if (today.isBetween(start, end)) {
      return ["Proses", "#FFC271"];
    } else if (today.isAfter(end)) {
      return ["Selesai", "#0DBD7F"];
    }

    return "";
  };

  const renderLabel = (status, startDate, endDate) => {
    return validateDateRange(status, startDate, endDate);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box boxShadow="lg" borderRadius="lg">
      <Box position="relative">
        <Image
          objectFit="cover"
          fallback={<Skeleton w="full" h="full" />}
          src={
            image ||
            "https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
          }
          alt="thumbnail"
          w="full"
          borderTopRadius="lg"
          h="200px"
        />
        <Box
          position="absolute"
          px={3}
          color="white"
          bg={
            renderLabel(
              item.status_publish,
              item.training_start_date,
              item.training_end_date,
            )[1]
          }
          borderRadius="40px"
          top={2}
          right={2}
          fontSize="sm"
          fontWeight="semibold"
        >
          {
            renderLabel(
              item.status_publish,
              item.training_start_date,
              item.training_end_date,
            )[0]
          }
        </Box>
      </Box>
      <Box p={4} bg="white" borderBottomRadius="lg">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="md" fontWeight="semibold">
            {item?.title}
          </Text>
          <Flex>
            <IconButton
              aria-label="Edit Item"
              icon={<FiEdit />}
              color="#F2994A"
              bg="transparent"
              fontSize="md"
              onClick={() => handleEdit(item?.id)}
            />

            <DeleteButton
              onDelete={() => handleDelete(item.id)}
              module={module}
            />
          </Flex>
        </Flex>
        {isEventPintar && (
          <>
            <Flex mt={4} alignItems="flex-end">
              <Icon as={AiOutlineCalendar} boxSize={5} mr={3} />
              <Text fontSize="sm" mr={2}>
                {formatDate(item?.training_start_date)}
              </Text>
              <Text mr={2} fontSize="sm">
                s.d
              </Text>
              <Text fontSize="sm">{formatDate(item?.training_end_date)}</Text>
            </Flex>
            <Flex mt={2} alignItems="end">
              <Icon as={AiOutlineStar} boxSize={5} mr={3} />
              <Text fontSize="sm">{capitalizeFirstLetter(item?.location)}</Text>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CardItem;
