import { MenuJenisPelatihanRepositoryInterface } from "../../../data/contracts/MenuJenisPelatihan";
import { MenuJenisPelatihanDataMapper } from "../../../data/mappers/MenuJenisPelatihan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  MenuJenisPelatihan,
  SubMenuJenisPelatihan,
} from "../../../entities/MenuJenisPelatihan";

export class MenuJenisPelatihanApiRepository
  implements MenuJenisPelatihanRepositoryInterface
{
  private apiService: ApiService;
  private mapper: MenuJenisPelatihanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: MenuJenisPelatihanDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllMenuJenisPelatihan(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<MenuJenisPelatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.menuJenisPelatihan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers,
    );
    return this.mapper.convertMenuJenisPelatihanDataFromApi(dataResponse);
  }

  public async getDetailMenuJenisPelatihan(
    id: number,
  ): Promise<MenuJenisPelatihan> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.menuJenisPelatihan() + "/" + id,
      {},
      null,
      headers,
    );
    return this.mapper.convertDetailMenuJenisPelatihanDataFromApi(dataResponse);
  }

  public async storeMenuJenisPelatihan(
    menu_jenis_pelatihan: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("menu_jenis_pelatihan", menu_jenis_pelatihan.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.menuJenisPelatihan(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateMenuJenisPelatihan(
    id: number,
    menu_jenis_pelatihan: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("menu_jenis_pelatihan", menu_jenis_pelatihan);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.menuJenisPelatihan() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteMenuJenisPelatihan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.menuJenisPelatihan() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }

  public async getAllSubMenuJenisPelatihan(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<SubMenuJenisPelatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      "public/" + this.endpoints.subMenuJenisPelatihan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers,
    );
    return this.mapper.convertSubMenuJenisPelatihanDataFromApi(dataResponse);
  }

  public async getDetailSubMenuJenisPelatihan(
    id: number,
  ): Promise<SubMenuJenisPelatihan> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.subMenuJenisPelatihan() + "/" + id,
      {},
      null,
      headers,
    );
    return this.mapper.convertDetailSubMenuJenisPelatihanDataFromApi(
      dataResponse,
    );
  }

  public async storeSubMenuJenisPelatihan(
    menu_jenis_pelatihan_id: number,
    sub_menu_jenis_pelatihan: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append(
      "menu_jenis_pelatihan_id",
      menu_jenis_pelatihan_id.toString(),
    );
    formData.append("sub_menu_jenis_pelatihan", sub_menu_jenis_pelatihan);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.subMenuJenisPelatihan(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateSubMenuJenisPelatihan(
    id: number,
    menu_jenis_pelatihan_id: number,
    sub_menu_jenis_pelatihan: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append(
      "menu_jenis_pelatihan_id",
      menu_jenis_pelatihan_id.toString(),
    );
    formData.append("sub_menu_jenis_pelatihan", sub_menu_jenis_pelatihan);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.subMenuJenisPelatihan() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteSubMenuJenisPelatihan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.subMenuJenisPelatihan() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
