export class dataFaq {
  id: number;
  pertanyaan: string;
  jawaban: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;
  status_publish: number;
  constructor(
    id: number,
    pertanyaan: string,
    jawaban: string,
    created_at: string,
    updated_at: string,
    jumlahData: number,
    status_publish: number,
  ) {
    this.id = id;
    this.pertanyaan = pertanyaan;
    this.jawaban = jawaban;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
    this.status_publish = status_publish;
  }
}
