import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { db } from "../../../services/firebase";

interface IState {
  isLoading: boolean;
  isLoadingJoinKonsultasi: boolean;
  isLoadingFetchLogChat: boolean;
  error: boolean;
  conversationPage: boolean;
  mainPage: boolean;
  logChat: any[];
  chat: any[];
  idChatCurrent: string;
  contentMessage: string;
  selectedMessage: any[];
  showChat: boolean;
  historyPage: boolean;
}

interface InitialState {
  state: IState;
  backPage: Function;
  toConversationPage: Function;
  toHistoryPage: Function;
  getLogChat: Function;
  getDetailChat: Function;
  startKonsultasi: Function;
  sendMessage: Function;
  setContentMessage: Function;
  setSelectedMessage: Function;
  setShowChat: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingJoinKonsultasi: false,
    isLoadingFetchLogChat: false,
    error: false,
    conversationPage: false,
    mainPage: true,
    logChat: [],
    chat: [],
    idChatCurrent: "",
    contentMessage: "",
    selectedMessage: [],
    showChat: false,
    historyPage: false,
  },
  backPage: () => {},
  toConversationPage: () => {},
  getLogChat: () => {},
  toHistoryPage: () => {},
  getDetailChat: () => {},
  startKonsultasi: () => {},
  sendMessage: () => {},
  setContentMessage: () => {},
  setSelectedMessage: () => {},
  setShowChat: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ChatProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingJoinKonsultasi: false,
    isLoadingFetchLogChat: false,
    error: false,
    conversationPage: false,
    mainPage: true,
    logChat: [],
    chat: [],
    idChatCurrent: "",
    contentMessage: "",
    selectedMessage: [],
    showChat: false,
    historyPage: false,
  });

  const logChatRef = collection(db, "log_chat");
  const chatRef = collection(db, "chat");

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setShowChat = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showChat: value,
    }));
  };

  const setLoadingJoinKonsultasi = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingJoinKonsultasi: value,
    }));
  };

  const setLoadingFetchLogChat = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingFetchLogChat: value,
    }));
  };

  const backPage = () => {
    setState((prevstate) => ({
      ...prevstate,
      conversationPage: false,
      mainPage: true,
      historyPage: false,
    }));
  };

  const toConversationPage = () => {
    setState((prevstate) => ({
      ...prevstate,
      conversationPage: true,
      mainPage: false,
      historyPage: false,
    }));
  };

  const setContentMessage = (message: string) => {
    setState((prevstate) => ({
      ...prevstate,
      contentMessage: message,
    }));
  };

  const setSelectedMessage = (idx: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedMessage: [state.logChat[idx]],
      idChatCurrent: state.logChat[idx].id,
    }));
  };

  const getLogChat = async () => {
    setLoadingFetchLogChat(true);

    const logRefChat = query(
      collection(db, "log_chat"),
      orderBy("timestamp", "desc"),
    );

    try {
      onSnapshot(logRefChat, (snapshot) => {
        setState((prevstate) => ({
          ...prevstate,
          logChat: [],
        }));

        snapshot.docs.map((doc) => {
          return setState((prevstate) => ({
            ...prevstate,
            logChat: [...prevstate.logChat, { id: doc.id, data: doc.data() }],
          }));
        });

        setLoadingFetchLogChat(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailChat = () => {
    setLoading(true);

    let chatRefByValue;

    if (state.idChatCurrent) {
      chatRefByValue = query(
        collection(db, "chat"),
        orderBy("timestamp", "asc"),
        where("id_log_chat", "==", state.idChatCurrent),
      );
    }

    try {
      onSnapshot(chatRefByValue, (snapshot) => {
        setState((prevstate) => ({
          ...prevstate,
          chat: [],
        }));

        snapshot.docs.map((doc) => {
          return setState((prevstate) => ({
            ...prevstate,
            chat: [...prevstate.chat, { id: doc.id, data: doc.data() }],
          }));
        });

        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const startKonsultasi = (
    id_materi: number,
    id_user: number,
    is_done: boolean,
    judul_materi: string,
    nama_user: string,
  ) => {
    const timestamp = new Date();
    setLoadingJoinKonsultasi(true);

    let chatRefByValue;
    const is_read = true;

    if (id_user) {
      chatRefByValue = query(
        collection(db, "log_chat"),
        where("id_user", "==", id_user),
      );
    }

    getDocs(chatRefByValue)
      .then((response) => {
        // check if response is empty, create data. but is not just update data
        if (response.empty) {
          // add doc
          addDoc(logChatRef, {
            id_materi,
            id_user,
            is_done,
            judul_materi,
            nama_user,
            timestamp,
          })
            .then((response) => {
              setState((prevstate) => ({
                ...prevstate,
                idChatCurrent: response.id,
              }));

              toConversationPage();
              setLoadingJoinKonsultasi(false);
            })
            .catch((err) => console.log(err));
        } else {
          let docChatRef;
          let chatRef;
          response.docs.map((value) => {
            docChatRef = doc(db, "log_chat", value.id);
            chatRef = query(
              collection(db, "chat"),
              where("id_log_chat", "==", value.id),
              where("is_admin", "==", true),
              where("is_read", "==", false),
            );
            return setState((prevstate) => ({
              ...prevstate,
              idChatCurrent: value.id,
            }));
          });

          getDocs(chatRef)
            .then((resp) => {
              resp.docs.map((value) => {
                // update chat
                return updateDoc(doc(db, "chat", value.id), { is_read })
                  .then(() => {})
                  .catch((err) => console.log(err));
              });

              // update log chat
              return updateDoc(docChatRef, { timestamp })
                .then(() => {
                  toConversationPage();
                  setLoadingJoinKonsultasi(false);
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   *
   * @param id_user
   * @param is_admin
   * @param nama_user
   */
  const sendMessage = (
    id_user: number,
    is_admin: boolean,
    nama_user: string,
  ) => {
    const id_log_chat = state.idChatCurrent;
    const content = state.contentMessage;
    const timestamp = new Date();
    const is_read = false;

    // add doc
    addDoc(chatRef, {
      id_log_chat,
      id_user,
      is_admin,
      is_read,
      content,
      nama_user,
      timestamp,
    })
      .then((response) => {})
      .catch((err) => console.log(err));
  };

  /**
   *
   * @param id_user
   */
  const toHistoryPage = (id_user: number) => {
    setState((prevstate) => ({
      ...prevstate,
      historyPage: true,
      mainPage: false,
    }));

    getLogChatByIdUser(id_user);
  };

  /**
   *
   * @param id_user
   */
  const getLogChatByIdUser = (id_user: number) => {
    setLoadingFetchLogChat(true);
    let chatRefByValue;

    if (id_user) {
      chatRefByValue = query(
        collection(db, "log_chat"),
        where("id_user", "==", id_user),
      );
    }
    getDocs(chatRefByValue)
      .then((response) => {
        setState((prevstate) => ({
          ...prevstate,
          logChat: [],
        }));
        response.docs.map((doc) => {
          return setState((prevstate) => ({
            ...prevstate,
            logChat: [...prevstate.logChat, { id: doc.id, data: doc.data() }],
          }));
        });
        setLoadingFetchLogChat(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingFetchLogChat(true);
      });
  };

  return (
    <ChatProvider
      value={{
        state,
        backPage,
        toConversationPage,
        getLogChat,
        getDetailChat,
        startKonsultasi,
        sendMessage,
        setContentMessage,
        setSelectedMessage,
        setShowChat,
        toHistoryPage,
      }}
    >
      {children}
    </ChatProvider>
  );
};

export const useChatContext = () => React.useContext(Context);

export default {
  useChatContext,
  Provider,
};
