import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface SlideBannerItem {
  id: number;
  title: string;
  description: string;
  link: string;
  file: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}

interface IState {
  state: {
    title: string;
    image: File | null;
    description: string;
    link: string;
    publish: boolean;
    sliderBannerData: SlideBannerItem[];
    loadingData: false;
    loadingAction: false;
    mode: string;
    itemId: string;
    url: string;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
  };
  setSlideBannerState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
  handleEdit: Function;
  clearState: Function;
}

const initialState: IState = {
  state: {
    title: "",
    image: null,
    description: "",
    link: "",
    publish: false,
    sliderBannerData: [],
    loadingData: false,
    loadingAction: false,
    mode: "",
    itemId: "",
    url: "management_content/slider_banner",
    pagination: null,
    page: 1,
    perPage: 9,
  },
  setSlideBannerState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleDelete: () => {},
  handleEdit: () => {},
  clearState: () => {},
};

/**
 * The SlideBannerContext provides a context for managing the state related to slide banners.
 * It includes state properties and functions to update the state and post data to a server.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: SlideBannerProvider } = Context;

/**
 * The provider component that wraps its children with the SlideBannerContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    title,
    description,
    image,
    link,
    publish,
    mode,
    itemId,
    url,
    sliderBannerData: data,
    page,
    perPage,
  } = state.state;

  const setSlideBannerState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const clearState = () => {
    setSlideBannerState("title", "");
    setSlideBannerState("description", "");
    setSlideBannerState("publish", false);
    setSlideBannerState("link", "");
    setSlideBannerState("image", null);
  };

  const fetchData = async (status?: string) => {
    setSlideBannerState("loadingData", true);

    try {
      const response = await axiosInstance.get(url, {
        params: {
          status: status ?? "0,1",
          rows: perPage,
          page: page,
        },
      });

      setSlideBannerState("sliderBannerData", response.data?.data);
      setSlideBannerState("pagination", response?.data.pagination);
      setSlideBannerState("loadingData", false);
    } catch (error) {
      throw error;
    }
  };

  const postData = async () => {
    if (title.length > 30) {
      return toast({
        title: "Maksimal judul 30 karakter",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
    setSlideBannerState("loadingAction", true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("link", link);
    formData.append("status_publish", publish ? "1" : "0");

    if (image) {
      let newImage;
      if (typeof image === "string") {
        newImage = "";
      } else {
        newImage = image;
      }
      formData.append("file", newImage);
    }

    try {
      const idParams = mode === "edit" ? "/" + itemId : "";

      const response = await axiosInstance.post(`${url}${idParams}`, formData);
      setSlideBannerState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      setSlideBannerState("mode", "");

      fetchData();

      return response.data;
    } catch (error: any) {
      setSlideBannerState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      if (responseData.data != null) {
        Object.keys(responseData.errors).forEach((key) => {
          responseData.errors[key].forEach((errorMessage) => {
            toast({
              title: titleMessage,
              description: errorMessage,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          });
        });
      } else {
        toast({
          title: "System Error",
          description:
            "Terdapat error pada sistem, silahkan coba beberapa saat lagi.",
          status: "error",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      }

      throw error;
    }
  };

  const handleEdit = (id) => {
    const dataEdit = data.find((item) => item.id === id);
    if (dataEdit) {
      setSlideBannerState("mode", "edit");
      setSlideBannerState("title", dataEdit?.title);
      setSlideBannerState("description", dataEdit?.description);
      setSlideBannerState(
        "publish",
        dataEdit?.status_publish === 0 ? false : true,
      );
      setSlideBannerState("link", dataEdit?.link);
      setSlideBannerState("image", dataEdit?.file);
      setSlideBannerState("itemId", id);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  return (
    <SlideBannerProvider
      value={{
        state: state.state,
        setSlideBannerState,
        fetchData,
        postData,
        handleDelete,
        handleEdit,
        clearState,
      }}
    >
      {children}
    </SlideBannerProvider>
  );
};

export const useSlideBannerContext = () => useContext(Context);

const SlideBannerContext = {
  useSlideBannerContext,
  Provider,
};

export default SlideBannerContext;
