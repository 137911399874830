import { AxiosResponse } from "axios";
import { Materi, Widyaiswara } from "../../entities/Materi";

export class MateriDataMapper {
  public convertMateriDataFromApi(result: AxiosResponse<any>): Materi[] {
    const { data } = result.data;

    const materi: Materi[] = [];

    data.map((m: any) => {
      const widyaiswara_assigned: Widyaiswara[] = [];

      m.widyaiswara_assigned.map((w: any) => {
        return widyaiswara_assigned.push(
          new Widyaiswara(w.user.name, w.user.path_foto ?? ""),
        );
      });

      return materi.push(
        new Materi(
          m.id,
          m.path_thumbnail,
          m.judul_materi,
          m.uraian_deskripsi,
          m.kompetensi,
          m.jam_pelatihan,
          m.status_konsultasi,
          m.widyaiswara,
          m.status_publish,
          m.created_at,
          m.updated_at,
          m.views,
          result.data.pagination.total_rows,
          m.tanggal_pembuatan,
          m.penyusun,
          m.jenis_pelatihan_id,
          m.rating,
          m.jenis_pelatihan,
          widyaiswara_assigned,
          m.count_reviewer,
          m.count_review_excellent,
          m.count_review_verry_good,
          m.count_review_average,
          m.count_review_poor,
          m.count_review_terrible,
        ),
      );
    });

    return materi;
  }

  public convertDetailMateriDataFromApi(result: AxiosResponse<any>): Materi {
    const { data } = result.data;

    let materi: Materi;

    const widyaiswara_assigned: Widyaiswara[] = [];

    data.widyaiswara_assigned.map((w: any) => {
      return widyaiswara_assigned.push(
        new Widyaiswara(w.user.name, w.user.path_foto ?? ""),
      );
    });

    materi = new Materi(
      data.id,
      data.path_thumbnail,
      data.judul_materi,
      data.uraian_deskripsi,
      data.kompetensi,
      data.jam_pelatihan,
      data.status_konsultasi,
      data.widyaiswara,
      data.status_publish,
      data.created_at,
      data.updated_at,
      data.views,
      0,
      data.tanggal_pembuatan,
      data.penyusun,
      data.jenis_pelatihan_id,
      data.rating,
      data.jenis_pelatihan,
      widyaiswara_assigned,
      data.count_reviewer,
      data.count_review_excellent,
      data.count_review_verry_good,
      data.count_review_average,
      data.count_review_poor,
      data.count_review_terrible,
    );

    return materi;
  }
}
