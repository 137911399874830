import axios from "axios";

// Buat instance Axios dengan konfigurasi default
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BE_PROD_BASE_URL, // Ubah sesuai dengan base URL API Anda
  // Anda dapat menambahkan konfigurasi lain di sini
});

// Jika Anda ingin menambahkan header default, contohnya:
axiosInstance.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("tokenAuth");

export default axiosInstance;
