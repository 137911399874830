/* eslint-disable eqeqeq */
import React from "react";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { FinalProjectPresenter } from "../../../../../data/presenters/FinalProjectPresenter";
import { PelatihanPresenter } from "../../../../../data/presenters/PelatihanPresenter";
import { Pelatihan } from "../../../../../entities/Pelatihan";

interface IState {
  isLoading: boolean;
  pelatihan: Pelatihan | null;
  judulPelatihan: string;
  tanggalPelatihan: string;
  waktuPengumpulan: string;
  uraian_penjelasan: string;
  path_final_project: any;
  id_sub_modul: string;
}

interface InitialState {
  state: IState;
  getDetailPelatihan: Function;
  storeFinalProject: Function;
  setUraianPenjelasan: Function;
  setPathFinalProject: Function;
}

const initialState = {
  state: {
    isLoading: true,
    pelatihan: null,
    judulPelatihan: "",
    tanggalPelatihan: "",
    waktuPengumpulan: "",
    uraian_penjelasan: "",
    path_final_project: null,
    id_sub_modul: "",
  },
  getDetailPelatihan: () => {},
  storeFinalProject: () => {},
  setUraianPenjelasan: () => {},
  setPathFinalProject: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ProjectProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    pelatihan: null,
    judulPelatihan: "",
    tanggalPelatihan: "",
    waktuPengumpulan: "",
    uraian_penjelasan: "",
    path_final_project: null,
    id_sub_modul: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setUraianPenjelasan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      uraian_penjelasan: value,
    }));
  };

  const setPathFinalProject = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_final_project: value,
    }));
  };

  // get detail pelatihan
  const getDetailPelatihan = async (id: number) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      const subModulData: any = data.subModulPelatihan.find(
        (x) => x.status_pembelajaran == "Sedang Mengikuti",
      );

      setState((prevstate) => ({
        ...prevstate,
        judulPelatihan: data.judul_pelatihan,
        tanggalPelatihan: data.tanggal_pelatihan,
        waktuPengumpulan: subModulData?.waktu_pengumpulan_final_project,
        id_sub_modul: subModulData?.id,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  // Store Project
  const storeFinalProject = async (pelatihan_id: number) => {
    setLoading(true);

    if (state.uraian_penjelasan == "" || state.path_final_project == "") {
      toast.error("Data yang anda masukkan kurang lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const finalProjectPresenter = container.resolve(FinalProjectPresenter);
      const store = await finalProjectPresenter.storeFinalProject(
        pelatihan_id,
        state.path_final_project,
        state.uraian_penjelasan,
      );

      const data = store.data;

      return data.code;
    } catch (error: any) {
      console.log("error store final project:", error);
      toast.error(
        `Error Saat Mengirim Project Akhir : ${error.response.data.message}`,
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        },
      );
      setLoading(false);
    }
  };

  return (
    <ProjectProvider
      value={{
        state,
        getDetailPelatihan,
        storeFinalProject,
        setUraianPenjelasan,
        setPathFinalProject,
      }}
    >
      {children}
    </ProjectProvider>
  );
};

export const useProjectContext = () => React.useContext(Context);

export default {
  useProjectContext,
  Provider,
};
