import { Icon } from "@chakra-ui/react";
import React from "react";
import { BsShare } from "react-icons/bs";
import { GetAuthData } from "../../../misc/GetAuthData";
import { usePodcastContext } from "../../controller/podcast";

/**
 * SharePodcast component for rendering an icon to share a podcast.
 *
 * @component
 * @param {object} props - Component props.
 * @param {number} props.id - The ID of the podcast to be shared.
 * @param {Function} props.onOpen - Callback function to open the share modal.
 * @returns {JSX.Element} SharePodcast component JSX element.
 * @author Muhammad Farras Jibran
 */

interface SharePodcastProps {
  id: number;
  onOpen: Function;
}

const SharePodcast = ({ id, onOpen }: SharePodcastProps) => {
  const useAuth = GetAuthData();
  const { setPodcastState } = usePodcastContext();
  return (
    <Icon
      id="share-podcast"
      as={BsShare}
      boxSize={5}
      color="black"
      _hover={{ cursor: "pointer" }}
      onClick={() => {
        if (useAuth !== null) {
          setPodcastState("currentId", id);
          onOpen();
        } else {
          window.location.href = "/login";
        }
      }}
    />
  );
};

export default SharePodcast;
