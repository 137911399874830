import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface PodcastItem {
  id: number;
  title: string;
  description: string;
  audio: string;
  thumbnail: string;
  duration: number;
  category: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}

interface IState {
  state: {
    title: string;
    image: File | null;
    audio: File | null;
    description: string;
    publish: boolean;
    loadingData: boolean;
    loadingAction: boolean;
    loadingEdit: boolean;
    listData: PodcastItem[];
    url: string;
    mode: string;
    itemId: string;
    category: {
      label: string;
      value: string;
    } | null;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
  };
  setManajemenPodcastState: Function;
  fetchData: Function;
  postData: Function;
  handleEdit: Function;
  handleDelete: Function;
  clearState: Function;
}

const initialState: IState = {
  state: {
    title: "",
    image: null,
    audio: null,
    description: "",
    publish: false,
    loadingData: false,
    loadingAction: false,
    loadingEdit: false,
    listData: [],
    url: "management_content/podcast",
    mode: "",
    itemId: "",
    category: null,
    pagination: null,
    page: 1,
    perPage: 9,
  },
  setManajemenPodcastState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  clearState: () => {},
};

/**
 * The ManajemenPodcastContext provides a context for managing the state related to manajemen podcast.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: ManajemenPodcastProvider } = Context;

/**
 * The provider component that wraps its children with the ManajemenPodcastContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    title,
    image,
    audio,
    publish,
    description,
    url,
    mode,
    listData,
    itemId,
    category,
    page,
    perPage,
  } = state.state;

  const setManajemenPodcastState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const clearState = () => {
    setManajemenPodcastState("title", "");
    setManajemenPodcastState("description", "");
    setManajemenPodcastState("publish", false);
    setManajemenPodcastState("audio", null);
    setManajemenPodcastState("image", null);
    setManajemenPodcastState("category", "");
  };

  const getCategoryPodcast = async () => {
    try {
      const response = await axiosInstance.get(
        "management_content/podcast/category",
      );
      const transformedData = response.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return transformedData;
    } catch (error) {
      throw error;
    }
  };

  const fetchData = async (status?: string) => {
    setManajemenPodcastState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: status ?? "0,1",
          rows: perPage,
          page: page,
        },
      });

      setManajemenPodcastState("listData", response.data?.data);
      setManajemenPodcastState("pagination", response?.data.pagination);
      setManajemenPodcastState("loadingData", false);
    } catch (error) {
      throw error;
    }
  };

  const postData = async () => {
    if (title.length > 30) {
      return toast({
        title: "Maksimal judul 30 karakter",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
    setManajemenPodcastState("loadingAction", true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("status_publish", publish ? "1" : "0");
    if (category) {
      formData.append("category", category.label);
    }

    if (image) {
      let newImage;
      if (typeof image === "string") {
        newImage = "";
      } else {
        newImage = image;
      }
      formData.append("thumbnail", newImage);
    }

    if (audio) {
      let newAudio;
      if (typeof audio === "string") {
        newAudio = "";
      } else {
        newAudio = audio;
      }
      formData.append("audio", newAudio);
    }

    try {
      const idParams = mode === "edit" ? "/" + itemId : "";
      const response = await axiosInstance.post(`${url}${idParams}`, formData);
      setManajemenPodcastState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      setManajemenPodcastState("mode", "");

      fetchData();

      return response.data;
    } catch (error: any) {
      setManajemenPodcastState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      Object.keys(responseData.errors).forEach((key) => {
        responseData.errors[key].forEach((errorMessage) => {
          toast({
            title: titleMessage,
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        });
      });

      throw error;
    }
  };

  const handleEdit = async (id) => {
    setManajemenPodcastState("loadingEdit", true);
    const dataEdit = listData.find((item) => item.id === id);

    if (dataEdit) {
      const listCategory = await getCategoryPodcast();

      const categorytSelected = listCategory.find(
        (item) => item.label === dataEdit?.category,
      );
      setManajemenPodcastState("title", dataEdit?.title);
      setManajemenPodcastState("category", categorytSelected);

      setManajemenPodcastState("description", dataEdit?.description);
      setManajemenPodcastState(
        "publish",
        dataEdit?.status_publish === 0 ? false : true,
      );
      setManajemenPodcastState("audio", dataEdit?.audio);
      setManajemenPodcastState("image", dataEdit?.thumbnail);
      setManajemenPodcastState("itemId", id);
      setManajemenPodcastState("mode", "edit");
    }

    setManajemenPodcastState("loadingEdit", false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  return (
    <ManajemenPodcastProvider
      value={{
        state: state.state,
        setManajemenPodcastState,
        fetchData,
        postData,
        handleEdit,
        handleDelete,
        clearState,
      }}
    >
      {children}
    </ManajemenPodcastProvider>
  );
};

export const useManajemenPodcastContext = () => useContext(Context);

const ManajemenPodcastContext = {
  useManajemenPodcastContext,
  Provider,
};

export default ManajemenPodcastContext;
