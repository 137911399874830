/**
 * KnowledgeCenter component.
 * This component represents the Knowledge Center view.
 */

import { Box, Container, Link, Skeleton, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { usePengetahuanContext } from "../../controller/pengetahuan/index";
import CardItem from "../global/Card/CardItem";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

/**
 * KnowledgeCenter component.
 *
 * @returns {React.FC} The KnowledgeCenter component.
 * @author Muhammad Farras Jibran
 */

const KnowledgeCenter: React.FC = () => {
  const { isLoading, listData } = usePengetahuanContext().state;
  const { fetchData } = usePengetahuanContext();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box bgGradient="linear(blue.50 0%,  white 100%)">
      <Container maxW="8xl" py={{ base: 8, md: 12 }} w="full">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          w="full"
        >
          <Text
            as="h2"
            fontWeight="semibold"
            fontSize={{ base: "xl", md: "2xl" }}
            letterSpacing="tight"
          >
            Knowledge Center
          </Text>

          <Link
            href="/pengetahuan"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="semibold"
            color="BluePrimary.900"
            _hover={{ color: "BluePrimary.900" }}
          >
            Lihat Semua
          </Link>
        </Box>
        <Box mt={5}>
          {isLoading ? (
            <Stack>
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
            </Stack>
          ) : (
            <Box>
              {listData?.length > 0 ? (
                <Carousel responsive={responsive}>
                  {listData.map((v: any) => (
                    <CardItem item={v} key={v.id} accessFor="pengetahuan" />
                  ))}
                </Carousel>
              ) : (
                <Box mx="auto" textAlign="center" my={20}>
                  <Text fontSize="xl" color="gray.400">
                    Belum Ada Pengetahuan Terbaru
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default KnowledgeCenter;
