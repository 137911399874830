import { SliderRepositoryInterface } from "../../../data/contracts/Slider";
import { SliderDataMapper } from "../../../data/mappers/Slider";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Slider } from "../../../entities/Slider";
import { GetLocalStorage } from "../../misc/UseLocalStorage";

export class SliderApiRepository implements SliderRepositoryInterface {
  private apiService: ApiService;
  private mapper: SliderDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: SliderDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllSlider(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<Slider[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? "superadmin/" + this.endpoints.slider()
        : "public/" + this.endpoints.slider(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers,
    );
    return this.mapper.convertSliderDataFromApi(dataResponse);
  }

  public async storeSlider(
    nama_user_testimoni: string,
    testimoni: string,
    jabatan_user_testimoni: string,
    path_slider: File,
    status_publish: boolean,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("nama_user_testimoni", nama_user_testimoni);
    formData.append("testimoni", testimoni);
    formData.append("jabatan_user_testimoni", jabatan_user_testimoni);
    formData.append("path_slider", path_slider);
    formData.append("status_publish", status_publish.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      "superadmin/" + this.endpoints.slider(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateSlider(
    id: number,
    nama_user_testimoni: string,
    testimoni: string,
    jabatan_user_testimoni: string,
    path_slider: File,
    status_publish: boolean,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("nama_user_testimoni", nama_user_testimoni);
    formData.append("testimoni", testimoni);
    formData.append("jabatan_user_testimoni", jabatan_user_testimoni);
    if (path_slider != null) {
      formData.append("path_slider", path_slider);
    }
    formData.append("status_publish", status_publish.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      "superadmin/" + this.endpoints.slider() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteSlider(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      "superadmin/" + this.endpoints.slider() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
