import { injectable } from "tsyringe";
import { SettingPenilaianApiRepository } from "../../app/repository/api/SettingPenilaianApiRepository";
import { SettingPenilaian } from "../../entities/SettingPenilaian";

@injectable()
export class SettingPenilaianPresenter {
  private repository: SettingPenilaianApiRepository;
  constructor(repository: SettingPenilaianApiRepository) {
    this.repository = repository;
  }

  public getAllSettingPenilaian(
    page: number,
    rows: number,
    keyword: string,
    jenis_pelatihan_id: string,
  ): Promise<SettingPenilaian[]> {
    return this.repository.getAllSettingPenilaian(
      page,
      rows,
      keyword,
      jenis_pelatihan_id,
    );
  }

  public getDetailSettingPenilaian(id: number): Promise<SettingPenilaian> {
    return this.repository.getDetailSettingPenilaian(id);
  }

  public storeSettingPenilaian(
    jenis_pelatihan_id: number,
    nilai_bawah: string,
    nilai_atas: string,
    predikat: string,
  ): Promise<any> {
    return this.repository.storeSettingPenilaian(
      jenis_pelatihan_id,
      nilai_bawah,
      nilai_atas,
      predikat,
    );
  }

  public updateSettingPenilaian(
    id: number,
    jenis_pelatihan_id: number,
    nilai_bawah: string,
    nilai_atas: string,
    predikat: string,
  ): Promise<any> {
    return this.repository.updateSettingPenilaian(
      id,
      jenis_pelatihan_id,
      nilai_bawah,
      nilai_atas,
      predikat,
    );
  }

  public deleteSettingPenilaian(id: number): Promise<any> {
    return this.repository.deleteSettingPenilaian(id);
  }
}
