import { AxiosResponse } from "axios";
import { PelatihanSaya, Peserta } from "../../entities/PelatihanSaya";

export class PelatihanSayaDataMapper {
  public convertListPelatihanSayaDataFromApi(
    result: AxiosResponse<any>,
  ): PelatihanSaya[] {
    const { data } = result.data;

    const pelatihanSaya: PelatihanSaya[] = [];

    data.map((d: any) => {
      const peserta = new Peserta(
        d.users.m_peserta.id,
        d.users.name,
        d.users.nip,
        d.users.m_peserta.pangkat,
        d.users.m_peserta.gol_ruang,
        d.users.m_peserta.level_jabatan,
      );

      return pelatihanSaya.push(
        new PelatihanSaya(
          d.id,
          d.user_id,
          d.m_pelatihan.id,
          d.m_pelatihan.jenis_pelatihan_id,
          d.m_pelatihan.path_thumbnail,
          d.path_sertifikat,
          d.status_pelatihan,
          d.nilai_akhir,
          d.predikat,
          d.m_pelatihan.judul_pelatihan,
          d.m_pelatihan.penyelenggara,
          d.m_pelatihan.waktu_mulai,
          d.m_pelatihan.waktu_selesai,
          d.modul_selesai,
          d.modul_belum,
          peserta,
          result.data.pagination.total_rows,
          d.status_tte,
        ),
      );
    });

    return pelatihanSaya;
  }
}
