/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PaketSoalPresenter } from "../../../../../../data/presenters/PaketSoalPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaketSoal } from "../../../../../../entities/PaketSoal";
import { KategoriPaketSoal } from "../../../../../../entities/KategoriPaketSoal";
import { KategoriPaketSoalPresenter } from "../../../../../../data/presenters/KategoriPaketSoalPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  tahun: string;
  kategori_soal: string;
  jenis_soal: string;
  totalPaketSoal: number;
  paketSoal: PaketSoal[];
  formId: number;
  formDialog: string;
  formJudul: string;
  formTahun: string;
  formKategori: string;
  formJenisSoal: string;
  formPetunjukSoal: string;
  petunjukSoal: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  kategoriPaketSoal: KategoriPaketSoal[];
}

interface InitialState {
  state: IState;
  getPaketSoal: Function;
  setPage: Function;
  searchPaketSoal: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormJudul: Function;
  setFormTahun: Function;
  setFormKategori: Function;
  setFormJenisSoal: Function;
  setFormPetunjukSoal: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deletePaketSoal: Function;
  filterTahun: Function;
  filterKategoriSoal: Function;
  filterJenisSoal: Function;
  getListKategoriPaketSoal: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalPaketSoal: 1,
    paketSoal: [],
    formDialog: "",
    formId: 0,
    formJudul: "",
    formTahun: "",
    formKategori: "",
    formJenisSoal: "",
    formPetunjukSoal: "",
    petunjukSoal: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    kategoriPaketSoal: [],
  },
  getPaketSoal: () => {},
  searchPaketSoal: () => {},
  setPage: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormJudul: () => {},
  setFormTahun: () => {},
  setFormKategori: () => {},
  setFormJenisSoal: () => {},
  setFormPetunjukSoal: () => {},
  setShowDialog: () => {},
  deletePaketSoal: () => {},
  setShowDialogDelete: () => {},
  filterTahun: () => {},
  filterKategoriSoal: () => {},
  filterJenisSoal: () => {},
  getListKategoriPaketSoal: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenSoalProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalPaketSoal: 0,
    paketSoal: [],
    formId: 0,
    formDialog: "",
    formJudul: "",
    formTahun: "",
    formKategori: "",
    formJenisSoal: "",
    formPetunjukSoal: "",
    petunjukSoal: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    kategoriPaketSoal: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setLoading(true);

    getPaketSoal(
      value + 1,
      state.keyword,
      state.tahun,
      state.kategori_soal,
      state.jenis_soal,
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getPaketSoal = async (
    pagee: number,
    keyword: string,
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
  ) => {
    setLoading(true);

    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const getAllPaketSoal = await paketSoalPresenter.getAllPaketSoal(
        pagee,
        12,
        keyword,
        tahun,
        kategori_soal,
        jenis_soal,
      );

      const data = getAllPaketSoal;

      setJumlahPage(data[0].jumlahDataPaketSoal / 12);

      setState((prevstate) => ({
        ...prevstate,
        paketSoal: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchPaketSoal = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      paketSoal: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPaketSoal(1, value, state.tahun, state.kategori_soal, state.jenis_soal);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
      page: 1,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: PaketSoal) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formJudul: value.judul_soal,
      formTahun: value.tahun,
      formKategori: `${value.kategori_paket_soal.id}`,
      formJenisSoal: value.jenis_soal,
      petunjukSoal: value.petunjuk_soal,
      formPetunjukSoal: value.petunjuk_soal,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (
      state.formTahun == "" ||
      state.formKategori == "" ||
      state.formJenisSoal == "" ||
      state.formJudul == "" ||
      state.formPetunjukSoal == ""
    ) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      if (state.formDialog == "Tambah") {
        const action = await paketSoalPresenter.storePaketSoal(
          state.formTahun,
          state.formKategori,
          state.formJenisSoal,
          state.formJudul,
          state.formPetunjukSoal,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getPaketSoal(
            state.page,
            state.keyword,
            state.tahun,
            state.kategori_soal,
            state.jenis_soal,
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await paketSoalPresenter.updatePaketSoal(
          state.formId,
          state.formTahun,
          state.formKategori,
          state.formJenisSoal,
          state.formJudul,
          state.formPetunjukSoal,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getPaketSoal(
            state.page,
            state.keyword,
            state.tahun,
            state.kategori_soal,
            state.jenis_soal,
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormJudul = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJudul: value,
    }));
  };

  const setFormJenisSoal = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJenisSoal: value,
    }));
  };

  const setFormKategori = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKategori: value,
    }));
  };

  const setFormPetunjukSoal = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPetunjukSoal: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const setFormTahun = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formTahun: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formJudul: "",
      formTahun: "",
      formKategori: "",
      formJenisSoal: "",
      formPetunjukSoal: "",
    }));
  };

  const deletePaketSoal = async () => {
    setFormLoading(true);

    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const action = await paketSoalPresenter.deletePaketSoal(state.formId);
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getPaketSoal(
          state.page,
          state.keyword,
          state.tahun,
          state.kategori_soal,
          state.jenis_soal,
        );
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const filterTahun = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      paketSoal: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPaketSoal(
      1,
      state.keyword,
      value,
      state.kategori_soal,
      state.jenis_soal,
    );
    setState((prevstate) => ({
      ...prevstate,
      tahun: value,
      page: 1,
    }));
  };

  const filterKategoriSoal = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      paketSoal: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPaketSoal(1, state.keyword, state.tahun, value, state.jenis_soal);
    setState((prevstate) => ({
      ...prevstate,
      kategori_soal: value,
      page: 1,
    }));
  };

  const filterJenisSoal = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      paketSoal: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPaketSoal(1, state.keyword, state.tahun, state.kategori_soal, value);
    setState((prevstate) => ({
      ...prevstate,
      jenis_soal: value,
      page: 1,
    }));
  };

  const getListKategoriPaketSoal = async () => {
    try {
      const kategoriPaketSoal = container.resolve(KategoriPaketSoalPresenter);
      const action = await kategoriPaketSoal.getAllKategoriPaketSoal(
        1,
        1000,
        "",
      );
      const data = action;

      setState((prevstate) => ({
        ...prevstate,
        kategoriPaketSoal: data,
      }));
    } catch (error: any) {
      console.log("error get kategori paket soal:", error);
    }
  };

  return (
    <ManajemenSoalProvider
      value={{
        state,
        getPaketSoal,
        setPage,
        searchPaketSoal,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormJudul,
        setFormJenisSoal,
        setFormKategori,
        setFormPetunjukSoal,
        setFormTahun,
        setShowDialog,
        deletePaketSoal,
        setShowDialogDelete,
        filterTahun,
        filterKategoriSoal,
        filterJenisSoal,
        getListKategoriPaketSoal,
      }}
    >
      {children}
    </ManajemenSoalProvider>
  );
};

export const useManajemenSoalContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenSoalContext,
  Provider,
};
