/**
 * MobileBottomNavbar Component displays the bottom navigation bar for mobile devices.
 */

import { Box, Divider, Grid } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  IoBook,
  IoBookOutline,
  IoHome,
  IoHomeOutline,
  IoInformationCircle,
  IoInformationCircleOutline,
  IoMic,
  IoMicOutline,
  IoPerson,
  IoPersonOutline,
  IoVideocam,
  IoVideocamOutline,
} from "react-icons/io5";
import { GetAuthData } from "../../../../misc/GetAuthData";
import MenuItem from "./MenuItem";

/**
 * MobileBottomNavbar component.
 *
 * @returns {React.FC} The MobileBottomNavbar component.
 * @author Muhammad Farras Jibran
 */

const MobileBottomNavbar: React.FC = () => {
  const [menuSelected, setMenuSelected] = useState<string>(
    window.location.pathname.substring(1),
  );

  const renderColor = (menu: string) => {
    return menuSelected === menu ? "BluePrimary.900" : "black";
  };

  const renderIcon = (menu: string) => {
    switch (menu) {
      case "":
        return menuSelected === menu ? IoHome : IoHomeOutline;
      case "pelatihan":
        return menuSelected === menu ? IoBook : IoBookOutline;
      case "video-course":
        return menuSelected === menu ? IoVideocam : IoVideocamOutline;
      case "pengetahuan":
        return menuSelected === menu
          ? IoInformationCircle
          : IoInformationCircleOutline;
      case "podcast":
        return menuSelected === menu ? IoMic : IoMicOutline;
      case "profile":
        return menuSelected === menu ? IoPerson : IoPersonOutline;
      default:
        return IoHomeOutline;
    }
  };

  const menuItems = [
    {
      menuSelected: "",
      title: "Beranda",
      color: renderColor(""),
      icon: renderIcon(""),
    },
    {
      menuSelected: "pelatihan",
      title: "Pelatihan",
      color: renderColor("pelatihan"),
      icon: renderIcon("pelatihan"),
    },
    {
      menuSelected: "pengetahuan",
      title: "Pengetahuan",
      color: renderColor("pengetahuan"),
      icon: renderIcon("pengetahuan"),
    },
    {
      menuSelected: "podcast",
      title: "Podcast",
      color: renderColor("podcast"),
      icon: renderIcon("podcast"),
    },
    {
      menuSelected: "profile",
      title: "Akun",
      color: renderColor("profile"),
      icon: renderIcon("profile"),
    },
  ];

  return (
    <Box
      position="sticky"
      bottom={0}
      bg="white"
      zIndex={40}
      maxH={100}
      display={{
        base: GetAuthData() !== null ? "block" : "none",
        md: "none",
      }}
    >
      <Divider orientation="horizontal" />
      <Box p={4}>
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.menuSelected}
              setMenuSelected={setMenuSelected}
              menuSelected={item.menuSelected}
              title={item.title}
              color={item.color}
              icon={item.icon}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MobileBottomNavbar;
