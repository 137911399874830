import { PaketSoalRepositoryInterface } from "../../../data/contracts/PaketSoal";
import { PaketSoalDataMapper } from "../../../data/mappers/PaketSoal";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { PaketSoal } from "../../../entities/PaketSoal";

export class PaketSoalApiRepository implements PaketSoalRepositoryInterface {
  private apiService: ApiService;
  private mapper: PaketSoalDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: PaketSoalDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllPaketSoal(
    page: number,
    rows: number,
    keyword: string,
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
  ): Promise<PaketSoal[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.paketSoal(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        tahun: tahun,
        kategori_paket_soal_id: kategori_soal,
        jenis_soal: jenis_soal,
      },
      null,
      headers,
    );
    return this.mapper.convertListPaketSoalDataFromApi(dataResponse);
  }

  public async getDetailPaketSoal(id: number): Promise<PaketSoal> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.paketSoal() + "/" + id,
      {},
      null,
      headers,
    );
    return this.mapper.convertDetailPaketSoalDataFromApi(dataResponse);
  }

  public async storePaketSoal(
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
    judul_soal: string,
    petunjuk_soal: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("tahun", tahun);
    formData.append("kategori_soal", kategori_soal);
    formData.append("jenis_soal", jenis_soal);
    formData.append("judul_soal", judul_soal);
    formData.append("petunjuk_soal", petunjuk_soal);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.paketSoal(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updatePaketSoal(
    id: number,
    tahun: string,
    kategori_soal: string,
    jenis_soal: string,
    judul_soal: string,
    petunjuk_soal: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("tahun", tahun);
    formData.append("kategori_soal", kategori_soal);
    formData.append("jenis_soal", jenis_soal);
    formData.append("judul_soal", judul_soal);
    formData.append("petunjuk_soal", petunjuk_soal);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.paketSoal() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deletePaketSoal(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.paketSoal() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
