export class User {
  id: number;
  name: string;
  nip: string;
  nik: string;
  email: string;
  level: string;
  token: string;
  path_foto: string;
  status_pengisian: boolean;
  expired_in: number;
  jenis_user: number;

  constructor(
    id: number,
    name: string,
    nip: string,
    nik: string,
    email: string,
    level: string,
    token: string,
    path_foto: string,
    status_pengisian: boolean,
    expired_in: number,
    jenis_user: number,
  ) {
    this.id = id;
    this.name = name;
    this.nip = nip;
    this.nik = nik;
    this.email = email;
    this.level = level;
    this.token = token;
    this.path_foto = path_foto;
    this.status_pengisian = status_pengisian;
    this.expired_in = expired_in;
    this.jenis_user = jenis_user;
  }
}

export class RegisterUser {
  id: number;
  name: string;
  nip: string;
  nik: string;
  email: string;
  level: string;
  token: string;
  phone_number: string;

  constructor(
    id: number,
    name: string,
    nip: string,
    nik: string,
    email: string,
    level: string,
    token: string,
    phone_number: string,
  ) {
    this.id = id;
    this.name = name;
    this.nip = nip;
    this.nik = nik;
    this.email = email;
    this.level = level;
    this.token = token;
    this.phone_number = phone_number;
  }
}

export class Users {
  id: number;
  name: string;
  email: string;
  nik: string;
  nip: string;
  phone_number: string;
  level: string;
  email_verified_at: string | null;
  remember_token: string | null;
  path_foto: string | null;
  created_at: string;
  updated_at: string;
  jumlahData: number;

  constructor(
    id: number,
    name: string,
    email: string,
    nik: string,
    nip: string,
    phone_number: string,
    level: string,
    email_verified_at: string | null,
    remember_token: string | null,
    path_foto: string | null,
    created_at: string,
    updated_at: string,
    jumlahData: number,
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.nik = nik;
    this.nip = nip;
    this.phone_number = phone_number;
    this.level = level;
    this.email_verified_at = email_verified_at;
    this.remember_token = remember_token;
    this.path_foto = path_foto;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
  }
}

export class ManagemenUser {
  id: number;
  name: string;
  email: string;
  nik: string;
  nip: string;
  phone_number: string;
  level: string;
  email_verified_at: string | null;
  remember_token: string | null;
  path_foto: string | null;
  jenis_user: number;
  created_at: string;
  updated_at: string;
  jumlahData: number;

  constructor(
    id: number,
    name: string,
    email: string,
    nik: string,
    nip: string,
    phone_number: string,
    level: string,
    email_verified_at: string | null,
    remember_token: string | null,
    path_foto: string | null,
    jenis_user: number,
    created_at: string,
    updated_at: string,
    jumlahData: number,
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.nik = nik;
    this.nip = nip;
    this.phone_number = phone_number;
    this.level = level;
    this.email_verified_at = email_verified_at;
    this.remember_token = remember_token;
    this.path_foto = path_foto;
    this.jenis_user = jenis_user;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
  }
}

export class detailUser {
  id: number;
  nama_lengkap: string;
  nip: string;
  pangkat: string;
  golongan: string;
  jabatan: string;
  instansi: string;
  tempat_tugas: string;
  nik: string;
  jenis_kelamin: string;
  tempat_lahir: string;
  tanggal_lahir: string;
  provinsi_id: number;
  kabupaten_id: number;
  kelurahan_id: number;
  kecamatan_id: number;
  alamat: string;
  no_hp: string;
  email: string;
  status_pengisian: boolean;
  path_foto: string;
  jenis_user: number;
  agama: string;
  status_kawin: string;
  status_pegawai: string;
  last_sync_simpeg: string;
  is_user_sso: number;
  is_update_profile: number;

  constructor(
    id: number,
    nama_lengkap: string,
    nip: string,
    pangkat: string,
    golongan: string,
    jabatan: string,
    instansi: string,
    tempat_tugas: string,
    nik: string,
    jenis_kelamin: string,
    tempat_lahir: string,
    tanggal_lahir: string,
    provinsi_id: number,
    kabupaten_id: number,
    kelurahan_id: number,
    kecamatan_id: number,
    alamat: string,
    no_hp: string,
    email: string,
    status_pengisian: boolean,
    path_foto: string,
    jenis_user: number,
    agama: string,
    status_kawin: string,
    status_pegawai: string,
    last_sync_simpeg: string,
    is_user_sso: number,
    is_update_profile: number,
  ) {
    this.id = id;
    this.nama_lengkap = nama_lengkap;
    this.nip = nip;
    this.pangkat = pangkat;
    this.golongan = golongan;
    this.jabatan = jabatan;
    this.instansi = instansi;
    this.tempat_tugas = tempat_tugas;
    this.nik = nik;
    this.jenis_kelamin = jenis_kelamin;
    this.tempat_lahir = tempat_lahir;
    this.tanggal_lahir = tanggal_lahir;
    this.provinsi_id = provinsi_id;
    this.kabupaten_id = kabupaten_id;
    this.kelurahan_id = kelurahan_id;
    this.kecamatan_id = kecamatan_id;
    this.alamat = alamat;
    this.no_hp = no_hp;
    this.email = email;
    this.status_pengisian = status_pengisian;
    this.path_foto = path_foto;
    this.jenis_user = jenis_user;
    this.agama = agama;
    this.status_kawin = status_kawin;
    this.status_pegawai = status_pegawai;
    this.last_sync_simpeg = last_sync_simpeg;
    this.is_user_sso = is_user_sso;
    this.is_update_profile = is_update_profile;
  }
}
