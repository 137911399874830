export class HelpdeskBantuan {
  id: number;
  nama: string;
  email: string;
  subject: string;
  asal_kantor: string;
  nip: string;
  pelatihan_diikuti: string;
  pesan: string;
  path_pendukung: string;
  tanggapan: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;
  constructor(
    id: number,
    nama: string,
    email: string,
    subject: string,
    asal_kantor: string,
    nip: string,
    pelatihan_diikuti: string,
    pesan: string,
    path_pendukung: string,
    tanggapan: string,
    created_at: string,
    updated_at: string,
    jumlahData: number,
  ) {
    this.id = id;
    this.nama = nama;
    this.email = email;
    this.subject = subject;
    this.asal_kantor = asal_kantor;
    this.nip = nip;
    this.pelatihan_diikuti = pelatihan_diikuti;
    this.pesan = pesan;
    this.path_pendukung = path_pendukung;
    this.tanggapan = tanggapan;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
  }
}
