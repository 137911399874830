import { UjianRepositoryInterface } from "../../../data/contracts/Ujian";
import { UjianDataMapper } from "../../../data/mappers/Ujian";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Exam } from "../../../entities/Exam";

export class UjianApiRepository implements UjianRepositoryInterface {
  private apiService: ApiService;
  private mapper: UjianDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: UjianDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getUjian(sub_modul_pelatihan_id: number): Promise<Exam> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.ujianPeserta() + "/" + sub_modul_pelatihan_id,
      {},
      null,
      headers,
    );
    return this.mapper.convertListUjianData(dataResponse);
  }

  public async startUjian(sub_modul_pelatihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.ujianPeserta() + "/start",
      {
        sub_modul_pelatihan_id: sub_modul_pelatihan_id,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async finishUjian(sub_modul_pelatihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.ujianPeserta() + "/finish",
      {
        sub_modul_pelatihan_id: sub_modul_pelatihan_id,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async answerUjian(
    soal_id: number,
    id_jawaban_peserta: number,
    jawaban_peserta: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.ujianPeserta() + "/answer",
      {
        soal_id: soal_id,
        id_jawaban_peserta: id_jawaban_peserta,
        jawaban_peserta: jawaban_peserta,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async updateWaktu(sub_modul_pelatihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.ujianPeserta() + "/update_waktu",
      {
        sub_modul_pelatihan_id: sub_modul_pelatihan_id,
      },
      null,
      headers,
    );
    return dataResponse;
  }
}
