export class MenuJenisPelatihan {
  id: number;
  menu_jenis_pelatihan: string;
  created_at: string;
  updated_at: string;
  subMenuJenisPelatihan: SubMenuJenisPelatihan[];

  constructor(
    id: number,
    menu_jenis_pelatihan: string,
    created_at: string,
    updated_at: string,
    subMenuJenisPelatihan: SubMenuJenisPelatihan[],
  ) {
    this.id = id;
    this.menu_jenis_pelatihan = menu_jenis_pelatihan;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.subMenuJenisPelatihan = subMenuJenisPelatihan;
  }
}

export class SubMenuJenisPelatihan {
  id: number;
  menu_jenis_pelatihan_id: number;
  sub_menu_jenis_pelatihan: string;
  judul_menu: string;
  created_at: string;
  updated_at: string;

  constructor(
    id: number,
    menu_jenis_pelatihan_id: number,
    sub_menu_jenis_pelatihan: string,
    judul_menu: string,
    created_at: string,
    updated_at: string,
  ) {
    this.id = id;
    this.menu_jenis_pelatihan_id = menu_jenis_pelatihan_id;
    this.sub_menu_jenis_pelatihan = sub_menu_jenis_pelatihan;
    this.judul_menu = judul_menu;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
