export class Kabupaten {
  id: number;
  provinsi_id: number;
  nama_kabupaten: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;

  constructor(
    id: number,
    provinsi_id: number,
    nama_kabupaten: string,
    created_at: string,
    updated_at: string,
    jumlahData: number,
  ) {
    this.id = id;
    this.provinsi_id = provinsi_id;
    this.nama_kabupaten = nama_kabupaten;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
  }
}
