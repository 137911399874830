import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect } from "react";
import { AiOutlineClockCircle } from "react-icons/all";
import CategoryBadge from "../../../components/global/Badge/CategoryBadge";
import CardItem from "../../../components/global/Card/CardItem";
import { Wrapper } from "../../../components/Wrapper";
import PengetahuanDetailContext, {
  usePengetahuanDetailContext,
} from "../../../controller/pengetahuan/detail";
import PengetahuanController, {
  usePengetahuanContext,
} from "../../../controller/pengetahuan/index";

/**
 * Component for displaying detailed knowledge content.
 * @returns {JSX.Element} The DetailPengetahuan component.
 * @author Muhammad Farras Jibran
 */

const DetailPengetahuan = () => {
  const { fetchData } = usePengetahuanDetailContext();
  const { pengetahuanItem, loadingData } = usePengetahuanDetailContext().state;
  const { fetchData: getAllPengetahuan } = usePengetahuanContext();
  const { listData, isLoading: loadingDataTerkait } =
    usePengetahuanContext().state;

  const filterListData = listData
    .filter((item) => {
      return item.id.toString() !== window.location.pathname.split("/", 4)[3];
    })
    .splice(0, 3);

  useEffect(() => {
    fetchData(window.location.pathname.split("/", 4)[3]);
    getAllPengetahuan();
  }, []);

  return (
    <>
      <Box m={{ base: 8, md: 12 }}>
        <Grid templateColumns="repeat(3, 1fr)" gap={8}>
          {loadingData ? (
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Skeleton h="600px" w="full" mb={4} />
              <Skeleton h="300px" w="full" />
            </GridItem>
          ) : (
            <GridItem colSpan={{ base: 3, md: 2 }}>
              {pengetahuanItem?.category === "pdf" && (
                <Box h={{ base: "300px", md: "550px" }} w="full">
                  <iframe
                    title="pdf preview"
                    src={pengetahuanItem?.path_file ?? ""}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                  />
                </Box>
              )}

              {pengetahuanItem?.category === "audio" && (
                <>
                  <Image
                    src={pengetahuanItem?.thumbnail}
                    w="full"
                    maxH={{ base: "auto", md: "550px" }}
                    objectFit="cover"
                    mb={4}
                    rounded="lg"
                  />
                  <audio
                    controls
                    src={pengetahuanItem?.path_file}
                    style={{ width: "100%" }}
                  ></audio>
                </>
              )}

              {pengetahuanItem?.category === "video" && (
                <Box w="full" h={{ base: "200px", md: "550px" }}>
                  <iframe
                    title="youtube video"
                    src={`https://www.youtube.com/embed/${pengetahuanItem.path_file}`}
                    allowFullScreen
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              )}

              {pengetahuanItem?.category === "ppt" && (
                <Box h={{ base: "300px", md: "550px" }} w="full">
                  <iframe
                    title="ppt preview"
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${pengetahuanItem.path_file}`}
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "6px" }}
                  />
                </Box>
              )}
              <Box rounded="lg" w="full" p={4} bg="white" mt={4}>
                <Flex alignItems="center">
                  <Text fontSize="lg" fontWeight="semibold" mr={2}>
                    {pengetahuanItem?.title}
                  </Text>
                  <CategoryBadge category={pengetahuanItem?.category || "-"} />
                </Flex>
                <Flex alignItems="center" mt={2}>
                  <Icon as={AiOutlineClockCircle} boxSize={4} mr={2} />
                  <Text fontSize="sm">
                    {moment(
                      pengetahuanItem?.created_at,
                      "YYYY-MM-DD HH:mm:ss",
                    ).fromNow()}
                  </Text>
                </Flex>
                <Box
                  mt={4}
                  as="div"
                  dangerouslySetInnerHTML={{
                    __html: pengetahuanItem?.description || "",
                  }}
                ></Box>
              </Box>
            </GridItem>
          )}
          {loadingDataTerkait ? (
            <GridItem colSpan={{ base: 3, md: 1 }}>
              <Skeleton h="300px" w="full" mb={4} />
              <Skeleton h="300px" w="full" mb={4} />
              <Skeleton h="300px" w="full" mb={4} />
            </GridItem>
          ) : (
            <GridItem colSpan={{ base: 3, md: 1 }}>
              <Box rounded="lg" p={4} bg="white">
                <Text fontSize="lg" fontWeight="semibold" mb={4}>
                  Pengetahuan Terkait
                </Text>
                {filterListData.length &&
                  filterListData.map((item, i) => (
                    <Box
                      mb={filterListData.length === i + 1 ? 0 : 4}
                      key={item.id}
                    >
                      <CardItem
                        item={item}
                        spacing={false}
                        accessFor="pengetahuan"
                      />
                    </Box>
                  ))}
              </Box>
            </GridItem>
          )}
        </Grid>
      </Box>
    </>
  );
};

const WrapperMain = () => {
  return (
    <Wrapper>
      <DetailPengetahuan />
    </Wrapper>
  );
};

const DetailPengetahuanMain = () => {
  return (
    <PengetahuanDetailContext.Provider>
      <PengetahuanController.Provider>
        <WrapperMain />
      </PengetahuanController.Provider>
    </PengetahuanDetailContext.Provider>
  );
};

export default DetailPengetahuanMain;
