import { Youtube } from "../../entities/Youtube";

export class YoutubeDataMapper {
  public convertYoutubeDataFromApi(result: any): Youtube[] {
    const youtube: Youtube[] = [];

    result.map((s: any) => {
      return youtube.push(
        new Youtube(s.snippet.title, s.snippet.resourceId.videoId),
      );
    });

    return youtube;
  }
}
