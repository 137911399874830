/**
 * This component is used to display a count card on a particular page.
 */

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import CountUp from "react-countup";
import ArtCard from "../../assets/svg/art-1.svg";
import { useLandingDataContext } from "../../controller/public/landing_data";

/**
 * CountingSection component.
 *
 * @returns {React.FC} The CountingSection component.
 * @author Muhammad Farras Jibran
 */

/**
 * CountingCard component.
 *
 * @typedef {Object} CountingCardProps
 * @property {string} title - The title of the counting card.
 * @property {number} data - The data value of the counting card.
 * @property {React.ReactElement} icon - The icon element of the counting card.
 */

interface CountingCardProps {
  title: string;
  data: number;
  icon: ReactElement;
}

const CountingCard = ({ title, data, icon }: CountingCardProps) => {
  return (
    <Box
      bg="white"
      rounded="lg"
      overflow="hidden"
      p={6}
      boxShadow="0px 0px 8px 3px #0F172A14"
      w="full"
      h="full"
      position="relative"
    >
      <Flex justifyContent="space-between" mb="16">
        <Box>
          <Text fontSize="md" color="GrayPrimary.400">
            {title}
          </Text>
          <Text fontSize="5xl" fontWeight="bold" color="Sky.600">
            <CountUp end={data ?? 0} duration={3} />
          </Text>
        </Box>
        <Box display="flex" justifyContent="end">
          <Box
            display="flex"
            w={54}
            h={54}
            bg="Sky.100"
            p={2}
            justifyContent="center"
            borderRadius="md"
            alignItems="center"
          >
            {icon}
          </Box>
        </Box>
      </Flex>
      <Image src={ArtCard} position="absolute" right="0" bottom="0" />
    </Box>
  );
};

const CountingSection: React.FC = () => {
  const { landingData } = useLandingDataContext().state;

  return (
    <Box bgGradient="linear(white 0%,  blue.50 90%)">
      <Container maxW="8xl" py={{ base: 8, md: 20 }} w="full">
        <Grid
          gridTemplateColumns={{
            lg: "repeat(2, 1fr)",
            base: "repeat(1, 1fr)",
          }}
          gap={{ base: 4, md: 8 }}
        >
          <GridItem colSpan={{ base: 2, lg: 1 }} mt={{ base: 0, md: 8 }} mb={8}>
            <Box maxWidth={"lg"}>
              <Text
                as="h1"
                fontWeight="bold"
                fontSize={{ base: "2xl", md: "2.5rem" }}
              >
                Jelajahi Kursus Online dengan{" "}
                <span style={{ color: "#1E40AF" }}>I-Course!</span>
              </Text>
              <Text fontSize="md" mt={6}>
                Lorem ipsum dolor sit amet consectetur. Urna etiam velit cum
                faucibus nec quis quis.
              </Text>
              <Box mt={6}>
                <Button
                  as="a"
                  href="/login"
                  mx="auto"
                  py={6}
                  fontSize="md"
                  fontWeight="medium"
                  rounded="full"
                  bg="Amber.400"
                  color="white"
                  _hover={{ bg: "BluePrimary.900" }}
                  _active={{ bg: "BluePrimary.900" }}
                >
                  Yuk Mulai Belajar
                </Button>
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 2, lg: 1 }}>
            <Grid
              gridTemplateColumns={{
                base: "repeat(1, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={6}
            >
              {/* Data Pelatihan */}
              <CountingCard
                title="Pelatihan"
                data={landingData?.count_pelatihan ?? 0}
                icon={
                  <i
                    className="ph ph-graduation-cap"
                    style={{ color: "#0284C7", fontSize: 32 }}
                  ></i>
                }
              />

              {/* Data Peserta */}
              <CountingCard
                title="Peserta"
                data={landingData?.count_peserta ?? 0}
                icon={
                  <i
                    className="ph ph-student"
                    style={{ color: "#0284C7", fontSize: 32 }}
                  ></i>
                }
              />

              {/* Data Modul Belajar */}
              <CountingCard
                title="Modul Belajar"
                data={landingData?.count_materi ?? 0}
                icon={
                  <i
                    className="ph ph-books"
                    style={{ color: "#0284C7", fontSize: 32 }}
                  ></i>
                }
              />

              {/* Data Pengguna Aktif */}
              <CountingCard
                title="Pengguna Aktif"
                data={landingData?.count_active_user ?? 0}
                icon={
                  <i
                    className="ph ph-users-three"
                    style={{ color: "#0284C7", fontSize: 32 }}
                  ></i>
                }
              />
            </Grid>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default CountingSection;
