import { DependencyContainer } from "tsyringe";
import ApiService from "../services/ApiService";
import { Endpoints } from "../misc/Endpoints";
import { AuthDataMapper } from "../../data/mappers/Auth";
import { AuthApiRepository } from "../repository/api/AuthApiRepository";
import { PaketSoalApiRepository } from "../repository/api/PaketSoalApiRepository";
import { PaketSoalDataMapper } from "../../data/mappers/PaketSoal";
import { SoalApiRepository } from "../repository/api/SoalApiRepository";
import { SoalDataMapper } from "../../data/mappers/Soal";
import { KategoriPaketSoalApiRepository } from "../repository/api/KategoriPaketSoalApiRepository";
import { KategoriPaketSoalDataMapper } from "../../data/mappers/KategoriPaketSoal";
import { MateriApiRepository } from "../repository/api/MateriApiRepository";
import { MateriDataMapper } from "../../data/mappers/Materi";
import { UsersApiRepository } from "../repository/api/UsersApiRepository";
import { UsersDataMapper } from "../../data/mappers/Users";
import { PelatihanApiRepository } from "../repository/api/PelatihanApiRepository";
import { PelatihanDataMapper } from "../../data/mappers/Pelatihan";
import { FaqRepository } from "../repository/api/FaqRespository";
import { FaqsDataMapper } from "../../data/mappers/Faq";
import { ProvinsiApiRepository } from "../repository/api/ProvinsiApiRepository";
import { ProvinsiDataMapper } from "../../data/mappers/Provinsi";
import { KabupatenApiRepository } from "../repository/api/KabupatenApiRepository";
import { KabupatenDataMapper } from "../../data/mappers/Kabupaten";
import { ModulPelatihanApiRepository } from "../repository/api/ModulPelatihanApiRepository";
import { ModulPelatihanDataMapper } from "../../data/mappers/ModulPelatihan";
import { KecamatanApiRepository } from "../repository/api/KecamatanApiRepository";
import { KecamatanDataMapper } from "../../data/mappers/Kecamatan";
import { KelurahanApiRepository } from "../repository/api/KelurahanApiRepository";
import { KelurahanDataMapper } from "../../data/mappers/Kelurahan";
import { MenuJenisPelatihanApiRepository } from "../repository/api/MenuJenisPelatihanApiRepository";
import { MenuJenisPelatihanDataMapper } from "../../data/mappers/MenuJenisPelatihan";
import { JenisPelatihanApiRepository } from "../repository/api/JenisPelatihanApiRepository";
import { JenisPelatihanDataMapper } from "../../data/mappers/JenisPelatihan";
import { ReviewPelatihanApiRepository } from "../repository/api/ReviewPelatihanApiRepository";
import { ReviewPelatihanDataMapper } from "../../data/mappers/ReviewPelatihan";
import { SettingPenilaianApiRepository } from "../repository/api/SettingPenilaianApiRepository";
import { SettingPenilaianDataMapper } from "../../data/mappers/SettingPenilaian";
import { PelatihanSayaApiRepository } from "../repository/api/PelatihanSayaApiRepository";
import { PelatihanSayaDataMapper } from "../../data/mappers/PelatihanSaya";
import { QuizApiRepository } from "../repository/api/QuizApiRepository";
import { QuizDataMapper } from "../../data/mappers/Quiz";
import { ReviewMateriApiRepository } from "../repository/api/ReviewMateriApiRepository";
import { ReviewMateriDataMapper } from "../../data/mappers/ReviewMateri";
import { SliderApiRepository } from "../repository/api/SliderApiRepository";
import { SliderDataMapper } from "../../data/mappers/Slider";
import { LandingDataApiRepository } from "../repository/api/LandingDataRepository";
import { ExamApiRepository } from "../repository/api/ExamApiRepository";
import { ExamDataMapper } from "../../data/mappers/Exam";
import { LogLatihanDataApiRepository } from "../repository/api/LogLatihanApiRepository";
import { LogLatihanDataMapper } from "../../data/mappers/LogLatihan";
import { FinalProjectApiRepository } from "../repository/api/FinalProjectApiRepository";
import { SproutVideoApiRepository } from "../repository/api/SproutVideoApiRepository";
import { SproutVideoDataMapper } from "../../data/mappers/SproutVideo";
import { FinalProjectDataMapper } from "../../data/mappers/FinalProject";
import { HelpdeskBantuanApiRepository } from "../repository/api/HelpdeskBantuanApiRepository";
import { HelpdeskBantuanDataMapper } from "../../data/mappers/HelpdeskBantuan";
import { HelpDeskBantuanPublicApiRepository } from "../repository/api/HelpDeskBantuanPublicApiRepository";
import { UjianApiRepository } from "../repository/api/UjianApiRepository";
import { UjianDataMapper } from "../../data/mappers/Ujian";
import { DashboardApiRepository } from "../repository/api/DashboardApiRepository";
import { DashboardDataMapper } from "../../data/mappers/Dashboard";
import { JawabanSoalApiRepository } from "../repository/api/JawabanSoalApiRepository";
import { JawabanSoalDataMapper } from "../../data/mappers/JawabanSoal";
import { PathMateriApiRepository } from "../repository/api/PathMateriApiRepository";
import { PathMateriDataMapper } from "../../data/mappers/PathMateri";
import { DashboardRekapitulasiApiRepository } from "../repository/api/DashboardRekapitulasiApiRepository";
import { DashboardRekapitulasiDataMapper } from "../../data/mappers/DashboardRekapitulasi";
import { SertifikatTTERepository } from "../repository/api/SertifikatTTERepository";
import { LogTTEDataMapper } from "../../data/mappers/LogTTE";
import { YoutubeApiRepository } from "../repository/api/YoutubeApiRepository";
import { YoutubeDataMapper } from "../../data/mappers/Youtube";
import { SimdiklatApiRepository } from "../repository/api/SimdiklatApiRepository";
import { SimdiklatDataMapper } from "../../data/mappers/Simdiklat";

export class RepositoryModule {
  public static init(container: DependencyContainer) {
    /**
     * [register description]
     *
     * @param   {[type]}  AuthApiRepository  [AuthApiRepository description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<AuthApiRepository>(AuthApiRepository, {
      useFactory: (d) => {
        return new AuthApiRepository(
          d.resolve(ApiService),
          d.resolve(AuthDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PaketSoalApiRepository  [PaketSoalApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<PaketSoalApiRepository>(PaketSoalApiRepository, {
      useFactory: (d) => {
        return new PaketSoalApiRepository(
          d.resolve(ApiService),
          d.resolve(PaketSoalDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SoalApiRepository  [SoalApiRepository description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<SoalApiRepository>(SoalApiRepository, {
      useFactory: (d) => {
        return new SoalApiRepository(
          d.resolve(ApiService),
          d.resolve(SoalDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<KategoriPaketSoalApiRepository>(
      KategoriPaketSoalApiRepository,
      {
        useFactory: (d) => {
          return new KategoriPaketSoalApiRepository(
            d.resolve(ApiService),
            d.resolve(KategoriPaketSoalDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  MateriApiRepository  [MateriApiRepository description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<MateriApiRepository>(MateriApiRepository, {
      useFactory: (d) => {
        return new MateriApiRepository(
          d.resolve(ApiService),
          d.resolve(MateriDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  UsersApiRepository  [UsersApiRepository description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<UsersApiRepository>(UsersApiRepository, {
      useFactory: (d) => {
        return new UsersApiRepository(
          d.resolve(ApiService),
          d.resolve(UsersDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<HelpDeskBantuanPublicApiRepository>(
      HelpDeskBantuanPublicApiRepository,
      {
        useFactory: (d) => {
          return new HelpDeskBantuanPublicApiRepository(
            d.resolve(ApiService),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  UjianApiRepository  [UjianApiRepository description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<UjianApiRepository>(UjianApiRepository, {
      useFactory: (d) => {
        return new UjianApiRepository(
          d.resolve(ApiService),
          d.resolve(UjianDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  JawabanSoalApiRepository  [JawabanSoalApiRepository description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<JawabanSoalApiRepository>(JawabanSoalApiRepository, {
      useFactory: (d) => {
        return new JawabanSoalApiRepository(
          d.resolve(ApiService),
          d.resolve(JawabanSoalDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  DashboardApiRepository  [DashboardApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<DashboardApiRepository>(DashboardApiRepository, {
      useFactory: (d) => {
        return new DashboardApiRepository(
          d.resolve(ApiService),
          d.resolve(DashboardDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PelatihanApiRepository  [PelatihanApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<PelatihanApiRepository>(PelatihanApiRepository, {
      useFactory: (d) => {
        return new PelatihanApiRepository(
          d.resolve(ApiService),
          d.resolve(PelatihanDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  FaqRepository  [FaqRepository description]
     *
     * @return  {[type]}                 [return description]
     */
    container.register<FaqRepository>(FaqRepository, {
      useFactory: (d) => {
        return new FaqRepository(
          d.resolve(ApiService),
          d.resolve(FaqsDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SproutVideoApiRepository  [SproutVideoApiRepository description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<SproutVideoApiRepository>(SproutVideoApiRepository, {
      useFactory: (d) => {
        return new SproutVideoApiRepository(
          d.resolve(ApiService),
          d.resolve(SproutVideoDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<ModulPelatihanApiRepository>(
      ModulPelatihanApiRepository,
      {
        useFactory: (d) => {
          return new ModulPelatihanApiRepository(
            d.resolve(ApiService),
            d.resolve(ModulPelatihanDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  ProvinsiApiRepository  [ProvinsiApiRepository description]
     *
     * @return  {[type]}                         [return description]
     */
    container.register<ProvinsiApiRepository>(ProvinsiApiRepository, {
      useFactory: (d) => {
        return new ProvinsiApiRepository(
          d.resolve(ApiService),
          d.resolve(ProvinsiDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<ReviewPelatihanApiRepository>(
      ReviewPelatihanApiRepository,
      {
        useFactory: (d) => {
          return new ReviewPelatihanApiRepository(
            d.resolve(ApiService),
            d.resolve(ReviewPelatihanDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  KabupatenApiRepository  [KabupatenApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<KabupatenApiRepository>(KabupatenApiRepository, {
      useFactory: (d) => {
        return new KabupatenApiRepository(
          d.resolve(ApiService),
          d.resolve(KabupatenDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KecamatanApiRepository  [KecamatanApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<KecamatanApiRepository>(KecamatanApiRepository, {
      useFactory: (d) => {
        return new KecamatanApiRepository(
          d.resolve(ApiService),
          d.resolve(KecamatanDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<MenuJenisPelatihanApiRepository>(
      MenuJenisPelatihanApiRepository,
      {
        useFactory: (d) => {
          return new MenuJenisPelatihanApiRepository(
            d.resolve(ApiService),
            d.resolve(MenuJenisPelatihanDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  KelurahanApiRepository  [KelurahanApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<KelurahanApiRepository>(KelurahanApiRepository, {
      useFactory: (d) => {
        return new KelurahanApiRepository(
          d.resolve(ApiService),
          d.resolve(KelurahanDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<JenisPelatihanApiRepository>(
      JenisPelatihanApiRepository,
      {
        useFactory: (d) => {
          return new JenisPelatihanApiRepository(
            d.resolve(ApiService),
            d.resolve(JenisPelatihanDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  PelatihanSayaApiRepository  [PelatihanSayaApiRepository description]
     *
     * @return  {[type]}                              [return description]
     */
    container.register<PelatihanSayaApiRepository>(PelatihanSayaApiRepository, {
      useFactory: (d) => {
        return new PelatihanSayaApiRepository(
          d.resolve(ApiService),
          d.resolve(PelatihanSayaDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<SettingPenilaianApiRepository>(
      SettingPenilaianApiRepository,
      {
        useFactory: (d) => {
          return new SettingPenilaianApiRepository(
            d.resolve(ApiService),
            d.resolve(SettingPenilaianDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  QuizApiRepository  [QuizApiRepository description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<QuizApiRepository>(QuizApiRepository, {
      useFactory: (d) => {
        return new QuizApiRepository(
          d.resolve(ApiService),
          d.resolve(QuizDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ExamApiRepository  [ExamApiRepository description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<ExamApiRepository>(ExamApiRepository, {
      useFactory: (d) => {
        return new ExamApiRepository(
          d.resolve(ApiService),
          d.resolve(ExamDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  FinalProjectApiRepository  [FinalProjectApiRepository description]
     *
     * @return  {[type]}                             [return description]
     */
    container.register<FinalProjectApiRepository>(FinalProjectApiRepository, {
      useFactory: (d) => {
        return new FinalProjectApiRepository(
          d.resolve(ApiService),
          d.resolve(Endpoints),
          d.resolve(FinalProjectDataMapper),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<LogLatihanDataApiRepository>(
      LogLatihanDataApiRepository,
      {
        useFactory: (d) => {
          return new LogLatihanDataApiRepository(
            d.resolve(ApiService),
            d.resolve(LogLatihanDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  LandingDataApiRepository  [LandingDataApiRepository description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<LandingDataApiRepository>(LandingDataApiRepository, {
      useFactory: (d) => {
        return new LandingDataApiRepository(
          d.resolve(ApiService),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ReviewMateriApiRepository  [ReviewMateriApiRepository description]
     *
     * @return  {[type]}                             [return description]
     */
    container.register<ReviewMateriApiRepository>(ReviewMateriApiRepository, {
      useFactory: (d) => {
        return new ReviewMateriApiRepository(
          d.resolve(ApiService),
          d.resolve(ReviewMateriDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SliderApiRepository  [SliderApiRepository description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<SliderApiRepository>(SliderApiRepository, {
      useFactory: (d) => {
        return new SliderApiRepository(
          d.resolve(ApiService),
          d.resolve(SliderDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<HelpdeskBantuanApiRepository>(
      HelpdeskBantuanApiRepository,
      {
        useFactory: (d) => {
          return new HelpdeskBantuanApiRepository(
            d.resolve(ApiService),
            d.resolve(HelpdeskBantuanDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  PathMateriApiRepository  [PathMateriApiRepository description]
     *
     * @return  {[type]}                           [return description]
     */
    container.register<PathMateriApiRepository>(PathMateriApiRepository, {
      useFactory: (d) => {
        return new PathMateriApiRepository(
          d.resolve(ApiService),
          d.resolve(PathMateriDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  DashboardRekapitulasiApiRepository  [DashboardRekapitulasiApiRepository description]
     *
     * @return  {[type]}                                      [return description]
     */
    container.register<DashboardRekapitulasiApiRepository>(
      DashboardRekapitulasiApiRepository,
      {
        useFactory: (d) => {
          return new DashboardRekapitulasiApiRepository(
            d.resolve(ApiService),
            d.resolve(DashboardRekapitulasiDataMapper),
            d.resolve(Endpoints),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  SertifikatTTERepository  [SertifikatTTERepository description]
     *
     * @return  {[type]}                           [return description]
     */
    container.register<SertifikatTTERepository>(SertifikatTTERepository, {
      useFactory: (d) => {
        return new SertifikatTTERepository(
          d.resolve(ApiService),
          d.resolve(LogTTEDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  YoutubeApiRepository  [YoutubeApiRepository description]
     *
     * @return  {[type]}                        [return description]
     */
    container.register<YoutubeApiRepository>(YoutubeApiRepository, {
      useFactory: (d) => {
        return new YoutubeApiRepository(
          d.resolve(ApiService),
          d.resolve(YoutubeDataMapper),
          d.resolve(Endpoints),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SimdiklatApiRepository  [SimdiklatApiRepository description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<SimdiklatApiRepository>(SimdiklatApiRepository, {
      useFactory: (d) => {
        return new SimdiklatApiRepository(
          d.resolve(ApiService),
          d.resolve(SimdiklatDataMapper),
          d.resolve(Endpoints),
        );
      },
    });
  }
}
