import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface PodcastCategoryItem {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface ListDataOption {
  label: string;
  value: string;
}

interface IState {
  state: {
    mode: string;
    name: string;
    url: string;
    urlPublic: string;
    openModal: boolean;
    listData: PodcastCategoryItem[];
    dataUpdate: PodcastCategoryItem | null;
    loadingAction: boolean;
    loadingData: boolean;
    listDataOption: ListDataOption[];
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
  };
  setPodcastCategoryState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
}

const initialState: IState = {
  state: {
    mode: "",
    name: "",
    url: "management_content/podcast/category",
    urlPublic: "public/podcast/category",
    openModal: false,
    listData: [],
    dataUpdate: null,
    loadingAction: false,
    loadingData: false,
    listDataOption: [],
    pagination: null,
    page: 1,
    perPage: 10,
  },
  setPodcastCategoryState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleDelete: () => {},
};

/**
 * The PodcastCategoryContext provides a context for managing the state related to podcast categories.
 * It includes state properties and functions to update the state and perform actions related to podcast categories.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: PodcastCategoryProvider } = Context;

/**
 * The provider component that wraps its children with the PodcastCategoryContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const { name, url, mode, dataUpdate, page, perPage, urlPublic } = state.state;

  const setPodcastCategoryState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async () => {
    setPodcastCategoryState("loadingData", true);

    const isAuthenticated = !!localStorage.getItem("tokenAuth");

    const apiUrl = isAuthenticated ? url : urlPublic;

    try {
      const response = await axiosInstance.get(apiUrl, {
        params: {
          rows: perPage,
          page: page,
          keyword: "",
        },
      });
      const data = response.data?.data;
      setPodcastCategoryState("listData", data);
      setPodcastCategoryState("loadingData", false);

      const transformedData = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setPodcastCategoryState("listDataOption", transformedData);
      setPodcastCategoryState("pagination", response.data?.pagination);
    } catch (error) {
      setPodcastCategoryState("loadingData", false);
      throw error;
    }
  };

  const postData = async () => {
    setPodcastCategoryState("loadingAction", true);
    const newUrl = mode === "create" ? url : `${url}/${dataUpdate?.id}`;
    try {
      const response = await axiosInstance.post(newUrl, { name });
      setPodcastCategoryState("loadingAction", false);
      setPodcastCategoryState("openModal", false);

      fetchData();
      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      setPodcastCategoryState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      Object.keys(responseData.errors).forEach((key) => {
        responseData.errors[key].forEach((errorMessage) => {
          toast({
            title: titleMessage,
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        });
      });
      throw error;
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  return (
    <PodcastCategoryProvider
      value={{
        state: state.state,
        setPodcastCategoryState,
        fetchData,
        postData,
        handleDelete,
      }}
    >
      {children}
    </PodcastCategoryProvider>
  );
};

/**
 * A custom hook function to access the PodcastCategoryContext.
 * @returns {IState} The state and functions provided by the context.
 */

export const usePodcastCategoryContext = () => useContext(Context);

const PodcastCategoryContext = {
  usePodcastCategoryContext,
  Provider,
};

export default PodcastCategoryContext;
