import { AuthRepositoryInterface } from "../../../data/contracts/Auth";
import { AuthDataMapper } from "../../../data/mappers/Auth";
import { User } from "../../../entities/User";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class AuthApiRepository implements AuthRepositoryInterface {
  private apiService: ApiService;
  private mapper: AuthDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: AuthDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   * [async description]
   *
   * @param   {string}         email   [email description]
   * @param   {string<User>}   password  [password description]
   *
   * @return  {Promise<User>}            [return description]
   */
  public async login(email: string, password: string): Promise<User> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.login(),
      {},
      formData,
      headers,
    );
    return this.mapper.convertUserDataFromApi(dataResponse);
  }

  /**
   * [async description]
   *
   * @return  {[type]}  [return description]
   */
  public async register(
    nip: string,
    nik: string,
    name: string,
    email: string,
    jenis_user: number,
    password: string,
    password_confirmation: string,
    path_foto: File,
    g_recaptcha_response: string,
  ): Promise<any> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("nip", nip);
    formData.append("nik", nik);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("jenis_user", jenis_user.toString());
    formData.append("password", password);
    formData.append("password_confirmation", password_confirmation);
    formData.append("path_foto", path_foto);
    formData.append("g-recaptcha-response", g_recaptcha_response);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.register(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  /**
   * [async description]
   *
   * @param   {string<any>}   email  [email description]
   *
   * @return  {Promise<any>}         [return description]
   */
  public async requestResetPassword(email: string): Promise<any> {
    const headers = new Map<string, string>();
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.password() + "/send_reset_link",
      {
        email: email,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  /**
   * [async description]
   *
   * @param   {string}        token                  [token description]
   * @param   {string}        password               [password description]
   * @param   {string<any>}   password_confirmation  [password_confirmation description]
   *
   * @return  {Promise<any>}                         [return description]
   */
  public async resetPassword(
    token: string,
    password: string,
    password_confirmation: string,
  ): Promise<any> {
    const headers = new Map<string, string>();
    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", password);
    formData.append("password_confirmation", password_confirmation);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.password() + "/reset",
      null,
      formData,
      headers,
    );
    return dataResponse;
  }
}
