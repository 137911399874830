import { FinalProjectInterface } from "../../../data/contracts/FinalProject";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { FinalProject } from "../../../entities/FinalProject";
import { FinalProjectDataMapper } from "../../../data/mappers/FinalProject";

export class FinalProjectApiRepository implements FinalProjectInterface {
  private apiService: ApiService;
  private endpoints: Endpoints;
  private mapper: FinalProjectDataMapper;

  constructor(
    apiService: ApiService,
    endpoints: Endpoints,
    mapper: FinalProjectDataMapper,
  ) {
    this.apiService = apiService;
    this.endpoints = endpoints;
    this.mapper = mapper;
  }

  public async storeFinalProject(
    pelatihan_id: number,
    path_final_project: File,
    uraian_penjelasan: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("pelatihan_id", pelatihan_id.toString());
    formData.append("path_file_final_project", path_final_project);
    formData.append("uraian_penjelasan", uraian_penjelasan);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.finalProjectPeserta(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async storeFinalProjectAdmin(
    sub_modul_pelatihan_id: number,
    panduan_pengerjaan: string,
    path_panduan: File,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append(
      "sub_modul_pelatihan_id",
      sub_modul_pelatihan_id.toString(),
    );
    formData.append("panduan_pengerjaan", panduan_pengerjaan);
    formData.append("path_panduan", path_panduan);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.finalProject(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteFinalProjectAdmin(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.finalProject() + "/" + id.toString(),
      null,
      null,
      headers,
    );
    return dataResponse;
  }

  public async updateFinalProjectAdmin(
    id: number,
    panduan_pengerjaan: string,
    path_panduan: File,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("panduan_pengerjaan", panduan_pengerjaan);
    formData.append("path_panduan", path_panduan);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.finalProject() + "/" + id.toString(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async getDetailFinalProjectAdmin(id: number): Promise<FinalProject> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.finalProject() + "/" + id,
      {},
      null,
      headers,
    );
    return this.mapper.convertDetailFinalProject(dataResponse);
  }
}
