import React from "react";
import { Placement } from "react-joyride";
import JoyrideComponent from "../global/Joyride";

/**
 * The PodcastGuide component is used to display interactive guides on the Podcast page.
 * It explains the steps for using the Podcast features in the application.
 * @author Muhammad Farras Jibran
 */
const PodcastGuide = () => {
  /**
   * List of interactive guide steps.
   * Each step includes explanatory content and targeting to specific elements.
   */

  const steps = [
    {
      content: <p>Search: Cari Podcast yang ingin kamu dengar disini</p>,
      placement: "bottom" as Placement,
      target: "#search-podcast",
      disableBeacon: true,
    },
    {
      content: <p>Kategori: Pilih Kategori untuk melihat daftar podcast</p>,
      placement: "bottom" as Placement,
      target: "#category-podcast",
      disableBeacon: true,
    },
    {
      content: <p>Mulai mendengarkan podcast</p>,
      placement: "bottom" as Placement,
      target: "#play-pause-podcast",
      disableBeacon: true,
    },
    {
      content: <p>Bagikan Podcast menarik kepada rekanmu</p>,
      placement: "bottom" as Placement,
      target: "#share-podcast",
      disableBeacon: true,
    },
    {
      content: (
        <p>Simpan podcast untuk mendengarkan dimana saja dan kapan saja</p>
      ),
      placement: "bottom" as Placement,
      target: "#download-podcast",
      disableBeacon: true,
    },
  ];

  return <JoyrideComponent steps={steps} />;
};

export default PodcastGuide;
