import { Editor } from "@tinymce/tinymce-react";
import AWS from "aws-sdk";

interface EditorProps {
  description: string;
  setDescription: Function;
  keyState?: string;
  bgColor?: string;
}

/**
 * Render the Editor component.
 *
 * @param {EditorProps} props - The props for the Editor component.
 * @param {string} props.description - The description value.
 * @param {Function} props.setDescription - The function to set the description value.
 * @return {JSX.Element} The rendered Editor component.
 * @author Muhammad Farras Jibran
 */

const EditorComponent = ({
  description,
  setDescription,
  keyState = "description",
  bgColor = "#F6F5F8",
}: EditorProps) => {
  return (
    <Editor
      value={description}
      init={{
        height: "300px",
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help | image",
        content_style: `body { background-color: ${bgColor} }`,
        images_upload_handler: async function (
          blobInfo: any,
          success: any,
          failure: any,
        ) {
          const S3_BUCKET = "pusdiklat-lms";
          const REGION = "sgp1";
          const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

          const s3Credentials = new AWS.Credentials({
            accessKeyId: String(process.env.REACT_APP_AWS_ACCESS_KEY),
            secretAccessKey: String(process.env.REACT_APP_AWS_SECRET_KEY),
          });

          const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            endpoint: ENDPOINT,
            region: REGION,
            credentials: s3Credentials,
          });

          function makeid(length) {
            let result = "";
            const characters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
              result += characters.charAt(
                Math.floor(Math.random() * charactersLength),
              );
            }
            return result;
          }

          try {
            const nameFile =
              makeid(16) + "." + blobInfo.blob().name.split(".")[1];

            const params = {
              ACL: "public-read",
              Body: blobInfo.blob(),
              Bucket: S3_BUCKET,
              Key: nameFile,
            };

            myBucket
              .putObject(params)
              .on("httpUploadProgress", (evt) => {})
              .send((err, data) => {
                if (err) {
                  failure(err);
                } else {
                  success(process.env.REACT_APP_AWS_URL + nameFile);
                }
              });
          } catch (error: any) {
            failure(error);
            return;
          }
        },
      }}
      onEditorChange={(val) => {
        setDescription(keyState, val);
      }}
    />
  );
};

export default EditorComponent;
