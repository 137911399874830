import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Img,
  Input,
  Select,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { FaSlidersH } from "react-icons/all";
import { Else, If, Then } from "react-if";
import { Placement } from "react-joyride";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";
import LogoPintar from "../../../assets/png/logo_pintar_black.png";
import IconPelatihan from "../../../assets/svg/ic_new_training.svg";
import PelatihanBackground from "../../../assets/webp/bg_pelatihan.webp";
import PelatihanBackgroundMobile from "../../../assets/webp/bg_pelatihan_mobile.webp";
import CardItemPelatihan from "../../../components/global/Card/CardItemPelatihan";
import JoyrideComponent from "../../../components/global/Joyride";
import { Wrapper } from "../../../components/Wrapper";
import PelatihanController, {
  usePelatihanContext,
} from "../../../controller/pelatihan/index";
import PelatihanSayaController, {
  usePelatihanSayaContext,
} from "../../../controller/registered/pelatihan_saya";

const IcPelatihan = () => <Image src={IconPelatihan} />;

const HeroPelatihan: React.FC = () => {
  const { handleSearchPelatihan, searchPelatihan } = usePelatihanContext();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const keyword = localStorage.getItem("keywordPelatihan");

  const onSubmitSearch = (e) => {
    e.preventDefault();
    handleSearchPelatihan();
  };

  return (
    <Box
      backgroundImage={{
        base: `url(${PelatihanBackgroundMobile})`,
        md: `url(${PelatihanBackground})`,
      }}
      backgroundSize="cover"
      h={{ base: "230px", md: "52vh" }}
      roundedBottom={{ base: "none", md: "3rem" }}
      w="auto"
      display="flex"
      justifyContent={{ base: "center", md: "normal" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={{ base: "center", md: "start" }}
        alignItems="start"
        mt={{ base: "-3rem", md: "4rem" }}
        ml={{ base: 0, md: "8rem" }}
      >
        {isMobile ? null : <Img src={LogoPintar} w="150px" alt="Logo Pintar" />}
        <Text
          fontSize={{ base: "2xl", md: "5xl" }}
          color="black"
          fontWeight="bold"
          textAlign={{ base: "center", md: "left" }}
          letterSpacing="tight"
          mt={{ base: 0, md: 8 }}
        >
          Temukan Pelatihan untuk
          <br />
          Tingkatkan Kompetensi Anda
        </Text>
        <Box
          position="relative"
          mt={{ base: 2, md: 4 }}
          w={{ base: "full", md: "600px" }}
        >
          <form
            id="search-pelatihan"
            onSubmit={(e) => onSubmitSearch(e)}
            style={{ display: "flex" }}
          >
            <Input
              type="text"
              placeholder={
                isMobile
                  ? "Cari Jadwal Pelatihan..."
                  : "Cari Jadwal Pelatihan & Kelas Pelatihan..."
              }
              defaultValue={keyword ? keyword : ""}
              onChange={(e) => searchPelatihan(e.target.value)}
              w="full"
              h={{ base: 8, md: 12 }}
              pl={3}
              pr={8}
              mr={4}
              fontSize={{ base: "xs", md: "sm" }}
              borderColor="BluePrimary.900"
              bg="white"
              rounded="xl"
              _focus={{
                borderColor: "BluePrimary.900",
                ring: "1px",
                ringColor: "BluePrimary.900",
              }}
            />
            <Button
              color="white"
              bg="BluePrimary.900"
              type="submit"
              rounded="xl"
              h={{ base: 8, md: 12 }}
              w={{ base: 16, md: 20 }}
              _hover={{ bg: "BluePrimary.900" }}
              _active={{ bg: "BluePrimary.900" }}
            >
              Cari
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

const Pagination = () => {
  const { setPage } = usePelatihanContext();
  const { jumlahPage } = usePelatihanContext().state;

  const endFix =
    jumlahPage > Number(jumlahPage.toFixed())
      ? Number(jumlahPage.toFixed()) + 1
      : Number(jumlahPage.toFixed());

  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  return (
    <If condition={endFix > 1}>
      <Then>
        <div className="flex justify-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Selanjutnya"
            activeClassName="active"
            containerClassName="pagination"
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            pageCount={
              jumlahPage > Number(jumlahPage.toFixed())
                ? Number(jumlahPage.toFixed()) + 1
                : Number(jumlahPage.toFixed())
            }
            previousLabel="Sebelumnya"
          />
        </div>
      </Then>
      <Else>
        <></>
      </Else>
    </If>
  );
};

const GuidePelatihan = () => {
  const steps = [
    {
      content: (
        <p>
          Search Pelatihan: Temukan pelatihan menarik yang bisa kamu ikuti
          disini
        </p>
      ),
      placement: "bottom" as Placement,
      target: "#search-pelatihan",
      disableBeacon: true,
    },
    {
      content: (
        <p>
          Lihat selengkapnya: Lihat dan baca informasi pelatihan, klik disini
        </p>
      ),
      placement: "top" as Placement,
      target: ".group:nth-child(1) .btn-pelatihan div",
      disableBeacon: true,
    },
  ];
  return (
    <Box mt={{ base: 4, md: 0 }}>
      <JoyrideComponent steps={steps} />
    </Box>
  );
};

const ListPelatihan: React.FC = () => {
  const { pelatihan, isLoading, subMenuJenisPelatihan } =
    usePelatihanContext().state;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { filterJenisPelatihan } = usePelatihanContext();
  return (
    <Box mx={8} my={10}>
      <Flex
        alignItems="center"
        ml={{ base: 0, md: 4 }}
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Icon as={IcPelatihan} boxSize={10} />
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            ml={2}
            fontWeight="semibold"
          >
            Pelatihan Terbaru
          </Text>
        </Flex>
        {!isMobile && <GuidePelatihan />}
      </Flex>
      <Flex ml={{ base: 0, md: 4 }} mt={4} alignItems="center">
        <Box position="relative">
          <Icon as={FaSlidersH} position="absolute" mt={3} ml={3} boxSize={4} />
          <Select
            w="full"
            h={10}
            border="1px"
            borderColor="gray.500"
            rounded="md"
            _focus={{
              ring: "1",
              borderColor: "BluePrimary.900",
              ringColor: "BluePrimary.900",
            }}
            _hover={{
              borderColor: "gray.500",
            }}
            fontSize="md"
            _placeholder={{ color: "gray.300" }}
            style={{ paddingLeft: "40px" }}
            defaultValue=""
            onChange={(e) => filterJenisPelatihan(e.target.value)}
          >
            <option value="">Semua Pelatihan</option>
            {subMenuJenisPelatihan.map((s, key) => {
              return (
                <option value={s.id} key={key}>
                  {s.judul_menu}
                </option>
              );
            })}
          </Select>
        </Box>
        <Box>
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="BluePrimary.900"
            ml={5}
          >
            Ditemukan {pelatihan.length > 0 ? pelatihan[0].jumlahData : 0}{" "}
            Pelatihan
          </Text>
        </Box>
      </Flex>
      {isMobile && <GuidePelatihan />}
      <Box mt={8} ml={{ base: 0, md: 4 }}>
        <If condition={isLoading}>
          <Then>
            <Stack>
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
            </Stack>
          </Then>
          <Else>
            <If condition={pelatihan.length > 0}>
              <Then>
                <Grid
                  gap={8}
                  templateColumns={{
                    base: "repeat(1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(4, 1fr)",
                  }}
                >
                  {pelatihan.map((v: Pelatihan) => (
                    <CardItemPelatihan pelatihan={v} key={v.id} />
                  ))}
                </Grid>
              </Then>
              <Else>
                <div className="text-center mt-20">
                  <h1 className="text-xl text-gray-400">Belum Ada Pelatihan</h1>
                </div>
              </Else>
            </If>
          </Else>
        </If>
        <Flex justifyContent="end">
          <Pagination />
        </Flex>
      </Box>
    </Box>
  );
};

const WrapperMain: React.FC = () => {
  const { getPelatihan, getSubMenuJenisPelatihan } = usePelatihanContext();
  const { getCurrentDateServer } = usePelatihanSayaContext();

  const keyword = localStorage.getItem("keywordPelatihan");

  const statusPengisian = GetLocalStorage({ name: "status_pengisian" });

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getCurrentDateServer().then(() => {
      if (keyword) {
        getPelatihan(1, keyword, "", "");
      } else {
        getPelatihan();
      }

      getSubMenuJenisPelatihan();
    });
  }, []);

  if (isAuthenticated() !== false && statusPengisian !== "true") {
    window.location.href = "/profile";
  }

  return (
    <Wrapper>
      <HeroPelatihan />

      {/* List Pelatihan */}
      <ListPelatihan />
    </Wrapper>
  );
};

const PelatihanList: React.FC = () => {
  return (
    <PelatihanController.Provider>
      <PelatihanSayaController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <WrapperMain />
      </PelatihanSayaController.Provider>
    </PelatihanController.Provider>
  );
};

export default PelatihanList;
