import { ProvinsiRepositoryInterface } from "../../../data/contracts/Provinsi";
import { ProvinsiDataMapper } from "../../../data/mappers/Provinsi";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Provinsi } from "../../../entities/Provinsi";

export class ProvinsiApiRepository implements ProvinsiRepositoryInterface {
  private apiService: ApiService;
  private mapper: ProvinsiDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: ProvinsiDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllProvinsi(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<Provinsi[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.provinsi(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers,
    );
    return this.mapper.convertProvinsiDataFromApi(dataResponse);
  }

  public async createProvinsi(nama_provinsi: string): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.wilayahProvinsi(),
      {
        nama_provinsi: nama_provinsi,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async updateProvinsi(id: number, nama_provinsi: string): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.wilayahProvinsi() + "/" + id,
      {
        nama_provinsi: nama_provinsi,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async deleteProvinsi(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.wilayahProvinsi() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
