import axios from "axios";
import moment from "moment";
import React from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { PelatihanSayaPresenter } from "../../../../../data/presenters/PelatihanSayaPresenter";
import { PelatihanSaya } from "../../../../../entities/PelatihanSaya";
import history from "../../../../../history";

interface IState {
  isLoading: boolean;
  pelatihanSaya: PelatihanSaya[];
  pelatihanSayaDone: PelatihanSaya[];
  page: number;
  keyword: string;
  error: boolean;
  msgError: string;
  currentTime: string;
  showModalQrPelatihan: boolean;
  qrCodeLinkPelatihan: string;
  jenisQr: string;
  pelatihanItem: PelatihanSaya | null;
}

interface InitialState {
  state: IState;
  getPelatihanSaya: Function;
  storePelatihanSaya: Function;
  checkSession: Function;
  getCurrentDateServer: Function;
  setShowModalQrPelatihan: Function;
  setQrCodeLinkPelatihan: Function;
  setJenisQr: Function;
  setPelatihanItem: Function;
}

const initialState = {
  state: {
    isLoading: false,
    pelatihanSaya: [],
    pelatihanSayaDone: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
    currentTime: "",
    showModalQrPelatihan: false,
    qrCodeLinkPelatihan: "",
    jenisQr: "",
    pelatihanItem: null,
  },
  getPelatihanSaya: () => {},
  storePelatihanSaya: () => {},
  checkSession: () => {},
  getCurrentDateServer: () => {},
  setShowModalQrPelatihan: () => {},
  setQrCodeLinkPelatihan: () => {},
  setJenisQr: () => {},
  setPelatihanItem: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: PelatihanSayaProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    pelatihanSaya: [],
    pelatihanSayaDone: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
    currentTime: "",
    showModalQrPelatihan: false,
    qrCodeLinkPelatihan: "",
    jenisQr: "",
    pelatihanItem: null,
  });

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  const setPelatihanItem = (value: PelatihanSaya) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihanItem: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setShowModalQrPelatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalQrPelatihan: value,
    }));
  };

  const setQrCodeLinkPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      qrCodeLinkPelatihan: value,
    }));
  };

  const setJenisQr = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jenisQr: value,
    }));
  };

  // Get Current Date Server
  const getCurrentDateServer = () => {
    try {
      // Ganti ke dev apabila ingin mencoba di local
      return axios(
        `${process.env.REACT_APP_BE_PROD_BASE_URL}public/serverDateTime`,
        {
          method: "GET",
        },
      )
        .then((resp) => {
          setState((prevstate) => ({
            ...prevstate,
            currentTime: moment(resp.data.data).format("YYYY-MM-DD HH:mm:ss"),
          }));

          return moment(resp.data.data).format("YYYY-MM-DD HH:mm:ss");
        })
        .catch((error) => console.log("error when fetch server time:", error));
    } catch (error: any) {
      console.log(error);
    }
  };

  // Get Pelatihan Saya
  const getPelatihanSaya = async (
    page: number,
    keyword: string,
    currentTime: string,
  ) => {
    setLoading(true);

    try {
      const pelatihanSayaPresenter = container.resolve(PelatihanSayaPresenter);
      const getPelatihanSaya = await pelatihanSayaPresenter.getAllPelatihanSaya(
        page,
        4,
        keyword,
        "",
      );

      const data = getPelatihanSaya;

      const currentDate = currentTime;

      const pelatihanSelesai: any = data.filter(
        (v: PelatihanSaya) =>
          v.status_pelatihan.toLowerCase() === "selesai dipelajari",
      );

      const dataPelatihanOnProgress: any = data.filter(
        (v: PelatihanSaya) =>
          v.status_pelatihan.toLowerCase() === "sedang mengikuti",
      );

      const dataPelatihanOnProgressFix: any = dataPelatihanOnProgress.filter(
        (v: PelatihanSaya) =>
          moment(currentDate).isAfter(v.waktu_mulai) &&
          moment(currentDate).isBefore(v.waktu_selesai),
      );

      setState((prevstate) => ({
        ...prevstate,
        pelatihanSaya: dataPelatihanOnProgressFix,
        pelatihanSayaDone: pelatihanSelesai,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error detail pelatihan saya:", error);
      setLoading(false);
    }
  };

  // Store Pelatihan Saya
  const storePelatihanSaya = async (
    pelatihan_id: number,
    waktu_buka_pendaftaran: string,
    waktu_tutup_pendaftaran: string,
  ) => {
    setLoading(true);

    const currentDate = state.currentTime;

    if (moment(currentDate).isBefore(waktu_buka_pendaftaran)) {
      toast.error("Mohon Maaf, Pendaftaran Belum Di Buka!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    } else if (moment(currentDate).isAfter(waktu_tutup_pendaftaran)) {
      toast.error("Mohon Maaf, Pendaftaran Sudah Di Tutup!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    } else if (moment(currentDate).isAfter(waktu_buka_pendaftaran)) {
      try {
        const pelatihanSayaPresenter = container.resolve(
          PelatihanSayaPresenter,
        );
        const storePelatihanSaya =
          await pelatihanSayaPresenter.storePelatihanSaya(pelatihan_id);

        const data = storePelatihanSaya;

        setLoading(false);

        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        window.location.reload();
      } catch (error: any) {
        console.log("error store pelatihan saya:", error.response.data.message);
        toast.error(error.response.data.message, {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setLoading(false);
      }
    }
  };

  const checkSession = async () => {
    if (isAuthenticated() !== false) {
      history.replace(`/`);
      window.location.reload();
    } else {
      history.replace("/login");
      window.location.reload();
      toast.error("Session anda telah habis, silahkan login kembali!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  return (
    <PelatihanSayaProvider
      value={{
        state,
        getPelatihanSaya,
        storePelatihanSaya,
        checkSession,
        getCurrentDateServer,
        setShowModalQrPelatihan,
        setQrCodeLinkPelatihan,
        setJenisQr,
        setPelatihanItem,
      }}
    >
      {children}
    </PelatihanSayaProvider>
  );
};

export const usePelatihanSayaContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  usePelatihanSayaContext,
  Provider,
};
