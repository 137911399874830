import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { usePodcastContext } from "../../controller/podcast";

/**
 * ModalShareAudio component for rendering a modal to share a podcast audio link.
 *
 * @component
 * @param {object} props - Component props.
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {Function} props.onClose - Callback function to close the modal.
 * @returns {JSX.Element} ModalShareAudio component JSX element.
 * @author Muhammad Farras Jibran
 */

interface ModalShareAudioProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalShareAudio = ({ isOpen, onClose }: ModalShareAudioProps) => {
  const url = window.location.href;

  const { handleShare, setPodcastState } = usePodcastContext();
  const { currentId, urlToCopy } = usePodcastContext().state;

  useEffect(() => {
    const newUrl = new URL(url);
    const searchParams = new URLSearchParams(newUrl.search);
    const trackValue = searchParams.get("track");

    if (trackValue) {
      setPodcastState("urlToCopy", url);
    } else {
      setPodcastState("urlToCopy", `${url}?track=${currentId}`);
    }
  }, [url, currentId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Di bawah ini tautan untuk mendengarkan podcast anda
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2}>
            Salin tautan dan bagikan kepada mereka yang ingin Anda ajak untuk
            mendengarkan bersama.
          </Text>
          <InputGroup>
            <Input defaultValue={urlToCopy} mb={2} />
            <InputRightElement width="3rem">
              <IconButton
                aria-label="copy"
                h="1.75rem"
                size="sm"
                icon={<AiOutlineCopy />}
                onClick={() => handleShare(urlToCopy)}
              />
            </InputRightElement>
          </InputGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalShareAudio;
