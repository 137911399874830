import If, { Else } from "if-else-react";
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { Wrapper } from "../../../components/Wrapper";
import BantuanController, {
  useBantuanContext,
} from "../../../controller/helpdesk/bantuan";
import PelatihanController, {
  usePelatihanContext,
} from "../../../controller/pelatihan/index";

const FormContactUs: React.FC = () => {
  // call controller
  const {
    setPathPendukung,
    setConfirmationCode,
    setPelatihanDiikuti,
    storeHelpDeskBantuan,
  } = useBantuanContext();

  const { isLoading } = useBantuanContext().state;

  const { pelatihan } = usePelatihanContext().state;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let options;

  if (pelatihan.length > 0) {
    options = pelatihan.map((item) => ({
      value: item.judul_pelatihan,
      label: item.judul_pelatihan,
    }));
  } else {
    options = [];
  }

  const onSubmitData = (data) => {
    storeHelpDeskBantuan(
      data.nama,
      data.email,
      data.subject,
      data.asal_kantor,
      data.nip,
      data.pesan,
    );
  };

  return (
    <div className="text-left mt-4">
      <form onSubmit={handleSubmit(onSubmitData)}>
        {/* Nama */}
        <div className="mb-7">
          <label className="block mb-2 text-base">
            Nama <span className="text-red-400">*</span>
          </label>
          <input
            type="text"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="e.g jhondoe"
            {...register("nama", { required: true })}
          />
          {errors?.nama?.type === "required" && (
            <span className="text-red-400 text-sm">
              *nama tidak boleh kosong
            </span>
          )}
        </div>

        {/* Email */}
        <div className="mb-7">
          <label className="block mb-2 text-base">
            Email <span className="text-red-400">*</span>
          </label>
          <input
            type="text"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="e.g jhondoe@gmail.com"
            {...register("email", { required: true })}
          />
          {errors?.email?.type === "required" && (
            <span className="text-red-400 text-sm">
              *email tidak boleh kosong
            </span>
          )}
        </div>

        {/* Subject */}
        <div className="mb-7">
          <label className="block mb-2 text-base">
            Subject <span className="text-red-400">*</span>
          </label>
          <input
            type="text"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="e.g testing"
            {...register("subject", { required: true })}
          />
          {errors?.subject?.type === "required" && (
            <span className="text-red-400 text-sm">
              *subject tidak boleh kosong
            </span>
          )}
        </div>

        {/* Asal Kantor */}
        <div className="mb-7">
          <label className="block mb-2 text-base">
            Asal Kantor <span className="text-red-400">*</span>
          </label>
          <input
            type="text"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="e.g jakarta"
            {...register("asal_kantor", { required: true })}
          />
          {errors?.asal_kantor?.type === "required" ? (
            <span className="text-red-400 text-sm">
              *asal kantor tidak boleh kosong
            </span>
          ) : (
            <span className="text-sm text-gray-400">
              *diisi dengan kode satker/katwil
            </span>
          )}
        </div>

        {/* NIP */}
        <div className="mb-7">
          <label className="block mb-2 text-base">NIP</label>
          <input
            type="text"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="e.g 32131293912"
            {...register("nip")}
          />
          <span className="text-sm text-gray-400">
            *kosongkan jika anda bukan asn
          </span>
        </div>

        {/* Pelatihan Diikuti */}
        <div className="mb-7">
          <label className="block mb-2 text-base">Pelatihan Diikuti</label>
          <Select
            options={options}
            onChange={(val: any) => setPelatihanDiikuti(val.value)}
            placeholder="Pilih Pelatihan Yang Diikuti"
          />
          <span className="text-sm text-gray-400">
            *kosongkan jika anda tidak mengikuti pelatihan
          </span>
        </div>

        {/* Pesan */}
        <div className="mb-7">
          <label className="block mb-2 text-base">
            Pesan <span className="text-red-400">*</span>
          </label>
          <textarea
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            rows={10}
            {...register("pesan", { required: true })}
          />
          {errors?.pesan?.type === "required" && (
            <span className="text-red-400 text-sm">
              *pesan tidak boleh kosong
            </span>
          )}
        </div>

        {/* Path Pendukung */}
        <div className="mb-7">
          <label className="block mb-2 text-base">File Pendukung</label>
          <input
            type="file"
            className="form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            placeholder="Masukkan File"
            onChange={(e) => setPathPendukung(e.target.files)}
          />
        </div>

        {/* Recaptcha Google */}
        <div className="mb-10">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY_GOOGLE_RECAPTCHA}
            onChange={setConfirmationCode}
          />
          <p className="text-sm text-gray-400 mt-3 w-7/12">
            Untuk membuktikan bahwa yang mengisi formulir ini bukan robot, mohon
            checklist checkbox yang telah di sediakan.
          </p>
        </div>

        {/* Button Send Or Reset */}
        <div className="mb-7 flex flex-row justify-end">
          <button
            type="button"
            className="focus:outline-none text-yellow-400 border border-yellow-400 hover:text-white hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            Reset
          </button>
          <button
            type="submit"
            className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            <If condition={isLoading}>
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
              <Else />
              Kirim
            </If>
          </button>
        </div>
      </form>
    </div>
  );
};

const ContentContactUs: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto mt-5 mb-5 w-7/12 text-center">
      <div className="bg-white px-20 py-10 rounded-md">
        <h1 className="text-xl font-semibold">Hubungi Kami</h1>
        <FormContactUs />
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  // call controller
  const { getPelatihan } = usePelatihanContext();

  useEffect(() => {
    getPelatihan();
  }, []);

  return (
    <Wrapper>
      {/* Content */}
      <ContentContactUs />
    </Wrapper>
  );
};

const ContactUs: React.FC = () => {
  return (
    <BantuanController.Provider>
      <PelatihanController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <WrapperMain />
      </PelatihanController.Provider>
    </BantuanController.Provider>
  );
};

export default ContactUs;
