/* eslint-disable eqeqeq */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  ListItem,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useBreakpointValue,
} from "@chakra-ui/react";
import If, { Else } from "if-else-react";
import React, { useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import { FaPen, FaSync, IoLogOut, MdErrorOutline } from "react-icons/all";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { detailUser } from "../../../../entities/User";
import { GetAuthData } from "../../../misc/GetAuthData";
import { resizeImage } from "../../../misc/ImageHelper";
import { GetLocalStorage } from "../../../misc/UseLocalStorage";
import PasFotoPria from "../../assets/png/past_photo_01.png";
import PasFotoWanita from "../../assets/png/past_photo_02.png";
import { Wrapper } from "../../components/Wrapper";
import UserController, {
  useUserContext,
} from "../../controller/registered/profile";
import SimdiklatController, {
  useSimdiklatContext,
} from "../../controller/simdiklat";
import KabupatenController, {
  useKabupatenContext,
} from "../../controller/wilayah/kabupaten";
import KecamatanController, {
  useKecamatanContext,
} from "../../controller/wilayah/kecamatan";
import KelurahanController, {
  useKelurahanContext,
} from "../../controller/wilayah/kelurahan";
import ProvinsiController, {
  useProvinsiContext,
} from "../../controller/wilayah/provinsi";

const FormProfile: React.FC = () => {
  // Get Data Detail User
  const {
    setName,
    setEmail,
    setAlamat,
    setInstansi,
    setJabatan,
    setJenisKelamin,
    setKabupaten,
    setKecamatan,
    setKelurahan,
    setProvinsi,
    setNik,
    setNoHp,
    setTanggalLahir,
    setTempatLahir,
    setTempatTugas,
    updateProfile,
    setPangkat,
    setAgama,
    setStatusKawin,
    setStatusPegawai,
    setGolongan,
  } = useUserContext();
  const {
    dataUser,
    foto_user,
    isLoading,
    provinsi_id,
    kabupaten_id,
    kecamatan_id,
    kelurahan_id,
    pangkat,
    jabatan,
    agama,
    status_kawin,
    status_pegawai,
    name,
    instansi,
    tempat_tugas,
    tempat_lahir,
    jenis_kelamin,
    nik,
    tanggal_lahir,
    alamat,
    no_hp,
    email,
    errorArray,
    isLoadingUpdateProfile,
    golongan,
  } = useUserContext().state;

  // Get Data Provinsi
  const { provinsi } = useProvinsiContext().state;

  // Get Data Kabupaten
  const { kabupaten } = useKabupatenContext().state;
  const { onChangeProvinsi } = useKabupatenContext();

  // Get Data Kecamatan
  const { kecamatan } = useKecamatanContext().state;
  const { onChangeKabupaten } = useKecamatanContext();

  // Get Data Kelurahan
  const { kelurahan } = useKelurahanContext().state;
  const { onChangeKecamatan } = useKelurahanContext();

  // Get List Master Simdiklat
  const {
    listMasterPangkat,
    listMasterJabatan,
    listMasterAgama,
    listMasterStatusKawin,
    listMasterStatusPegawai,
  } = useSimdiklatContext().state;

  // Push data wilayah to user controller
  const onPickProvinsi = (value: any) => {
    onChangeProvinsi(value);
    setProvinsi(value);
  };

  const onPickKabupaten = (value: any) => {
    onChangeKabupaten(value);
    setKabupaten(value);
  };

  const onPickKecamatan = (value: any) => {
    onChangeKecamatan(value);
    setKecamatan(value);
  };

  const handleUpdateProfile = () => {
    if (foto_user == null) {
      toast("Silahkan upload foto anda terlebih dahulu!");
    } else {
      updateProfile();
    }
  };

  // set options for provinsi
  let optionsProvinsi: any;

  if (provinsi.length > 0) {
    optionsProvinsi = provinsi.map((item) => ({
      value: item.id,
      label: item.nama_provinsi,
    }));
  } else {
    optionsProvinsi = [];
  }

  // set options for kabupaten
  let optionsKabupaten: any;

  if (kabupaten.length > 0) {
    optionsKabupaten = kabupaten.map((item) => ({
      value: item.id,
      label: item.nama_kabupaten,
    }));
  } else {
    optionsKabupaten = [];
  }

  // set options for kecamatan
  let optionsKecamatan: any;

  if (kecamatan.length > 0) {
    optionsKecamatan = kecamatan.map((item) => ({
      value: item.id,
      label: item.nama_kecamatan,
    }));
  } else {
    optionsKecamatan = [];
  }

  // set options for kelurahan
  let optionsKelurahan: any;

  if (kelurahan.length > 0) {
    optionsKelurahan = kelurahan.map((item) => ({
      value: item.id,
      label: item.nama_kelurahan,
    }));
  } else {
    optionsKelurahan = [];
  }

  // set options pangkat master
  let optionsMasterPangkat: any;

  if (listMasterPangkat.length > 0) {
    optionsMasterPangkat = listMasterPangkat.map((item) => ({
      value: item.pangkat,
      label: item.pangkat,
    }));
  } else {
    optionsMasterPangkat = [];
  }

  // set options jabatan master
  let optionsMasterJabatan: any;

  if (listMasterJabatan.length > 0) {
    optionsMasterJabatan = listMasterJabatan.map((item) => ({
      value: item.nama,
      label: item.nama,
    }));
  } else {
    optionsMasterJabatan = [];
  }

  // set options agama master
  let optionsMasterAgama: any;

  if (listMasterAgama.length > 0) {
    optionsMasterAgama = listMasterAgama.map((item) => ({
      value: item.value,
      label: item.value,
    }));
  } else {
    optionsMasterAgama = [];
  }

  // set options status kawin master
  let optionsMasterStatusKawin: any;

  if (listMasterStatusKawin.length > 0) {
    optionsMasterStatusKawin = listMasterStatusKawin.map((item) => ({
      value: item.value,
      label: item.value,
    }));
  } else {
    optionsMasterStatusKawin = [];
  }

  // set options status pegawai
  let optionsMasterStatusPegawai: any;

  if (listMasterStatusPegawai.length > 0) {
    optionsMasterStatusPegawai = listMasterStatusPegawai
      .filter((item) => item.value !== "PNS")
      .map((item) => ({
        value: item.value,
        label: item.value,
      }));
  } else {
    optionsMasterStatusPegawai = [];
  }

  const getErrorMessage = (field) => {
    return errorArray.map((item) => item[field])[0] ?? null;
  };

  const isErrorName = getErrorMessage("name") || name === null;
  const isErrorPangkat = getErrorMessage("pangkat") || pangkat === null;
  const isErrorGolongan = getErrorMessage("gol_ruang") || golongan === null;
  const isErrorJabatan = getErrorMessage("level_jabatan") || jabatan == null;
  const isErrorAgama = getErrorMessage("agama") || agama === null;
  const isErrorStatusKawin =
    getErrorMessage("status_kawin") || status_kawin === null;
  const isErrorStatusPegawai =
    getErrorMessage("status_pegawai") || status_pegawai === null;
  const isErrorInstansi = getErrorMessage("satuan_kerja") || instansi === null;
  const isErrorTempatTugas =
    getErrorMessage("tempat_tugas") || tempat_tugas === null;
  const isErrorNik = getErrorMessage("nik") || nik === "";
  const isErrorJenisKelamin =
    getErrorMessage("jenis_kelamin") || jenis_kelamin === null;
  const isErrorTempatLahir =
    getErrorMessage("tempat_lahir") || tempat_lahir === null;
  const isErrorTanggalLahir =
    getErrorMessage("tanggal_lahir") || tanggal_lahir === null;
  const isErrorProvinsi =
    getErrorMessage("provinsi_id") || provinsi_id === null;
  const isErrorKabupaten =
    getErrorMessage("kabupaten_id") || kabupaten_id === null;
  const isErrorKecamatan =
    getErrorMessage("kecamatan_id") || kecamatan_id === null;
  const isErrorKelurahan =
    getErrorMessage("kelurahan_id") || kelurahan_id === null;
  const isErrorAlamat = getErrorMessage("alamat") || alamat === null;
  const isErrorNoHp = getErrorMessage("no_hp") || no_hp === null;
  const isErrorEmail = getErrorMessage("email") || email === null;

  return (
    <div className="text-center">
      <p className="text-lg font-semibold">Profil Pengguna</p>
      {/* Divider */}
      <div className="border-b-2 border-dashed mt-4"></div>
      {/* /Divider */}

      {isLoading ? (
        <>
          <SkeletonText mt="4" noOfLines={5} spacing="6" skeletonHeight="6" />
          <SkeletonText mt="4" noOfLines={5} spacing="6" skeletonHeight="6" />
        </>
      ) : (
        <>
          <div className="text-left w-9/12 mt-4 mx-auto">
            {/* Nama */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Nama</label>
              <input
                type="text"
                className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
             border-solid
            ${
              isErrorName ? "border-red-500 border-2" : "border-gray-300 border"
            }
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                placeholder="e.g jhondoe"
                // defaultValue={dataUser != null ? dataUser.nama_lengkap : ""}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {isErrorName && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("name")}
                </div>
              )}
            </div>

            {/* NIP */}
            {GetAuthData()?.jenis_user == 1 ? (
              <div className="mb-7">
                <label className="block mb-2 text-base">NIP</label>
                <input
                  type="text"
                  className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-gray-300 bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
                  disabled
                  placeholder="e.g 123871293182"
                  value={dataUser != null ? dataUser.nip : ""}
                />
              </div>
            ) : null}

            {GetAuthData()?.jenis_user == 1 ? (
              <>
                {/* Pangkat & Golongan */}
                <div className="mb-7 flex flex-row">
                  {/* Pangkat */}
                  <div className="w-11/12 mr-3">
                    <label className="block mb-2 text-base">Pangkat</label>
                    <input
                      type="text"
                      className={`form-control block
              w-full
              px-3
              py-1.5
              text-sm
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid 
              ${
                isErrorPangkat
                  ? "border-red-500 border-2"
                  : "border-gray-300 border"
              }
              rounded
              transition
              ease-in-out
              m-0
              active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                      placeholder="e.g pusdiklat"
                      // disabled
                      // defaultValue={dataUser != null ? dataUser.pangkat : ""}
                      value={pangkat}
                      onChange={(e) => setPangkat(e.target.value)}
                    />
                    {isErrorGolongan && (
                      <div className="text-xs text-red-500">
                        {getErrorMessage("pangkat")}
                      </div>
                    )}
                  </div>

                  {/* Golongan */}
                  <div className="w-full">
                    <label className="block mb-2 text-base">Golongan</label>
                    <input
                      type="text"
                      className={`form-control block
              w-full
              px-3
              py-1.5
              text-sm
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid 
              ${
                isErrorGolongan
                  ? "border-red-500 border-2"
                  : "border-gray-300 border"
              }
              rounded
              transition
              ease-in-out
              m-0
              active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                      placeholder="e.g pusdiklat"
                      // disabled
                      // defaultValue={dataUser != null ? dataUser.golongan : ""}
                      value={golongan}
                      onChange={(e) => setGolongan(e.target.value)}
                    />
                  </div>
                  {isErrorGolongan && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("gol_ruang")}
                    </div>
                  )}
                </div>

                <div className="mb-7">
                  <label className="block mb-2 text-base">Status Kawin</label>
                  <input
                    type="text"
                    className={`form-control block
              w-full
              px-3
              py-1.5
              text-sm
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid
              ${
                isErrorStatusKawin
                  ? "border-red-500 border-2"
                  : "border-gray-300 border"
              }
              rounded
              transition
              ease-in-out
              m-0
              active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                    placeholder="e.g pusdiklat"
                    // disabled
                    // defaultValue={dataUser != null ? dataUser.status_kawin : ""}
                    value={status_kawin}
                    onChange={(e) => setStatusKawin(e.target.value)}
                  />
                  {isErrorStatusKawin && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("status_kawin")}
                    </div>
                  )}
                </div>

                {/* Jabatan */}
                <div className="mb-7">
                  <label className="block mb-2 text-base">Jabatan</label>
                  <input
                    type="text"
                    className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-clip-padding
          bg-white
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                    placeholder="e.g pusdiklat"
                    // disabled={GetAuthData()?.jenis_user == 1 ? true : false}
                    // defaultValue={dataUser != null ? dataUser.jabatan : ""}
                    value={jabatan}
                    onChange={(e) => setJabatan(e.target.value)}
                  />
                  {isErrorJabatan && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("jabatan")}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="mb-7">
                  <label className="block mb-2 text-base">Pangkat</label>
                  <Select
                    options={optionsMasterPangkat}
                    value={optionsMasterPangkat.filter(
                      (opt: any) => opt.value === pangkat,
                    )}
                    onChange={(val: any) => {
                      setPangkat(val.value);
                    }}
                    placeholder="Pilih Pangkat"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isErrorPangkat ? "red" : "",
                      }),
                    }}
                  />
                  {isErrorPangkat && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("pangkat")}
                    </div>
                  )}
                </div>

                <div className="mb-7">
                  <label className="block mb-2 text-base">Jabatan</label>
                  <Select
                    options={optionsMasterJabatan}
                    value={optionsMasterJabatan.filter(
                      (opt: any) => opt.value === jabatan,
                    )}
                    onChange={(val: any) => {
                      setJabatan(val.value);
                    }}
                    placeholder="Pilih Jabatan"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isErrorJabatan ? "red" : "",
                      }),
                    }}
                  />
                  {isErrorJabatan && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("level_jabatan")}
                    </div>
                  )}
                </div>

                <div className="mb-7">
                  <label className="block mb-2 text-base">Agama</label>
                  <Select
                    options={optionsMasterAgama}
                    value={optionsMasterAgama.filter(
                      (opt: any) => opt.value === agama,
                    )}
                    onChange={(val: any) => {
                      setAgama(val.value);
                    }}
                    placeholder="Pilih Agama"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isErrorAgama ? "red" : "",
                      }),
                    }}
                  />
                  {isErrorAgama && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("agama")}
                    </div>
                  )}
                </div>

                <div className="mb-7">
                  <label className="block mb-2 text-base">Status Kawin</label>
                  <Select
                    options={optionsMasterStatusKawin}
                    value={optionsMasterStatusKawin.filter(
                      (opt: any) => opt.value === status_kawin,
                    )}
                    onChange={(val: any) => {
                      setStatusKawin(val.value);
                    }}
                    placeholder="Pilih Status Kawin"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isErrorStatusKawin ? "red" : "",
                      }),
                    }}
                  />
                  {isErrorStatusKawin && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("status_kawin")}
                    </div>
                  )}
                </div>

                <div className="mb-7">
                  <label className="block mb-2 text-base">Status Pegawai</label>
                  <Select
                    options={optionsMasterStatusPegawai}
                    value={optionsMasterStatusPegawai.filter(
                      (opt: any) => opt.value === status_pegawai,
                    )}
                    onChange={(val: any) => {
                      setStatusPegawai(val.value);
                    }}
                    placeholder="Pilih Status Pegawai"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isErrorStatusPegawai ? "red" : "",
                      }),
                    }}
                  />
                  {isErrorStatusPegawai && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("status_pegawai")}
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Instansi / Unit Kerja */}
            <div className="mb-7">
              <label className="block mb-2 text-base">
                Instansi / Unit Kerja
              </label>
              <input
                type="text"
                className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-soli
            ${
              isErrorInstansi
                ? "border-red-500 border-2"
                : "border-gray-300 border"
            }
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                placeholder="e.g kemenag"
                // defaultValue={dataUser != null ? dataUser.instansi : ""}
                value={instansi}
                onChange={(e) => setInstansi(e.target.value)}
              />
              {isErrorInstansi && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("satuan_kerja")}
                </div>
              )}
            </div>

            {/* Tempat Tugas */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Tempat Tugas</label>
              <input
                type="text"
                className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid
            ${
              isErrorTempatTugas
                ? "border-red-500 border-2"
                : "border-gray-300 border"
            }
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                placeholder="e.g jakarta"
                // defaultValue={dataUser != null ? dataUser.tempat_tugas : ""}
                value={tempat_tugas}
                onChange={(e) => setTempatTugas(e.target.value)}
              />
              {isErrorTempatTugas && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("tempat_tugas")}
                </div>
              )}
            </div>

            {/* NIK */}
            <div className="mb-7">
              <label className="block mb-2 text-base">NIK</label>
              <input
                type="text"
                className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid 
            ${isErrorNik ? "border-red-500 border-2" : "border-gray-300 border"}
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                placeholder="e.g 31273851293"
                // defaultValue={dataUser != null ? dataUser.nik : ""}
                value={nik}
                onChange={(e) => setNik(e.target.value)}
              />
              {isErrorNik && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("nik")}
                </div>
              )}
            </div>

            {/* Jenis Kelamin */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Jenis Kelamin</label>
              <select
                className={`form-select form-select-xs
                w-full
                text-sm
                font-normal
                text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                border border-solid
                ${
                  isErrorJenisKelamin
                    ? "border-red-500 border-2"
                    : "border-gray-300 border"
                }
                rounded
                transition
                ease-in-out
                active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                onChange={(e) => setJenisKelamin(e.target.value)}
              >
                <option>Pilih Jenis Kelamin...</option>
                <option value="1" selected={jenis_kelamin === "1"}>
                  Laki-Laki
                </option>
                <option value="0" selected={jenis_kelamin === "0"}>
                  Perempuan
                </option>
              </select>
              {isErrorJenisKelamin && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("jenis_kelamin")}
                </div>
              )}
            </div>

            {/* Tempat Lahir & Tanggal Lahir */}
            <div className="mb-7">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-2 text-base">Tempat Lahir</label>
                  <input
                    type="text"
                    className={`form-control block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid 
                ${
                  isErrorTempatLahir
                    ? "border-red-500 border-2"
                    : "border-gray-300 border"
                }
                rounded
                transition
                ease-in-out
                m-0
                active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                    placeholder="e.g jakarta"
                    // defaultValue={dataUser != null ? dataUser.tempat_lahir : ""}
                    value={tempat_lahir}
                    onChange={(e) => setTempatLahir(e.target.value)}
                  />
                  {isErrorTempatLahir && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("tempat_lahir")}
                    </div>
                  )}
                </div>
                <div>
                  <label className="block mb-2 text-base">Tanggal Lahir</label>
                  <input
                    type="date"
                    className={`form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid 
              ${
                isErrorTanggalLahir
                  ? "border-red-500 border-2"
                  : "border-gray-300 border"
              }
              rounded
              transition
              ease-in-out
              m-0
              active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                    placeholder="e.g 31273851293"
                    // defaultValue={dataUser != null ? dataUser.tanggal_lahir : ""}
                    value={tanggal_lahir}
                    onChange={(e) => setTanggalLahir(e.target.value)}
                  />
                  {isErrorTanggalLahir && (
                    <div className="text-xs text-red-500">
                      {getErrorMessage("tanggal_lahir")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Provinsi */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Provinsi</label>
              <Select
                options={optionsProvinsi}
                value={optionsProvinsi.filter(
                  (opt: any) => opt.value === provinsi_id,
                )}
                onChange={(val: any) => {
                  onPickProvinsi(val.value);
                }}
                placeholder="Pilih Provinsi"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: isErrorProvinsi ? "red" : "",
                  }),
                }}
              />
              {isErrorProvinsi && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("provinsi_id")}
                </div>
              )}
            </div>

            {/* Kabupaten */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Kabupaten</label>
              <Select
                options={optionsKabupaten}
                value={optionsKabupaten.filter(
                  (opt: any) => opt.value === kabupaten_id,
                )}
                onChange={(val: any) => {
                  onPickKabupaten(val.value);
                }}
                placeholder="Pilih Kabupaten"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: isErrorKabupaten ? "red" : "",
                  }),
                }}
              />
              {isErrorKabupaten && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("kabupaten_id")}
                </div>
              )}
            </div>

            {/* Kecamatan */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Kecamatan</label>
              <Select
                options={optionsKecamatan}
                value={optionsKecamatan.filter(
                  (opt: any) => opt.value === kecamatan_id,
                )}
                onChange={(val: any) => {
                  onPickKecamatan(val.value);
                }}
                placeholder="Pilih Kecamatan"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: isErrorKecamatan ? "red" : "",
                  }),
                }}
              />
              {isErrorKecamatan && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("kecamatan_id")}
                </div>
              )}
            </div>

            {/* Kelurahan */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Kelurahan</label>
              <Select
                options={optionsKelurahan}
                value={optionsKelurahan.filter(
                  (opt: any) => opt.value === kelurahan_id,
                )}
                onChange={(val: any) => {
                  setKelurahan(val.value);
                }}
                placeholder="Pilih Kelurahan"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: isErrorKelurahan ? "red" : "",
                  }),
                }}
              />
              {isErrorKelurahan && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("kelurahan_id")}
                </div>
              )}
            </div>

            {/* Alamat */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Alamat</label>
              <textarea
                className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-soli
            ${
              isErrorAlamat
                ? "border-red-500 border-2"
                : "border-gray-300 border"
            }
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                placeholder="e.g jln.mana aja"
                // defaultValue={dataUser != null ? dataUser.alamat : ""}
                value={alamat}
                onChange={(e) => setAlamat(e.target.value)}
              />
              {isErrorAlamat && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("alamat")}
                </div>
              )}
            </div>

            {/* No. HP */}
            <div className="mb-7">
              <label className="block mb-2 text-base">No. HP</label>
              <input
                type="text"
                className={`form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid 
            ${
              isErrorNoHp ? "border-red-500 border-2" : "border-gray-300 border"
            }
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                placeholder="e.g 088123712321"
                // defaultValue={dataUser != null ? dataUser.no_hp : ""}
                value={no_hp}
                onChange={(e) => setNoHp(e.target.value)}
              />
              {isErrorNoHp && (
                <div className="text-xs text-red-500">
                  {getErrorMessage("no_hp")}
                </div>
              )}
            </div>

            {/* Email */}
            <div className="mb-7">
              <label className="block mb-2 text-base">Email</label>
              <div>
                <input
                  type="email"
                  className={`form-control block
              w-full
              px-3
              py-1.5
              text-sm
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid 
              ${
                isErrorEmail
                  ? "border-red-500 border-2"
                  : "border-gray-300 border"
              }
              rounded
              transition
              ease-in-out
              m-0
              active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300`}
                  placeholder="e.g johndoe@gmail.com"
                  // defaultValue={dataUser != null ? dataUser.email : ""}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <p className="text-xs italic text-gray-500 mt-1">
                  *pastikan email di atas adalah email aktif dan bisa digunakan
                </p>
                {isErrorEmail && (
                  <div className="text-xs text-red-500">
                    {getErrorMessage("email")}
                  </div>
                )}
              </div>
            </div>
          </div>

          <button
            className="py-2 px-5 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-3 md:text-md md:px-5 mx-auto mb-5"
            onClick={handleUpdateProfile}
          >
            <If condition={isLoadingUpdateProfile}>
              <svg
                role="status"
                className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <Else />
              Simpan Perubahan
            </If>
          </button>
        </>
      )}
    </div>
  );
};

const FormPassword: React.FC = () => {
  const { setOldPassword, setPassword, setConfirmPassword, updatePassword } =
    useUserContext();

  const { isLoading } = useUserContext().state;

  return (
    <div className="text-center">
      <p className="text-lg font-semibold">Ubah Password</p>

      {/* Divider */}
      <div className="border-b-2 border-dashed mt-4"></div>
      {/* /Divider */}

      {/* Form */}
      <div className="text-left w-9/12 mt-4 mx-auto">
        {/* Password Lama */}
        <div className="mb-7 mt-7">
          <label className="block mb-2 text-base">Password Lama</label>
          <input
            type="password"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="Masukkan Password Lama"
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>

        {/* Password Baru */}
        <div className="mb-7 mt-7">
          <label className="block mb-2 text-base">Password Baru</label>
          <input
            type="password"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="Masukkan Password Baru"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {/* Konfirmasi Password */}
        <div className="mb-7 mt-7">
          <label className="block mb-2 text-base">Konfirmasi Password</label>
          <input
            type="password"
            className="form-control block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
            placeholder="Masukkan Lagi Password Baru"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>

      {/* Submit Button */}
      <button
        className="py-2 px-5 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-3 md:text-md md:px-5 mx-auto mb-5"
        onClick={() => updatePassword()}
      >
        <If condition={isLoading}>
          <svg
            role="status"
            className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <Else />
          Simpan Perubahan
        </If>
      </button>
    </div>
  );
};

const ModalUploadImage: React.FC = () => {
  const { showModalUploadFoto, isLoadingPhoto } = useUserContext().state;
  const { setShowModalUploadFoto, updatePhoto } = useUserContext();
  const [fileImage, setFileImage] = React.useState<File | null>(null);
  const [error, setError] = React.useState("");

  const handleChangeImage = async (e) => {
    const imageFile = e.files[0];

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setError("Mohon pilih file yang sesuai dan valid.");
      e.value = null;
      return false;
    }

    setError("");

    const resizedImage = await resizeImage(imageFile);

    setFileImage(resizedImage as File);
  };

  const submitClick = () => {
    updatePhoto(fileImage);
  };

  return (
    <>
      {showModalUploadFoto ? (
        <>
          <dialog
            className="fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto inset-0 h-modal md:h-full justify-center items-center flex bg-gray-800 opacity-50"
            aria-modal="true"
            tabIndex={-1}
            style={{ zIndex: 999999 }}
          ></dialog>

          {/* Modal */}
          <div
            className="flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
            style={{ zIndex: 999999 }}
          >
            <div className="relative w-full h-full max-w-2xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Upload Foto Profile Untuk Sertifikat
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowModalUploadFoto(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-5">
                  {/* Keterangan */}
                  <Flex
                    p={4}
                    mb={4}
                    fontSize="sm"
                    color="#1d4ed8"
                    bg="#dbeafe"
                    borderRadius="lg"
                    flexDirection="column"
                    role="alert"
                  >
                    <Box>
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 inline w-5 h-5 mr-3"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Info</span>
                      <span className="font-medium">
                        Keterangan untuk foto profile
                      </span>
                    </Box>
                    <Box>
                      <ul className="mt-1.5 ml-4 text-blue-700 list-disc list-inside">
                        <li>
                          Pas Photo 4x6 dengan resolusi 300x450px Formal
                          Background Merah
                        </li>
                        <li>
                          Disarankan Menggunakan Jas dan Dasi Hitam (Untuk Pria)
                        </li>
                        <li>
                          Menggunakan hijab Polos Warna Gelap/Hitam (Untuk
                          Perempuan Berhijab)
                        </li>
                        <li>Tipe file : jpg, jpeg, png | Ukuran maks : 2MB</li>
                      </ul>
                    </Box>
                    <Flex
                      justifyContent="space-between"
                      px={{ base: 2, md: 8 }}
                      mt={2}
                      w="full"
                    >
                      <Box>
                        <Text color="#1d4ed8" fontWeight="semibold">
                          Contoh Pria
                        </Text>
                        <Image
                          src={PasFotoPria}
                          w={{ base: "100px", md: "200px" }}
                        />
                      </Box>
                      <Box>
                        <Text color="#1d4ed8" fontWeight="semibold">
                          Contoh Wanita
                        </Text>
                        <Image
                          src={PasFotoWanita}
                          w={{ base: "100px", md: "200px" }}
                        />
                      </Box>
                    </Flex>
                  </Flex>

                  {/* Error */}
                  {error !== "" ? (
                    <div
                      className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                      role="alert"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 inline w-5 h-5 mr-3"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <span className="font-medium">Perhatian!</span> {error}
                      </div>
                    </div>
                  ) : null}

                  {/* File Upload */}
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      htmlFor="file_input"
                    >
                      Upload image
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="file_input_help"
                      id="file_input"
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleChangeImage(e.target)}
                    />
                    <p
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      JPEG, PNG, or JPG (MAX SIZE. 2MB).
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                  <button
                    type="button"
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    onClick={() => submitClick()}
                  >
                    <If condition={isLoadingPhoto}>
                      <svg
                        role="status"
                        className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <Else />
                      Simpan Perubahan
                    </If>
                  </button>
                  <button
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    onClick={() => setShowModalUploadFoto(false)}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const UserProfile = ({ activeProfile, status_pengisian, errorArray }) => {
  return (
    <If condition={activeProfile}>
      <If condition={status_pengisian == false}>
        <Alert status="warning" borderRadius="md" mb={5}>
          <AlertIcon as={MdErrorOutline} boxSize={5} color="yellow.700" />
          <Box ml={3} fontSize="sm" color="yellow.700">
            Anda diharuskan melengkapi profil Anda terlebih dahulu untuk dapat
            mengakses halaman lainnya.
          </Box>
          <CloseButton
            size="sm"
            ml="auto"
            color="yellow.500"
            borderRadius="full"
            _hover={{ bg: "yellow.200", color: "yellow.600" }}
          />
        </Alert>
      </If>

      <FormProfile />
    </If>
  );
};

const SyncSimpegData = ({
  isLoadingUpdateProfileSimpeg,
  updateProfileSimpeg,
  dataUser,
}) => {
  return (
    <>
      {GetAuthData()?.jenis_user == 1 ? (
        <Box mt={{ base: 0, md: 4 }} mb={4}>
          <Button
            colorScheme="blue"
            fontSize={{ base: "sm", md: "md" }}
            w="full"
            mt={4}
            h="50px"
            onClick={() => updateProfileSimpeg()}
            isLoading={isLoadingUpdateProfileSimpeg}
            loadingText="Loading..."
            leftIcon={<FaSync />}
            rounded="xl"
          >
            Sinkronisasi Data Simpeg
          </Button>
          <Text fontSize="sm" mt={2} textAlign="center" color="gray.500">
            <i>
              Last Sync :{" "}
              {dataUser != null && dataUser.last_sync_simpeg != null
                ? new Date(dataUser.last_sync_simpeg).toLocaleString()
                : "-"}
            </i>
          </Text>
        </Box>
      ) : null}
    </>
  );
};

const ProfileSection: React.FC = () => {
  const history = useHistory();
  const signOut = useSignOut();
  const [activeProfile, setActiveProfile] = React.useState(true);
  const [activePassword, setActivePassword] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const activeClass = (e: any) => {
    const classId = e.currentTarget.id;

    switch (classId) {
      case "profil":
        setActivePassword(false);
        setActiveProfile(true);
        break;

      case "password":
        setActivePassword(true);
        setActiveProfile(false);
        break;

      case "progress_saya":
        setActivePassword(false);
        setActiveProfile(false);
        break;

      case "sertifikat":
        setActivePassword(false);
        setActiveProfile(false);
        break;

      default:
        break;
    }
  };

  // Get Data Detail User
  const {
    status_pengisian,
    errorArray,
    foto_user,
    isLoadingUpdateProfileSimpeg,
    dataUser,
    isLoading,
  } = useUserContext().state;
  const { setShowModalUploadFoto, updateProfileSimpeg } = useUserContext();

  const onLogout = () => {
    if (signOut()) {
      localStorage.clear();
      history.replace(`/login`);
    }
  };

  return (
    <div>
      {GetAuthData() && isMobile ? (
        <Box display="flex" px={8} py={6} justifyContent="space-between">
          <Text fontSize="md" fontWeight="semibold">
            Akun
          </Text>
          <Box display="flex" alignItems="center" onClick={() => onLogout()}>
            <Icon as={IoLogOut} color="red.400" mr={1} boxSize={6} />
            <Text fontSize="md" color="red.400" fontWeight="semibold">
              Keluar
            </Text>
          </Box>
        </Box>
      ) : null}

      <Box maxW="80rem" mx="auto" w="full" mt={{ base: 0, md: 8 }}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={4}
          mt={{ base: 0, md: 10 }}
          mb={10}
          display="grid"
        >
          {isLoading && !isMobile ? (
            <Box
              py={4}
              px={8}
              bg="white"
              h="300px"
              borderRadius={{ base: 0, md: "20px" }}
            >
              <SkeletonCircle size="120px" m="auto" mb={4} />
              <Skeleton h="20px" w="150px" m="auto" mb={12} />
              <Skeleton w="80%" h="25px" mb={2} />
              <Skeleton w="full" h="25px" />
            </Box>
          ) : (
            <GridItem colSpan={{ base: 3, md: 1 }}>
              <Box
                bg="white"
                px={8}
                py={4}
                textAlign="center"
                borderRadius={{ base: 0, md: "20px" }}
                mb={4}
              >
                {isLoading && isMobile ? (
                  <Center>
                    <SkeletonCircle size="150px" />
                  </Center>
                ) : (
                  <Box position="relative">
                    {foto_user != null ? (
                      <Image
                        src={foto_user}
                        fallback={<Skeleton w="full" h="full" />}
                        alt={`gambar_profile_${GetAuthData()?.username}`}
                        rounded="full"
                        mx="auto"
                        mt={5}
                        mb={3}
                        w="150px"
                        h="150px"
                      />
                    ) : (
                      <Image
                        src={
                          GetLocalStorage({ name: "path_photo" }) !== "null"
                            ? GetLocalStorage({ name: "path_photo" })
                            : `https://ui-avatars.com/api/?name=${
                                GetAuthData()?.username
                              }&background=007E39&color=fff`
                        }
                        alt={`gambar_profile_${GetAuthData()?.username}`}
                        rounded="full"
                        mx="auto"
                        mt={5}
                        mb={3}
                        w="150px"
                        h="150px"
                      />
                    )}

                    <Button
                      colorScheme="blue"
                      p={4}
                      rounded="full"
                      w="12"
                      h="12"
                      position="absolute"
                      right="100px"
                      bottom="0"
                      onClick={() => setShowModalUploadFoto(true)}
                    >
                      <Icon as={FaPen} color="white" />
                    </Button>
                  </Box>
                )}

                <Text fontSize="lg" fontWeight="semibold">
                  {GetAuthData()?.username}
                </Text>

                {/* Menu */}
                {!isMobile && (
                  <Box
                    textAlign="left"
                    mt={7}
                    fontSize="lg"
                    lineHeight="1.75rem"
                  >
                    <UnorderedList listStyleType="none" ml={0}>
                      <ListItem
                        mb={1}
                        transition="all"
                        transitionDuration="300ms"
                        fontWeight="bold"
                        color={activeProfile ? "blue.600" : ""}
                        _hover={{
                          color: activeProfile ? "BluePrimary.900" : "gray.500",
                        }}
                      >
                        <Box
                          id="profil"
                          fontWeight={activeProfile ? "semibold" : "normal"}
                          _hover={{ cursor: "pointer" }}
                          onClick={(e) => activeClass(e)}
                        >
                          Profil
                        </Box>
                      </ListItem>
                      <ListItem
                        mb={1}
                        transition="all"
                        transitionDuration="300ms"
                        fontWeight="bold"
                        color={activePassword ? "blue.600" : ""}
                        _hover={{
                          color: activePassword
                            ? "BluePrimary.900"
                            : "gray.500",
                        }}
                      >
                        <Box
                          id="password"
                          onClick={(e) => activeClass(e)}
                          fontWeight={activePassword ? "semibold" : "normal"}
                          _hover={{ cursor: "pointer" }}
                        >
                          Password
                        </Box>
                      </ListItem>
                    </UnorderedList>
                  </Box>
                )}
              </Box>

              {/* Button Sync Simpeng */}

              <SyncSimpegData
                isLoadingUpdateProfileSimpeg={isLoadingUpdateProfileSimpeg}
                updateProfileSimpeg={updateProfileSimpeg}
                dataUser={dataUser}
              />

              {/* Tabs */}
              {isMobile ? (
                <Box mt={4}>
                  <Tabs variant="unstyled" isLazy>
                    <TabList>
                      <Tab>Profil</Tab>
                      <Tab>Ubah Password</Tab>
                    </TabList>
                    <TabIndicator
                      mt="-1.5px"
                      height="2px"
                      bg="blue"
                      borderRadius="1px"
                    />
                    <TabPanels>
                      <TabPanel>
                        <UserProfile
                          activeProfile={activeProfile}
                          status_pengisian={status_pengisian}
                          errorArray={errorArray}
                        />
                      </TabPanel>
                      <TabPanel>
                        <FormPassword />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              ) : null}
            </GridItem>
          )}

          {!isMobile ? (
            <GridItem colSpan={2} display="grid">
              <GridItem colSpan={1}>
                <Box bg="white" px={7} py={4} borderRadius="20px">
                  <UserProfile
                    activeProfile={activeProfile}
                    status_pengisian={status_pengisian}
                    errorArray={errorArray}
                  />
                  <If condition={activePassword}>
                    <FormPassword />
                  </If>
                </Box>
              </GridItem>
            </GridItem>
          ) : null}
        </Grid>
      </Box>
    </div>
  );
};

const WrapperProfile: React.FC = () => {
  // Get Detail User
  const { getDetailUser } = useUserContext();

  // Get Provinsi
  const { getAllProvinsi } = useProvinsiContext();

  // Get Kabupaten
  const { getKabupatenById } = useKabupatenContext();

  // Get Kecamatan
  const { getKecamatanById } = useKecamatanContext();

  // Get Kelurahan
  const { getKelurahanById } = useKelurahanContext();

  // Get Master Simdiklat
  const {
    getListMasterPangkat,
    getListMasterJabatan,
    getListMasterAgama,
    getListMasterStatusKawin,
    getListMasterStatusPegawai,
  } = useSimdiklatContext();

  useEffect(() => {
    getDetailUser().then((user: detailUser) => {
      getAllProvinsi();
      getKabupatenById(user?.provinsi_id);
      getKecamatanById(user?.kabupaten_id);
      getKelurahanById(user?.kecamatan_id);
      if (user?.jenis_user === 2) {
        getListMasterJabatan();
        getListMasterPangkat();
        getListMasterAgama();
        getListMasterStatusKawin();
        getListMasterStatusPegawai();
      }
    });
  }, []);

  return (
    <Wrapper>
      <ModalUploadImage />
      {/* Content */}
      <ProfileSection />
    </Wrapper>
  );
};

const Main: React.FC = () => {
  return (
    <UserController.Provider>
      <ProvinsiController.Provider>
        <KabupatenController.Provider>
          <KecamatanController.Provider>
            <KelurahanController.Provider>
              <SimdiklatController.Provider>
                <ToastContainer
                  position="top-center"
                  style={{ width: "60%", marginTop: "5%" }}
                />
                <WrapperProfile />
              </SimdiklatController.Provider>
            </KelurahanController.Provider>
          </KecamatanController.Provider>
        </KabupatenController.Provider>
      </ProvinsiController.Provider>
    </UserController.Provider>
  );
};

export default Main;
