import { Skeleton, Stack } from "@chakra-ui/react";
import parse from "html-react-parser";
import React, { useEffect } from "react";
import { Else, If, Then } from "react-if";
import { dataFaq } from "../../../../entities/Faq";
import BackgroundKuning2 from "../../assets/png/background_kuning_2.png";
import HeroPng from "../../assets/svg/slider_cover.svg";
import { Wrapper } from "../../components/Wrapper";
import FaqPublicController, {
  useFaqContext,
} from "../../controller/public/faq";

const HeroMain: React.FC = () => {
  const { setKeyword, searchFaq } = useFaqContext();

  const onSubmitSearch = (e) => {
    e.preventDefault();
    searchFaq();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${HeroPng})`,
        height: "300px",
        backgroundSize: "cover",
      }}
      className="text-center"
    >
      <p className="text-2xl font-semibold pt-24 text-white">
        Anda Mengalami Kendala Tertentu?
      </p>
      <div className="mt-5 sm:mt-8 w-4/12 mx-auto">
        <div className="relative text-gray-700">
          <form onSubmit={(e) => onSubmitSearch(e)}>
            <input
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border-3 border-yellow-400 rounded-lg focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
              type="text"
              placeholder="Cari Tema Bantuan Disini"
              onChange={(e) => setKeyword(e.target.value)}
            />
            <button
              className="absolute inset-y-0 right-0 flex items-center px-7 text-white bg-yellow-400 rounded-r-lg hover:bg-yellow-500 focus:bg-yellow-500"
              type="submit"
            >
              Cari
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const FaqMenu = ({ faq }: { faq: dataFaq }) => {
  return (
    <details className="question py-4 border-b border-t border-grey-600">
      <summary className="flex items-center">
        <p className="text-lg font-semibold">{faq.pertanyaan}</p>
        <button className="ml-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
        </button>
      </summary>
      <div className="mt-4 leading-normal text-md border-t border-grey-600">
        <p className="mt-4 text-sm">{parse(faq.jawaban)}</p>
      </div>
    </details>
  );
};

const ContentFAQ: React.FC = () => {
  const { faq, isLoading } = useFaqContext().state;

  return (
    <div className="max-w-2xl mx-auto lg:max-w-7xl lg:px-8 px-8 pt-4 pb-28 w-full">
      <div className="mt-12">
        <If condition={isLoading}>
          <Then>
            <Stack>
              <Skeleton style={{ height: "50px", marginBottom: "20px" }} />
              <Skeleton style={{ height: "50px", marginBottom: "20px" }} />
              <Skeleton style={{ height: "50px", marginBottom: "20px" }} />
              <Skeleton style={{ height: "50px", marginBottom: "20px" }} />
              <Skeleton style={{ height: "50px", marginBottom: "20px" }} />
              <Skeleton style={{ height: "50px", marginBottom: "20px" }} />
            </Stack>
          </Then>
          <Else>
            {faq.map((f: dataFaq, key) => {
              return <FaqMenu faq={f} key={key} />;
            })}
          </Else>
        </If>
      </div>
    </div>
  );
};

const Contact: React.FC = () => {
  return (
    <div
      className="bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${BackgroundKuning2})` }}
    >
      <div className="max-w-2xl mx-auto lg:max-w-6xl pt-32 pb-36 w-full text-center">
        <h1 className="text-4xl font-semibold text-white mb-10">
          Belum Menemukan Solusi <br /> Permasalahan Anda?
        </h1>
        <a
          href="/contact_us"
          className="px-7 mx-auto py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:text-md"
        >
          Hubungi Kami
        </a>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  const { getFaqsData } = useFaqContext();

  useEffect(() => {
    getFaqsData(1, "");
  }, []);

  return (
    <Wrapper>
      <HeroMain />

      {/* Content */}
      <ContentFAQ />

      {/* Contact */}
      <Contact />
    </Wrapper>
  );
};

const Faq: React.FC = () => {
  return (
    <FaqPublicController.Provider>
      <WrapperMain />
    </FaqPublicController.Provider>
  );
};

export default Faq;
