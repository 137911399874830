/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { ReviewPelatihanPresenter } from "../../../../data/presenters/ReviewPelatihanPresenter";
import {
  ReviewPelatihan,
  DetailReview,
} from "../../../../entities/ReviewPelatihan";
import { toast } from "react-toastify";

interface IState {
  isLoading: boolean;
  isLoadingReview: boolean;
  isLoadingReviewDetail: boolean;
  reviewPelatihan: ReviewPelatihan[];
  page: number;
  keyword: string;
  error: boolean;
  msgError: string;
  rating: string;
  foto: boolean;
  pelatihan_id: number;
  jumlahPage: number;
  komentar: string;
  path_foto_review_pelatihan: any[];
  ratingInput: number;
  showModal: boolean;
  countReviewFoto: number;
  countReview: number;
  reviewId: number;
  detailReview: DetailReview | null;
  pathFotoReview: string;
  indexSelectedPathFotoReview: number;
  formEvaluasiEvidence: File | null;
}

interface InitialState {
  state: IState;
  setPage: Function;
  setRating: Function;
  setFoto: Function;
  getReviewPelatihan: Function;
  storeReviewPelatihan: Function;
  setKomentar: Function;
  setPathFotoReviewPelatihan: Function;
  setRatingInput: Function;
  setShowModal: Function;
  setReviewId: Function;
  getDetailReviewPelatihan: Function;
  setPathFotoReview: Function;
  setIndexSelectedPathFotoReview: Function;
  clearFormReviewDetail: Function;
  setFormEvaluasiEvidence: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingReview: false,
    isLoadingReviewDetail: false,
    reviewPelatihan: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
    rating: "",
    foto: false,
    pelatihan_id: 0,
    jumlahPage: 0,
    komentar: "",
    path_foto_review_pelatihan: [],
    ratingInput: 0,
    showModal: false,
    countReviewFoto: 0,
    countReview: 0,
    reviewId: 0,
    detailReview: null,
    pathFotoReview: "",
    indexSelectedPathFotoReview: 0,
    formEvaluasiEvidence: null,
  },
  setPage: () => {},
  setRating: () => {},
  setFoto: () => {},
  getReviewPelatihan: () => {},
  storeReviewPelatihan: () => {},
  setKomentar: () => {},
  setPathFotoReviewPelatihan: () => {},
  setRatingInput: () => {},
  setShowModal: () => {},
  setReviewId: () => {},
  getDetailReviewPelatihan: () => {},
  setPathFotoReview: () => {},
  setIndexSelectedPathFotoReview: () => {},
  clearFormReviewDetail: () => {},
  setFormEvaluasiEvidence: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ReviewPelatihanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingReview: true,
    isLoadingReviewDetail: false,
    reviewPelatihan: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
    rating: "",
    foto: false,
    pelatihan_id: 0,
    jumlahPage: 0,
    komentar: "",
    path_foto_review_pelatihan: [],
    ratingInput: 0,
    showModal: false,
    countReviewFoto: 0,
    countReview: 0,
    reviewId: 0,
    detailReview: null,
    pathFotoReview: "",
    indexSelectedPathFotoReview: 0,
    formEvaluasiEvidence: null,
  });

  const setFormEvaluasiEvidence = (value: File) => {
    setState((prevstate) => ({
      ...prevstate,
      formEvaluasiEvidence: value,
    }));
  };
  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingReview = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingReview: value,
    }));
  };

  const setLoadingReviewDetail = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingReviewDetail: value,
    }));
  };

  const setPathFotoReview = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      pathFotoReview: value,
    }));
  };

  const setIndexSelectedPathFotoReview = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexSelectedPathFotoReview: value,
    }));
  };

  const setPage = (value: number) => {
    setLoadingReview(true);
    getReviewPelatihan(
      value + 1,
      state.rating,
      Number(state.pelatihan_id),
      state.foto,
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setRating = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      reviewPelatihan: [],
    }));
    setLoadingReview(true);
    getReviewPelatihan(1, value, Number(state.pelatihan_id), false);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      rating: value,
      foto: false,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const setShowModal = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModal: value,
    }));
  };

  const setReviewId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      reviewId: value,
    }));
  };

  // Get Data Review
  const getReviewPelatihan = async (
    pagee: number,
    rating: string,
    pelatihan_id: number,
    foto: boolean,
  ) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihan_id: pelatihan_id,
    }));

    try {
      const reviewPelatihanPresenter = container.resolve(
        ReviewPelatihanPresenter,
      );
      const getAllReviewPelatihan =
        await reviewPelatihanPresenter.getReviewPelatihan(
          pagee,
          10,
          "",
          pelatihan_id,
          rating,
          foto,
        );

      const getAllReviewPelatihanFoto =
        await reviewPelatihanPresenter.getReviewPelatihan(
          pagee,
          10,
          "",
          pelatihan_id,
          rating,
          true,
        );

      const data = getAllReviewPelatihan;

      const dataFoto = getAllReviewPelatihanFoto;

      if (data.length > 0) {
        setJumlahPage(data[0].jumlahDataReview / 10);
      }

      setState((prevstate) => ({
        ...prevstate,
        reviewPelatihan: data,
        countReview: data.length > 0 ? data[0].jumlahDataReview : 0,
        countReviewFoto: dataFoto.length > 0 ? dataFoto[0].jumlahDataReview : 0,
      }));

      setLoadingReview(false);
    } catch (error: any) {
      console.log("error review pelatihan:", error);
      setLoadingReview(false);
    }
  };

  const setFoto = () => {
    setState((prevstate) => ({
      ...prevstate,
      reviewPelatihan: [],
    }));
    setLoadingReview(true);
    getReviewPelatihan(1, "", Number(state.pelatihan_id), true);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      rating: "",
      foto: true,
    }));
  };

  const setKomentar = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      komentar: value,
    }));
  };

  const setPathFotoReviewPelatihan = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_foto_review_pelatihan: [
        ...prevstate.path_foto_review_pelatihan,
        value[0],
      ],
    }));
  };

  const setRatingInput = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      ratingInput: value,
    }));
  };

  const storeReviewPelatihan = async (pelatihan_id: number) => {
    setLoading(true);

    if (state.ratingInput == 0) {
      toast.error("Anda diharuskan memberikan rating dari 1 - 5!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.formEvaluasiEvidence === null) {
      toast.error("Mohon upload screenshot bukti sudah mengisi form evaluasi", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    try {
      const reviewPelatihanPresenter = container.resolve(
        ReviewPelatihanPresenter,
      );

      const listFotoReviewPelatihan: File[] = [];
      state.path_foto_review_pelatihan.map((w) => {
        return listFotoReviewPelatihan.push(w);
      });

      await reviewPelatihanPresenter.storeReviewPelatihan(
        pelatihan_id,
        state.komentar,
        listFotoReviewPelatihan,
        state.ratingInput,
        state.formEvaluasiEvidence,
      );

      toast.success("Berhasil mengirim review", {
        style: {
          boxShadow: "0px 1px 6px #019166",
        },
      });

      window.location.reload();
    } catch (error: any) {
      console.log(
        "error store review pelatihan :",
        error.response.data.message,
      );
      toast.error(error.response.data.message, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const getDetailReviewPelatihan = async () => {
    setLoadingReviewDetail(true);

    try {
      const reviewPelatihanPresenter = container.resolve(
        ReviewPelatihanPresenter,
      );
      const getDetailReviewPelatihan =
        await reviewPelatihanPresenter.getDetailReviewPelatihan(state.reviewId);

      const data = getDetailReviewPelatihan.data;

      if (data.code === 200) {
        setState((prevstate) => ({
          ...prevstate,
          detailReview: data.data,
          pathFotoReview:
            data.data.path_foto_komentar_pelatihan.length > 0
              ? data.data.path_foto_komentar_pelatihan[
                  state.indexSelectedPathFotoReview
                ].label
              : "",
        }));
        setLoadingReviewDetail(false);
      }
    } catch (error: any) {
      console.log("error get detail review", error);
      setLoadingReviewDetail(false);
    }
  };

  const clearFormReviewDetail = () => {
    setState((prevstate) => ({
      ...prevstate,
      pathFotoReview: "",
      reviewId: 0,
    }));
  };

  return (
    <ReviewPelatihanProvider
      value={{
        state,
        setPage,
        setRating,
        setFoto,
        getReviewPelatihan,
        storeReviewPelatihan,
        setKomentar,
        setPathFotoReviewPelatihan,
        setRatingInput,
        setShowModal,
        setReviewId,
        getDetailReviewPelatihan,
        setPathFotoReview,
        setIndexSelectedPathFotoReview,
        clearFormReviewDetail,
        setFormEvaluasiEvidence,
      }}
    >
      {children}
    </ReviewPelatihanProvider>
  );
};

export const useReviewPelatihanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useReviewPelatihanContext,
  Provider,
};
