import { injectable } from "tsyringe";
import { ReviewPelatihanApiRepository } from "../../app/repository/api/ReviewPelatihanApiRepository";
import { ReviewPelatihan } from "../../entities/ReviewPelatihan";

@injectable()
export class ReviewPelatihanPresenter {
  private repository: ReviewPelatihanApiRepository;
  constructor(repository: ReviewPelatihanApiRepository) {
    this.repository = repository;
  }

  /**
   *
   * @param page
   * @param rows
   * @param keyword
   * @param pelatihan_id
   * @param rating
   * @param foto
   * @returns
   */
  public getReviewPelatihan(
    page: number,
    rows: number,
    keyword: string,
    pelatihan_id: number,
    rating: string,
    foto: boolean,
  ): Promise<ReviewPelatihan[]> {
    return this.repository.getReviewPelatihan(
      page,
      rows,
      keyword,
      pelatihan_id,
      rating,
      foto,
    );
  }

  /**
   *
   * @param pelatihan_id
   * @param komentar
   * @param path_foto_review_pelatihan
   * @param rating
   * @returns
   */
  public storeReviewPelatihan(
    pelatihan_id: number,
    komentar: string,
    path_foto_review_pelatihan: any[],
    rating: number,
    screenshot_form_evaluasi: File,
  ): Promise<any> {
    return this.repository.storeReviewPelatihan(
      pelatihan_id,
      komentar,
      path_foto_review_pelatihan,
      rating,
      screenshot_form_evaluasi,
    );
  }

  /**
   *
   * @param review_id
   * @returns
   */
  public getDetailReviewPelatihan(review_id: number): Promise<any> {
    return this.repository.getDetailReviewPelatihan(review_id);
  }
}
