import React from "react";
import { container } from "tsyringe";
import { KelurahanPresenter } from "../../../../../data/presenters/KelurahanPresenter";
import { Kelurahan } from "../../../../../entities/Kelurahan";

interface IState {
  isLoading: boolean;
  kelurahan: Kelurahan[];
  id: number;
  page: number;
  keyword: string;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  getKelurahanById: Function;
  getAllKelurahan: Function;
  onChangeKecamatan: Function;
}

const initialState = {
  state: {
    isLoading: false,
    kelurahan: [],
    id: 0,
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  },
  getKelurahanById: () => {},
  getAllKelurahan: () => {},
  onChangeKecamatan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: KelurahanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    kelurahan: [],
    id: 0,
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  // Get Data Kelurahan
  const getAllKelurahan = async () => {
    setLoading(true);

    try {
      const kelurahanPresenter = container.resolve(KelurahanPresenter);
      const get = await kelurahanPresenter.getAllKelurahan(
        state.page,
        999,
        state.keyword,
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        kelurahan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data Kelurahan:", error);
      setLoading(false);
    }
  };

  // Get Data Kelurahan By ID
  const getKelurahanById = async (id: number) => {
    setLoading(true);

    try {
      const kelurahanPresenter = container.resolve(KelurahanPresenter);
      const get = await kelurahanPresenter.getKelurahanById(id);

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        kelurahan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data Kelurahan:", error);
      setLoading(false);
    }
  };

  // Handle On Change Kabupaten
  const onChangeKecamatan = (value) => {
    getKelurahanById(value);
  };

  return (
    <KelurahanProvider
      value={{
        state,
        getKelurahanById,
        getAllKelurahan,
        onChangeKecamatan,
      }}
    >
      {children}
    </KelurahanProvider>
  );
};

export const useKelurahanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useKelurahanContext,
  Provider,
};
