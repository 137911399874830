/* eslint-disable eqeqeq */
import If, { Else } from "if-else-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useAuthUser } from "react-auth-kit";
import { useParams } from "react-router-dom";
import ChatController, { useChatContext } from "../../controller/chat";
import DetailPelatihanController, {
  useDetailPelatihanContext,
} from "../../controller/pelatihan/detail";

const haveId = window.location.pathname.split("/", 4)[3];
const hasPelatihan = window.location.pathname.split("/", 3)[2];

const KontenConversation: React.FC = () => {
  // Call Controller
  const { backPage, setContentMessage, sendMessage } = useChatContext();
  const { contentMessage, chat, isLoading } = useChatContext().state;
  const { pelatihan, judulMateri, uraianDeskripsi } =
    useDetailPelatihanContext().state;

  // use auth data
  const auth = useAuthUser();

  // handle message
  const handleSendMessage = (e) => {
    e.preventDefault();
    sendMessage(auth()?.id, false, auth()?.username);
    setContentMessage("");
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current != null) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat, isLoading]);

  return (
    <>
      {/* Header */}
      <div
        className="w-full py-4 px-5 flex flex-row justify-between duration-300 transition-all"
        style={{
          backgroundColor: "#8029D1",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <div className="flex">
          <button
            className="text-lg text-white mr-3"
            onClick={() => backPage()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <img
            className="w-10 h-10 rounded-full"
            src="https://ui-avatars.com/api/?background=random"
            alt="Rounded avatar"
          />
          <p className="text-sm text-white font-semibold ml-3 mt-2">
            Admin Pusdiklat
          </p>
        </div>
      </div>

      {/* Body */}
      <div className="pt-5 mb-5 px-5 overflow-y-auto h-96">
        <If condition={pelatihan != null}>
          <div className="bg-gray-700 px-4 py-4 rounded-lg mb-3">
            <p className="text-md font-semibold text-white">{judulMateri}</p>
            <p className="text-white text-sm">
              {uraianDeskripsi.length > 100
                ? uraianDeskripsi.substring(0, 100) + "..."
                : uraianDeskripsi}
            </p>
          </div>
        </If>

        <If condition={isLoading}>
          <div className="text-center mt-5">
            <svg
              role="status"
              className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
          <Else />
          {chat.map((value, key) =>
            value.data.is_admin == false ? (
              <div className="flex items-end justify-end mb-3" key={key}>
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-purple-500 text-white ">
                      {value.data.content}
                    </span>
                  </div>
                </div>
                <img
                  src={`https://ui-avatars.com/api/?name=${value.data.nama_user}&background=007E39&color=fff`}
                  alt="My profile"
                  className="w-6 h-6 rounded-full order-2"
                />
              </div>
            ) : (
              <div className="flex items-end mb-3" key={key}>
                <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                  <div>
                    <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-100 text-gray-600">
                      {value.data.content}
                    </span>
                  </div>
                </div>
                <img
                  src={`https://ui-avatars.com/api/?name=${value.data.nama_user}&background=007E39&color=fff`}
                  alt="My profile"
                  className="w-6 h-6 rounded-full order-1"
                />
              </div>
            ),
          )}
        </If>
        <div ref={messagesEndRef} />
      </div>

      {/* Footer */}
      <div className="absolute bottom-0 w-full">
        <form onSubmit={(e) => handleSendMessage(e)}>
          <div className="flex items-center py-2 px-3 border-t">
            <input
              id="chat"
              className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border-none"
              placeholder="Pesan anda..."
              value={contentMessage}
              onChange={(e) => setContentMessage(e.target.value)}
            />
            <button
              type="submit"
              className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100"
            >
              <svg
                className="w-6 h-6 rotate-90"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const KontenMain: React.FC = () => {
  // Call Controller
  const { startKonsultasi, toHistoryPage } = useChatContext();
  const { isLoadingJoinKonsultasi } = useChatContext().state;

  // use auth data
  const auth = useAuthUser();

  const handleStartKonsultasi = (e) => {
    e.preventDefault();
    startKonsultasi(0, auth()?.id, false, "", auth()?.username);
  };

  return (
    <>
      {/* Header */}
      <div
        className="w-full py-10 px-7"
        style={{
          backgroundColor: "#8029D1",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <h1 className="text-white text-1xl font-semibold">
          Hi {auth()?.username} !
        </h1>
      </div>

      {/* Mulai Percakapan */}
      <div className="card-chat-group">
        <p className="text-sm font-medium">Mulai Percakapan</p>
        <div className="mt-5 flex flex-row">
          <div className="flex -space-x-4">
            <img
              className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
              src="https://ui-avatars.com/api/?background=random"
            />
            <img
              className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
              src="https://ui-avatars.com/api/?background=random"
            />
            <img
              className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
              src="https://ui-avatars.com/api/?background=random"
            />
            <a
              className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800"
              href="#"
            >
              +99
            </a>
          </div>
          <p className="w-7/12 ml-3 text-gray-500">
            Waktu balasan kami biasanya beberapa menit
          </p>
        </div>
        <button
          className="mx-auto py-3 block w-full border border-transparent text-md font-medium rounded-md text-white hover:bg-purple-800 bg-purple-700 md:text-md transition-all duration-500 text-center mt-5"
          id="mulai-konsultasi"
          onClick={(e) => handleStartKonsultasi(e)}
        >
          <If condition={isLoadingJoinKonsultasi}>
            <svg
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Loading...
            <Else />
            Mulai Konsultasi
          </If>
        </button>
      </div>

      {/* Riwayat Percakapan */}
      <div className="card-chat-group" style={{ marginTop: "20px" }}>
        <p className="text-sm font-medium">Riwayat Percakapan</p>
        <div className="mt-5 flex flex-row">
          <img
            className="w-10 h-10 rounded-full"
            src="https://ui-avatars.com/api/?background=random"
            alt="Rounded avatar"
          />
          <p className="ml-3 text-gray-500">
            You : mohon informasi panduan... <br /> elsa. 9m ago
          </p>
        </div>
        <button
          className="mx-auto py-3 block w-full border border-transparent text-md font-medium rounded-md text-white hover:bg-purple-800 bg-purple-700 md:text-md transition-all duration-500 text-center mt-5"
          onClick={() => toHistoryPage(auth()?.id)}
        >
          Lihat Histori
        </button>
      </div>
    </>
  );
};

const KontenHistory: React.FC = () => {
  const { backPage, setSelectedMessage, toConversationPage } = useChatContext();
  const { isLoadingFetchLogChat, logChat } = useChatContext().state;

  const handleSelectMessage = (val) => {
    setSelectedMessage(val);
    toConversationPage();
  };

  // use auth data
  const auth = useAuthUser();

  return (
    <div>
      {/* Header */}
      <div
        className="w-full py-10 px-7"
        style={{
          backgroundColor: "#8029D1",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <div className="flex items-center">
          <button
            className="text-lg text-white mr-5"
            onClick={() => backPage()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <h1 className="text-white text-1xl font-semibold">
            Hi {auth()?.username} !
          </h1>
        </div>
      </div>

      {/* Body */}
      <div className="p-4">
        <If condition={isLoadingFetchLogChat}>
          <div className="text-center mt-5">
            <svg
              role="status"
              className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
          <Else />
          {logChat.length > 0 ? (
            logChat.map((value, idx) => {
              const timestampDate = new Date(
                value.data.timestamp.seconds * 1000,
              );
              const date = moment(timestampDate).format("DD MMMM YYYY");
              const startFrom = moment(timestampDate).fromNow();

              return (
                <button
                  className="w-full"
                  onClick={() => handleSelectMessage(idx)}
                >
                  <div className="flex flex-row border-b-2 mb-4 pb-4 justify-between items-center">
                    <div className="flex flex-row">
                      <img
                        src={`https://ui-avatars.com/api/?name=${value.data.nama_user}&background=007E39&color=fff`}
                        alt="My profile"
                        className="w-10 h-10 rounded-full mr-3"
                      />
                      <div className="flex flex-col text-left">
                        <p
                          className="font-semibold"
                          style={{ color: "#8029D1" }}
                        >
                          {value.data.nama_user}
                        </p>
                        <p className="text-xs text-gray-400">
                          {date} . {startFrom}
                        </p>
                      </div>
                    </div>
                    <i
                      className="fas fa-angle-right text-2xl"
                      style={{ color: "#8029D1" }}
                    ></i>
                  </div>
                </button>
              );
            })
          ) : (
            <div className="text-sm text-center text-gray-400">
              Belum ada history
            </div>
          )}
        </If>
      </div>
    </div>
  );
};

const WrapperChat = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  // Call Controller
  const { getDetailChat } = useChatContext();
  const { mainPage, conversationPage, idChatCurrent, historyPage, showChat } =
    useChatContext().state;
  const { getDetailPelatihan } = useDetailPelatihanContext();

  useEffect(() => {
    if (idChatCurrent) {
      getDetailChat();
    }
  }, [idChatCurrent]);

  useEffect(() => {
    if (hasPelatihan == "pelatihan" && haveId && showChat == true) {
      getDetailPelatihan(id);
    }
  }, []);

  return (
    <>
      <If condition={conversationPage}>
        <KontenConversation />
      </If>
      <If condition={mainPage}>
        <KontenMain />
      </If>
      <If condition={historyPage}>
        <KontenHistory />
      </If>
    </>
  );
};

export const ChatDiv = () => {
  return (
    <>
      <ChatController.Provider>
        <DetailPelatihanController.Provider>
          <WrapperChat />
        </DetailPelatihanController.Provider>
      </ChatController.Provider>
    </>
  );
};
