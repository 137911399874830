import { injectable } from "tsyringe";
import { QuizApiRepository } from "../../app/repository/api/QuizApiRepository";
import { Quiz } from "../../entities/Quiz";

@injectable()
export class QuizPresenter {
  private repository: QuizApiRepository;
  constructor(repository: QuizApiRepository) {
    this.repository = repository;
  }

  public getAllQuiz(sub_modul_id: number): Promise<Quiz[]> {
    return this.repository.getAllQuiz(sub_modul_id);
  }

  public getDetailQuiz(id: number): Promise<Quiz> {
    return this.repository.getDetailQuiz(id);
  }

  public storeQuiz(
    sub_modul_pelatihan_id: number,
    materi_id: number,
    waktu_ke: string,
    pertanyaan: string,
    pilihan_a: string,
    jawaban_benar_a: boolean,
    pilihan_b: string,
    jawaban_benar_b: boolean,
    pilihan_c: string,
    jawaban_benar_c: boolean,
    pilihan_d: string,
    jawaban_benar_d: boolean,
    pilihan_e: string,
    jawaban_benar_e: boolean,
  ): Promise<any> {
    return this.repository.storeQuiz(
      sub_modul_pelatihan_id,
      materi_id,
      waktu_ke,
      pertanyaan,
      pilihan_a,
      jawaban_benar_a,
      pilihan_b,
      jawaban_benar_b,
      pilihan_c,
      jawaban_benar_c,
      pilihan_d,
      jawaban_benar_d,
      pilihan_e,
      jawaban_benar_e,
    );
  }

  public updateQuiz(
    quizId: number,
    waktu_ke: string,
    pertanyaan: string,
    pilihan_a: string,
    jawaban_benar_a: boolean,
    jawaban_quiz_id_a: number,
    pilihan_b: string,
    jawaban_benar_b: boolean,
    jawaban_quiz_id_b: number,
    pilihan_c: string,
    jawaban_benar_c: boolean,
    jawaban_quiz_id_c: number,
    pilihan_d: string,
    jawaban_benar_d: boolean,
    jawaban_quiz_id_d: number,
    pilihan_e: string,
    jawaban_benar_e: boolean,
    jawaban_quiz_id_e: number,
  ): Promise<any> {
    return this.repository.updateQuiz(
      quizId,
      waktu_ke,
      pertanyaan,
      pilihan_a,
      jawaban_benar_a,
      jawaban_quiz_id_a,
      pilihan_b,
      jawaban_benar_b,
      jawaban_quiz_id_b,
      pilihan_c,
      jawaban_benar_c,
      jawaban_quiz_id_c,
      pilihan_d,
      jawaban_benar_d,
      jawaban_quiz_id_d,
      pilihan_e,
      jawaban_benar_e,
      jawaban_quiz_id_e,
    );
  }

  public deleteQuiz(id: number): Promise<any> {
    return this.repository.deleteQuiz(id);
  }
}
