export class Exam {
  judul_sub_modul: string;
  durasi_latihan: number;
  passing_grade: string;
  sisa_waktu: number;
  nilai: string;
  status: string;
  m_soal: MSoal[];
  sequence: string;
  total_sequence: string;

  constructor(
    judul_sub_modul: string,
    durasi_latihan: number,
    passing_grade: string,
    sisa_waktu: number,
    nilai: string,
    status: string,
    m_soal: MSoal[],
    sequence: string,
    total_sequence: string,
  ) {
    this.judul_sub_modul = judul_sub_modul;
    this.durasi_latihan = durasi_latihan;
    this.sisa_waktu = sisa_waktu;
    this.passing_grade = passing_grade;
    this.nilai = nilai;
    this.status = status;
    this.m_soal = m_soal;
    this.sequence = sequence;
    this.total_sequence = total_sequence;
  }
}

export class MPaketSoal {
  id: number;
  tahun: string;
  kategori_paket_soal_id: number;
  jenis_soal: string;
  judul_soal: string;
  petunjuk_soal: string;
  created_at: string;
  updated_at: string;
  m_soal: MSoal[];

  constructor(
    id: number,
    tahun: string,
    kategori_paket_soal_id: number,
    jenis_soal: string,
    judul_soal: string,
    petunjuk_soal: string,
    created_at: string,
    updated_at: string,
    m_soal: MSoal[],
  ) {
    this.id = id;
    this.tahun = tahun;
    this.kategori_paket_soal_id = kategori_paket_soal_id;
    this.jenis_soal = jenis_soal;
    this.judul_soal = judul_soal;
    this.petunjuk_soal = petunjuk_soal;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.m_soal = m_soal;
  }
}

export class MSoal {
  id: number;
  paket_soal_id: number;
  soal: string;
  id_jawaban_latihan: number;
  jawaban_peserta: string;
  created_at: string;
  updated_at: string;
  m_jawaban_soal: MJawabanSoal[];

  constructor(
    id: number,
    paket_soal_id: number,
    soal: string,
    id_jawaban_latihan: number,
    jawaban_peserta: string,
    created_at: string,
    updated_at: string,
    m_jawaban_soal: MJawabanSoal[],
  ) {
    this.id = id;
    this.paket_soal_id = paket_soal_id;
    this.soal = soal;
    this.id_jawaban_latihan = id_jawaban_latihan;
    this.jawaban_peserta = jawaban_peserta;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.m_jawaban_soal = m_jawaban_soal;
  }
}

export class MJawabanSoal {
  id: number;
  soal_id: number;
  pilihan: string;
  jawaban_benar: number;
  created_at: string;
  updated_at: string;

  constructor(
    id: number,
    soal_id: number,
    pilihan: string,
    jawaban_benar: number,
    created_at: string,
    updated_at: string,
  ) {
    this.id = id;
    this.soal_id = soal_id;
    this.pilihan = pilihan;
    this.jawaban_benar = jawaban_benar;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
