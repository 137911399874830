import { AxiosResponse } from "axios";
import {
  PathFotoKomentar,
  ReviewPelatihan,
} from "../../entities/ReviewPelatihan";

export class ReviewPelatihanDataMapper {
  public convertListReviewPelatihanDataFromApi(
    result: AxiosResponse<any>,
  ): ReviewPelatihan[] {
    const { data } = result.data;

    const review: ReviewPelatihan[] = [];

    data.map((d: any) => {
      const pathFoto: PathFotoKomentar[] = [];

      d.path_foto_komentar_pelatihan.map((p: any) => {
        return pathFoto.push(
          new PathFotoKomentar(p.id, p.label, p.review_pelatihan_id),
        );
      });

      return review.push(
        new ReviewPelatihan(
          d.id,
          d.user_id,
          d.pelatihan_id,
          d.komentar,
          d.rating,
          d.created_at,
          d.updated_at,
          result.data.pagination.total_rows,
          pathFoto,
          d.name,
          d.path_foto,
          result.data.reviews,
        ),
      );
    });

    return review;
  }
}
