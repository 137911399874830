import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle, BsCircleFill, MdLogout } from "react-icons/all";
import Select, { StylesConfig } from "react-select";
import { useManajemenUserContext } from "../../../controller/admin/manajemen_user";
import Loader from "../../Loader";

/**
 * Represents the LogUser component, which is used to display user activity logs.
 * This component includes user details, a date selection dropdown, and a list of user activities.
 * It allows users to filter and view their activity logs based on selected dates.
 * @returns {JSX.Element} The LogUser component JSX.
 * @author Muhammad Farras Jibran
 */

interface Option {
  value: string;
  label: string;
}

const formatDate = (date, type) => {
  // Mengonversi tanggal ke format yang diinginkan
  const dateResult = format(new Date(date), "EEEE, dd MMM yyyy", {
    locale: id,
  });

  const dateTime = format(new Date(date), "HH:mm", { locale: id });

  return type === "general" ? dateResult : dateTime;
};

const ActivityItem = ({ data, item, iItem, iObject = 0 }) => {
  return (
    <Flex>
      <Box mr={3} position="relative">
        {iItem === 0 && (
          <Icon
            as={BsCircleFill}
            color="#7A8990"
            h="10px"
            w="10px"
            position="absolute"
            top={0}
          />
        )}
        <Box
          borderLeft="2px"
          borderColor="#7A8990"
          borderLeftStyle="dashed"
          h="full"
          ml={1}
        ></Box>
        {iItem === data.length - 1 && (
          <Icon
            as={BsCircleFill}
            color="#7A8990"
            h="10px"
            w="10px"
            position="absolute"
            bottom={0}
          />
        )}
      </Box>

      <Box
        border="1px"
        borderColor="#D1E5EE"
        borderRadius="lg"
        p={4}
        mb={iItem === data.length - 1 ? 0 : 4}
        bg={iItem === 0 && iObject === 0 ? "#EDF2F7" : "white"}
        overflow="hidden"
        w="full"
      >
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="md" mb={1} letterSpacing="wider">
            {item.activity}
          </Text>
          <Text color="#2E81F7" fontSize="sm">
            {formatDate(item.created_at, "time")}
          </Text>
        </Flex>
        <Text fontSize="sm" letterSpacing="wider">
          {item.metadata}
        </Text>
      </Box>
    </Flex>
  );
};

const LogUser = () => {
  const [dateSelected, setDateSelected] = useState<Option | null>(null);
  const { getLogActivity, setShowLogActivity } = useManajemenUserContext();
  const {
    logActivity,
    formName,
    formNIK,
    formLevel,
    formPathFoto,
    isLoadingLogActivity,
  } = useManajemenUserContext().state;

  const groupedData = logActivity.reduce((result, item) => {
    const date = item.created_at.split(" ")[0];

    if (!result[date]) {
      result[date] = [];
    }

    result[date].push(item);

    return result;
  }, {});

  const listData = Object.keys(groupedData).map((item) => {
    return { label: formatDate(item, "general"), value: item };
  });

  const colourStyles: StylesConfig = {
    control: (styles) => {
      return {
        ...styles,
        width: "250px",
      };
    },
  };

  useEffect(() => {
    getLogActivity();
  }, []);

  return (
    <Box>
      {isLoadingLogActivity ? (
        <Loader />
      ) : (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mx={12}
            mb={12}
            mt={6}
          >
            <Box display="flex">
              <Avatar size="xl" name={formName} src={formPathFoto} mr={4} />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Heading
                  as="h2"
                  fontWeight="bold"
                  fontSize="2xl"
                  mb={2}
                  letterSpacing="widest"
                >
                  {formName}
                </Heading>
                <Flex>
                  <Text
                    fontSize="sm"
                    letterSpacing="wider"
                    fontWeight="semibold"
                  >
                    {formNIK}
                  </Text>
                  <Text fontSize="sm" mx={1}>
                    -
                  </Text>
                  <Text
                    fontSize="sm"
                    letterSpacing="wider"
                    fontWeight="semibold"
                  >
                    {formLevel}
                  </Text>
                </Flex>
              </Box>
            </Box>
            <Button
              rightIcon={<MdLogout />}
              fontSize="md"
              colorScheme="red"
              onClick={() => setShowLogActivity(false)}
            >
              Keluar
            </Button>
          </Box>

          <Box borderRadius="xl" bg="white" mt={6} p={4}>
            <Box display="flex" justifyContent="space-between" pl={3}>
              <Flex justifyContent="center" alignItems="center">
                <Icon
                  as={AiOutlineClockCircle}
                  boxSize={4}
                  color="#FFC271"
                  mr={2}
                />
                <Text fontWeight="bold" fontSize="xl" letterSpacing="widest">
                  Aktivitas Terakhir
                </Text>
              </Flex>
              <Select
                placeholder="Pilih Tanggal"
                options={listData}
                isClearable
                onChange={(e) => setDateSelected(e as Option)}
                value={dateSelected}
                styles={colourStyles}
              />
            </Box>

            {logActivity.length ? (
              <Box maxH="550px" overflowY="auto" p={4} pt={0} mt={4}>
                {dateSelected?.value ? (
                  <Box>
                    <Text
                      fontSize="md"
                      color="#C4D0DD"
                      letterSpacing="wider"
                      mb={4}
                      ml={5}
                      fontWeight="semibold"
                    >
                      {formatDate(dateSelected?.value, "general")}
                    </Text>
                    {groupedData[dateSelected.value].map((item, iItem) => (
                      <ActivityItem
                        data={groupedData[dateSelected.value]}
                        item={item}
                        iItem={iItem}
                        key={item.id}
                      />
                    ))}
                  </Box>
                ) : (
                  Object.keys(groupedData).map((key, iObject) => (
                    <Box key={key}>
                      <Text
                        fontSize="md"
                        color="#C4D0DD"
                        letterSpacing="wider"
                        mb={4}
                        mt={iObject !== 0 ? 7 : 0}
                        ml={5}
                        fontWeight="semibold"
                      >
                        {formatDate(key, "general")}
                      </Text>

                      {Object.keys(groupedData).length
                        ? groupedData[key].map((item, iItem) => (
                            <ActivityItem
                              data={groupedData[key]}
                              item={item}
                              iItem={iItem}
                              iObject={iObject}
                              key={item.id}
                            />
                          ))
                        : logActivity.map((item, iItem) => (
                            <ActivityItem
                              data={logActivity}
                              item={item}
                              iItem={iItem}
                              iObject={iObject}
                              key={item.id}
                            />
                          ))}
                    </Box>
                  ))
                )}
              </Box>
            ) : (
              <Flex h="200px" justifyContent="center" alignItems="center">
                <Text fontSize="xl" letterSpacing="wide">
                  Belum ada aktivitas terbaru
                </Text>
              </Flex>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LogUser;
