import { FaqRepositoryInterface } from "../../../data/contracts/FaqPublic";
import { FaqsDataMapper } from "../../../data/mappers/Faq";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { dataFaq } from "../../../entities/Faq";

export class FaqRepository implements FaqRepositoryInterface {
  private apiService: ApiService;
  private mapper: FaqsDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: FaqsDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllFaqPublic(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<dataFaq[]> {
    const headers = new Map<string, string>();
    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.getFaqPublic(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers,
    );
    return this.mapper.convertListFaqsPublic(dataResponse);
  }

  public async getAllFaq(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<dataFaq[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.getFaq(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers,
    );
    return this.mapper.convertListFaqsPublic(dataResponse);
  }

  public async storeFaq(
    pertanyaan: string,
    jawaban: string,
    status_publish: boolean,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("pertanyaan", pertanyaan);
    formData.append("jawaban", jawaban);
    formData.append("status_publish", status_publish.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.getFaq(),
      {},
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateFaq(
    id: number,
    pertanyaan: string,
    jawaban: string,
    status_publish: boolean,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("pertanyaan", pertanyaan);
    formData.append("jawaban", jawaban);
    formData.append("status_publish", status_publish.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.getFaq() + "/" + id,
      {},
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteFaq(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.getFaq() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
