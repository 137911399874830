/* eslint-disable eqeqeq */
import { AxiosResponse } from "axios";
import { GetLocalStorage } from "../../app/misc/UseLocalStorage";
import { Kelurahan } from "../../entities/Kelurahan";

export class KelurahanDataMapper {
  public convertKelurahanDataFromApi(result: AxiosResponse<any>): Kelurahan[] {
    const { data } = result.data;

    const kelurahan: Kelurahan[] = [];

    data.map((u: any) => {
      return kelurahan.push(
        new Kelurahan(
          u.id,
          u.kecamatan_id,
          u.nama_kelurahan,
          u.created_at,
          u.updated_at,
          GetLocalStorage({ name: "level" }) == "superadmin"
            ? result.data.pagination.total_rows
            : "",
          u.kabupaten_id,
        ),
      );
    });

    return kelurahan;
  }
}
