import { SettingPenilaianRepositoryInterface } from "../../../data/contracts/SettingPenilaian";
import { SettingPenilaianDataMapper } from "../../../data/mappers/SettingPenilaian";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { SettingPenilaian } from "../../../entities/SettingPenilaian";

export class SettingPenilaianApiRepository
  implements SettingPenilaianRepositoryInterface
{
  private apiService: ApiService;
  private mapper: SettingPenilaianDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: SettingPenilaianDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllSettingPenilaian(
    page: number,
    rows: number,
    keyword: string,
    jenis_pelatihan_id: string,
  ): Promise<SettingPenilaian[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.settingPenilaian(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        jenis_pelatihan_id: jenis_pelatihan_id,
      },
      null,
      headers,
    );
    return this.mapper.convertSettingPenlaianDataFromApi(dataResponse);
  }

  public async getDetailSettingPenilaian(
    id: number,
  ): Promise<SettingPenilaian> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.settingPenilaian() + "/" + id,
      {},
      null,
      headers,
    );
    return this.mapper.convertDetailSettingPenlaianDataFromApi(dataResponse);
  }

  public async storeSettingPenilaian(
    jenis_pelatihan_id: number,
    nilai_bawah: string,
    nilai_atas: string,
    predikat: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("jenis_pelatihan_id", jenis_pelatihan_id.toString());
    formData.append("nilai_bawah", nilai_bawah);
    formData.append("nilai_atas", nilai_atas);
    formData.append("predikat", predikat);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.settingPenilaian(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateSettingPenilaian(
    id: number,
    jenis_pelatihan_id: number,
    nilai_bawah: string,
    nilai_atas: string,
    predikat: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("jenis_pelatihan_id", jenis_pelatihan_id.toString());
    formData.append("nilai_bawah", nilai_bawah);
    formData.append("nilai_atas", nilai_atas);
    formData.append("predikat", predikat);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.settingPenilaian() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteSettingPenilaian(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.settingPenilaian() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
