import { Image, Skeleton, Stack } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import moment from "moment";
import PrettyRating from "pretty-rating-react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  BsFillPencilFill,
  BsFillTrashFill,
  TbCertificate,
} from "react-icons/all";
import { BsSearch, BsTrashFill } from "react-icons/bs";
import { Else, If, Then } from "react-if";
import ReactLoading from "react-loading";
import { ToastContainer } from "react-toastify";
import CalendarIconYellow from "../../../../../assets/svg/ic_calendar_yellow.svg";
import TimeIconYellow from "../../../../../assets/svg/ic_time_yellow.svg";
import UsersIconYellow from "../../../../../assets/svg/ic_users_yellow.svg";
import { Navbar } from "../../../../../components/Admin/Navbar";
import { Sidebar } from "../../../../../components/Admin/Sidebar";
import DetailPelatihanDaftarPesertaController, {
  useDetailPelatihanDaftarPesertaContext,
} from "../../../../../controller/admin/manajemen_pelatihan/detail/daftar_peserta";

const colors = {
  star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
};

const BottomHeader: React.FC = () => {
  const { setSelectedId, setShowDialogDelete } =
    useDetailPelatihanDaftarPesertaContext();
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Detail Pelatihan</a>
      </div>
      <div className="flex flex-row">
        <button
          type="button"
          className="mr-2 flex px-6 py-2.5 bg-red-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-red-700 mb-3 mt-3"
          onClick={() => {
            setSelectedId(window.location.pathname.split("/", 6)[5]);
            setShowDialogDelete(true);
          }}
        >
          Hapus
          <BsFillTrashFill className="ml-2" style={{ marginTop: "2px" }} />
        </button>
        <a
          href={`/admin/ubah/manajemen_pelatihan/${
            window.location.pathname.split("/", 6)[5]
          }`}
          className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
        >
          Ubah
          <BsFillPencilFill className="ml-2" style={{ marginTop: "2px" }} />
        </a>
      </div>
    </div>
  );
};

const HeaderBanner: React.FC = () => {
  const { getDetailPelatihan, getDaftarPeserta } =
    useDetailPelatihanDaftarPesertaContext();
  const { pelatihan, isLoading } =
    useDetailPelatihanDaftarPesertaContext().state;

  useEffect(() => {
    getDetailPelatihan(window.location.pathname.split("/", 6)[5]);
    getDaftarPeserta(1, "", 10, window.location.pathname.split("/", 6)[5]);
  }, []);

  return (
    <div className="container px-9 mt-6">
      <div className="py-10 bg-green-600 mt-6 rounded-lg">
        <If condition={isLoading}>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="col-span-1 md:col-span-2">
              <div className="ml-10 mr-10 rounded-lg h-full">
                <Skeleton height={228} />
              </div>
            </div>
            <div className="col-span-3 mr-6 ml-6">
              <Stack>
                <Skeleton height={20} style={{ marginTop: "11px" }} />
                <Skeleton height={20} style={{ marginTop: "11px" }} />
                <Skeleton height={20} style={{ marginTop: "11px" }} />
                <Skeleton height={20} style={{ marginTop: "11px" }} />
                <Skeleton height={20} style={{ marginTop: "11px" }} />
                <Skeleton height={20} style={{ marginTop: "11px" }} />
                <Skeleton height={20} style={{ marginTop: "11px" }} />
              </Stack>
            </div>
          </div>
        </If>

        <div
          className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 ${
            isLoading ? "hidden" : ""
          }`}
        >
          <div className="col-span-3 md:col-span-2">
            <div
              className="bg-green-200 hover:bg-blue-300 transition-all duration-500 ml-10 mr-10 rounded-lg w-11/12"
              style={{
                backgroundImage: `url(${pelatihan?.path_thumbnail})`,
                backgroundSize: "cover",
                height: "228px",
              }}
            ></div>
          </div>
          <div className="col-span-3 mr-6 ml-6">
            <h2 className="font-semibold text-2xl text-white">
              {pelatihan?.judul_pelatihan}
            </h2>
            <div className="grid grid-cols-6 mt-4 items-center">
              <div className="bg-white text-xs rounded-full p-2 col-span-3 text-center">
                <p className="text-green-400">{pelatihan?.penyelenggara}</p>
              </div>
              <div className="col-span-3">
                <div className="ml-2 text-right text-sm flex flex-row">
                  <PrettyRating
                    value={pelatihan != null ? pelatihan.rating : 0}
                    colors={colors.star}
                  />
                  <p className="text-white ml-2 font-semibold">
                    {pelatihan?.rating}
                  </p>
                  <p className="text-white ml-2 font-semibold">
                    ({pelatihan?.count_reviewer} Reviews)
                  </p>
                  {/* <p className="text-white ml-2 font-semibold">
                    ({pelatihan?.count_diskusi} Diskusi)
                  </p> */}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <UsersIconYellow />
              <p className="text-white ml-2 font-semibold">
                {pelatihan?.count_peserta} Peserta
              </p>
              <Image src={CalendarIconYellow} className="ml-3" />
              <p className="text-white ml-2 font-base">
                {moment(pelatihan?.waktu_mulai).format("LL")} s.d{" "}
                {moment(pelatihan?.waktu_selesai).format("LL")}
              </p>
              <Image src={TimeIconYellow} className="ml-3" />
              <p className="text-white ml-2 font-base">
                <b>{pelatihan?.jp} JP</b>
                {/* ± 39 Jam */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, isLoadingDelete } =
    useDetailPelatihanDaftarPesertaContext().state;
  const { setShowDialogDelete, deletePelatihan } =
    useDetailPelatihanDaftarPesertaContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deletePelatihan()}
                  >
                    <If condition={isLoadingDelete}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDeletePeserta: React.FC = () => {
  const { showDialogDeletePeserta, isLoadingDeletePeserta } =
    useDetailPelatihanDaftarPesertaContext().state;
  const { setShowDialogDeletePeserta, deletePesertaPelatihan } =
    useDetailPelatihanDaftarPesertaContext();

  return (
    <>
      {showDialogDeletePeserta ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Peserta Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDeletePeserta(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Peserta Pelatihan Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDeletePeserta(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => {
                      deletePesertaPelatihan();
                    }}
                  >
                    <If condition={isLoadingDeletePeserta}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalSertifikat = ({ nama_pelatihan }: { nama_pelatihan: string }) => {
  const { showModalSertifikat, path_sertifikat } =
    useDetailPelatihanDaftarPesertaContext().state;
  const { setShowSertifikat } = useDetailPelatihanDaftarPesertaContext();

  const handleDownloadSertifikat = () => {
    saveAs(
      path_sertifikat,
      `Sertifikat ${nama_pelatihan} ${moment().format("DD-MM-YYYY")} .pdf`,
    );
  };

  return (
    <>
      {showModalSertifikat ? (
        <>
          {/* background modal */}
          <div
            className="fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto inset-0 h-modal md:h-full justify-center items-center flex bg-gray-800 opacity-50"
            aria-modal="true"
            role="dialog"
            tabIndex={-1}
            style={{ zIndex: 999999 }}
          ></div>

          {/* Modal */}
          <div
            className="flex justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
            style={{ zIndex: 999999 }}
          >
            <div className="relative w-full h-full max-w-7xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Sertifikat Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowSertifikat(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-5">
                  <iframe
                    src={`${path_sertifikat}`}
                    width={"100%"}
                    height={"600"}
                    style={{ borderRadius: "6px" }}
                  />
                </div>
                <div className="items-center p-6 space-x-2 rounded-b">
                  <button
                    type="button"
                    className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"
                    onClick={handleDownloadSertifikat}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const {
    search,
    setPageRow,
    getDaftarPeserta,
    setShowDialogDeletePeserta,
    setPelatihanPesertaId,
    setShowSertifikat,
    setPathSertifikat,
  } = useDetailPelatihanDaftarPesertaContext();
  const { pelatihanSaya, isLoading, keyword, pelatihan } =
    useDetailPelatihanDaftarPesertaContext().state;
  const [perPage, setPerPage] = useState(10);
  const [filterText] = React.useState("");
  const [resetPaginationToggle] = React.useState(false);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Nama Lengkap",
      selector: (row) => row.peserta.name,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "NIP / NIK",
      selector: (row) => row.peserta.nip,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Nilai",
      selector: (row) => row.nilai_akhir,
      sortable: true,
    },
    {
      name: "Predikat",
      selector: (row) => (row.predikat != null ? row.predikat : "-"),
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Modul Selesai",
      selector: (row) => row.modul_selesai,
      sortable: true,
      wrap: true,
    },
    {
      name: "Modul Total",
      selector: (row) => row.modul_belum,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status_pelatihan,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            {/* Delete Button */}
            <button
              className="ml-2 px-2 py-1 text-xs text-red-700 bg-red-100 hover:bg-red-200 rounded-lg"
              onClick={() => {
                setShowDialogDeletePeserta(true);
                setPelatihanPesertaId(row.id);
              }}
            >
              <BsTrashFill style={{ marginTop: "2px", fontSize: "20px" }} />
            </button>

            {/* Download Sertifikat */}
            <If
              condition={
                row.status_tte === "2" &&
                (row.path_sertifikat !== "" || row.path_sertifikat != null)
              }
            >
              <button
                className="ml-2 px-2 py-1 text-xs text-blue-700 bg-blue-100 hover:bg-blue-200 rounded-lg"
                onClick={() => {
                  setPathSertifikat(row.path_sertifikat);
                  setShowSertifikat(true);
                }}
              >
                <TbCertificate style={{ marginTop: "2px", fontSize: "20px" }} />
              </button>
            </If>
          </div>
        );
      },
      sortable: true,
      width: "200px",
      wrap: true,
    },
  ];

  const handlePageChange = (page) => {
    getDaftarPeserta(
      page,
      keyword,
      perPage,
      window.location.pathname.split("/", 6)[5],
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getDaftarPeserta(
      page,
      keyword,
      newPerPage,
      window.location.pathname.split("/", 6)[5],
    );
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="input-group relative flex flex-wrap  mr-2">
        <button
          className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          style={{ height: "38px" }}
        >
          <BsSearch />
        </button>
        <input
          type="search"
          className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
          placeholder="Search"
          onChange={(event) =>
            search(
              event.target.value,
              window.location.pathname.split("/", 6)[5],
            )
          }
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"blue"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  return (
    <div className="app">
      <ModalDelete />
      <ModalDeletePeserta />
      <ModalSertifikat
        nama_pelatihan={pelatihan != null ? pelatihan.judul_pelatihan : ""}
      />
      <Sidebar />
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <Navbar title="Manajemen Pelatihan" />
          <BottomHeader />
        </header>

        <div className="container mb-10">
          <HeaderBanner />
          <div className="container px-9 mt-6">
            <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0">
              <li className="nav-item">
                <a
                  href={`/admin/detail/manajemen_pelatihan/data_pelatihan/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        bg-white
                        "
                >
                  Data Pelatihan
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  href={`/admin/detail/manajemen_pelatihan/konten_pelatihan/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Konten Pelatihan
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  href={`/admin/detail/manajemen_pelatihan/penilaian/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Penilaian
                </a>
              </li>
              <li className="nav-item">
                <a
                  href=""
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        active
                        bg-white
                        "
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Daftar Peserta
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active bg-white px-6 py-6">
                <DataTable
                  columns={columns}
                  data={pelatihanSaya}
                  progressPending={isLoading}
                  pagination
                  paginationServer
                  paginationTotalRows={
                    pelatihanSaya.length > 0 ? pelatihanSaya[0].jumlahData : 0
                  }
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  customStyles={customStyles}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  progressComponent={<CustomLoader />}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const DetailDaftarPesertaManajemenPelatihan: React.FC = () => {
  return (
    <DetailPelatihanDaftarPesertaController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </DetailPelatihanDaftarPesertaController.Provider>
  );
};

export default DetailDaftarPesertaManajemenPelatihan;
