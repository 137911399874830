import { injectable } from "tsyringe";
import { DashboardRekapitulasiApiRepository } from "../../app/repository/api/DashboardRekapitulasiApiRepository";
import { DashboardRekapitulasi } from "../../entities/DashboardRekapitulasi";

@injectable()
export class DashboardRekapitulasiPresenter {
  private repository: DashboardRekapitulasiApiRepository;
  constructor(repository: DashboardRekapitulasiApiRepository) {
    this.repository = repository;
  }

  /**
   *
   * @param page
   * @param rows
   * @param keyword
   * @param tahun
   * @returns
   */
  public getDashboardRekapitulasiData({
    page,
    rows,
    keyword,
    tahun,
    is_simdiklat,
  }: {
    page: number;
    rows: number;
    keyword: string;
    tahun: string;
    is_simdiklat: boolean;
  }): Promise<DashboardRekapitulasi[]> {
    return this.repository.getDashboardRekapitulasiData(
      page,
      rows,
      keyword,
      tahun,
      is_simdiklat !== undefined ? is_simdiklat : false,
    );
  }
}
