import { injectable } from "tsyringe";
import { JenisPelatihanApiRepository } from "../../app/repository/api/JenisPelatihanApiRepository";
import { JenisPelatihan } from "../../entities/JenisPelatihan";

@injectable()
export class JenisPelatihanPresenter {
  private repository: JenisPelatihanApiRepository;

  constructor(repository: JenisPelatihanApiRepository) {
    this.repository = repository;
  }

  public getAllJenisPelatihan(
    page: number,
    rows: number,
    keyword: string,
    sub_menu_jenis_pelatihan_id: string,
  ): Promise<JenisPelatihan[]> {
    return this.repository.getAllJenisPelatihan(
      page,
      rows,
      keyword,
      sub_menu_jenis_pelatihan_id,
    );
  }

  public getDetailJenisPelatihan(id: number): Promise<JenisPelatihan> {
    return this.repository.getDetailJenisPelatihan(id);
  }

  public storeJenisPelatihan(
    sub_menu_jenis_pelatihan_id: number,
    kode: string,
    nama_pelatihan: string,
    kompetensi: string,
    jp: string,
    keterangan: string,
    metode_penambahan: number,
  ): Promise<any> {
    return this.repository.storeJenisPelatihan(
      sub_menu_jenis_pelatihan_id,
      kode,
      nama_pelatihan,
      kompetensi,
      jp,
      keterangan,
      metode_penambahan,
    );
  }

  public updateJenisPelatihan(
    id: number,
    sub_menu_jenis_pelatihan_id: number,
    kode: string,
    nama_pelatihan: string,
    kompetensi: string,
    jp: string,
    keterangan: string,
    metode_penambahan: number,
  ): Promise<any> {
    return this.repository.updateJenisPelatihan(
      id,
      sub_menu_jenis_pelatihan_id,
      kode,
      nama_pelatihan,
      kompetensi,
      jp,
      keterangan,
      metode_penambahan,
    );
  }

  public deleteJenisPelatihan(id: number): Promise<any> {
    return this.repository.deleteJenisPelatihan(id);
  }
}
