import { injectable } from "tsyringe";
import { MateriApiRepository } from "../../app/repository/api/MateriApiRepository";
import { Materi } from "../../entities/Materi";

@injectable()
export class MateriPresenter {
  private repository: MateriApiRepository;
  constructor(repository: MateriApiRepository) {
    this.repository = repository;
  }

  public getAllMateri(
    page: number,
    rows: number,
    keyword: string,
    jenis_pelatihan_id: string,
    jenis_materi: string[],
  ): Promise<Materi[]> {
    return this.repository.getAllMateri(
      page,
      rows,
      keyword,
      jenis_pelatihan_id,
      jenis_materi,
    );
  }

  public getDetailMateri(id: number): Promise<Materi> {
    return this.repository.getDetailMateri(id);
  }

  public storeMateri(
    path_thumbnail: File,
    jenis_pelatihan: string,
    judul_materi: string,
    uraian_deskripsi: string,
    kompetensi: string,
    jam_pelatihan: string,
    status_konsultasi: boolean,
    widyaiswara: number[],
    status_publish: boolean,
    penyusun: string,
  ): Promise<any> {
    return this.repository.storeMateri(
      path_thumbnail,
      jenis_pelatihan,
      judul_materi,
      uraian_deskripsi,
      kompetensi,
      jam_pelatihan,
      status_konsultasi,
      widyaiswara,
      status_publish,
      penyusun,
    );
  }

  public updateMateri(
    id: number,
    path_thumbnail: any,
    jenis_pelatihan: string,
    judul_materi: string,
    uraian_deskripsi: string,
    kompetensi: string,
    jam_pelatihan: string,
    status_konsultasi: boolean,
    widyaiswara: number[],
    status_publish: boolean,
    penyusun: string,
  ): Promise<any> {
    return this.repository.updateMateri(
      id,
      path_thumbnail,
      jenis_pelatihan,
      judul_materi,
      uraian_deskripsi,
      kompetensi,
      jam_pelatihan,
      status_konsultasi,
      widyaiswara,
      status_publish,
      penyusun,
    );
  }

  public deleteMateri(id: number): Promise<any> {
    return this.repository.deleteMateri(id);
  }
}
