import { injectable } from "tsyringe";
import { KategoriPaketSoalApiRepository } from "../../app/repository/api/KategoriPaketSoalApiRepository";
import { KategoriPaketSoal } from "../../entities/KategoriPaketSoal";

@injectable()
export class KategoriPaketSoalPresenter {
  private repository: KategoriPaketSoalApiRepository;
  constructor(repository: KategoriPaketSoalApiRepository) {
    this.repository = repository;
  }

  public getAllKategoriPaketSoal(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<KategoriPaketSoal[]> {
    return this.repository.getAllKategoriPaketSoal(page, rows, keyword);
  }

  public storeKategoriPaketSoal(kategori_paket_soal: string): Promise<any> {
    return this.repository.storeKategoriPaketSoal(kategori_paket_soal);
  }

  public updateKategoriPaketSoal(
    id: number,
    kategori_paket_soal: string,
  ): Promise<any> {
    return this.repository.updateKategoriPaketSoal(id, kategori_paket_soal);
  }

  public deleteKategoriPaketSoal(id: number): Promise<any> {
    return this.repository.deleteKategoriPaketSoal(id);
  }
}
