import React from "react";
import { container } from "tsyringe";
import { SimdiklatPresenter } from "../../../../../data/presenters/SimdiklatPresenter";
import { toast } from "react-toastify";

interface IState {
  isLoading: boolean;
  isLoadingLog: boolean;
  page: number;
  rows: number;
  logData: any[];
}

interface InitialState {
  state: IState;
  kirimDataAlumni: Function;
  getLogData: Function;
  setPage: Function;
  setPageRow: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingLog: false,
    page: 1,
    rows: 12,
    logData: [],
  },
  kirimDataAlumni: () => {},
  getLogData: () => {},
  setPage: () => {},
  setPageRow: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenAlumniSimdiklat } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingLog: false,
    page: 1,
    rows: 12,
    logData: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingLog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingLog: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const kirimDataAlumni = async (pelatihan_id: number) => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const response =
        await simdiklatPresenter.kirimDataAlumniSimdiklat(pelatihan_id);

      if (response.status === 200) {
        toast.success(response.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error: any) {
      console.log("error saat kirim data alumni ke simdiklat :", error);
      toast.error("Error saat kirim data alumni ke simdiklat", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const getLogData = async (page, rows) => {
    setLoadingLog(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const tteLog = await simdiklatPresenter.logDataAlumniSimdiklat(
        page,
        rows,
      );

      const data = tteLog;

      setState((prevstate) => ({
        ...prevstate,
        logData: data,
      }));

      setLoadingLog(false);
    } catch (error: any) {
      console.log("error saat get log data :", error);
      setLoadingLog(false);
    }
  };

  return (
    <ManajemenAlumniSimdiklat
      value={{
        state,
        kirimDataAlumni,
        getLogData,
        setPage,
        setPageRow,
      }}
    >
      {children}
    </ManajemenAlumniSimdiklat>
  );
};

export const useManajemenAlumniSimdiklat = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenAlumniSimdiklat,
  Provider,
};
