import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { BiSearch } from "react-icons/bi";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import Loader from "../../../../components/Loader";
import PodcastCategoryContext, {
  usePodcastCategoryContext,
} from "../../../../controller/admin/data_master/kategori_podcast";

/**
 * Represents a modal dialog for creating or editing podcast categories.
 * @component
 * @returns {JSX.Element} JSX.Element
 */

const ModalDialog = () => {
  const { mode, dataUpdate, openModal, loadingAction } =
    usePodcastCategoryContext().state;
  const { setPodcastCategoryState, postData } = usePodcastCategoryContext();

  const handleCloseModal = () => {
    setPodcastCategoryState("dataUpdate", null);
    setPodcastCategoryState("openModal", false);
  };
  return (
    <Modal isOpen={openModal} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="black">
          {mode === "create" ? "Tambah" : "Ubah"} Kategori Podcast
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="semibold" w="20%">
              Kategori
            </Text>
            <Input
              w="70%"
              type="text"
              defaultValue={dataUpdate?.name}
              onChange={(e) => setPodcastCategoryState("name", e.target.value)}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} h="30px" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button
            colorScheme="blue"
            h="30px"
            isLoading={loadingAction}
            spinnerPlacement="start"
            onClick={() => postData()}
          >
            Simpan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * Represents the main Podcast Category page component.
 * This component displays the list of podcast categories in a data table,
 * along with search and pagination features. Users can create, edit, and delete
 * podcast categories using this interface.
 * @component
 * @returns {JSX.Element} JSX.Element
 */
const PodcastCategory = () => {
  const { listData, loadingData, pagination, page, perPage } =
    usePodcastCategoryContext().state;
  const { setPodcastCategoryState, fetchData, handleDelete } =
    usePodcastCategoryContext();

  const handlePageChange = (e) => {
    setPodcastCategoryState("page", e);
  };

  const handlePerRowsChange = (e) => {
    setPodcastCategoryState("perPage", e);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage]);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Kategori",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <Button
              px="2"
              py="1"
              fontSize="xs"
              bg="blue.100"
              variant="outline"
              rounded="full"
              h="24px"
              _hover={{ bg: "blue.200" }}
              _active={{
                bg: "blue.200",
              }}
              onClick={() => {
                setPodcastCategoryState("mode", "edit");
                setPodcastCategoryState("dataUpdate", row);
                setPodcastCategoryState("openModal", true);
              }}
            >
              Ubah Data
            </Button>
            <Button
              ml="2"
              px="2"
              py="1"
              fontSize="xs"
              bg="red.100"
              variant="outline"
              rounded="full"
              h="24px"
              _hover={{ bg: "red.200" }}
              _active={{
                bg: "red.200",
              }}
              onClick={() => {
                handleDelete(row.id);
              }}
            >
              Hapus Data
            </Button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <SidebarWithHeader>
      <Box boxShadow="lg" borderRadius="md" bg="white" px={4} py={8}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          px={4}
        >
          <Heading
            as="h2"
            fontSize="md"
            fontWeight="bold"
            lineHeight="normal"
            _hover={{ cursor: "pointer" }}
          >
            Kategori Podcast
          </Heading>
          <Box w={{ base: "full", md: "30%" }}>
            <Flex>
              <InputGroup mr={2}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BiSearch} color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search"
                  _focus={{
                    ring: "1",
                    ringColor: "blue",
                    borderColor: "blue",
                  }}
                />
              </InputGroup>
              <Button
                colorScheme="blue"
                onClick={() => {
                  setPodcastCategoryState("mode", "create");
                  setPodcastCategoryState("openModal", true);
                }}
              >
                Tambah Baru
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Box mt={4}>
          <DataTable
            columns={columns}
            data={listData}
            progressPending={loadingData}
            pagination
            paginationServer
            paginationTotalRows={pagination ? pagination.total_rows : 0}
            paginationPerPage={perPage}
            progressComponent={<Loader />}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Box>
      </Box>
      <ModalDialog />
    </SidebarWithHeader>
  );
};

/**
 * Represents the main Podcast Category page component wrapped in the context provider.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */
const PodcastCategoryMain: React.FC = () => {
  return (
    <PodcastCategoryContext.Provider>
      <PodcastCategory />
    </PodcastCategoryContext.Provider>
  );
};

export default PodcastCategoryMain;
