/* eslint-disable eqeqeq */
import { Editor } from "@tinymce/tinymce-react";
import AWS from "aws-sdk";
import If, { Else } from "if-else-react";
import React, { useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SetInterval from "set-interval";
import { Wrapper } from "../../../components/Wrapper";
import DetailPelatihanController, {
  useDetailPelatihanContext,
} from "../../../controller/pelatihan/detail";
import ProjectController, {
  useProjectContext,
} from "../../../controller/project/index";

const FormProject: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  // File Type
  const fileType = ["pdf", "pptx", "ppt", "pptm", "mp4", "mov", "png", "jpg"];

  // Call Controller
  const { setPathFinalProject, setUraianPenjelasan, storeFinalProject } =
    useProjectContext();

  const { id_sub_modul, isLoading } = useProjectContext().state;

  const { handleNextSubModul } = useDetailPelatihanContext();

  const handleChangePathFinalProject = (file: File) => {
    setPathFinalProject(file);
  };

  const handleSendFinalProject = async () => {
    const response = await storeFinalProject(id);
    if (response == 200) {
      const responseCode = await handleNextSubModul(id_sub_modul);
      if (responseCode == 200 || responseCode == 400) {
        toast("Berhasil Mengirim Project");
        window.location.href = "/detail/pelatihan/" + id;
      }
    }
  };

  return (
    <table className="text-left">
      <tr>
        <td className="text-sm font-semibold pb-5">Uraian Penjelasan</td>
        <td className="pl-8 pb-5">:</td>
        <td className="pl-5 pb-5">
          <Editor
            initialValue=""
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help | image",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              images_upload_handler: async function (
                blobInfo: any,
                success: any,
                failure: any,
              ) {
                const S3_BUCKET = "pusdiklat-lms";
                const REGION = "sgp1";
                const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                const s3Credentials = new AWS.Credentials({
                  accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                  secretAccessKey:
                    "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                });

                const myBucket = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  endpoint: ENDPOINT,
                  region: REGION,
                  credentials: s3Credentials,
                });

                function makeid(length) {
                  let result = "";
                  const characters =
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                  const charactersLength = characters.length;
                  for (let i = 0; i < length; i++) {
                    result += characters.charAt(
                      Math.floor(Math.random() * charactersLength),
                    );
                  }
                  return result;
                }

                try {
                  const nameFile =
                    makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                  const params = {
                    ACL: "public-read",
                    Body: blobInfo.blob(),
                    Bucket: S3_BUCKET,
                    Key: nameFile,
                  };

                  myBucket
                    .putObject(params)
                    .on("httpUploadProgress", (evt) => {
                      console.log(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .send((err, data) => {
                      if (err) {
                        failure(err);
                      } else {
                        success(
                          "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                            nameFile,
                        );
                      }
                    });
                } catch (error: any) {
                  console.log(error);
                  failure(error);
                  return;
                }
              },
            }}
            onEditorChange={(a) => setUraianPenjelasan(a)}
          />
        </td>
      </tr>
      <tr>
        <td className="text-sm font-semibold">File Project</td>
        <td className="pl-8">:</td>
        <td className="pl-5 pt-5">
          <FileUploader
            multiple={false}
            handleChange={handleChangePathFinalProject}
            name="file"
            types={fileType}
          />
          <span className="text-sm text-red-400 ">
            *filetype yang diizinkan hanya pdf, office, images, video
          </span>
        </td>
      </tr>
      <tr>
        <td></td>
        <td className="pl-8"></td>
        <td className="pl-5 pt-5">
          {/* Button Kirim */}
          <div className="flex flex-row">
            <a
              href={`/detail_pelatihan_saya/${id}`}
              className="text-green-500 hover:text-white border border-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-8 py-2.5 text-center mr-2 mb-2"
            >
              Batal
            </a>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-8 py-2.5 mr-2 mb-2"
              onClick={handleSendFinalProject}
            >
              <If condition={isLoading}>
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
                <Else />
                Kirim
              </If>
            </button>
          </div>
        </td>
      </tr>
    </table>
  );
};

const ContentProject: React.FC = () => {
  // Call Controller
  const { judulPelatihan, tanggalPelatihan, waktuPengumpulan } =
    useProjectContext().state;

  return (
    <div className="max-w-2xl mx-auto lg:max-w-7xl lg:px-7 mb-5 w-full mt-5 text-center">
      <div className="bg-white shadow-md text-center p-4 rounded-lg px-10 mb-20">
        <h1 className="text-xl font-semibold mt-5">
          Formulir Untuk Melaporkan Hasil Final Project Anda
        </h1>
        {/* Info */}
        <div className="mt-10 flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col text-left">
              <table>
                <tr>
                  <td className="text-sm font-semibold pb-3">Nama Pelatihan</td>
                  <td className="pl-3 pb-3">:</td>
                  <td className="pl-5 pb-3">{judulPelatihan}</td>
                </tr>
                <tr>
                  <td className="text-sm font-semibold">Tanggal Pelaksanaan</td>
                  <td className="pl-3">:</td>
                  <td className="pl-5">{tanggalPelatihan}</td>
                </tr>
              </table>
            </div>
            {/* Alert */}
            <div
              className="flex p-4 mb-4 text-sm text-white bg-red-500 shadow-lg rounded-lg"
              role="alert"
            >
              <i className="far fa-clock text-xl mr-3"></i>
              <div className="mt-1">
                <p className="text-md">{waktuPengumpulan}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Form Project */}
        <div className="mb-10 mt-5">
          <FormProject />
        </div>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  // Call Controller
  const { getDetailPelatihan } = useProjectContext();

  useEffect(() => {
    getDetailPelatihan(id);
  }, []);

  SetInterval.start(() => {
    getDetailPelatihan(id);
  }, 1000 * 60);

  return (
    <Wrapper>
      {/* Content */}
      <ContentProject />
    </Wrapper>
  );
};

const ProjectView: React.FC = () => {
  return (
    <DetailPelatihanController.Provider>
      <ProjectController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <WrapperMain />
      </ProjectController.Provider>
    </DetailPelatihanController.Provider>
  );
};

export default ProjectView;
