import { AxiosResponse } from "axios";
import { KategoriPaketSoal } from "../../entities/KategoriPaketSoal";
import { Jawaban, PaketSoal, Soal } from "../../entities/PaketSoal";

export class PaketSoalDataMapper {
  public convertListPaketSoalDataFromApi(
    result: AxiosResponse<any>,
  ): PaketSoal[] {
    const { data } = result.data;

    const paketSoal: PaketSoal[] = [];

    data.map((p: any) => {
      const soal: Soal[] = [];

      p.m_soal.map((s: any) => {
        const jawaban: Jawaban[] = [];

        s.m_jawaban_soal.map((j: any) => {
          return jawaban.push(
            new Jawaban(
              j.id,
              j.soal_id,
              j.pilihan,
              j.jawaban_benar,
              j.created_at,
              j.updated_at,
            ),
          );
        });

        return soal.push(
          new Soal(
            s.id,
            s.paket_soal_id,
            s.soal,
            s.created_at,
            s.updated_at,
            jawaban,
          ),
        );
      });

      let kategori_paket_soal: KategoriPaketSoal;

      kategori_paket_soal = new KategoriPaketSoal(
        p.m_kategori_paket_soal.id,
        p.m_kategori_paket_soal.kategori_paket_soal,
        p.m_kategori_paket_soal.created_at,
        p.m_kategori_paket_soal.updated_at,
        0,
      );

      return paketSoal.push(
        new PaketSoal(
          p.id,
          p.tahun,
          p.jenis_soal,
          p.judul_soal,
          p.petunjuk_soal,
          p.created_at,
          p.updated_at,
          result.data.pagination.total_rows,
          soal,
          kategori_paket_soal,
        ),
      );
    });

    return paketSoal;
  }

  public convertDetailPaketSoalDataFromApi(
    result: AxiosResponse<any>,
  ): PaketSoal {
    const { data } = result.data;

    let paketSoal: PaketSoal;

    const soal: Soal[] = [];

    data.m_soal.map((s: any) => {
      const jawaban: Jawaban[] = [];

      s.m_jawaban_soal.map((j: any) => {
        return jawaban.push(
          new Jawaban(
            j.id,
            j.soal_id,
            j.pilihan,
            j.jawaban_benar,
            j.created_at,
            j.updated_at,
          ),
        );
      });

      return soal.push(
        new Soal(
          s.id,
          s.paket_soal_id,
          s.soal,
          s.created_at,
          s.updated_at,
          jawaban,
        ),
      );
    });

    let kategori_paket_soal: KategoriPaketSoal;

    kategori_paket_soal = new KategoriPaketSoal(
      data.m_kategori_paket_soal.id,
      data.m_kategori_paket_soal.kategori_paket_soal,
      data.m_kategori_paket_soal.created_at,
      data.m_kategori_paket_soal.updated_at,
      0,
    );

    paketSoal = new PaketSoal(
      data.id,
      data.tahun,
      data.jenis_soal,
      data.judul_soal,
      data.petunjuk_soal,
      data.created_at,
      data.updated_at,
      0,
      soal,
      kategori_paket_soal,
    );

    return paketSoal;
  }
}
