import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useEffect } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import CountUp from "react-countup";
import DataTable from "react-data-table-component";
import {
  AiOutlineFileDone,
  FiUsers,
  GiProgression,
  MdClose,
  MdOutlineDone,
} from "react-icons/all";
import { BsPrinter } from "react-icons/bs";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { PesertaPertahun } from "../../../../../entities/Dashboard";
import IconPenggunaOnline from "../../../assets/svg/ic_pengguna_online.svg";
import IconPenggunaTerdaftar from "../../../assets/svg/ic_pengguna_terdaftar.svg";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import DashboardController, {
  useDashboardContext,
} from "../../../controller/admin/dashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

const GrafikTab: React.FC = () => {
  // Call State From Controller
  const { dashboard, isLoading } = useDashboardContext().state;

  // Example Grafik Jumlah Peserta Tahunan
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  let labels;

  if (dashboard != null) {
    labels = dashboard.peserta_pertahun.map(
      (item: PesertaPertahun) => item.tahun,
    );
  } else {
    labels = [];
  }

  const data = {
    labels,
    datasets: [
      {
        data: dashboard?.peserta_pertahun.map(
          (item: PesertaPertahun) => item.jumlah_peserta,
        ),
        backgroundColor: "rgba(207, 242, 229, 1)",
        borderRadius: 9999,
        barThickness: 10,
      },
    ],
  };

  // Example Grafik Jumlah Peserta Menurut Predikat
  const optionsPredikat = {
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
      },
    },
  };

  const dataPredikat = {
    labels: ["Sangat Kompeten", "Kompeten", "Cukup Kompeten", "Tidak Lulus"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          dashboard?.total_peserta_predikat_sangat_kompeten,
          dashboard?.total_peserta_predikat_kompeten,
          dashboard?.total_peserta_predikat_cukup_kompeten,
          dashboard?.total_peserta_predikat_tidak_lulus,
        ],
        backgroundColor: [
          "rgba(46, 129, 247, 1)",
          "rgba(13, 189, 127, 1)",
          "rgba(255, 184, 0, 1)",
          "rgba(255, 76, 97, 1)",
        ],
      },
    ],
  };

  // Example Grafik Jumlah Peserta Per Jenis Latihan
  const optionsJenisLatihan = {
    indexAxis: "y" as const,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
    },
    scales: {
      y: {
        stacked: true,
      },
    },
  };

  let labelsJenisLatihan;

  if (dashboard != null) {
    labelsJenisLatihan = dashboard.peserta_perjenis_pelatihan.map(
      (item) => item.jenis_pelatihan,
    );
  } else {
    labelsJenisLatihan = [];
  }

  const dataJenisLatihan = {
    labels: labelsJenisLatihan,
    datasets: [
      {
        label: "Lulus",
        data: dashboard?.peserta_perjenis_pelatihan.map(
          (item) => item.jumlah_peserta_lulus,
        ),
        backgroundColor: "rgba(46, 129, 247, 1)",
        borderRadius: 6,
        barThickness: 20,
      },
      {
        label: "Tidak Lulus",
        data: dashboard?.peserta_perjenis_pelatihan.map(
          (item) => item.jumlah_peserta_tidak_lulus,
        ),
        backgroundColor: "rgba(198, 221, 255, 1)",
        borderRadius: 6,
        barThickness: 20,
      },
    ],
  };

  // Example Grafik Jumlah Pengguna Aktif Per Hari
  const optionsJumlahPengguna = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  let labelsJumlahPengguna;

  if (dashboard != null) {
    dashboard.pengguna_aktif_perhari.sort((a, b) => a.id - b.id);
    labelsJumlahPengguna = dashboard.pengguna_aktif_perhari.map(
      (item) => item.tanggal,
    );
  } else {
    labelsJumlahPengguna = [];
  }

  const dataPengguna = {
    labels: labelsJumlahPengguna,
    datasets: [
      {
        data: dashboard?.pengguna_aktif_perhari.map(
          (item) => item.jumlah_user_online,
        ),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const formatNumberWithSeparator = (number) => {
    if (number) {
      const numberString = number.toString();

      const parts = numberString.split(".");

      let integerPart = parts[0];

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      const formattedNumber =
        parts.length > 1 ? integerPart + "." + parts[1] : integerPart;

      return formattedNumber;
    }

    return 0;
  };

  const totalUser = formatNumberWithSeparator(dashboard?.total_pengguna);

  const decimals = (data) => {
    if (!data) {
      return 0;
    }
    return data.toString().length > 3 ? 3 : 0;
  };

  const CardInfo = ({ title, color, value, icon }) => {
    const valueWithSeperator = formatNumberWithSeparator(value);
    return (
      <Box
        p={5}
        bg="gray.100"
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
      >
        <Flex direction="column" textAlign="center" align="center">
          <Icon as={icon} boxSize={10} color={color} />
          <Text
            mt={7}
            mb={5}
            fontSize={{ base: "14px", lg: 14, md: "12px", sm: "10px" }}
            fontWeight={700}
          >
            {title}
          </Text>
          {isLoading ? (
            <Spinner />
          ) : (
            <Text fontSize={24} fontWeight={700}>
              <CountUp
                end={dashboard != null ? valueWithSeperator : 0}
                decimals={decimals(value)}
              />
            </Text>
          )}
        </Flex>
      </Box>
    );
  };

  return (
    <Grid
      templateColumns={{
        lg: "repeat(3, 1fr)",
        md: "repeat(2, 1fr)",
        sm: "repeat(1, 1fr)",
      }}
      gap={3}
      mb={10}
    >
      <GridItem colSpan={{ lg: 2, md: 1 }}>
        <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
          <Text fontSize={14} fontWeight={700}>
            Pelatihan
          </Text>
          <Grid
            templateColumns={{
              lg: "repeat(3, 1fr)",
              md: "repeat(2, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
            gap={6}
            mt={5}
          >
            <CardInfo
              title="Jumlah Pelatihan"
              color="#2F80F7"
              value={dashboard?.total_pelatihan}
              icon={FiUsers}
            />
            <CardInfo
              title="Pelatihan On Progress"
              color="#FFC270"
              value={dashboard?.total_pelatihan_on_progress}
              icon={GiProgression}
            />
            <CardInfo
              title="Pelatihan Selesai"
              color="#07BD7F"
              value={dashboard?.total_pelatihan_finish}
              icon={AiOutlineFileDone}
            />
          </Grid>
        </Box>
      </GridItem>
      <GridItem>
        <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
          <Text fontSize={14} fontWeight={700}>
            Pengguna
          </Text>
          <Grid mt={5} gap={5}>
            <Grid templateColumns="repeat(2, 1fr)">
              <Image src={IconPenggunaTerdaftar} />
              <Box textAlign="right">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Text fontSize={24} fontWeight={700} color="blue.500">
                    <CountUp
                      end={dashboard != null ? totalUser : 0}
                      decimals={decimals(dashboard?.total_pengguna)}
                    />
                  </Text>
                )}
                <Text>Pengguna Terdaftar</Text>
              </Box>
            </Grid>

            <Divider size="md" />

            <Grid templateColumns="repeat(2, 1fr)">
              <Image src={IconPenggunaOnline} />
              <Box textAlign="right">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Text fontSize={24} fontWeight={700} color="blue.500">
                    <CountUp
                      end={
                        dashboard != null ? dashboard.total_pengguna_online : 0
                      }
                    />
                  </Text>
                )}
                <Text>Pengguna Online</Text>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </GridItem>
      <GridItem>
        <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
          <Text fontSize={14} fontWeight={700}>
            Jumlah Peserta Per Tahun
          </Text>
          <Box mt={5}>
            <Bar options={options} data={data} />
          </Box>
        </Box>
      </GridItem>
      <GridItem colSpan={{ lg: 2, md: 1 }}>
        <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
          <Text fontSize={14} fontWeight={700}>
            Peserta
          </Text>
          <Grid
            templateColumns={{
              lg: "repeat(3, 1fr)",
              md: "repeat(2, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
            gap={6}
            mt={5}
          >
            <CardInfo
              title="Jumlah Peserta"
              color="#9B51DF"
              value={dashboard?.total_peserta}
              icon={FiUsers}
            />
            <CardInfo
              title="Total ASN"
              color="#FFC270"
              value={dashboard?.total_asn}
              icon={FiUsers}
            />
            <CardInfo
              title="Total NON ASN"
              color="#2F80F7"
              value={dashboard?.total_peserta_lulus}
              icon={FiUsers}
            />
            <CardInfo
              title="Lulus"
              color="#36B449"
              value={dashboard?.total_peserta_lulus}
              icon={MdOutlineDone}
            />
            <CardInfo
              title="Tidak Lulus"
              color="#F67070"
              value={dashboard?.total_peserta_tidak_lulus}
              icon={MdClose}
            />
          </Grid>
        </Box>
      </GridItem>
      <GridItem colSpan={{ lg: 2, md: 1 }}>
        <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
          <Text fontSize={14} fontWeight={700}>
            Peserta Per Jenis Pelatihan
          </Text>
          <Box mt={5}>
            <Bar options={optionsJenisLatihan} data={dataJenisLatihan} />
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
          <Text fontSize={14} fontWeight={700}>
            Peserta Menurut Predikat
          </Text>
          <Box mt={5}>
            <Doughnut data={dataPredikat} options={optionsPredikat} />
          </Box>
        </Box>
      </GridItem>
      <GridItem colSpan={{ lg: 3, md: 1 }}>
        <Box
          bg="white"
          borderRadius="md"
          boxShadow="md"
          p={5}
          h="full"
          w="full"
        >
          <Text fontSize={14} fontWeight={700}>
            Pengguna Aktif Per Hari
          </Text>
          <Box mt={5}>
            <Line options={optionsJumlahPengguna} data={dataPengguna} />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

const RekapitulasiTab: React.FC = () => {
  const {
    getDashboardRekapitulasi,
    setPageRow,
    setTahun,
    downloadExcel,
    setPage,
  } = useDashboardContext();
  const {
    dataRekapitulasi,
    keyword,
    isLoading,
    isLoadingDownload,
    page,
    rows,
  } = useDashboardContext().state;

  const columns = [
    {
      name: "No.",
      selector: (row, index) => (page - 1) * rows + (index + 1),
      grow: 0,
    },
    {
      name: "Nama Pelatihan",
      selector: (row) => row.judul_pelatihan,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "JP",
      selector: (row) => row.jp,
      sortable: true,
      grow: 0,
    },
    {
      name: "Waktu Pelaksanaan",
      selector: (row) => {
        return (
          <p>
            {moment(row.waktu_mulai).format("DD-MM-YYYY")} sd <br />{" "}
            {moment(row.waktu_selesai).format("DD-MM-YYYY")}
          </p>
        );
      },
      sortable: true,
      grow: 2,
    },
    {
      name: (
        <p>
          Jumlah <br /> Peserta
        </p>
      ),
      selector: (row) => row.jumlah_peserta,
      sortable: true,
    },
    {
      name: (
        <p>
          Nilai <br /> Tertinggi
        </p>
      ),
      selector: (row) => row.nilai_tertinggi,
      sortable: true,
    },
    {
      name: (
        <p>
          Nilai <br /> Terendah
        </p>
      ),
      selector: (row) => row.nilai_terendah,
      sortable: true,
    },
    {
      name: (
        <p>
          Nilai <br /> Rata-Rata <br /> Keseluruhan
        </p>
      ),
      selector: (row) => row.nilai_rata_rata_keseluruhan,
      sortable: true,
      width: "150px",
      wrap: true,
    },

    {
      name: (
        <p>
          Nilai <br /> Rata-Rata <br /> Lulus
        </p>
      ),
      selector: (row) => row.nilai_rata_rata_lulus,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: <p>Status Pelatihan</p>,
      selector: (row) => row.update_status_pelatihan,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Predikat",
      cell: (row) => {
        return (
          <table>
            {row.predikat.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.variable}</td>
                  <td>:</td>
                  <td>{val.value}</td>
                </tr>
              );
            })}
          </table>
        );
      },
      sortable: true,
      width: "300px",
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getDashboardRekapitulasi(keyword);
  }, [page, rows]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPageRow(newPerPage);
  };

  const dataTahun: number[] = [];

  for (let i = 2020; i <= 2028; i++) {
    dataTahun.push(i);
  }

  let optionsTahun;

  if (dataTahun.length > 0) {
    optionsTahun = dataTahun.map((item) => ({ value: item, label: item }));
  } else {
    optionsTahun = [];
  }

  const CustomLoader = () => (
    <Box p={24}>
      <Spinner />
    </Box>
  );

  return (
    <>
      <Box bg="white" borderRadius="md" boxShadow="md" p={5} h="full">
        <Flex direction="row" justifyContent="end" mb={5}>
          {/* Filter Tahun */}
          <Select
            options={optionsTahun}
            onChange={(val: any) => setTahun(val.value)}
            placeholder="Pilih Tahun"
          />

          {/* Button */}
          <Button
            onClick={() => downloadExcel()}
            colorScheme="whatsapp"
            backgroundColor="GreenPrimary.500"
            marginLeft={4}
            isLoading={isLoadingDownload}
          >
            Download Excel <BsPrinter className="ml-3" />
          </Button>
        </Flex>
        <DataTable
          columns={columns}
          data={dataRekapitulasi}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={
            dataRekapitulasi.length > 0 ? dataRekapitulasi[0].jumlah_data : 0
          }
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          customStyles={customStyles}
          progressComponent={<CustomLoader />}
        />
      </Box>
    </>
  );
};

const NavigationTab: React.FC = () => {
  return (
    <>
      <Box>
        <Tabs variant="unstyled" isLazy>
          <TabList>
            <Tab
              _selected={{ color: "white", bg: "blue.500", fontWeight: 700 }}
              borderRadius="md"
              background="white"
              color="gray.500"
              mr={3}
            >
              Grafik
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "blue.500", fontWeight: 700 }}
              borderRadius="md"
              background="white"
              color="gray.500"
            >
              Rekapitulasi
            </Tab>
          </TabList>
          <TabPanels p={0} mt={5}>
            <TabPanel p={0}>
              <GrafikTab />
            </TabPanel>
            <TabPanel p={0}>
              <RekapitulasiTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

const WrapperMain: React.FC = () => {
  // Call Controller
  const { getDashboard } = useDashboardContext();

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <SidebarWithHeader title="Dashboard">
      <NavigationTab />
    </SidebarWithHeader>
  );
};

const AdminDashboard: React.FC = () => {
  return (
    <DashboardController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </DashboardController.Provider>
  );
};

export default AdminDashboard;
