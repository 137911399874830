import Axios, { AxiosInstance } from "axios";
import { DependencyContainer } from "tsyringe";
import { Endpoints } from "../misc/Endpoints";
import ApiService from "../services/ApiService";

export class RootModule {
  public static init(container: DependencyContainer) {
    /**
     * [register description]
     *
     * @param   {[type]}  Endpoints  [Endpoints description]
     *
     * @return  {[type]}             [return description]
     */
    container.register<Endpoints>(Endpoints, {
      useFactory: (_) => {
        return new Endpoints(`${process.env.REACT_APP_BE_PROD_BASE_URL}`);
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ApiService  [ApiService description]
     *
     * @return  {[type]}              [return description]
     */
    container.register<ApiService>(ApiService, {
      useFactory: (d) => {
        return new ApiService(d.resolve("AxiosInstance"));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  AxiosInstance  [AxiosInstance description]
     *
     * @return  {[type]}                 [return description]
     */
    container.register<AxiosInstance>("AxiosInstance", {
      useFactory: (d) => {
        const client = Axios.create({
          baseURL: d.resolve(Endpoints).getBaseUrl(),
        });

        client.interceptors.response.use((response: any) => {
          return response;
        });

        client.interceptors.request.use(async (request: any) => {
          return request;
        });

        return client;
      },
    });
  }
}
