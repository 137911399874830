/* eslint-disable eqeqeq */
import { AxiosResponse } from "axios";
import { GetLocalStorage } from "../../app/misc/UseLocalStorage";
import { Kecamatan } from "../../entities/Kecamatan";

export class KecamatanDataMapper {
  public convertKecamatanDataFromApi(result: AxiosResponse<any>): Kecamatan[] {
    const { data } = result.data;

    const kecamatan: Kecamatan[] = [];

    data.map((u: any) => {
      return kecamatan.push(
        new Kecamatan(
          u.id,
          u.kabupaten_id,
          u.nama_kecamatan,
          u.created_at,
          u.updated_at,
          GetLocalStorage({ name: "level" }) == "superadmin"
            ? result.data.pagination.total_rows
            : "",
        ),
      );
    });

    return kecamatan;
  }
}
