import { injectable } from "tsyringe";
import { ProvinsiApiRepository } from "../../app/repository/api/ProvinsiApiRepository";
import { Provinsi } from "../../entities/Provinsi";

@injectable()
export class ProvinsiPresenter {
  private repository: ProvinsiApiRepository;
  constructor(repository: ProvinsiApiRepository) {
    this.repository = repository;
  }

  public getAllProvinsi(
    page: number,
    rows: number,
    keyword: string,
  ): Promise<Provinsi[]> {
    return this.repository.getAllProvinsi(page, rows, keyword);
  }

  public createProvinsi(nama_provinsi: string): Promise<any> {
    return this.repository.createProvinsi(nama_provinsi);
  }

  public updateProvinsi(id: number, nama_provinsi: string): Promise<any> {
    return this.repository.updateProvinsi(id, nama_provinsi);
  }

  public deleteProvinsi(id: number): Promise<any> {
    return this.repository.deleteProvinsi(id);
  }
}
