import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { GetAuthData } from "../../../misc/GetAuthData";
import LogoIcourse from "../../assets/svg/logo-icourse.svg";
import MenuBottomNavbar from "./MobileBottomNavbar";
import UserProfile from "./UserProfile";

interface WrapperNavigateProps {
  path: string;
  isMenu?: boolean;
  children: React.ReactNode;
}

const WrapperNavigate = ({
  path,
  isMenu = false,
  children,
}: WrapperNavigateProps) => {
  const history = useHistory();

  const handleNavigate = (path: string) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
    <Box
      _hover={{ cursor: "pointer" }}
      display="flex"
      onClick={() => handleNavigate(path)}
      alignItems="center"
    >
      {isMenu ? (
        <Text
          fontSize="md"
          display="inline-block"
          _after={{
            content: `''`,
            display: "block",
            width: "0",
            height: "2px",
            background: "BluePrimary.900",
          }}
          _hover={{
            "::after": {
              width: "100%",
              transition: "width",
              transitionDuration: ".2s",
            },
          }}
        >
          {children}
        </Text>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};

const NewNavbar: React.FC = () => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");

  const locationPage = window.location.pathname;

  const logoNavbar = localStorage.getItem("logoNavbar") ?? "";

  const searchPelatihan = (e) => {
    e.preventDefault();
    localStorage.setItem("keywordPelatihan", keyword);
    history.push("/pelatihan");
  };

  const handleNavigate = (path: string) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* large screen */}
      <Box bg="white" display={{ base: "none", md: "block" }}>
        <Flex
          justify="space-between"
          align="center"
          h={20}
          px={{ base: 8, md: 12 }}
        >
          <Flex align="center" className="gap-4">
            {GetAuthData() ? (
              <UserProfile />
            ) : (
              <Button
                type="button"
                color="white"
                bg="BluePrimary.900"
                _hover={{ bg: "BluePrimary.900" }}
                _active={{ bg: "BluePrimary.900" }}
                fontSize="sm"
                px={4}
                rounded="lg"
                onClick={() => handleNavigate("/login")}
                display={locationPage === "/login" ? "none" : "block"}
              >
                Masuk
              </Button>
            )}
            <WrapperNavigate path="/pelatihan" isMenu={true}>
              Pelatihan
            </WrapperNavigate>
            <WrapperNavigate path="/pengetahuan" isMenu={true}>
              Pengetahuan
            </WrapperNavigate>
          </Flex>
          {locationPage === "/" && GetAuthData() === null && (
            <Flex position="relative">
              <Box
                position="absolute"
                insetY={0}
                left={0}
                pl={3}
                display="flex"
                alignItems="center"
              >
                <Icon as={AiOutlineSearch} h={5} w={5} />
              </Box>
              <form onSubmit={(e) => searchPelatihan(e)}>
                <Input
                  type="text"
                  width="300px"
                  fontSize="md"
                  variant="outline"
                  borderColor="BluePrimary.900"
                  borderRadius="2xl"
                  _hover={{ borderColor: "BluePrimary.900" }}
                  _focus={{
                    ring: "1",
                    ringColor: "BluePrimary.900",
                    borderColor: "BluePrimary.900",
                  }}
                  height={10}
                  pl={10}
                  placeholder="Cari Pelatihan"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </form>
            </Flex>
          )}
          <Flex gap={4} align="center">
            <WrapperNavigate path="/">
              <Image src={logoNavbar} height={10} />
            </WrapperNavigate>
          </Flex>
        </Flex>
      </Box>

      {/* small screen */}
      {GetAuthData() ? (
        <MenuBottomNavbar />
      ) : (
        <Box
          display={{ base: "block", md: "none" }}
          py={2}
          h={showMenu ? "100vh" : "auto"}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            py={2}
            px={8}
          >
            <WrapperNavigate path="/">
              <Image src={LogoIcourse} width={200} height={70} />
            </WrapperNavigate>
            <Icon
              as={showMenu ? IoMdClose : GiHamburgerMenu}
              boxSize={6}
              onClick={() => setShowMenu(!showMenu)}
              _hover={{ cursor: "pointer" }}
            />
          </Flex>
          {showMenu ? (
            <Box
              py={2}
              display="flex"
              flexDirection="column"
              h="full"
              position="relative"
            >
              <Box>
                <WrapperNavigate path="/pelatihan">
                  <Text
                    color="black"
                    fontSize="md"
                    _hover={{ bg: "BluePrimary.900", color: "white" }}
                    py={4}
                    px={8}
                    w="full"
                  >
                    Pelatihan
                  </Text>
                </WrapperNavigate>
                <WrapperNavigate path="/pengetahuan">
                  <Text
                    color="black"
                    fontSize="md"
                    _hover={{ bg: "BluePrimary.900", color: "white" }}
                    py={4}
                    px={8}
                    w="full"
                  >
                    Pengetahuan
                  </Text>
                </WrapperNavigate>
              </Box>
              <Box w="full" px={8} mb={20} position="absolute" bottom={14}>
                <Divider mb={12} />
                <Box my={4}>
                  <Text fontSize="xl" fontWeight="semibold">
                    Sudah Punya Akun?
                  </Text>
                  <Text fontSize="xs" mt={2}>
                    Masuk atau daftar untuk akses ratusan video pelatihan
                  </Text>
                </Box>
                <Button
                  w="full"
                  color="white"
                  bg="BluePrimary.900"
                  _hover={{ bg: "BluePrimary.900" }}
                  _active={{ bg: "BluePrimary.900" }}
                  variant="outline"
                  onClick={() => handleNavigate("/login")}
                >
                  Masuk
                </Button>
                <Box position="relative" py="10">
                  <Divider />
                  <AbsoluteCenter bg="white" px="4">
                    Atau
                  </AbsoluteCenter>
                </Box>
                <Button
                  w="full"
                  color="BluePrimary.900"
                  bg="white"
                  borderColor="BluePrimary.900"
                  _hover={{ bg: "BluePrimary.900", color: "white" }}
                  _active={{ bg: "BluePrimary.900", color: "white" }}
                  variant="outline"
                  onClick={() => handleNavigate("/register")}
                >
                  Daftar
                </Button>
              </Box>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default NewNavbar;
