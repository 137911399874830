/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MateriPresenter } from "../../../../../../data/presenters/MateriPresenter";
import "react-toastify/dist/ReactToastify.css";
import { Materi } from "../../../../../../entities/Materi";
import { JenisPelatihan } from "../../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../../data/presenters/JenisPelatihanPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  tahun: string;
  kategori_soal: string;
  jenis_soal: string;
  totalMateri: number;
  materi: Materi[];
  jenis_materi_id: string;
  jenis_materi: string[];
  jenisPelatihan: JenisPelatihan[];
  checkVideo: boolean;
  checkPDF: boolean;
  checkPPT: boolean;
  checkAudio: boolean;
  checkAll: boolean;
}

interface InitialState {
  state: IState;
  getMateri: Function;
  setPage: Function;
  searchMateri: Function;
  getJenisPelatihan: Function;
  filterJenisPelatihan: Function;
  filterJenisMateri: Function;
  filterSemuaJenisMateri: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalMateri: 1,
    materi: [],
    jenis_materi_id: "",
    jenis_materi: [],
    jenisPelatihan: [],
    checkVideo: false,
    checkPDF: false,
    checkPPT: false,
    checkAudio: false,
    checkAll: false,
  },
  getMateri: () => {},
  searchMateri: () => {},
  setPage: () => {},
  getJenisPelatihan: () => {},
  filterJenisPelatihan: () => {},
  filterJenisMateri: () => {},
  filterSemuaJenisMateri: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenMateriProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    tahun: "",
    kategori_soal: "",
    jenis_soal: "",
    totalMateri: 0,
    materi: [],
    jenis_materi_id: "",
    jenis_materi: [],
    jenisPelatihan: [],
    checkVideo: false,
    checkPDF: false,
    checkPPT: false,
    checkAudio: false,
    checkAll: false,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setLoading(true);

    getMateri(
      value + 1,
      state.keyword,
      state.jenis_materi_id,
      state.jenis_materi,
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getMateri = async (
    pagee: number,
    keyword: string,
    jenis_materi_id: string,
    jenis_materi: string[],
  ) => {
    setLoading(true);

    try {
      const materiPresenter = container.resolve(MateriPresenter);
      const getAllMateri = await materiPresenter.getAllMateri(
        pagee,
        12,
        keyword,
        jenis_materi_id,
        jenis_materi,
      );

      const data = getAllMateri;

      setJumlahPage(data[0].jumlahData / 12);

      setState((prevstate) => ({
        ...prevstate,
        materi: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchMateri = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);

    getMateri(1, value, state.jenis_materi_id, state.jenis_materi);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
      page: 1,
    }));
  };

  const getJenisPelatihan = async () => {
    try {
      const jenisPelatihan = container.resolve(JenisPelatihanPresenter);

      const get = await jenisPelatihan.getAllJenisPelatihan(
        1,
        10000000,
        "",
        "",
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const filterJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getMateri(1, state.keyword, value, state.jenis_materi);
    setState((prevstate) => ({
      ...prevstate,
      jenis_materi_id: value,
      page: 1,
    }));
  };

  const filterJenisMateri = (value: boolean, type: string) => {
    if (type == "video") {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: value,
      }));
    } else if (type == "pdf") {
      setState((prevstate) => ({
        ...prevstate,
        checkPDF: value,
      }));
    } else if (type == "ppt") {
      setState((prevstate) => ({
        ...prevstate,
        checkPPT: value,
      }));
    } else if (type == "audio") {
      setState((prevstate) => ({
        ...prevstate,
        checkAudio: value,
      }));
    }
    setState((prevstate) => ({
      ...prevstate,
      materi: [],
      jumlahPage: 0,
    }));
    const jns_materi = state.jenis_materi;
    if (value) {
      jns_materi.push(`${type}`);
    } else {
      const index = jns_materi.indexOf(type);
      delete jns_materi[index];
    }

    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, jns_materi);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      jenis_materi: jns_materi,
    }));
  };

  const filterSemuaJenisMateri = (value: boolean) => {
    let jns_materi: string[];
    if (value) {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: true,
        checkPDF: true,
        checkPPT: true,
        checkAudio: true,
      }));
      jns_materi = ["video", "pdf", "ppt", "audio"];
    } else {
      setState((prevstate) => ({
        ...prevstate,
        checkVideo: false,
        checkPDF: false,
        checkPPT: false,
        checkAudio: false,
      }));
      jns_materi = [];
    }
    setLoading(true);
    getMateri(1, state.keyword, state.jenis_materi_id, jns_materi);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      jenis_materi: jns_materi,
      checkAll: value,
    }));
  };

  return (
    <ManajemenMateriProvider
      value={{
        state,
        getMateri,
        setPage,
        searchMateri,
        getJenisPelatihan,
        filterJenisPelatihan,
        filterJenisMateri,
        filterSemuaJenisMateri,
      }}
    >
      {children}
    </ManajemenMateriProvider>
  );
};

export const useManajemenMateriContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenMateriContext,
  Provider,
};
