import React, { createContext, useState, useContext } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface PengetahuanItem {
  id: number;
  title: string;
  description: string;
  category: string;
  path_file: string;
  total_view: number | null;
  review_score: number | null;
  status_publish: 1;
  created_at: string;
  updated_at: string;
  thumbnail: string;
}

interface IState {
  state: {
    url: string;
    pengetahuanItem: PengetahuanItem | null;
    loadingData: boolean;
  };
  fetchData: Function;
}

const initialState: IState = {
  state: {
    url: "public/pengetahuan/",
    pengetahuanItem: null,
    loadingData: false,
  },
  fetchData: () => {},
};

const Context = createContext<IState>(initialState);
const { Provider: PengetahuanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const { url } = state.state;

  const setPengetahuanDetailState = (
    key: keyof IState["state"],
    value: any,
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async (id: string) => {
    setPengetahuanDetailState("loadingData", true);
    try {
      const response = await axiosInstance.get(url + id);

      setPengetahuanDetailState("pengetahuanItem", response.data.data);
      setPengetahuanDetailState("loadingData", false);
    } catch (error) {
      setPengetahuanDetailState("loadingData", false);
      throw error;
    }
  };

  return (
    <PengetahuanDetailProvider value={{ state: state.state, fetchData }}>
      {children}
    </PengetahuanDetailProvider>
  );
};

export const usePengetahuanDetailContext = () => useContext(Context);

const PengetahuanDetailContext = {
  usePengetahuanDetailContext,
  Provider,
};

export default PengetahuanDetailContext;
