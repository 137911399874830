/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { AuthPresenter } from "../../../../../data/presenters/AuthPresenter";
import { UsersPresenter } from "../../../../../data/presenters/UsersPresenter";
import history from "../../../../../history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBase64 } from "../../../../misc/GetBase64";
import { useIsAuthenticated } from "react-auth-kit";

interface IState {
  isLoading: boolean;
  isLoadingPhoto: boolean;
  isLoadingCheckNip: boolean;
  nip: string;
  nik: string;
  name: string;
  email: string;
  jenis: number;
  password: string;
  pathPhoto: any;
  imagePhoto: any;
  validateSimpeg: boolean;
  confirmPassword: string;
  validationRobot: string;
  error: boolean;
  msgError: string;
  errorMessage: any;
}

interface InitialState {
  state: IState;
  handleDoRegister: Function;
  changeNip: Function;
  changePassword: Function;
  changeConfirmPassword: Function;
  checkNip: Function;
  checkBox: Function;
  checkSession: Function;
  handleUploadImage: Function;
  changeNik: Function;
  changeName: Function;
  changeEmail: Function;
  changeJenis: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingPhoto: false,
    isLoadingCheckNip: false,
    validateSimpeg: false,
    pathPhoto: null,
    imagePhoto: null,
    password: "",
    confirmPassword: "",
    nip: "",
    nik: "",
    name: "",
    email: "",
    jenis: 0,
    validationRobot: "",
    error: false,
    msgError: "",
    errorMessage: [],
  },
  handleDoRegister: () => {},
  changeNip: () => {},
  changePassword: () => {},
  changeConfirmPassword: () => {},
  checkNip: () => {},
  checkBox: () => {},
  checkSession: () => {},
  handleUploadImage: () => {},
  changeNik: () => {},
  changeName: () => {},
  changeEmail: () => {},
  changeJenis: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: RegisterProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingPhoto: false,
    isLoadingCheckNip: false,
    validateSimpeg: false,
    password: "",
    pathPhoto: null,
    imagePhoto: null,
    confirmPassword: "",
    nip: "",
    nik: "",
    name: "",
    email: "",
    jenis: 0,
    validationRobot: "",
    error: false,
    msgError: "",
    errorMessage: [],
  });

  // use is authenticate
  const isAuthenticated = useIsAuthenticated();

  /**
   *
   * @param value
   * @returns
   */
  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const setLoadingPhoto = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingPhoto: value,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const setLoadingCheckNip = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingCheckNip: value,
    }));
    return;
  };

  /**
   *
   * @param nip
   * @returns
   */
  const changeNip = (nip: string) => {
    setState((prevstate) => ({
      ...prevstate,
      nip: nip,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const changeNik = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      nik: value,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const changeName = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      name: value,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const changeEmail = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      email: value,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const changeJenis = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jenis: value,
      nik: "",
      nip: "",
      name: "",
      email: "",
    }));
    return;
  };

  /**
   *
   * @param password
   * @returns
   */
  const changePassword = (password: string) => {
    setState((prevstate) => ({
      ...prevstate,
      password: password,
    }));
    return;
  };

  /**
   *
   * @param confirmPassword
   * @returns
   */
  const changeConfirmPassword = (confirmPassword: string) => {
    setState((prevstate) => ({
      ...prevstate,
      confirmPassword: confirmPassword,
    }));
    return;
  };

  /**
   *
   * @param value
   * @returns
   */
  const checkBox = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      validationRobot: value,
    }));
    return;
  };

  /**
   *
   * @returns
   */
  const handleDoRegister = async () => {
    setState((prevstate) => ({
      ...prevstate,
      errorMessage: [],
      msgError: "",
    }));

    setLoading(true);

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!state.email || regex.test(state.email) === false) {
      toast.error(
        "Email yang di masukan tidak valid! Mohon cek kembali email anda.",
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        },
      );
      setLoading(false);
      return;
    }

    if (state.confirmPassword === "") {
      toast.error("Konfirmasi password harus di isi.", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.confirmPassword !== state.password) {
      toast.error(
        "Konfirmasi password yang anda masukkan salah, silahkan ulang kembali.",
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        },
      );
      setLoading(false);
      return;
    }

    if (state.validationRobot === "") {
      toast.error(
        "Mohon untuk klik tombol checkbox validasi terlebih dahulu!",
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        },
      );
      setLoading(false);
      return;
    }

    if (state.jenis == 1 && state.validateSimpeg === false) {
      toast.error(
        "Mohon untuk masukkan nip dan klik tombol cari untuk mencari user anda!",
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        },
      );
      setLoading(false);
      return;
    }

    try {
      const authPresenter = container.resolve(AuthPresenter);

      if (state.jenis == 1) {
        await authPresenter.doRegisterPresenter(
          state.nip,
          "",
          state.name,
          state.email,
          state.jenis,
          state.password,
          state.confirmPassword,
          state.pathPhoto != null ? state.pathPhoto : "",
          state.validationRobot,
        );
      } else {
        await authPresenter.doRegisterPresenter(
          state.nik,
          state.nik,
          state.name,
          state.email,
          state.jenis,
          state.password,
          state.confirmPassword,
          state.pathPhoto != null ? state.pathPhoto : "",
          state.validationRobot,
        );
      }

      setLoading(false);

      if (state.jenis == 1) {
        toast.success(
          "Selamat Anda Berhasil Registrasi Akun PINTAR Gunakan NIP Anda, untuk login Aplikasi",
          {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          },
        );
      }

      if (state.jenis == 2) {
        toast.success(
          "Selamat Anda Berhasil Registrasi Akun PINTAR Gunakan NIK Anda, untuk login Aplikasi",
          {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          },
        );
      }

      history.replace(`/login`);

      window.location.reload();
    } catch (error: any) {
      if (error.response.data.code == 422) {
        setState((prevstate) => ({
          ...prevstate,
          errorMessage: [error.response.data.errors],
        }));
      } else if (error.response.data.code == 404) {
        setState((prevstate) => ({
          ...prevstate,
          msgError: error.response.data.message,
        }));
      } else {
        setState((prevstate) => ({
          ...prevstate,
          msgError:
            "Terjadi kesalahan pada sistem. Kami akan memperbaikinya segera.",
        }));
      }
      console.log("error register:", error);
      setLoading(false);
    }
  };

  /**
   *
   * @returns
   */
  const checkNip = async () => {
    setLoadingCheckNip(true);

    if (state.nip === "") {
      toast.error("Anda belum memasukkan NIP!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingCheckNip(false);
      return;
    }

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const data = await usersPresenter.checkNip(state.nip);

      if (data.data.code === 200) {
        setLoadingCheckNip(false);
        setState((prevstate) => ({
          ...prevstate,
          validateSimpeg: true,
          name: data.data.data.NAMA_LENGKAP,
          email: data.data.data.EMAIL,
        }));
        toast.success("User Anda Di Temukan", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
      }
    } catch (error: any) {
      const dataError = error.response.data;
      if (dataError.code === 404) {
        toast.error(`Data NIP Tidak Ditemukan`, {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      } else {
        toast.error(`Terjadi error pada server : ${dataError.message}`, {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingCheckNip(false);
    }
  };

  /**
   *
   * @returns
   */
  const checkSession = async () => {
    if (isAuthenticated() !== false) {
      history.replace(`/`);
      window.location.reload();
    } else {
      history.replace("/login");
      window.location.reload();
      toast.error("Session anda telah habis, silahkan login kembali!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  /**
   *
   * @param file
   */
  const handleUploadImage = (file: File) => {
    setLoadingPhoto(true);

    setState((prevstate) => ({
      ...prevstate,
      pathPhoto: file,
    }));

    try {
      const filedata = file;
      getBase64(filedata).then((base64) => {
        setState((prevstate) => ({
          ...prevstate,
          imagePhoto: base64,
        }));
      });

      setLoadingPhoto(false);
    } catch (error: any) {
      console.log("error upload photo: ", error);
    }
  };

  return (
    <RegisterProvider
      value={{
        state,
        handleDoRegister,
        changeNip,
        changePassword,
        changeConfirmPassword,
        checkSession,
        checkNip,
        checkBox,
        handleUploadImage,
        changeNik,
        changeName,
        changeJenis,
        changeEmail,
      }}
    >
      {children}
    </RegisterProvider>
  );
};

export const useRegisterContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useRegisterContext,
  Provider,
};
