import React from "react";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { HelpDeskBantuanPublicPresenter } from "../../../../../data/presenters/HelpDeskBantuanPublicPresenter";

interface IState {
  isLoading: boolean;
  pelatihan_diikuti: string;
  path_pendukung: any;
  confirmation_code: string;
  error: boolean;
}

interface InitialState {
  state: IState;
  setPelatihanDiikuti: Function;
  setPathPendukung: Function;
  setConfirmationCode: Function;
  storeHelpDeskBantuan: Function;
}

const initialState = {
  state: {
    isLoading: false,
    pelatihan_diikuti: "",
    path_pendukung: null,
    confirmation_code: "",
    error: false,
  },
  setPelatihanDiikuti: () => {},
  setPathPendukung: () => {},
  setConfirmationCode: () => {},
  storeHelpDeskBantuan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: BantuanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    pelatihan_diikuti: "",
    path_pendukung: null,
    confirmation_code: "",
    error: false,
  });

  /**
   *
   * @param value
   */
  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  /**
   *
   * @param value
   */
  const setPelatihanDiikuti = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihan_diikuti: value,
    }));
  };

  /**
   *
   * @param value
   */
  const setConfirmationCode = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      confirmation_code: value,
    }));
    return;
  };

  /**
   *
   * @param value
   */
  const setPathPendukung = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_pendukung: value[0],
    }));
  };

  /**
   *
   * @param nama
   * @param email
   * @param subject
   * @param asal_kantor
   * @param nip
   * @param pesan
   * @returns
   */
  const storeHelpDeskBantuan = async (
    nama: string,
    email: string,
    subject: string,
    asal_kantor: string,
    nip: string,
    pesan: string,
  ) => {
    setLoading(true);

    if (state.confirmation_code == null) {
      toast.error("Anda harus klik checkbox recaptcha untuk memvalidasi", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    try {
      const helpDeskBantuan = container.resolve(HelpDeskBantuanPublicPresenter);
      await helpDeskBantuan.storeHelpDeskBantuan(
        nama,
        email,
        subject,
        asal_kantor,
        nip,
        state.pelatihan_diikuti,
        pesan,
        state.path_pendukung,
        state.confirmation_code,
      );

      toast.success("Berhasil Mengirim Pesan", {
        style: {
          boxShadow: "0px 1px 6px #019166",
        },
      });

      window.location.reload();
    } catch (error: any) {
      console.log("error store bantuan:", error);
      setLoading(false);
    }
  };

  return (
    <BantuanProvider
      value={{
        state,
        storeHelpDeskBantuan,
        setPathPendukung,
        setPelatihanDiikuti,
        setConfirmationCode,
      }}
    >
      {children}
    </BantuanProvider>
  );
};

export const useBantuanContext = () => React.useContext(Context);

export default {
  useBantuanContext,
  Provider,
};
