import { Box, Image, Link, Skeleton } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import { useSliderBannerPublicContext } from "../../../controller/public/slider_banner";

/**
 * Renders a Hero Carousel component for desktop.
 *
 * @returns {React.FC} The HeroCarousel component.
 * @author Muhammad Farras Jibran
 */

const settingsReactSlick = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "240px",
  slidesToShow: 1,
  speed: 1500,
  arrows: false,
  autoplay: true,
  dots: true,
};

const HeroCarouselDesktop: React.FC = () => {
  const { sliderBannerData: data, loadingData } =
    useSliderBannerPublicContext().state;

  if (loadingData) {
    return (
      <Box display="flex" justifyContent="center" my={8}>
        <Skeleton height="350px" width="80%" />
      </Box>
    );
  }

  if (data.length === 1) {
    return (
      <Box w="full" bg="BlueSage" display="flex" justifyContent="center" py={8}>
        <Image src={data[0].file} h="50vh" borderRadius="lg" />
      </Box>
    );
  }

  return (
    <Box
      bg="BlueSage"
      display="flex"
      justifyContent="center"
      py="10"
      flexDirection="column"
    >
      <Box w="full" className="slider-container">
        <Slider {...settingsReactSlick}>
          {data.map((item) =>
            item.link ? (
              <Link
                href={item.link}
                isExternal
                position="relative"
                key={item.id}
                px={2}
              >
                <Image
                  fallback={<Skeleton h="full" w="full" />}
                  borderRadius={"xl"}
                  src={item.file}
                  alt={`Hero ${item.title}`}
                  w="full"
                  objectFit="cover"
                  margin="auto"
                />
              </Link>
            ) : (
              <Box position="relative" px={2}>
                <Image
                  fallback={<Skeleton h="full" w="full" />}
                  borderRadius={"xl"}
                  src={item.file}
                  alt={`Hero ${item.title}`}
                  w="full"
                  objectFit="cover"
                  margin="auto"
                />
              </Box>
            ),
          )}
        </Slider>
      </Box>
    </Box>
  );
};

export default HeroCarouselDesktop;
