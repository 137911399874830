/* eslint-disable */
import { AxiosInstance, AxiosResponse, Method } from "axios";
import qs from "qs";
import { injectable } from "tsyringe";
import { GetLocalStorage } from "../misc/UseLocalStorage";

@injectable()
export default class ApiService {
  public client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async invoke(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = {},
    headers: Map<string, string> = new Map(),
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] =
      "application/x-www-form-urlencoded";
    // set token
    if (GetLocalStorage({ name: "tokenAuth" }) !== undefined) {
      this.client.defaults.headers["Authorization"] = GetLocalStorage({
        name: "tokenAuth",
      });
    }
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });
    return await this.client.request({
      url,
      params,
      paramsSerializer: (par) => qs.stringify(par, { encode: false }),
      data: payload,
      method,
    });
  }
}
