import { Box, Text } from "@chakra-ui/react";
import parse from "html-react-parser";
import React from "react";

/**
 * ItemOpsi is a component that displays a single option for a question.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.jawabanBenar - Indicates whether the option is the correct answer.
 * @param {string} props.opsi - The text of the option.
 * @param {string} props.jawaban - The explanation or content of the option.
 * @returns {JSX.Element} The rendered ItemOpsi component.
 * @author Muhammad Farras Jibran
 */

const ItemOpsi = ({
  jawabanBenar,
  opsi,
  jawaban,
}: {
  jawabanBenar: boolean;
  opsi: string;
  jawaban: string;
}) => {
  return (
    <Box
      className="flex relative px-3 py-3 mb-5 rounded-md"
      bg={jawabanBenar ? "#EFFFF9" : "#F3F3F3"}
    >
      <Text
        as="a"
        px={2}
        py={1}
        textTransform="uppercase"
        fontWeight="semibold"
        rounded="md"
        borderWidth="1px"
        borderColor={jawabanBenar ? "#0DBD7F" : "#4F4F4F"}
        color={jawabanBenar ? "#0DBD7F" : "#4F4F4F"}
        height="25px"
      >
        {opsi}
      </Text>
      <Text
        as="a"
        px={2}
        py={1}
        textTransform="uppercase"
        fontWeight="normal"
        rounded="md"
        borderWidth="1px"
        borderColor={jawabanBenar ? "#0DBD7F" : "#4F4F4F"}
        color={jawabanBenar ? "#0DBD7F" : "#4F4F4F"}
        height="25px"
        ml={3}
      >
        Jawaban{jawabanBenar ? "Benar" : "Salah"}
      </Text>
      <Text
        as="p"
        px={2}
        py={1}
        fontWeight="normal"
        color={jawabanBenar ? "#0DBD7F" : "#4F4F4F"}
        ml={3}
      >
        {parse(jawaban)}
      </Text>
    </Box>
  );
};

export default ItemOpsi;
