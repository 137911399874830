import { injectable } from "tsyringe";
import { LogLatihanDataApiRepository } from "../../app/repository/api/LogLatihanApiRepository";
import { LogLatihan } from "../../entities/LogLatihan";

@injectable()
export class LogLatihanPresenter {
  private repository: LogLatihanDataApiRepository;
  constructor(repository: LogLatihanDataApiRepository) {
    this.repository = repository;
  }

  public getLogLatihan(
    page: number,
    rows: number,
    latihan_id: number,
  ): Promise<LogLatihan[]> {
    return this.repository.getLogLatihan(page, rows, latihan_id);
  }
}
