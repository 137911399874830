export class Materi {
  id: number;
  path_thumbnail: string;
  judul_materi: string;
  uraian_deskripsi: string;
  kompetensi: string;
  jam_pelatihan: string;
  status_konsultasi: number;
  widyaiswara: number;
  status_publish: number;
  created_at: string;
  updated_at: string;
  views: number;
  jumlahData: number;
  tanggal_pembuatan: string;
  penyusun: string;
  jenis_pelatihan_id: number;
  rating: number;
  jenis_pelatihan: string;
  widyaiswara_assigned: Widyaiswara[];
  count_reviewer: number;
  count_review_excellent: number;
  count_review_verry_good: number;
  count_review_average: number;
  count_review_poor: number;
  count_review_terrible: number;

  constructor(
    id: number,
    path_thumbnail: string,
    judul_materi: string,
    uraian_deskripsi: string,
    kompetensi: string,
    jam_pelatihan: string,
    status_konsultasi: number,
    widyaiswara: number,
    status_publish: number,
    created_at: string,
    updated_at: string,
    views: number,
    jumlahData: number,
    tanggal_pembuatan: string,
    penyusun: string,
    jenis_pelatihan_id: number,
    rating: number,
    jenis_pelatihan: string,
    widyaiswara_assigned: Widyaiswara[],
    count_reviewer: number,
    count_review_excellent: number,
    count_review_verry_good: number,
    count_review_average: number,
    count_review_poor: number,
    count_review_terrible: number,
  ) {
    this.id = id;
    this.path_thumbnail = path_thumbnail;
    this.judul_materi = judul_materi;
    this.uraian_deskripsi = uraian_deskripsi;
    this.kompetensi = kompetensi;
    this.jam_pelatihan = jam_pelatihan;
    this.status_konsultasi = status_konsultasi;
    this.widyaiswara = widyaiswara;
    this.status_publish = status_publish;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.views = views;
    this.jumlahData = jumlahData;
    this.tanggal_pembuatan = tanggal_pembuatan;
    this.penyusun = penyusun;
    this.jenis_pelatihan_id = jenis_pelatihan_id;
    this.rating = rating;
    this.jenis_pelatihan = jenis_pelatihan;
    this.widyaiswara_assigned = widyaiswara_assigned;
    this.count_reviewer = count_reviewer;
    this.count_review_excellent = count_review_excellent;
    this.count_review_verry_good = count_review_verry_good;
    this.count_review_average = count_review_average;
    this.count_review_poor = count_review_poor;
    this.count_review_terrible = count_review_terrible;
  }
}

export class Widyaiswara {
  name: string;
  path_foto: string;

  constructor(name: string, path_foto: string) {
    this.name = name;
    this.path_foto = path_foto;
  }
}
