import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Img,
  Input,
  InputGroup,
  Select,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { FaSlidersH } from "react-icons/fa";
import { Else, If, Then } from "react-if";
import { Placement } from "react-joyride";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";
import LogoPintar from "../../../assets/png/logo_pintar_black.png";
import PengetahuanBackground from "../../../assets/webp/bg_pengetahuan.webp";
import PengetahuanBackgroundMobile from "../../../assets/webp/bg_pengetahuan_mobile.webp";
import CardItem from "../../../components/global/Card/CardItem";
import JoyrideComponent from "../../../components/global/Joyride";
import Pagination from "../../../components/Pagination";
import { Wrapper } from "../../../components/Wrapper";
import PengetahuanController, {
  usePengetahuanContext,
} from "../../../controller/pengetahuan/index";

/**
 * Component for displaying the hero section of the knowledge center page.
 * @returns {JSX.Element} The HeroPelatihan component.
 * @author Muhammad Farras Jibran
 */

const HeroPengetahuan: React.FC = () => {
  const { setState, fetchData } = usePengetahuanContext();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const onSubmitSearch = (e) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <Box
      backgroundImage={{
        base: `url(${PengetahuanBackgroundMobile})`,
        md: `url(${PengetahuanBackground})`,
      }}
      h={{ base: "230px", sm: "350px", md: "52vh" }}
      w="auto"
      backgroundSize="cover"
      display="flex"
      justifyContent={{ base: "center", md: "start" }}
      alignItems="center"
    >
      <Flex
        justifyContent="center"
        alignItems={{ base: "center", md: "start" }}
        flexDirection="column"
        ml={{ base: 0, md: "8rem" }}
        mt={{ base: "-50px", md: "-2rem" }}
        w={{ base: "90%", md: "auto" }}
      >
        {isMobile ? null : <Img src={LogoPintar} w="150px" alt="Logo Pintar" />}
        <Text
          mt={{ base: 0, md: 8 }}
          fontSize={{ base: "2xl", md: "5xl" }}
          fontWeight="bold"
          color="black"
          textAlign={{ base: "center", md: "left" }}
          letterSpacing="tight"
        >
          Mari Belajar Melalui Pusat
          <br />
          Pengetahuan
        </Text>
        <Box mt={{ base: 2, md: 6 }} w="full">
          <form onSubmit={(e) => onSubmitSearch(e)} id="search-pengetahuan">
            <InputGroup h={{ base: 8, md: 12 }}>
              <Input
                type="text"
                placeholder="Cari Materi"
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }));
                }}
                pr="8rem"
                bg="white"
                border="1px"
                borderColor="BluePrimary.900"
                rounded="xl"
                h="full"
                w="90%"
                mr={3}
                fontSize="sm"
                _hover={{ border: "BluePrimary.900" }}
                _focus={{
                  borderColor: "BluePrimary.900",
                  ring: "1px",
                  ringColor: "BluePrimary.900",
                }}
              />

              <Button
                rounded="xl"
                w={{ base: 16, md: 20 }}
                h="full"
                type="submit"
                bg="BluePrimary.900"
                color="white"
                _hover={{ bg: "BluePrimary.900" }}
                _active={{ bg: "BluePrimary.900" }}
              >
                Cari
              </Button>
            </InputGroup>
          </form>
        </Box>
      </Flex>
    </Box>
  );
};

const GuidePengetahuan = () => {
  const steps = [
    {
      content: (
        <p>
          Search Pengetahuan: Temukan pengetahuan baru yang ingin kamu ketahui
          disini
        </p>
      ),
      placement: "bottom" as Placement,
      target: "#search-pengetahuan",
      disableBeacon: true,
    },
    {
      content: <p>Lihat detail dan informasi pengetahuan, disini</p>,
      placement: "bottom" as Placement,
      target: ".pengetahuan-item:nth-child(1)",
      disableBeacon: true,
    },
  ];

  return (
    <Box mt={{ base: 4, md: 0 }}>
      <JoyrideComponent steps={steps} />
    </Box>
  );
};

/**
 * Component for displaying the list of knowledge items.
 * @returns {JSX.Element} The ListPengetahuan component.
 */

const ListPengetahuan: React.FC = () => {
  const { fetchData, setState } = usePengetahuanContext();
  const { isLoading, listData, category, pagination, page, perPage } =
    usePengetahuanContext().state;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  const handlePageChange = (e) => {
    setState((prev) => ({
      ...prev,
      page: e.selected + 1,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [category, page]);

  return (
    <Box mx={{ base: 8, md: 12 }} my={8}>
      <Flex alignItems="center" justifyContent="space-between" w="full">
        <Flex justifyContent="center" alignItems="center">
          <Box position="relative">
            <Icon
              as={FaSlidersH}
              position="absolute"
              mt={3}
              ml={3}
              boxSize={4}
            />
            <Select
              w="full"
              h={10}
              border="1px"
              borderColor="gray.500"
              rounded="md"
              _focus={{
                ring: "1",
                borderColor: "BluePrimary.900",
                ringColor: "BluePrimary.900",
              }}
              _hover={{
                borderColor: "gray.500",
              }}
              fontSize="md"
              _placeholder={{ color: "gray.300" }}
              style={{ paddingLeft: "40px" }}
              defaultValue=""
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  category: e.target.value,
                }));
              }}
            >
              <option value="">Semua Pengetahuan</option>
              <option value="video">Video</option>
              <option value="ppt">PPT</option>
              <option value="pdf">PDF</option>
              <option value="audio">Audio</option>
            </Select>
          </Box>
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="BluePrimary.900"
            ml={5}
          >
            Ditemukan {listData.length} Pengetahuan
          </Text>
        </Flex>
        {!isMobile && <GuidePengetahuan />}
      </Flex>
      {isMobile && <GuidePengetahuan />}
      <div className="mt-8">
        <If condition={isLoading}>
          <Then>
            <Stack>
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
            </Stack>
          </Then>
          <Else>
            <If condition={listData.length > 0}>
              <Then>
                <Grid
                  gap={8}
                  templateColumns={{
                    base: "repeat(1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                  }}
                >
                  {listData.map((item, key) => {
                    return (
                      <CardItem
                        item={item}
                        spacing={false}
                        key={key}
                        accessFor="pengetahuan"
                      />
                    );
                  })}
                </Grid>
              </Then>
              <Else>
                <Box textAlign="center" mt={20}>
                  <Text fontSize="xl" color="gray.400">
                    Belum Ada Pengetahuan
                  </Text>
                </Box>
              </Else>
            </If>
          </Else>
        </If>
      </div>

      {pagination && totalPage > 1 && (
        <Flex justifyContent="end" mt={8}>
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={page}
          />
        </Flex>
      )}
    </Box>
  );
};

const WrapperMain: React.FC = () => {
  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  const statusPengisian = GetLocalStorage({ name: "status_pengisian" });

  if (isAuthenticated() !== false && statusPengisian !== "true") {
    window.location.href = "/profile";
  }

  return (
    <Wrapper>
      {/* Hero */}
      <HeroPengetahuan />

      {/* List Pengetahuan */}
      <ListPengetahuan />
    </Wrapper>
  );
};

const Main: React.FC = () => {
  return (
    <PengetahuanController.Provider>
      <WrapperMain />
    </PengetahuanController.Provider>
  );
};

export default Main;
