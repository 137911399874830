import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Link,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { FiPlay } from "react-icons/all";
import { If, Then } from "react-if";
import Select from "react-select";
import { useDetailPelatihanKontenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/detail/konten_pelatihan";
import VideoPlayer from "../../VideoPlayer";
import ItemSoal from "./ItemSoal";
import TabsLatihan from "./TabsLatihan";
import TabsMateri from "./TabsMateri";
import TabsQuiz from "./TabsQuiz";

/**
 * ContentPelatihan is a component that displays the content of a training.
 * @component
 * @author Muhammad Farras Jibran
 */

interface Option {
  value: number;
  label: string;
}

const ContentPelatihan: React.FC = () => {
  const [urlVideo, setUrlVideo] = useState<string | null>("");
  const [optionsPathMateri, setOptionsPathMateri] = useState<Option[]>([]);
  const [selectedOptionMateri, setSelectedOptionMateri] =
    useState<Option | null>(null);

  const { selectSubModul, setIndexPathMateri } =
    useDetailPelatihanKontenPelatihanContext();
  const {
    modulPelatihan,
    selectSubModulType,
    selectModulIdx,
    selectSubModulIdx,
    detailPaketSoal,
    pelatihan,
    quiz,
    pathMateri,
    indexPathMateri,
    pathPanduanProject,
    isLoading,
  } = useDetailPelatihanKontenPelatihanContext().state;

  useEffect(() => {
    if (pathMateri != null && pathMateri.length > 0) {
      const newOptionsPathMateri = pathMateri.map((val, idx) => ({
        value: idx,
        label: val.jenis_materi.toUpperCase(),
      }));
      setOptionsPathMateri(newOptionsPathMateri);
      setSelectedOptionMateri(newOptionsPathMateri[0]);
    } else {
      setOptionsPathMateri([]);
    }
  }, [pathMateri]);
  //  video js options

  useEffect(() => {
    if (pathMateri != null && pathMateri.length > 0) {
      setUrlVideo(
        `https://www.youtube.com/watch?v=${pathMateri[indexPathMateri].path_materi}`,
      );
    } else {
      setUrlVideo(null);
    }
  }, [pathMateri]);

  const judulSection =
    modulPelatihan.length > 0 &&
    selectSubModulType !== "" &&
    selectSubModulType !== "sub_modul_materi"
      ? selectSubModulType === "sub_modul_ujian_project"
        ? `${
            modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
              Number(selectSubModulIdx)
            ].judul
          } : Project`
        : `${
            modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
              Number(selectSubModulIdx)
            ].judul
          } : Detail Ujian (${detailPaketSoal?.soal.length} Soal)`
      : "";

  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    controlBar: {
      children: [
        "playToggle",
        "currentTimeDisplay",
        "durationDisplay",
        "volumePanel",
        "fullscreenToggle",
      ],
    },
    userActions: {
      hotkeys: false,
      click: false,
    },
    techOrder: ["youtube"],
    sources: [
      {
        src: urlVideo,
        type: "video/youtube",
      },
    ],
    youtube: {
      controls: 0,
      rel: 0,
      modestbranding: 1,
      disablekb: 1,
      fs: 0,
      enablejsapi: 1,
    },
  };

  return isLoading ? (
    <Center>
      <Spinner />
    </Center>
  ) : (
    <Grid
      templateColumns={{
        md: "repeat(4, 1fr)",
        base: "repeat(1, 1fr)",
      }}
      gap={8}
    >
      <GridItem colSpan={1}>
        <Text fontSize="md" textAlign="center" mb={4}>
          Total JP : {pelatihan?.jp} Jam Pelatihan
        </Text>
        <Divider mb={4} />
        <Text fontWeight="semibold" ml={4} fontSize="md" mb={4}>
          Section
        </Text>
        <Flex direction="column" maxH="700px" overflowY="auto">
          {modulPelatihan.map((m, idx) => {
            const SubSection: JSX.Element[] = [];
            m.subModulPelatihan.map((sm, idxSm) => {
              return SubSection.push(
                <Link
                  style={{ textDecoration: "none" }}
                  _focus={{ boxShadow: "none" }}
                  mb="2"
                >
                  <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    _hover={{
                      bg: "blue.400",
                      color: "white",
                    }}
                    color={
                      modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
                        Number(selectSubModulIdx)
                      ].id ===
                        modulPelatihan[Number(idx)].subModulPelatihan[
                          Number(idxSm)
                        ].id && selectSubModulType !== ""
                        ? "blue.400"
                        : ""
                    }
                    onClick={() => {
                      if (sm.jenis_sub_modul === "materi") {
                        selectSubModul(idx, idxSm, "sub_modul_materi");
                      } else {
                        if (sm.jenis_ujian === "project") {
                          selectSubModul(idx, idxSm, "sub_modul_ujian_project");
                        } else {
                          selectSubModul(idx, idxSm, "sub_modul_ujian_ujian");
                        }
                      }
                    }}
                  >
                    <Icon as={FiPlay} mr={2} />
                    {sm.judul}
                  </Flex>
                </Link>,
              );
            });

            return (
              <>
                <Link
                  style={{ textDecoration: "none" }}
                  _focus={{ boxShadow: "none" }}
                  mb={2}
                >
                  <Flex
                    align="center"
                    background="GreenPrimary.500"
                    color="white"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                  >
                    Section {Number(idx + 1)}: {m.judul_modul}
                  </Flex>
                </Link>

                {SubSection}
              </>
            );
          })}
        </Flex>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 3 }}>
        {selectSubModulType === "sub_modul_materi" ? null : (
          <Text fontSize="md" ml={4} mb={4}>
            {judulSection}
          </Text>
        )}
        {selectSubModulType === "" && (
          <Flex justifyContent="center" alignItems="center" h="100%">
            <Heading as="h3" fontSize="2xl">
              Belum Memilih Sub Section
            </Heading>
          </Flex>
        )}

        {selectSubModulType === "sub_modul_ujian_ujian" && (
          <Box overflowY="scroll" pr={3} height="750px">
            {detailPaketSoal?.soal.map((s, i) => (
              <ItemSoal
                key={i}
                idAccordion={"Soal" + (i + 1)}
                nomor={i + 1}
                soal={s.soal}
                jawaban={s.jawaban}
              />
            ))}
          </Box>
        )}

        {selectSubModulType === "sub_modul_materi" && (
          <>
            {pathMateri != null && pathMateri.length > 0 ? (
              <>
                <If
                  condition={
                    pathMateri[indexPathMateri].jenis_materi === "video"
                  }
                >
                  <Then>
                    <VideoPlayer options={videoJsOptions} />
                  </Then>
                </If>
                <If
                  condition={
                    pathMateri[indexPathMateri].jenis_materi === "audio"
                  }
                >
                  <Then>
                    <ReactAudioPlayer
                      src={pathMateri[indexPathMateri].path_materi}
                      autoPlay
                      controls
                      style={{
                        borderRadius: 10 + "px",
                        width: "100%",
                        color: "#111111",
                      }}
                    />
                  </Then>
                </If>
                <If
                  condition={pathMateri[indexPathMateri].jenis_materi === "ppt"}
                >
                  <Then>
                    <iframe
                      title="ppt"
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${pathMateri[indexPathMateri].path_materi}`}
                      width="100%"
                      height="600px"
                      style={{ borderRadius: "6px" }}
                    />
                  </Then>
                </If>
                <If
                  condition={pathMateri[indexPathMateri].jenis_materi === "pdf"}
                >
                  <Then>
                    <iframe
                      title="pdf"
                      src={pathMateri[indexPathMateri].path_materi}
                      width="100%"
                      height="600px"
                      style={{ borderRadius: "6px" }}
                    />
                  </Then>
                </If>
              </>
            ) : null}

            <Tabs mt={6}>
              <Box display="flex" justifyContent="space-between" w="full">
                <TabList>
                  <Tab
                    _selected={{ color: "blue", borderColor: "blue" }}
                    fontWeight="semibold"
                  >
                    Materi
                  </Tab>
                  {quiz.length ? (
                    <Tab
                      _selected={{ color: "blue", borderColor: "blue" }}
                      fontWeight="semibold"
                    >
                      Quiz
                    </Tab>
                  ) : null}
                  {selectModulIdx !== "" &&
                  modulPelatihan[selectModulIdx].subModulPelatihan[
                    selectSubModulIdx
                  ].ada_latihan === 1 ? (
                    <Tab
                      _selected={{ color: "blue", borderColor: "blue" }}
                      fontWeight="semibold"
                    >
                      Latihan
                    </Tab>
                  ) : null}
                </TabList>

                {optionsPathMateri.length > 0 ? (
                  <Box w="20%">
                    <Select
                      className="w-full"
                      options={optionsPathMateri}
                      placeholder="Pilih Jenis Materi"
                      value={selectedOptionMateri}
                      onChange={(val: any) => {
                        setSelectedOptionMateri(val);
                        setIndexPathMateri(val.value);
                      }}
                    />
                  </Box>
                ) : null}
              </Box>

              <TabPanels>
                <TabPanel>
                  <TabsMateri />
                </TabPanel>

                {quiz.length && (
                  <TabPanel>
                    <TabsQuiz />
                  </TabPanel>
                )}

                {selectModulIdx !== "" &&
                  modulPelatihan[selectModulIdx].subModulPelatihan[
                    selectSubModulIdx
                  ].ada_latihan === 1 && (
                    <TabPanel>
                      <TabsLatihan />
                    </TabPanel>
                  )}
              </TabPanels>
            </Tabs>
          </>
        )}

        {selectSubModulType === "sub_modul_ujian_project" && (
          <iframe
            title="sub_modul_ujian_project"
            src={pathPanduanProject}
            width="100%"
            height="600px"
            style={{ borderRadius: "6px" }}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export default ContentPelatihan;
