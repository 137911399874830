import { Box, Text } from "@chakra-ui/react";
import React from "react";
import ReactLoading from "react-loading";

/**
 * Represents a loading indicator component.
 * This component displays a loading animation along with a message.
 * It is commonly used to indicate that data is being fetched or processed.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Loader = () => {
  return (
    <Box
      p={16}
      w="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <ReactLoading type={"bars"} color={"blue"} height={100} width={100} />
      <Text>Loading Data...</Text>
    </Box>
  );
};

export default Loader;
