/**
 * CardItem component.
 * This component represents an individual card item in the Knowledge Center and Video Course.
 */

import React from "react";
import { Box, Icon, Text, Flex } from "@chakra-ui/react";
import { AiOutlineClockCircle } from "react-icons/all";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CategoryBadge from "../Badge/CategoryBadge";
import { GetAuthData } from "../../../../misc/GetAuthData";

const CardItem = ({ item, spacing = true, accessFor }) => {
  const useAuth = GetAuthData();
  const history = useHistory();
  const location = window.location.pathname;

  const urlDetail =
    accessFor === "kursus-video"
      ? "/detail/kursus-video"
      : "/detail/pengetahuan";

  const classItem =
    accessFor === "kursus-video" ? "video-course-item" : "pengetahuan-item";

  return (
    <Box
      className={classItem}
      borderRadius="lg"
      position="relative"
      background={`url(${item.thumbnail}) lightgray 50% / cover no-repeat`}
      mr={{ base: 0, md: spacing ? 8 : 0 }}
      h={{
        base: "200px",
        md: location === "/" ? "240px" : "300px",
      }}
      _hover={{ shadow: "lg", cursor: "pointer" }}
      onClick={() => {
        if (useAuth !== null) {
          window.location.href = `${urlDetail}/${item.id}`;
        } else {
          history.push("/login");
          window.scrollTo(0, 0);
        }
      }}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
        >
          <g filter="url(#filter0_d_4942_123022)">
            <path
              d="M47.0003 71.888C40.4417 71.888 34.1517 69.2826 29.5141 64.6449C24.8764 60.0073 22.271 53.7173 22.271 47.1587C22.271 40.6001 24.8764 34.3101 29.5141 29.6725C34.1517 25.0349 40.4417 22.4295 47.0003 22.4295C53.5589 22.4295 59.8489 25.0349 64.4865 29.6725C69.1241 34.3101 71.7295 40.6001 71.7295 47.1587C71.7295 53.7173 69.1241 60.0073 64.4865 64.6449C59.8489 69.2826 53.5589 71.888 47.0003 71.888ZM47.0003 75.4207C54.4958 75.4207 61.6844 72.4431 66.9845 67.143C72.2847 61.8428 75.2623 54.6543 75.2623 47.1587C75.2623 39.6632 72.2847 32.4746 66.9845 27.1745C61.6844 21.8743 54.4958 18.8967 47.0003 18.8967C39.5047 18.8967 32.3162 21.8743 27.016 27.1745C21.7159 32.4746 18.7383 39.6632 18.7383 47.1587C18.7383 54.6543 21.7159 61.8428 27.016 67.143C32.3162 72.4431 39.5047 75.4207 47.0003 75.4207Z"
              fill="white"
            />
          </g>
          <g filter="url(#filter1_d_4942_123022)">
            <path
              d="M40.8919 36.7544C41.1809 36.6057 41.5052 36.5395 41.8293 36.5632C42.1535 36.5869 42.4648 36.6995 42.729 36.8887L55.0936 45.7206C55.3226 45.884 55.5092 46.0997 55.638 46.3498C55.7668 46.5999 55.8339 46.8771 55.8339 47.1584C55.8339 47.4397 55.7668 47.7169 55.638 47.967C55.5092 48.2171 55.3226 48.4328 55.0936 48.5962L42.729 57.4281C42.4648 57.6171 42.1537 57.7297 41.8298 57.7534C41.5058 57.7771 41.1816 57.711 40.8928 57.5624C40.604 57.4137 40.3617 57.1884 40.1927 56.911C40.0236 56.6337 39.9343 56.3151 39.9346 55.9903V38.3265C39.9342 38.0018 40.0234 37.6833 40.1923 37.4059C40.3612 37.1286 40.6033 36.9032 40.8919 36.7544Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_4942_123022"
              x="0.738281"
              y="0.896729"
              width="92.5239"
              height="92.5239"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="9" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.452381 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4942_123022"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4942_123022"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_4942_123022"
              x="21.9346"
              y="18.5585"
              width="51.8994"
              height="57.1996"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="9" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.452381 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4942_123022"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4942_123022"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </Box>
      <Box
        position="relative"
        top={{ base: "140px", md: location === "/" ? "180px" : "230px" }}
        ml={{ base: 4, md: 6 }}
      >
        <Flex alignItems="center">
          <Text fontSize="sm" fontWeight="semibold" color="white" mr={2}>
            {item.title > 39 ? item.title.substring(0, 39) + "..." : item.title}
          </Text>
          {accessFor === "pengetahuan" && (
            <CategoryBadge category={item?.category} />
          )}
        </Flex>
        <Flex alignItems="center" mt={{ base: 0, md: 2 }}>
          <Icon as={AiOutlineClockCircle} boxSize={4} color="white" mr={2} />
          <Text fontSize="sm" color="white">
            {moment(item.created_at, "YYYY-MM-DD HH:mm:ss").fromNow()}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default CardItem;
