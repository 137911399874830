import { Icon } from "@chakra-ui/react";
import React from "react";
import { BsArrowDownCircle } from "react-icons/bs";
import { GetAuthData } from "../../../misc/GetAuthData";
import { usePodcastContext } from "../../controller/podcast";

/**
 * DownloadPodcast component for rendering a download icon to download a podcast audio.
 *
 * @component
 * @param {object} props - Component props.
 * @param {string} props.title - The title of the podcast.
 * @param {string} props.audio - The URL of the podcast audio.
 * @returns {JSX.Element} DownloadPodcast component JSX element.
 * @author Muhammad Farras Jibran
 */

interface DownloadPodcastProps {
  title: string;
  audio: string;
}

const DownloadPodcast = ({ title, audio }: DownloadPodcastProps) => {
  const useAuth = GetAuthData();
  const { handleDownloadAudio } = usePodcastContext();
  return (
    <Icon
      id="download-podcast"
      as={BsArrowDownCircle}
      boxSize={5}
      color="black"
      _hover={{ cursor: "pointer" }}
      onClick={() => {
        if (useAuth !== null) {
          handleDownloadAudio(title, audio);
        } else {
          window.location.href = "/login";
        }
      }}
    />
  );
};

export default DownloadPodcast;
