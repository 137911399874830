/* eslint-disable eqeqeq */

import { Image } from "@chakra-ui/react";
import parse from "html-react-parser";
import PrettyRating from "pretty-rating-react";
import React, { useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import {
  AiOutlineCloudDownload,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineStar,
  BiTimeFive,
  BsBook,
} from "react-icons/all";
import { Else, If, Then } from "react-if";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import Shimmer from "react-shimmer-effect";
import Switch from "react-switch";
import { ToastContainer } from "react-toastify";
import { ReviewMateri } from "../../../../../../entities/ReviewMateri";
import ImgUsers from "../../../../assets/png/img_users.png";
import RateFullIcon from "../../../../assets/svg/ic_full_rate.svg";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import VideoPlayer from "../../../../components/VideoPlayer";
import ManajemenMateriDetailController, {
  useManajemenMateriDetailContext,
} from "../../../../controller/admin/manajemen_materi/detail";

const colors = {
  star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
};

const BottomHeader: React.FC = () => {
  const { setSelectedId, setShowDialogDelete } =
    useManajemenMateriDetailContext();
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Detail Materi </a>
      </div>
      <div className="flex flex-row">
        <button
          type="button"
          className="mr-2 flex px-6 py-2.5 bg-red-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-red-700 mb-3 mt-3"
          onClick={() => {
            setSelectedId(window.location.pathname.split("/", 5)[4]);
            setShowDialogDelete(true);
          }}
        >
          Hapus
        </button>
        <a
          href={`/admin/ubah/manajemen_materi/${
            window.location.pathname.split("/", 5)[4]
          }`}
          className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
        >
          Ubah
          <AiOutlineEdit className="ml-2" style={{ marginTop: "2px" }} />
        </a>
      </div>
    </div>
  );
};

const Detail: React.FC = () => {
  const { setIndexJenisMateri } = useManajemenMateriDetailContext();
  const { materi, isLoading, pathMateri } =
    useManajemenMateriDetailContext().state;

  let optionsJenisMateri;

  if (pathMateri) {
    optionsJenisMateri = pathMateri.map((val, idx) => ({
      value: idx,
      label: val.jenis_materi.toUpperCase(),
    }));
  } else {
    optionsJenisMateri = [];
  }

  return (
    <If condition={isLoading}>
      <Then></Then>
      <Else>
        <div className="bg-white rounded-lg mt-4 px-6 py-5">
          <div className="flex justify-between">
            <a className="text-base font-semibold">{materi?.judul_materi}</a>
            <a className="flex ">
              <Switch
                className="ml-2 mr-2"
                height={20}
                onChange={() => {}}
                checked={materi?.status_publish == 1 ? true : false}
              />
              {materi?.status_publish == 1 ? "Publikasi" : "Tidak dipublikasi"}{" "}
            </a>
          </div>
          <div className="flex flex-wrap mt-2">
            <a className="px-3 py-1 bg-gray-200 text-xs rounded-lg">
              {materi?.jenis_pelatihan}
            </a>
            <a className="px-3 py-1 bg-gray-200 text-xs rounded-lg ml-2">
              {materi?.penyusun}
            </a>
            <select
              className="py-1 bg-gray-200 text-xs rounded-lg ml-2 border-none"
              onChange={(v) => setIndexJenisMateri(v.target.value)}
            >
              {optionsJenisMateri.length > 0
                ? optionsJenisMateri.map((val, idx) => {
                    return (
                      <option
                        key={idx}
                        value={val.value}
                        selected={idx == 0 ? true : false}
                      >
                        {val.label}
                      </option>
                    );
                  })
                : null}
            </select>
            <a className="py-1 text-xs rounded-lg ml-2 flex">
              <BiTimeFive
                className="mr-1"
                style={{ color: "#0DBD7F", fontSize: "16px" }}
              />{" "}
              {materi?.tanggal_pembuatan}
            </a>
            <a className="py-1 text-xs rounded-lg ml-2 flex">
              <BsBook
                className="mr-1"
                style={{ color: "#0DBD7F", fontSize: "16px" }}
              />{" "}
              {materi?.jam_pelatihan} JP
            </a>
            <a className="py-1 text-xs rounded-lg ml-2 flex">
              <AiOutlineCloudDownload
                className="mr-1"
                style={{ color: "#0DBD7F", fontSize: "16px" }}
              />{" "}
              20
            </a>
            <a className="py-1 text-xs rounded-lg ml-2 flex">
              <AiOutlineEye
                className="mr-1"
                style={{ color: "#0DBD7F", fontSize: "16px" }}
              />{" "}
              {materi?.views}
            </a>
            <a className="py-1 text-xs rounded-lg ml-2 flex">
              <AiOutlineStar
                className="mr-1"
                style={{ color: "#0DBD7F", fontSize: "16px" }}
              />{" "}
              {materi?.rating}
            </a>
          </div>
          <p className="text-justify text-sm text-gray-500 mt-3 mb-5">
            {materi != null ? parse(materi.uraian_deskripsi) : null}
          </p>
          <a className="font-semibold">Kompetensi</a>
          <p className="text-justify text-sm text-gray-500 mt-2 mb-5">
            {materi != null ? parse(materi.kompetensi) : null}
          </p>

          <div className="flex flex-wrap">
            {materi?.widyaiswara_assigned.map((w, idx) => {
              return (
                <div className="mr-4" key={idx}>
                  <img
                    src={w.path_foto != "" ? w.path_foto : ImgUsers}
                    className="rounded-full mx-auto mb-1"
                    style={{ width: "50px" }}
                  />
                  <a className="font-medium">{w.name}</a>
                </div>
              );
            })}
          </div>
        </div>
      </Else>
    </If>
  );
};

const ItemReviewes = ({ reviewMateri }: { reviewMateri: ReviewMateri }) => {
  return (
    <div
      className="container mt-4 p-5"
      style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
    >
      <div className="grid md:grid-cols-9 sm:grid-cols-3 gap-5">
        <div className="col-span-1 md:col-span-1 sm:grid-cols-1 ml-1 mr-1">
          <img
            src={ImgUsers}
            className="rounded-full"
            alt="ic-user"
            style={{ width: "50px" }}
          />
        </div>
        <div className="col-span-3 md:col-span-3 sm:grid-cols-1 ml-1 mr-1">
          <p className="font-medium text-base">{reviewMateri.user.name}</p>
          <p className="text-xs text-gray-500">{reviewMateri.created_at}</p>
          <div className="text-sm">
            <PrettyRating value={reviewMateri.rating} colors={colors.star} />
          </div>
        </div>
        <div className="col-span-5 md:col-span-5 sm:grid-cols-1 ml-1 mr-1">
          <If condition={reviewMateri.path_foto_komentar_materi.length > 0}>
            <Then>
              <div className="flex flex-wrap mb-2">
                {reviewMateri.path_foto_komentar_materi.map((p, idx) => {
                  return (
                    <a href={p.label} target="_blank" key={p.id}>
                      <img
                        className="object-cover h-20 w-35"
                        src={p.label}
                      ></img>
                    </a>
                  );
                })}
              </div>
            </Then>
          </If>
          <p className="text-xs text-gray-500">{reviewMateri.komentar}</p>
        </div>
      </div>
    </div>
  );
};

const Pagination = () => {
  const { setPage } = useManajemenMateriDetailContext();
  const { jumlahPage } = useManajemenMateriDetailContext().state;

  const endFix =
    jumlahPage > Number(jumlahPage.toFixed())
      ? Number(jumlahPage.toFixed()) + 1
      : Number(jumlahPage.toFixed());

  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  return (
    <If condition={endFix > 1}>
      <Then>
        <div className="flex justify-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Selanjutnya"
            activeClassName="active"
            containerClassName="pagination"
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            pageCount={
              jumlahPage > Number(jumlahPage.toFixed())
                ? Number(jumlahPage.toFixed()) + 1
                : Number(jumlahPage.toFixed())
            }
            previousLabel="Sebelumnya"
          />
        </div>
      </Then>
      <Else>
        <></>
      </Else>
    </If>
  );
};

const Reviews: React.FC = () => {
  const { setRating, setFoto } = useManajemenMateriDetailContext();
  const { reviewMateri, isLoadingReview, rating, foto } =
    useManajemenMateriDetailContext().state;
  let buttonAll = "btn-review";
  let buttonDenganFoto = "btn-review";
  let buttonBintang1 = "btn-review";
  let buttonBintang2 = "btn-review";
  let buttonBintang3 = "btn-review";
  let buttonBintang4 = "btn-review";
  let buttonBintang5 = "btn-review";
  if (rating == "") {
    if (foto) {
      buttonDenganFoto = "btn-review-active";
      buttonAll = "btn-review";
    } else {
      buttonAll = "btn-review-active";
      buttonDenganFoto = "btn-review";
    }
  } else if (rating == "1") {
    buttonBintang1 = "btn-review-active";
  } else if (rating == "2") {
    buttonBintang2 = "btn-review-active";
  } else if (rating == "3") {
    buttonBintang3 = "btn-review-active";
  } else if (rating == "4") {
    buttonBintang4 = "btn-review-active";
  } else if (rating == "5") {
    buttonBintang5 = "btn-review-active";
  }

  return (
    <div
      className="container mt-4 p-5 mb-10"
      style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
    >
      <h2 className="font-semibold text-base ">Reviews</h2>
      <div className="mt-4 flex flex-wrap">
        <button
          className={`py-2 px-4 ${buttonAll} mr-2 mt-2`}
          onClick={() => setRating("")}
        >
          Semua
        </button>
        <button
          className={`py-2 px-4 ${buttonDenganFoto} mr-2 mt-2`}
          onClick={() => setFoto()}
        >
          Dengan Foto
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang5} mr-2 mt-2`}
          onClick={() => setRating("5")}
        >
          <Image src={RateFullIcon} className="mr-1" />5
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang4} mr-2 mt-2`}
          onClick={() => setRating("4")}
        >
          <Image src={RateFullIcon} className="mr-1" />4
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang3} mr-2 mt-2`}
          onClick={() => setRating("3")}
        >
          <Image src={RateFullIcon} className="mr-1" />3
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang2} mr-2 mt-2`}
          onClick={() => setRating("2")}
        >
          <Image src={RateFullIcon} className="mr-1" />2
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang1} mr-2 mt-2`}
          onClick={() => setRating("1")}
        >
          <Image src={RateFullIcon} className="mr-1" />1
        </button>
      </div>

      <If condition={isLoadingReview}>
        <Then>
          <Shimmer>
            <div className="container mt-4 p-5"></div>
          </Shimmer>
          <Shimmer>
            <div className="container mt-4 p-5"></div>
          </Shimmer>
          <Shimmer>
            <div className="container mt-4 p-5"></div>
          </Shimmer>
          <Shimmer>
            <div className="container mt-4 p-5"></div>
          </Shimmer>
        </Then>
        <Else>
          {reviewMateri.map((r: ReviewMateri, idx) => {
            return <ItemReviewes reviewMateri={r} key={idx} />;
          })}
        </Else>
      </If>
      <Pagination />
    </div>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, isLoadingDelete } =
    useManajemenMateriDetailContext().state;
  const { setShowDialogDelete, deleteMateri } =
    useManajemenMateriDetailContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Materi
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteMateri()}
                  >
                    <If condition={isLoadingDelete}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const { getDetailMateri } = useManajemenMateriDetailContext();
  const { indexJenisMateri, pathMateri } =
    useManajemenMateriDetailContext().state;

  useEffect(() => {
    getDetailMateri(window.location.pathname.split("/", 5)[4]);
  }, []);

  //  video js options
  let urlVideo;

  if (pathMateri != null && pathMateri.length > 0) {
    urlVideo = `https://www.youtube.com/watch?v=${pathMateri[indexJenisMateri].path_materi}`;
  } else {
    urlVideo = null;
  }

  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    controlBar: {
      children: [
        "playToggle",
        "currentTimeDisplay",
        "durationDisplay",
        "volumePanel",
        "fullscreenToggle",
      ],
    },
    userActions: {
      hotkeys: false,
      click: false,
    },
    techOrder: ["youtube"],
    sources: [
      {
        src: urlVideo,
        type: "video/youtube",
      },
    ],
    youtube: {
      controls: 0,
      rel: 0,
      modestbranding: 1,
      disablekb: 1,
      fs: 0,
      enablejsapi: 1,
    },
  };

  return (
    <div className="app">
      <ModalDelete />
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-50 drop-shadow-md">
          <BottomHeader />
        </header>
        <div className="container px-7">
          {pathMateri != null ? (
            <>
              <If
                condition={pathMateri[indexJenisMateri].jenis_materi == "video"}
              >
                <Then>
                  <VideoPlayer options={videoJsOptions} />
                </Then>
              </If>
              <If
                condition={pathMateri[indexJenisMateri].jenis_materi == "audio"}
              >
                <Then>
                  <ReactAudioPlayer
                    src={pathMateri[indexJenisMateri].path_materi}
                    autoPlay
                    controls
                    style={{
                      borderRadius: 10 + "px",
                      width: "100%",
                      color: "#111111",
                    }}
                  />
                </Then>
              </If>
              <If
                condition={pathMateri[indexJenisMateri].jenis_materi == "ppt"}
              >
                <Then>
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${pathMateri[indexJenisMateri].path_materi}`}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    style={{ borderRadius: "6px" }}
                  />
                </Then>
              </If>
              <If
                condition={pathMateri[indexJenisMateri].jenis_materi == "pdf"}
              >
                <Then>
                  <iframe
                    src={pathMateri[indexJenisMateri].path_materi}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    style={{ borderRadius: "6px" }}
                  />
                </Then>
              </If>
            </>
          ) : (
            <>
              <Shimmer>
                <div className="container mt-4 p-5"></div>
              </Shimmer>
              <Shimmer>
                <div className="container mt-4 p-5"></div>
              </Shimmer>
              <Shimmer>
                <div className="container mt-4 p-5"></div>
              </Shimmer>
              <Shimmer>
                <div className="container mt-4 p-5"></div>
              </Shimmer>
            </>
          )}
          <Detail />
          <Reviews />
        </div>
      </main>
    </div>
  );
};

const ManajemenMateriDetail: React.FC = () => {
  return (
    <ManajemenMateriDetailController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <SidebarWithHeader title="Manajemen Materi">
        <WrapperMain />
      </SidebarWithHeader>
    </ManajemenMateriDetailController.Provider>
  );
};

export default ManajemenMateriDetail;
