import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface InfografisItem {
  id: number;
  title: string;
  image: string;
  description: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}
interface IState {
  state: {
    title: string;
    image: File | null;
    description: string;
    publish: boolean;
    loadingData: boolean;
    loadingAction: boolean;
    infografisData: InfografisItem[];
    mode: string;
    itemId: string;
    url: string;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
  };
  setInfografisState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
  handleEdit: Function;
  clearState: Function;
}

const initialState: IState = {
  state: {
    title: "",
    image: null,
    description: "",
    publish: false,
    loadingData: false,
    loadingAction: false,
    infografisData: [],
    mode: "",
    itemId: "",
    url: "management_content/infografis",
    pagination: null,
    page: 1,
    perPage: 9,
  },
  setInfografisState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleDelete: () => {},
  handleEdit: () => {},
  clearState: () => {},
};

/**
 * The InfografisContext provides a context for managing the state related to infografis.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: InfografisProvider } = Context;

/**
 * The provider component that wraps its children with the InfografisContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */
const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    title,
    description,
    image,
    publish,
    mode,
    itemId,
    url,
    infografisData: data,
    perPage,
    page,
  } = state.state;

  const setInfografisState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const clearState = () => {
    setInfografisState("title", "");
    setInfografisState("description", "");
    setInfografisState("publish", false);
    setInfografisState("image", null);
  };

  const fetchData = async (status?: string) => {
    setInfografisState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: status ?? "0,1",
          rows: perPage,
          page: page,
        },
      });

      setInfografisState("infografisData", response.data?.data);
      setInfografisState("pagination", response?.data.pagination);
      setInfografisState("loadingData", false);
    } catch (error) {
      throw error;
    }
  };

  const postData = async () => {
    if (title.length > 30) {
      return toast({
        title: "Maksimal judul 30 karakter",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
    setInfografisState("loadingAction", true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("status_publish", publish ? "1" : "0");

    if (image) {
      let newImage;
      if (typeof image === "string") {
        newImage = "";
      } else {
        newImage = image;
      }
      formData.append("image", newImage);
    }

    try {
      const idParams = mode === "edit" ? "/" + itemId : "";

      const response = await axiosInstance.post(`${url}${idParams}`, formData);
      setInfografisState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      setInfografisState("mode", "");

      fetchData();

      return response.data;
    } catch (error: any) {
      setInfografisState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      Object.keys(responseData.errors).forEach((key) => {
        responseData.errors[key].forEach((errorMessage) => {
          toast({
            title: titleMessage,
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        });
      });

      throw error;
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);
      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleEdit = (id) => {
    const dataEdit = data.find((item) => item.id === id);
    if (dataEdit) {
      setInfografisState("mode", "edit");
      setInfografisState("title", dataEdit?.title);
      setInfografisState("description", dataEdit?.description);
      setInfografisState(
        "publish",
        dataEdit?.status_publish === 0 ? false : true,
      );
      setInfografisState("image", dataEdit?.image);
      setInfografisState("itemId", id);
    }
  };

  return (
    <InfografisProvider
      value={{
        state: state.state,
        setInfografisState,
        fetchData,
        postData,
        handleDelete,
        handleEdit,
        clearState,
      }}
    >
      {children}
    </InfografisProvider>
  );
};

export const useInfografisContext = () => useContext(Context);

const InfografisContext = {
  useInfografisContext,
  Provider,
};

export default InfografisContext;
