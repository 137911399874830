/* eslint-disable eqeqeq */
import { Box, Container, Link, Skeleton, Stack, Text } from "@chakra-ui/react";
import If, { Else } from "if-else-react";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { ToastContainer } from "react-toastify";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import CardItemPelatihan from "../../../components/global/Card/CardItemPelatihan";
import AboutComponent from "../../../components/LandingPage/AboutComponent";
import CountingSection from "../../../components/LandingPage/CountingSection";
import HeroCarousel from "../../../components/LandingPage/HeroCarousel";
import KnowledgeCenter from "../../../components/LandingPage/KnowledgeCenter";
import { Wrapper } from "../../../components/Wrapper";
import PelatihanController, {
  usePelatihanContext,
} from "../../../controller/pelatihan/index";
import PengetahuanController, {
  usePengetahuanContext,
} from "../../../controller/pengetahuan/index";
import EventPintarPublicContext, {
  useEventPintarPublicContext,
} from "../../../controller/public/event_pintar";
import InfografisPublicContext, {
  useInfografisPublicContext,
} from "../../../controller/public/infografis";
import LandingDataController, {
  useLandingDataContext,
} from "../../../controller/public/landing_data";
import SliderBannerPublicContext, {
  useSliderBannerPublicContext,
} from "../../../controller/public/slider_banner";
import PelatihanSayaController, {
  usePelatihanSayaContext,
} from "../../../controller/registered/pelatihan_saya";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

const TrainingSection: React.FC = () => {
  const { pelatihan, isLoading } = usePelatihanContext().state;

  return (
    <Box className="bg-white" bgGradient="linear(blue.50 0%,  blue.50 100%)">
      <Container maxW="8xl" py={{ base: 8, md: 12 }} w="full">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          w="full"
        >
          <Text
            as="h2"
            fontWeight="semibold"
            letterSpacing="tight"
            fontSize={{ base: "xl", md: "2xl" }}
          >
            Pelatihan Terbaru
          </Text>

          <Link
            href="/pelatihan"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="semibold"
            color="BluePrimary.900"
            _hover={{ color: "BluePrimary.900" }}
          >
            Lihat Semua
          </Link>
        </Box>
        <Box mt={5}>
          <If condition={isLoading}>
            <Stack>
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
              <Skeleton
                style={{ height: "400px", width: "280px", marginRight: "20px" }}
              />
            </Stack>
            <Else />
            <If condition={pelatihan.length > 0}>
              <Carousel responsive={responsive} ssr={true}>
                {pelatihan.map((v: Pelatihan, key) => (
                  <CardItemPelatihan pelatihan={v} key={v.id} />
                ))}
              </Carousel>
              <Else />
              <Box mx="auto" textAlign="center" my={20}>
                <Text as="h1" fontSize="xl" color="gray.400">
                  Belum Ada Pelatihan Terbaru
                </Text>
              </Box>
            </If>
          </If>
        </Box>
      </Container>
    </Box>
  );
};

const WrapperMain: React.FC = () => {
  const { getPelatihan } = usePelatihanContext();

  const { getMateri } = usePengetahuanContext();

  const { getLandingData } = useLandingDataContext();

  const { getCurrentDateServer } = usePelatihanSayaContext();

  const { fetchData: getSliderBanner } = useSliderBannerPublicContext();

  const { getInfografis } = useInfografisPublicContext();

  const { getEventPintar } = useEventPintarPublicContext();

  useEffect(() => {
    getCurrentDateServer().then(() => {
      getPelatihan(1, "");
      getMateri(1, "");
      getLandingData();
      getSliderBanner();
      getInfografis();
      getEventPintar();
    });
  }, []);

  return (
    <Wrapper>
      {/* Carousel */}
      <HeroCarousel />

      {/* About Section */}
      <AboutComponent />

      {/* Training Section */}
      <TrainingSection />

      {/* Knowledge Center Section */}
      <KnowledgeCenter />

      {/* Counting Section */}
      <CountingSection />
    </Wrapper>
  );
};

const PageMainBeforeLogin: React.FC = () => {
  return (
    <PelatihanController.Provider>
      <PengetahuanController.Provider>
        <PelatihanSayaController.Provider>
          <LandingDataController.Provider>
            <SliderBannerPublicContext.Provider>
              <InfografisPublicContext.Provider>
                <EventPintarPublicContext.Provider>
                  <ToastContainer />
                  <WrapperMain />
                </EventPintarPublicContext.Provider>
              </InfografisPublicContext.Provider>
            </SliderBannerPublicContext.Provider>
          </LandingDataController.Provider>
        </PelatihanSayaController.Provider>
      </PengetahuanController.Provider>
    </PelatihanController.Provider>
  );
};

export default PageMainBeforeLogin;
