/* eslint-disable eqeqeq */
import { Box, Skeleton } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useAuthUser } from "react-auth-kit";
import { Else, If, Then } from "react-if";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import ChatController, { useChatContext } from "../../../../controller/chat";

const Chats: React.FC = () => {
  // use user auth data
  const auth = useAuthUser();

  const { sendMessage, setContentMessage } = useChatContext();
  const { selectedMessage, chat, isLoading, contentMessage } =
    useChatContext().state;

  const handleSendMessage = (e) => {
    e.preventDefault();
    sendMessage(auth()?.id, true, auth()?.username);
    setContentMessage("");
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current != null) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat, isLoading]);

  return (
    <If condition={selectedMessage.length > 0}>
      <Then>
        {selectedMessage.length > 0 && (
          <div className="col-span-8 md:col-span-8 sm:grid-cols-1">
            <div className="flex-1 px-2 justify-between flex flex-col">
              {/* Header */}
              <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
                <div className="relative flex items-center space-x-4">
                  <div className="relative">
                    <img
                      src={`https://ui-avatars.com/api/?name=${selectedMessage[0].data.nama_user}&background=007E39&color=fff`}
                      alt="My profile"
                      className="w-8 h-8 rounded-full order-1"
                    />
                  </div>
                  <div className="flex flex-col leading-tight">
                    <div className="text-base mt-1 flex items-center">
                      <span className="text-gray-700 mr-3">
                        {selectedMessage[0].data.nama_user}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    style={{ backgroundColor: "#EB5757" }}
                    className="inline-flex items-center justify-center rounded-lg border h-10 w-20 transition duration-500 ease-in-out text-white hover:bg-white focus:outline-none"
                  >
                    Selesai
                  </button>
                  <div className="border-none px-2 sm:mb-0">
                    <div className="relative flex">
                      <span className="absolute inset-y-0 flex items-center">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.0462 14.8692C12.4022 16.1142 10.3333 16.7015 8.2607 16.5116C6.1881 16.3217 4.26767 15.3689 2.89043 13.8471C1.5132 12.3253 0.782727 10.349 0.847746 8.32056C0.912765 6.29215 1.76839 4.36417 3.2404 2.92917C4.71241 1.49416 6.69012 0.660053 8.77084 0.596669C10.8516 0.533285 12.8788 1.24539 14.4399 2.588C16.0009 3.9306 16.9784 5.80275 17.1732 7.82325C17.368 9.84374 16.7655 11.8606 15.4884 13.4633L20.233 18.0713C20.6347 18.4614 20.6353 19.095 20.2344 19.4859C19.8335 19.8767 19.1831 19.8764 18.7826 19.4852L14.0564 14.8692H14.0462ZM9.03434 14.5502C9.84026 14.5502 10.6383 14.3954 11.3829 14.0948C12.1274 13.7941 12.804 13.3534 13.3739 12.7979C13.9437 12.2423 14.3958 11.5828 14.7042 10.8569C15.0126 10.1311 15.1713 9.35312 15.1713 8.56746C15.1713 7.7818 15.0126 7.00384 14.7042 6.27798C14.3958 5.55213 13.9437 4.8926 13.3739 4.33706C12.804 3.78151 12.1274 3.34083 11.3829 3.04017C10.6383 2.73951 9.84026 2.58477 9.03434 2.58477C7.40671 2.58477 5.84574 3.21508 4.69483 4.33706C3.54392 5.45903 2.89735 6.98075 2.89735 8.56746C2.89735 10.1542 3.54392 11.6759 4.69483 12.7979C5.84574 13.9198 7.40671 14.5502 9.03434 14.5502Z"
                              fill="#7A8990"
                            />
                          </svg>
                        </button>
                      </span>
                      <input
                        type="text"
                        placeholder="Cari disini"
                        className="border-none w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-100 rounded-md h-10 text-sm duration-300"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Body */}
              <div
                className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-green scrollbar-thumb-rounded scrollbar-track-green-lighter scrollbar-w-2 scrolling-touch"
                style={{ height: "530px" }}
              >
                <If condition={isLoading}>
                  <Then>
                    <div className="text-center mt-5">
                      <svg
                        role="status"
                        className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </Then>
                  <Else>
                    {chat.map((value, key) =>
                      value.data.is_admin == false ? (
                        <div className="chat-message" key={key}>
                          <div className="flex items-end">
                            <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                              <div>
                                <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-100 text-gray-600">
                                  {value.data.content}
                                </span>
                              </div>
                            </div>
                            <img
                              src={`https://ui-avatars.com/api/?name=${value.data.nama_user}&background=007E39&color=fff`}
                              alt="My profile"
                              className="w-6 h-6 rounded-full order-1"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="chat-message" key={key}>
                          <div className="flex items-end justify-end">
                            <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                              <div>
                                <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-green-400 text-white ">
                                  {value.data.content}
                                </span>
                              </div>
                            </div>
                            <img
                              src={`https://ui-avatars.com/api/?name=${value.data.nama_user}&background=007E39&color=fff`}
                              alt="My profile"
                              className="w-6 h-6 rounded-full order-2"
                            />
                          </div>
                        </div>
                      ),
                    )}
                    <div ref={messagesEndRef} />
                  </Else>
                </If>
              </div>

              {/* Footer */}
              <div className="border-t-2 border-gray-200 px-4 pt-4 pb-4 sm:mb-0">
                <form onSubmit={(e) => handleSendMessage(e)}>
                  <div className="relative flex">
                    <input
                      type="text"
                      placeholder="Pesan Anda"
                      className="w-full border-none focus:outline-green-400 focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-100 rounded-md py-3"
                      style={{ paddingRight: "220px" }}
                      value={contentMessage}
                      onChange={(e) => setContentMessage(e.target.value)}
                    />
                    <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-green-600 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-6 w-6 ml-2 transform rotate-90"
                        >
                          <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </Then>
      <Else>
        <div className="col-span-8 md:col-span-8 sm:grid-cols-1 place-self-center">
          <div className="p-24 text-center">
            <p className="text-2xl font-semibold">Pilih Chat Terlebih Dahulu</p>
          </div>
        </div>
      </Else>
    </If>
  );
};

const ItemChats = ({ chat }: { chat: Array<any> }): JSX.Element => {
  const { setSelectedMessage } = useChatContext();

  const data: JSX.Element[] = [];
  chat.map((value, idx) => {
    const timestampDate = new Date(value.data.timestamp.seconds * 1000);
    const date = moment(timestampDate).format("DD MMMM YYYY");

    return data.push(
      <button
        onClick={() => setSelectedMessage(idx)}
        className="w-full"
        key={idx}
      >
        <div className="flex flex-row px-4 py-4 text-left">
          <img
            src={`https://ui-avatars.com/api/?name=${value.data.nama_user}&background=007E39&color=fff`}
            className="w-10 h-10 rounded-full"
            alt="image_user"
          />
          <div className="flex flex-col ml-5">
            <p className="text-green-600 text-md font-semibold">
              {value.data.nama_user.length > 35
                ? value.data.nama_user.substring(0, 35) + "..."
                : value.data.nama_user}
            </p>
            <p className="text-xs">{date}</p>
          </div>
        </div>
        <div style={{ borderTop: "1px solid #E0E0E0", width: "100%" }}></div>
      </button>,
    );
  });
  return (
    <>
      {data.map((data, key) => (
        <div key={key}>{data}</div>
      ))}
    </>
  );
};

const Konten: React.FC = () => {
  const { logChat, isLoadingFetchLogChat } = useChatContext().state;

  return (
    <div
      className="bg-white rounded-lg -mt-6 shadow-lg"
      style={{ height: "680px" }}
    >
      <div className="grid md:grid-cols-11 sm:grid-cols-3 border-b-2 h-full">
        <div className="col-span-3 md:col-span-3 sm:grid-cols-1 h-full border-r-2">
          <div className="border-none px-3 sm:mb-0 py-3">
            <div className="relative flex">
              <span className="absolute inset-y-0 flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0462 14.8692C12.4022 16.1142 10.3333 16.7015 8.2607 16.5116C6.1881 16.3217 4.26767 15.3689 2.89043 13.8471C1.5132 12.3253 0.782727 10.349 0.847746 8.32056C0.912765 6.29215 1.76839 4.36417 3.2404 2.92917C4.71241 1.49416 6.69012 0.660053 8.77084 0.596669C10.8516 0.533285 12.8788 1.24539 14.4399 2.588C16.0009 3.9306 16.9784 5.80275 17.1732 7.82325C17.368 9.84374 16.7655 11.8606 15.4884 13.4633L20.233 18.0713C20.6347 18.4614 20.6353 19.095 20.2344 19.4859C19.8335 19.8767 19.1831 19.8764 18.7826 19.4852L14.0564 14.8692H14.0462ZM9.03434 14.5502C9.84026 14.5502 10.6383 14.3954 11.3829 14.0948C12.1274 13.7941 12.804 13.3534 13.3739 12.7979C13.9437 12.2423 14.3958 11.5828 14.7042 10.8569C15.0126 10.1311 15.1713 9.35312 15.1713 8.56746C15.1713 7.7818 15.0126 7.00384 14.7042 6.27798C14.3958 5.55213 13.9437 4.8926 13.3739 4.33706C12.804 3.78151 12.1274 3.34083 11.3829 3.04017C10.6383 2.73951 9.84026 2.58477 9.03434 2.58477C7.40671 2.58477 5.84574 3.21508 4.69483 4.33706C3.54392 5.45903 2.89735 6.98075 2.89735 8.56746C2.89735 10.1542 3.54392 11.6759 4.69483 12.7979C5.84574 13.9198 7.40671 14.5502 9.03434 14.5502Z"
                      fill="#7A8990"
                    />
                  </svg>
                </button>
              </span>
              <input
                type="text"
                placeholder="Cari disini"
                className="border-none w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-100 rounded-md h-10 text-sm duration-300"
              />
            </div>
          </div>

          <div style={{ borderTop: "1px solid #E0E0E0", width: "100%" }} />

          <If condition={isLoadingFetchLogChat}>
            <Then>
              <div className="px-3 py-3">
                <Skeleton height={"20px"} />
              </div>
            </Then>
            <Else>
              <If condition={logChat.length > 0}>
                <Then>
                  <div
                    className="overflow-y-auto scrollbar-thumb-green scrollbar-thumb-rounded scrollbar-track-green-lighter scrollbar-w-2 scrolling-touch"
                    style={{ height: "615px" }}
                  >
                    <ItemChats chat={logChat} />
                  </div>
                </Then>
                <Else>
                  <div className="px-3 py-3 text-center text-md font-semibold">
                    <p>Belum Ada Chat!</p>
                  </div>
                </Else>
              </If>
            </Else>
          </If>
        </div>

        <Chats />
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  const { getLogChat, getDetailChat } = useChatContext();
  const { idChatCurrent } = useChatContext().state;

  useEffect(() => {
    getLogChat();
  }, []);

  useEffect(() => {
    getDetailChat();
  }, [idChatCurrent]);

  return (
    <SidebarWithHeader title="Helpdesk - Konsultasi">
      <Box className="app">
        <Box as="main" style={{ padding: "0px" }}>
          <Box py={6}>
            <Konten />
          </Box>
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const HelpdeskKonsultasi: React.FC = () => {
  return (
    <ChatController.Provider>
      <WrapperMain />
    </ChatController.Provider>
  );
};

export default HelpdeskKonsultasi;
