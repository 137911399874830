/**
 * PodcastFilter component displays the filter section of the podcast page.
 */

import { Box, Button, Input, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { usePodcastCategoryContext } from "../../controller/admin/data_master/kategori_podcast";
import { usePodcastContext } from "../../controller/podcast";
import PodcastGuide from "./PodcastGuide";

/**
 * PodcastFilter component.
 * @component
 * @returns {React.FC} The PodcastFilter component.
 * @author Muhammad Farras Jibran
 */

const PodcastFilter: React.FC = () => {
  const [showFilter, setShowFilter] = useState(true);
  const { fetchData: getCategoryPodcast } = usePodcastCategoryContext();
  const { listData: listCategory } = usePodcastCategoryContext().state;
  const { fetchData: getPodcast, setPodcastState } = usePodcastContext();
  const { keyword, listCategorySelected } = usePodcastContext().state;

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const trackId = searchParams.get("track");

    if (trackId) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }

    getCategoryPodcast();
  }, []);

  return (
    <Box>
      <Box mx={{ base: 8, md: 12 }} pb={4}>
        <Box
          position="relative"
          pt={8}
          pb={4}
          display="flex"
          w="full"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box w={{ base: "300px", md: "450px" }}>
            <form
              style={{ display: "flex" }}
              id="search-podcast"
              onSubmit={(e) => {
                e.preventDefault();
                getPodcast();
              }}
            >
              <label htmlFor="search"></label>
              <Input
                w="full"
                h="10"
                pl="3"
                pr="8"
                mr="4"
                id="search"
                fontSize="sm"
                placeholder="Cari Podcast"
                borderWidth="3"
                borderColor="BluePrimary.900"
                borderRadius="xl"
                _hover={{ borderColor: "BluePrimary.900" }}
                _focus={{
                  ring: "1",
                  ringColor: "BluePrimary.900",
                  borderColor: "BluePrimary.900",
                }}
                value={keyword}
                onChange={(e) => setPodcastState("keyword", e.target.value)}
              />
              <Button
                color="white"
                bg="BluePrimary.900"
                type="submit"
                rounded="xl"
                _hover={{ bg: "BluePrimary.900" }}
                _active={{ bg: "BluePrimary.900" }}
              >
                Cari
              </Button>
            </form>
          </Box>
          {!isMobile && <PodcastGuide />}
        </Box>
        {isMobile && <PodcastGuide />}
        {showFilter && listCategory.length ? (
          <Box
            my={4}
            overflowX="auto"
            display="flex"
            id="category-podcast"
            sx={{
              "&::-webkit-scrollbar": {
                w: 0,
                h: 0,
              },
            }}
          >
            <Button
              color="white"
              bg={
                listCategorySelected.includes("")
                  ? "BluePrimary.900"
                  : "gray.400"
              }
              mr={2}
              rounded="xl"
              minW="100px"
              _hover={{ bg: "BluePrimary.900" }}
              _active={{ bg: "BluePrimary.900" }}
              onClick={() => {
                if (!listCategorySelected.includes("")) {
                  setPodcastState("listCategorySelected", [""]);
                }
              }}
            >
              Semua
            </Button>
            {listCategory.map((item, i) => (
              <Button
                key={i}
                color="white"
                bg={
                  listCategorySelected.includes(item.name)
                    ? "BluePrimary.900"
                    : "gray.400"
                }
                mr={2}
                rounded="xl"
                minW="100px"
                _hover={{ bg: "BluePrimary.900" }}
                _active={{ bg: "BluePrimary.900" }}
                onClick={() => {
                  if (listCategorySelected.includes(item.name)) {
                    let filterCategory: string[];

                    filterCategory = listCategorySelected.filter(
                      (cat) => cat !== item.name,
                    );

                    if (!filterCategory.length) {
                      filterCategory = [""];
                    }
                    setPodcastState("listCategorySelected", filterCategory);
                  } else {
                    setPodcastState("listCategorySelected", [
                      ...listCategorySelected,
                      item.name,
                    ]);
                    if (listCategorySelected.includes("")) {
                      let filterCategory;
                      filterCategory = listCategorySelected.filter(
                        (cat) => cat !== "",
                      );

                      setPodcastState("listCategorySelected", [
                        ...filterCategory,
                        item.name,
                      ]);
                    }
                  }
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        ) : !showFilter ? (
          <Button
            color="white"
            bg="BluePrimary.900"
            my={4}
            rounded="xl"
            minW="100px"
            _hover={{ bg: "BluePrimary.900" }}
            _active={{ bg: "BluePrimary.900" }}
            onClick={() => (window.location.href = "/podcast")}
          >
            Semua Podcast
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default PodcastFilter;
