/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import { Box, Heading } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { ToastContainer } from "react-toastify";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import DashboardController, {
  useDashboardContext,
} from "../../../controller/admin/dashboard";
import ManajemenAlumniSimdiklatController, {
  useManajemenAlumniSimdiklat,
} from "../../../controller/admin/manajemen_alumni_simdiklat";

const WrapperMain: React.FC = () => {
  const { getDashboardRekapitulasi, setPageRow, setPage } =
    useDashboardContext();
  const { dataRekapitulasi, keyword, isLoading, page, rows } =
    useDashboardContext().state;
  const { getLogData, kirimDataAlumni } = useManajemenAlumniSimdiklat();
  const {
    logData,
    isLoadingLog,
    isLoading: isLoadingKirim,
  } = useManajemenAlumniSimdiklat().state;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [perPage, setPerPage] = useState(10);
  const [perPageTTE, setPerPageTTE] = useState(10);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => (page - 1) * rows + (index + 1),
      grow: 0,
    },
    {
      name: "Nama Pelatihan",
      selector: (row) => row.judul_pelatihan,
      wrap: true,
    },
    {
      name: "Waktu Pelaksanaan",
      selector: (row) => {
        return (
          <>
            <p>
              {moment(row.waktu_mulai).format("DD-MM-YYYY")} sd <br />{" "}
              {moment(row.waktu_selesai).format("DD-MM-YYYY")}
            </p>
          </>
        );
      },
      wrap: true,
    },
    {
      name: "Jumlah Peserta Lulus",
      cell: (row) => {
        const data = row.predikat.filter((v) => v.variable.includes("Lulus,"));

        return (
          <>
            <table>
              {data.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.variable}</td>
                    <td>:</td>
                    <td>{val.value}</td>
                  </tr>
                );
              })}
            </table>
          </>
        );
      },
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            {/* Edit Button */}
            <button
              className="ml-2 px-2 py-2 text-xs font-semibold text-green-800 bg-green-100 hover:bg-green-200 rounded-lg mb-2 mt-2"
              onClick={() => {
                kirimDataAlumni(row.id);
              }}
            >
              {isLoadingKirim ? (
                <svg
                  role="status"
                  className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Kirim Data"
              )}
            </button>
          </div>
        );
      },
      wrap: true,
    },
  ];

  const columnsLog = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Nama Pelatihan",
      selector: (row) => row.judul_pelatihan,
      wrap: true,
    },
    {
      name: "Waktu Kirim Data",

      wrap: true,
      selector: (row) => {
        return (
          <>
            <p>{moment(row.created_at).format("DD-MM-YYYY HH:mm")}</p>
          </>
        );
      },
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    {
      name: "Di Proses",
      selector: (row) => row.diproses,
    },
    {
      name: "Berhasil",
      selector: (row) => row.berhasil,
    },
    {
      name: "Gagal",
      selector: (row) => row.gagal,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row.diproses < row.total !=
              moment(row.updated_at)
                .startOf("day")
                .fromNow()
                .includes("hari") &&
            moment(row.updated_at).startOf("day").fromNow().includes("jam") ? (
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Sedang Dalam Proses
              </span>
            ) : row.total == row.berhasil ? (
              <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Selesai
              </span>
            ) : row.gagal == row.diproses ? (
              <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Gagal
              </span>
            ) : row.diproses != row.berhasil && row.berhasil > 0 ? (
              <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Terdapat ({row.gagal}) Data Gagal Di Kirim
              </span>
            ) : (
              moment(row.updated_at)
                .startOf("day")
                .fromNow()
                .includes("hari") && (
                <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Job Tidak Terupdate Selama{" "}
                  {moment(row.updated_at).startOf("day").fromNow()}
                </span>
              )
            )}
          </>
        );
      },
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getLogData(1, 10);
  }, []);

  useEffect(() => {
    getDashboardRekapitulasi(keyword, "", true);
  }, [page, rows]);

  // handle page change data pelatihan
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  // handle page change data log tte
  const handlePageChangeTTE = (page) => {
    getLogData(page, perPageTTE);
  };

  const handlePerRowsChangeTTE = async (newPerPageTTE, page) => {
    getLogData(page, newPerPageTTE);
    setPerPageTTE(newPerPageTTE);
  };

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"blue"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  return (
    <SidebarWithHeader title="Manajemen Alumni Pelatihan Simdiklat">
      <Box className="app">
        <Box as="main" style={{ padding: "0px" }}>
          <Box className="container">
            {/* Table Data Pelatihan */}
            <Box bg="white" p={5} borderRadius="lg" mb={5} shadow="lg">
              <Heading as="h4" fontSize="xl" fontWeight="semibold" mb={5}>
                Data Pelatihan
              </Heading>
              <DataTable
                columns={columns}
                data={dataRekapitulasi}
                progressPending={isLoading}
                pagination
                paginationServer
                paginationTotalRows={
                  dataRekapitulasi.length > 0
                    ? dataRekapitulasi[0].jumlah_data
                    : 0
                }
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
                progressComponent={<CustomLoader />}
              />
            </Box>

            {/* Table Log */}
            <Box
              bg="white"
              p={5}
              borderRadius="lg"
              mb={5}
              shadow="lg"
              className="bg-white px-5 py-5 rounded-lg mb-5 shadow-lg"
            >
              <Heading as="h4" fontSize="xl" fontWeight="semibold" mb={5}>
                Log Data
              </Heading>
              <DataTable
                columns={columnsLog}
                data={logData}
                progressPending={isLoadingLog}
                pagination
                paginationServer
                paginationTotalRows={
                  logData.length > 0 ? logData[0].jumlah_data : 0
                }
                onChangeRowsPerPage={handlePerRowsChangeTTE}
                onChangePage={handlePageChangeTTE}
                customStyles={customStyles}
                progressComponent={<CustomLoader />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const ManajemenAlumniSimdiklat: React.FC = () => {
  return (
    <DashboardController.Provider>
      <ManajemenAlumniSimdiklatController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <WrapperMain />
      </ManajemenAlumniSimdiklatController.Provider>
    </DashboardController.Provider>
  );
};

export default ManajemenAlumniSimdiklat;
